<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ data.dialogTitle }}</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div mat-dialog-content>
    <ng-container>
      <div class="row">
        <div class="col-sm-12">
          <mat-label>Report Name</mat-label>
          <div class="field-value">{{ data.report.config.name }}</div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-label>Generated on</mat-label>
          <div class="field-value">{{ data.report.runDate | date:'medium' }}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close oiq-loading [spinner]="false">Cancel</button>
    <div class="body body-padding">
      <div class="report-link">
        <a href="#" mat-raised-button color="primary" class="btn-wide" (click)="download()">DOWNLOAD</a>
      </div>
    </div>
  </div>

</div>