import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MoneyThumbService extends AbstractService {
  private _ocrAutomation: Subject<any> = new Subject<any>();
  public ocrAutomation$ = this._ocrAutomation.asObservable();

  private _ocrLoadingFinished: Subject<boolean> = new Subject<boolean>();
  public ocrLoading$ = this._ocrLoadingFinished.asObservable();

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'moneythumb/';
  }

  createInsights(data: any) {
    return this.http.post(this.getUrl() + `pdf-insights`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getInsights(applicationId: number) {
    return this.http.get(this.getUrl() + `pdf-insights/${applicationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getReportUrl(applicationId: number) {
    return this.http.get(this.getUrl() + `pdf-insights/${applicationId}/web`)
      .pipe(map(res => {
        return res;
      }));
  }

  getScoreCard(applicationId: number) {
    return this.http.get(this.getUrl() + `pdf-insights/${applicationId}/scorecard`)
    .pipe(map(res => {
      return res;
    }));
  }

  getMetrics(tenantId?: number, startDate?: string, endDate?: string) {
    const params = {};

    if (tenantId) { params['tenantId'] = tenantId.toString(); }
    if (startDate) { params['startDate'] = startDate.toString(); }
    if (endDate) { params['endDate'] = endDate.toString(); }

    return this.http.get(this.getUrl() + `pdf-insights/metrics`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getConversions(tenantId?: number, startDate?: string, endDate?: string, userIds?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (tenantId) { params['tenantId'] = tenantId.toString(); }
    if (startDate) { params['startDate'] = startDate.toString(); }
    if (endDate) { params['endDate'] = endDate.toString(); }
    if (userIds) { params['userIds'] = userIds.toString(); }

    return this.http.get(this.getUrl() + `pdf-insights/conversions`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getRemaining(tenantId?: number) {
    return this.http.get(this.getUrl() + `pdf-insights/remaining${tenantId ? '?tenantId=' + tenantId : ''}`)
    .pipe(map(res => {
      return res;
    }));
  }

  triggerOcrAutomation(data) {
    this._ocrAutomation.next(data);
  }

  loadingFinished(data) {
    this._ocrLoadingFinished.next(data);
  }
}
