<div class="auth-container">

  <div class="pattern">
    <div class="onyx-text"></div>
  </div>

  <div class="auth-content">
    <div>
      <div class="reset-box" *ngIf="!resetVerified">
        <h1>Forgot Your Password?</h1>

        <p *ngIf="!resetRequestSuccess">
          Enter your email address below and we'll get you back on track.
        </p>

        <p *ngIf="resetRequestSuccess">
          A link to reset your password has been sent to you. <br>
          Didn't get it? Check the Junk mail box before requesting again.
        </p>

        <form id="login-form" [formGroup]="form" (ngSubmit)="requestResetLink()" *ngIf="!resetRequestSuccess">
          <div class="form-group">
            <mat-form-field appearance="outline">
              <input matInput formControlName="email" type="email" [email]="true" placeholder="Email" required>
            </mat-form-field>
          </div>

          <button mat-raised-button color="red" type="submit" class="btn-block submit"
            oiq-loading [buttonDisabled]="!form.valid">
            REQUEST RESET LINK
          </button>

          <div *ngIf="recordNotFound" class="error-text login-error">
             No user associated with email.
          </div>

          <a href="#" routerLink="/login">Back to Login</a>
        </form>
      </div>

      <div class="reset-box" *ngIf="resetVerified">
        <div *ngIf="!passResetSuccess">
          <h1>New Password</h1>

          <form id="password-form" [formGroup]="passForm" (ngSubmit)="newPassSubmit()">
            <div class="form-group">
              <mat-form-field appearance="outline">
                <input matInput formControlName="password" type="password" placeholder="New Password" required>
              </mat-form-field>
              <div style="max-width: 100%;">
                <oiq-password-strength [passwordToCheck]="passForm.value.password" (passwordStrength)="onPasswordStrengthChanged($event)"></oiq-password-strength>
                <div class="error-text" *ngIf="passForm.value.password.length < minPasswordLength">Password must be at least {{minPasswordLength}} characters long</div>
                <div class="error-text" *ngIf="passForm.value.password.length > maxPasswordLength">Password must be no more than {{maxPasswordLength}} characters in length.</div>
                <span class="error-text" *ngIf="!strongPassword">At least one number, one lowercase character, one upper case and one special character (, $, *, #, etc.) is required.</span>
              </div>
            </div>

            <button mat-raised-button color="red" type="submit" class="btn-block submit"
                    oiq-loading [buttonDisabled]="!passForm.valid || !strongPassword">
              CONFIRM
            </button>
          </form>
        </div>

        <div *ngIf="passResetSuccess">
          <h4 class="text-center">
            You have successfully changed your password. You can now sign in with new one.
          </h4>

          <a href="#" routerLink="/login" mat-raised-button color="red" class="btn-block submit">BACK TO LOGIN</a>
        </div>
      </div>
    </div>
  </div>
</div>
