import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NotificationTemplateService } from '@core/services';

@Component({
  selector: 'oiq-add-template',
  templateUrl: './add-template.component.html',
  styleUrls: ['./add-template.component.scss']
})

export class AddTemplateComponent implements OnInit {
  form: FormGroup;
  loadedTemplate: any;
  templateId: number;
  editMode = false;
  notificationTypes = [
    {'name': 'Email', 'value': 'Email'},
    {'name': 'SMS', 'value': 'SMS'}
  ];

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private notificationTemplateService: NotificationTemplateService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      from: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
      createdDate: [{value: '', disabled: true}, Validators.required]
    });

    this.templateId = this.activatedRoute.snapshot.queryParams['id'];

    if (this.templateId) {
      this.form.disable();

      this.notificationTemplateService.getById(this.templateId)
        .subscribe((r: any) => {
            this.loadedTemplate = r;
            this.form.patchValue(this.loadedTemplate);
          });
    }
  }

  toggleEditTemplate(enableEdit) {
    if (enableEdit) {
      this.editMode = true;
      this.form.enable();
    } else {
      this.editMode = false;
      this.form.disable();
    }
  }

  mapFields(tenant) {
    this.form.controls['name'].setValue(tenant.name);
    this.form.controls['createdDate'].setValue(tenant.createdDate);
    this.form.controls['createdBy'].setValue(tenant.createdBy.firstName);
    for (const value in tenant.address) {
      if (tenant.address.hasOwnProperty(value)) {
        this.form.controls.address['controls'][value] ?
          this.form.controls.address['controls'][value].setValue(tenant.address[value]) :
          // tslint:disable-next-line:no-unused-expression
          '';
      }
    }
  }

  saveTemplate() {
    if (!this.templateId) {
      this.notificationTemplateService.create(this.form.value)
        .subscribe((r: any) => {
          if (r.id) {
            this.templateId = r.id;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                ...this.activatedRoute.snapshot.queryParams,
                id: r.id
              }
            });
            this.form.controls['createdDate'].setValue(r.createdDate);
          }
        },
          (error) => {
            this.editMode = false;
            this.form.disable();
          },
          () => {
            this.editMode = false;
            this.form.disable();
          }
        );
    } else {
      this.notificationTemplateService.update(this.form.value, this.templateId)
        .subscribe((r: any) => {
          if (r.id) {
            this.templateId = r.id;
            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                ...this.activatedRoute.snapshot.queryParams,
                id: r.id
              }
            });
            this.form.controls['createdDate'].setValue(r.createdDate);
          }
        },
          (error) => {
            this.editMode = false;
            this.form.disable();
          },
          () => {
            this.editMode = false;
            this.form.disable();
          }
        );
    }
  }
}
