import { Injectable } from '@angular/core';
import { StateService } from './state-service';
import { Observable } from 'rxjs';
import { ApplicationNotificationsSettings } from '@core/models/application-notifications-settings';


/**
 *
 * This class is used for sharing global state, specificity application notifications settings
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationNotificationsStateService extends StateService<ApplicationNotificationsSettings> {

  applicationNotificationsSettings$: Observable<ApplicationNotificationsSettings> = this.select(state => state);

  constructor() {
    super({
      id: '',
      applicationId: 0,
      notificationSettings: {
        overrideDefaultSettings: null,
        events: [],
        sendEmailNotifications: null,
        sendSmsNotifications: null,
        sendIsoNonFundingEmailNotifications: null,
        sendIsoNonFundingSmsNotifications: null,
        sendIsoFundingEmailNotifications: null,
        sendIsoFundingSmsNotifications: null,
        sendSyndicatorEmailNotifications: null,
        sendSyndicatorSmsNotifications: null,
        failedPaymentNotificationTarget: ""
      }
    });
  }

  setApplicationNotificationsSettingsState(applicationNotificationsSettings: ApplicationNotificationsSettings) {
    this.setState(applicationNotificationsSettings)
  }

  /**
  *  ### Example of setting partial state
  *
  * setEmailApplicationNotificationsSettingsProp(sendEmailNotifications: boolean) {
  *   let state = this.state;
  *   state.notificationSettings.sendEmailNotifications = sendEmailNotifications
  *   this.setState(state)
  * }
  */

}
