import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentDays'
})
export class PaymentDaysPipe implements PipeTransform {
  transform(paymentDays: any): string {
    return Object.entries(paymentDays)
      .filter(([key, value]) => value === true && key !== 'firstDayOfWeek')
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join('<br>');
  }
}
