import { Component, Input, OnInit } from '@angular/core';
import { AttachmentService } from '@core/services';

@Component({
  selector: 'oiq-open-file-button',
  templateUrl: './open-file-button.component.html',
  styleUrls: ['./open-file-button.component.scss'],
})
export class OpenFileButtonComponent implements OnInit {

  isFileOpenable: boolean;
  allowedExtensionsForFileOpen: Array<string> = ['png', 'jpeg', 'jpg', 'pdf'];

  @Input() data;
  @Input() attachmentId;
  @Input() cssClass = 'btn-table me-2';

  constructor(private attachmentService: AttachmentService) {
  }

  ngOnInit() {
    if (this.data) {
      this.checkIfFileOpenable(this.data);
    } else if(this.attachmentId) {
      this.isFileOpenable = true;
    }
  }

  openFile() {
    return this.attachmentService.openInWindowById(this.attachmentId, true);
  }

  checkIfFileOpenable(item) {
    const extension = item.filename.split('.').pop();
    this.isFileOpenable = this.allowedExtensionsForFileOpen.includes(extension.toLowerCase());
    this.attachmentId = item.id;
  }
}
