import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'roles/';
  }

  getAllRoles() {
    return this.http.get(this.getUrl() + 'user-management')
      .pipe(map(res => {
        return res;
      }));
  }

  addPrivilegeToRole(roleId, privilegeDto) {
    return this.http.post(this.getUrl() + `${roleId}/privileges`, privilegeDto)
      .pipe(map(res => {
        return res;
      }));
  }

  deletePrivilegeFromRole(roleId, privilegeId) {
    return this.http.delete(this.getUrl() + `${roleId}/privileges/${privilegeId}`)
      .pipe(map(res => {
        return res;
      }));
  }

}
