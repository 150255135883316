import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'experianDate'
})
export class ExperianDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
    	return '';
    }

    if (value.length === 8) {
    	const month = value.substring(0, 2);
    	const day = value.substring(2, 4);
    	const year = value.substring(4);

    	return month + '/' + day + '/' + year;
    }

    return value;
  }

}
