import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BankInfo, addressValidator } from '@core/models';
import { BankService } from '@core/services';

import * as moment from 'moment';

@Component({
  selector: 'add-bank-info-modal',
  templateUrl: './add-bank-info-modal.component.html',
  styleUrls: ['./add-bank-info-modal.component.scss']
})
export class AddBankInfoModalComponent implements OnInit {

  editMode: boolean = true;
  bankId: number;
  bankForm: FormGroup;
  accountTypes = [
    { value: 'Checking', name: 'Checking' },
    { value: 'Savings', name: 'Savings' }
  ];

  constructor(
    private bankService: BankService,
    public dialogRef: MatDialogRef<AddBankInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.bankId = this.data.funding.value.bankId;
    this.bankForm = new BankInfo();
    this.bankForm.get('address').setValidators(addressValidator);
    (this.bankForm.controls['address'] as FormGroup).controls.address1.setValidators(Validators.required);

    if (this.data.funding.value.bankId) {
      this.getBank(this.bankId);
    }
  }

  getBank(id: number) {
    this.bankService.getById(id).subscribe((r: any) => {
      this.bankForm.patchValue(r);
      this.bankForm.get('routingNumber').setValue(this.data.funding.value.routingNumber);
      this.bankForm.get('accountNumber').setValue(this.data.funding.value.accountNumber);
      this.bankForm.updateValueAndValidity();
    });
  }

  close() {
    this.dialogRef.close();
  }

  updateBank() {
    const bankInfo = this.bankForm.value;

    if (this.bankId) {
      bankInfo['id'] = null;
      bankInfo['address']['id'] = null;
      this.bankService.update(this.bankId, bankInfo).subscribe((r:any) => {
        this.dialogRef.close(r);
      })
    } else {
      this.bankService.create(bankInfo).subscribe((r:any) => {
        this.dialogRef.close(r);
      })
    }
  }

}
