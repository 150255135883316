import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplateService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'notification-templates/';
  }

  listByCriteria(types?: Array<string>, mediums?: Array<string>, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (types && types.length > 0) { params['types'] = types.join(','); }
    if (mediums && mediums.length > 0) { params['mediums'] = mediums.join(','); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listByTypeAndMedium(type: string, medium: string): Observable<any> {
    return this.http.get(this.getUrl() + `type/${type}/medium/${medium}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listTypes() {
    return this.http.get(this.getUrl() + `types`)
      .pipe(map(res => {
        return res;
      }));
  }

  getMasterEmailTemplate() {
    return this.http.get(this.getUrl() + `master-email-template`, { responseType: 'text' })
      .pipe(map(res => {
        return res;
      }));
  }

  markAsDefault(id: number) {
    return this.http.put(this.getUrl() + `${id}/default`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  enableNotification(id: number) {
    return this.http.put(this.getUrl() + `${id}/enable`, {})
    .pipe(map(res => {
      return res;
    }));
  }

  disableNotification(id: number) {
    return this.http.put(this.getUrl() + `${id}/disable`, {})
    .pipe(map(res => {
      return res;
    }));
  }
}
