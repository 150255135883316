import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unusedFees'
})
export class UnusedFeesPipe implements PipeTransform {

  transform(fees: any[], ...args: any[]): any {
  	return fees.filter(row => {
      return row.selectIndex === undefined || row.selectIndex == args[0];
    });
  }

}
