import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationService } from '@core/services';

@Component({
  selector: 'oiq-select-stipulation-dialog',
  templateUrl: './select-stipulation-dialog.component.html',
  styleUrls: ['./select-stipulation-dialog.component.scss']
})
export class SelectStipulationDialogComponent implements OnInit {

  application: any = {};

  applicationStipulations: Array<any> = [];

  selectedApplicationStipulation: any;

  constructor(
    public dialogRef: MatDialogRef<SelectStipulationDialogComponent>,
    private applicationService: ApplicationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (this.dialogRef.componentInstance.data) {
      this.application = this.dialogRef.componentInstance.data.application;
    }

    this.getApplicationStipulations(this.application.id);
  }

  selectApplicationStipulation() {
    this.dialogRef.close(this.selectedApplicationStipulation);
  }

  getApplicationStipulations(id) {
    this.applicationService.listApplicationStipulations(id)
      .subscribe((r: any) => {
          this.applicationStipulations = r;
        });
  }
}
