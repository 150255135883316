import { FormControl, FormGroup } from '@angular/forms';

export class Attachment extends FormGroup {
  constructor(id?: number, type?: string, filename?: string, size?: number, storageKey?: string, location?: string, source?: string) {
    super({
      id: new FormControl(id),
      type: new FormControl(type),
      filename: new FormControl(filename),
      size: new FormControl(size),
      storageKey: new FormControl(storageKey),
      location: new FormControl(location),
      source: new FormControl(source)
    });
  }
}
