import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { IsoService, ApplicationService, TenantService } from '@core/services';
import { AuthService } from '@core/authentication/auth.service';
import { forkJoin } from 'rxjs';

import { Email } from '@core/models';

@Component({
  selector: 'oiq-application-event-recipient-list',
  templateUrl: './application-event-recipient-list.component.html',
  styleUrls: ['./application-event-recipient-list.component.scss']
})
export class ApplicationEventRecipientListComponent implements OnInit, AfterViewInit {

  @Input()
  application: any;

  @Input()
  event: string;

  @Output() onLoadingDone: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  form: FormGroup;

  tenant: any = {};
  tenantUsers: Array<any> = [];
  isos: Array<any> = [];
  isoUsers: Array<any> = [];
  applicationSettings: any;
  disableAnimation = true;
  tenantEventSettings: any;
  eventSetting: any;
  isoSettings: any;
  tenantSettings: any;
  panelOpenState = false;

  constructor(
    private authService: AuthService,
    private tenantService: TenantService,
    private applicationService: ApplicationService,
    private isoService: IsoService
  ) {
  }

  formatter = (x: {name: string}) => x.name;

  ngAfterViewInit(): void {
    setTimeout(() => this.disableAnimation = false);
  }

  ngOnInit() {
    this.getNotificationSettings();

    if (this.application) {
      this.application.iso && this.getIsoUsers(this.application.iso.id);
    }

    this.getTenant();
    this.getTenantUsers();
  }

  addEmailAddress(type: string) {
    let emailAddresses = this.form.get(type) as FormArray;

    const email = new Email();

    emailAddresses.push(email);
  }

  removeEmailAddress(index: number, type: string) {
    let emailAddresses = this.form.get(type) as FormArray;
    emailAddresses.removeAt(index);

    this.form.markAsDirty();
  }

  getNotificationSettings() {
    const observables = [
      this.tenantService.getEvents(this.authService.getUser().tenantId),
      this.tenantService.getSettings(this.authService.getUser().tenantId)
    ];

    if (this.application) {
      if (this.application.id) {
        observables.push(this.applicationService.getSettings(this.application.id));
      }

      if (this.application.isoId && this.application.isoId > 0) {
        observables.push(this.isoService.getSettings(this.application.isoId));
      }
    }

    forkJoin(observables).subscribe((data) => {
      this.tenantEventSettings = data[0];
      this.tenantSettings = data[1];

      if (data.length > 2) {
        this.applicationSettings = data[2];
      }

      if (this.application.id && data.length > 3) {
        this.isoSettings = data[3];
      } else if (!this.application.id && data.length > 2) {
        this.isoSettings = data[2];
      }

      let isoEmailAddresses;
      let tenantEmailAddresses;

      if (this.isoSettings && this.isoSettings.emailAddressRecipients) {
        isoEmailAddresses = this.isoSettings.emailAddressRecipients;
      }

      if (this.tenantSettings && this.tenantSettings.emailAddressRecipients) {
        tenantEmailAddresses = this.tenantSettings.emailAddressRecipients;
      }

      if (this.applicationSettings && this.applicationSettings.notificationSettings && this.applicationSettings.notificationSettings.overrideDefaultSettings) {
        this.populateNotificationSettings(this.applicationSettings.notificationSettings, isoEmailAddresses, tenantEmailAddresses);
      } else {
        this.populateNotificationSettings(this.tenantEventSettings, isoEmailAddresses, tenantEmailAddresses);
      }
    });
  }

  populateNotificationSettings(notificationSettings, isoEmailAddresses, tenantEmailAddresses) {
    const event = notificationSettings.events.filter((row) => row.event === this.event)[0];

    if (event && event.applicationSubmitter && event.applicationSubmitter.enabled) {
      this.form.get('applicationSubmitter').setValue(true);
    }

    if (event && event.underwriter && event.underwriter.enabled) {
      this.form.get('underwriter').setValue(true);
    }

    if (event && event.isoManager && event.isoManager.enabled) {
      this.form.get('isoManager').setValue(true);
    }

    if (event && event.isoSalesRepresentative && event.isoSalesRepresentative.enabled) {
      this.form.get('isoSalesRepresentative').setValue(true);
    }

    if (event && event.tenantUsers) {
      event.tenantUsers.targetValues = event.tenantUsers.targetValues.map((row) => parseInt(row));

      this.form.get('tenantUserIdRecipients').patchValue(event.tenantUsers.targetValues);
    }

    if (event && event.isoUsers) {
      event.isoUsers.targetValues = event.isoUsers.targetValues.map((row) => parseInt(row));

      this.form.get('isoUserIdRecipients').patchValue(event.isoUsers.targetValues);
    }

    const emailAddressRecipientsFormArray = <FormArray>this.form.get('emailAddressRecipients');

    if (event && event.tenantEmailAddresses && event.tenantEmailAddresses.targetValues.length > 0) {
      event.tenantEmailAddresses.targetValues.forEach((row) => {
        const email = new Email();

        email.patchValue({ email: row });

        emailAddressRecipientsFormArray.push(email);
      });
    } else if (event && tenantEmailAddresses) {
      tenantEmailAddresses.forEach((row) => {
        const email = new Email();

        email.patchValue({ email: row });

        emailAddressRecipientsFormArray.push(email);
      });
    }

    const isoEmailAddressRecipientsFormArray = <FormArray>this.form.get('isoEmailAddressRecipients');

    if (event && event.isoEmailAddresses && event.isoEmailAddresses.targetValues.length > 0) {
      event.isoEmailAddresses.targetValues.forEach((row) => {
        const email = new Email();

        email.patchValue({ email: row });

        isoEmailAddressRecipientsFormArray.push(email);
      });
    } else if (event && isoEmailAddresses) {
      isoEmailAddresses.forEach((row) => {
        const email = new Email();

        email.patchValue({ email: row });

        isoEmailAddressRecipientsFormArray.push(email);
      });
    }

    this.onLoadingDone.emit(true);
  }

  getTenant() {
    this.tenantService.getById(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
          this.tenant = r;
        });
  }

  getTenantUsers() {
    this.tenantService.getTenantUsersByIdMinimal(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
          this.tenantUsers = r;
        });
  }

  getIsoUsers(isoId) {
    this.isoService.listUsersMinimal(isoId)
      .subscribe((r: any) => {

        this.isoUsers = r.content;
      });
  }
}
