import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { ClientService, IsoService, SyndicatorService, TenantService, BankService } from "@core/services";
import { BankInfo } from '@core/models';

@Component({
  selector: 'oiq-bank-selection',
  templateUrl: './bank-selection.component.html',
  styleUrls: ['./bank-selection.component.scss']
})
export class BankSelectionComponent implements OnInit, OnChanges {

  @Input() type: string;
  @Input() id: number;
  @Input() fundingBankId: number;
  @Input() paymentBankId: number;
  @Input() singleBank: boolean;
  @Input() fundingBankVisible: boolean = true;
  @Input() paymentBankVisible: boolean = true;
  @Input() fundingBankDisabled: boolean;
  @Input() paymentBankDisabled: boolean;
  @Input() layout: string;
  @Input() disabled: boolean;
  @Input() fundingBankLabel: string = 'Funding';
  @Input() repaymentBankRequired: boolean = true;

  @Output() onFundingBankSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPaymentBankSelected: EventEmitter<any> = new EventEmitter<any>();

  accountTypes = [
    { value: 'Checking', name: 'Checking' },
    { value: 'Savings', name: 'Savings' }
  ];
  banks = [];

  showFundingBankSelection = false;
  showPaymentBankSelection = false;

  // bank: any;
  fundingBank: any;
  paymentBank: any;

  newBank = {
    id: null,
    name: '',
    branch: '',
    accountNumber: '',
    routingNumber: '',
    dda: '',
    accountType: '',
    clientId: null,
    isoId: null,
    syndicatorId: null,
    tenantId: null
  };

  // banksWithMissingInfo: boolean = false;
  fundingBankWithMissingInfo: boolean = false;
  paymentBankWithMissingInfo: boolean = false;
  showFundingBankDeletedText: boolean;
  showPaymentBankDeletedText: boolean;

  constructor(
    private clientService: ClientService,
    private isoService: IsoService,
    private syndicatorService: SyndicatorService,
    private tenantService: TenantService,
    private bankService: BankService) {
  }

  ngOnInit() {
    this.getBanks();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  bankSelectionChanged(type, event) {
    if (type === 'f' && this.fundingBankId === -1) {
      this.paymentBankId = null;
    } else if (type === 'p' && this.paymentBankId === -1) {
      this.fundingBankId = null;
    }

    if (type === 'f') {
      if (event.value === null || event.value === -1) {
        this.onFundingBankSelected.emit(null);
        this.showFundingBankSelection = true;
        this.showPaymentBankSelection = false;
      } else {
        const fundingBank = this.getBankInfo(event.value);

        if(fundingBank && !this.getIsMissingAccountOrRoutingNumberOrAccountType(fundingBank)) {
          this.onFundingBankSelected.emit(event.value);
          this.fundingBankWithMissingInfo = false;
          this.showFundingBankDeletedText = false;
        } else if(fundingBank && fundingBank.id !== -1) {
          this.onFundingBankSelected.emit(null);
          this.fundingBankWithMissingInfo = true;
          this.fundingBank = fundingBank;
          fundingBank.accountType = fundingBank.accountType === 'Unknown' ? undefined : fundingBank.accountType;
          this.fundingBank.form = this.setBankInfoForm(fundingBank);
        }
      }
      this.showFundingBankSelection = false;
    } else if (type === 'p') {
      if (event.value === null || event.value === -1) {
        this.onPaymentBankSelected.emit(null);
      } else {
         const paymentBank = this.getBankInfo(event.value);

        if(paymentBank && !this.getIsMissingAccountOrRoutingNumberOrAccountType(paymentBank)) {
          this.onPaymentBankSelected.emit(event.value);
          this.paymentBankWithMissingInfo = false;
          this.showPaymentBankDeletedText = false;
        } else if(paymentBank && paymentBank.id !== -1) {
          this.onPaymentBankSelected.emit(null);
          this.paymentBankWithMissingInfo = true;
          this.paymentBank = paymentBank;
          paymentBank.accountType = paymentBank.accountType === 'Unknown' ? undefined : paymentBank.accountType;
          this.paymentBank.form = this.setBankInfoForm(paymentBank);
        }
      }
      this.showPaymentBankSelection = false;
    }
  }

  getBankInfo(id) {
    return this.banks.filter(bank => bank.id === id)[0];
  };

  toggleFundingBank() {
    this.showFundingBankSelection = true;
  }

  togglePaymentBank() {
    this.showPaymentBankSelection = true;
  }

  cancelAddBank() {
    this.fundingBankId = null;
    this.paymentBankId = null;
    this.newBank = {
      id: null,
      name: '',
      branch: '',
      accountNumber: '',
      routingNumber: '',
      accountType: '',
      dda: '',
      clientId: null,
      isoId: null,
      syndicatorId: null,
      tenantId: null
    };
  }

  saveBank() {
    this.newBank[this.type + 'Id'] = + this.id;
    delete this.newBank.id;

    if (this.type === 'client') {
      this.newBank.clientId = this.id;
    } else if (this.type === 'iso') {
      this.newBank.isoId = this.id;
    } else if (this.type === 'syndicator') {
      this.newBank.syndicatorId = this.id;
    } else if (this.type === 'tenant') {
      this.newBank.tenantId = this.id;
    }

    this.bankService.create(this.newBank)
      .subscribe((r: any) => {
        this.newBank = r;
        if (this.paymentBankId === -1) {
          this.paymentBankId = this.newBank.id;

          this.onPaymentBankSelected.emit(this.newBank.id);
        } else if (this.fundingBankId === -1) {
          this.fundingBankId = this.newBank.id;

          this.onFundingBankSelected.emit(this.newBank.id);
        }

        this.newBank = {
          id: null,
          name: '',
          branch: '',
          accountNumber: '',
          routingNumber: '',
          accountType: '',
          dda: '',
          clientId: null,
          isoId: null,
          syndicatorId: null,
          tenantId: null
        };
        this.showFundingBankDeletedText = false;
        this.showPaymentBankDeletedText = false;
      },
        null,
        () => {
          this.getBanks();
        }
      );
  }

  updateBank(type, bank) {
    const bankInfo = bank.form.value;
    if (bank.form.valid && bank.id == bank.form.value.id) {
      bankInfo['bankStatements'] = bank.bankStatements;
      this.bankService.update(bank.id, bankInfo).subscribe((r: any) => {
        this.getBanks();
        if(type === 'f') {
          this.onFundingBankSelected.emit(bank.id);
          this.fundingBankWithMissingInfo = false;
        } else if(type === 'p') {
          this.onPaymentBankSelected.emit(bank.id);
          this.paymentBankWithMissingInfo = false;
        }
      });
    };
    this.showFundingBankDeletedText = false;
    this.showPaymentBankDeletedText = false;
  }

  getBanks() {
    let observable;

    if (this.type === 'client') {
      observable = this.clientService.listBanks(this.id);
    } else if (this.type === 'iso') {
      observable = this.isoService.listBanks(this.id);
    } else if (this.type === 'syndicator') {
      observable = this.syndicatorService.listBanks(this.id);
    } else if (this.type === 'tenant') {
      observable = this.tenantService.listBanks(this.id);
    }

    observable
      .subscribe((r: any) => {
        // this.banksWithMissingInfo = r.content.filter((row) => !row.routingNumber || !row.accountNumber).length > 0;
        // this.banks = r.content.filter((row) => row.routingNumber && row.accountNumber);
        this.banks = r.content;

        if (this.fundingBankId) {
          let fundingBank = this.getBankInfo(this.fundingBankId);

          if(fundingBank){
            if(!this.getIsMissingAccountOrRoutingNumberOrAccountType(fundingBank)) {
              this.fundingBankWithMissingInfo = false;
            } else {
              this.fundingBankWithMissingInfo = true;
              this.fundingBank = fundingBank;
              this.fundingBank.form = this.setBankInfoForm(fundingBank);
            }
            this.showFundingBankDeletedText = false;
          } else {
            this.showFundingBankDeletedText = true;
            this.showFundingBankSelection = true;
            this.onFundingBankSelected.emit(undefined);
          }
        }

        if (this.paymentBankId) {
          let paymentBank = this.getBankInfo(this.paymentBankId);

          if(paymentBank){
            if(!this.getIsMissingAccountOrRoutingNumberOrAccountType(paymentBank)) {
              this.paymentBankWithMissingInfo = false;
            } else {
              this.paymentBankWithMissingInfo = true;
              this.paymentBank = paymentBank;
              this.paymentBank.form = this.setBankInfoForm(paymentBank);
            }
            this.showPaymentBankDeletedText = false;
          } else {
            this.showPaymentBankDeletedText = true;
            this.showPaymentBankSelection = true;
            this.onPaymentBankSelected.emit(undefined);
          }
        }
      });

  }

  getIsMissingAccountOrRoutingNumberOrAccountType(bank) {
    return !bank.routingNumber || !bank.accountNumber || bank.accountNumber.includes('x') || bank.accountType === 'Unknown';
  }

  setBankInfoForm(bank) {
    return new BankInfo(
      bank.id,
      bank.name,
      bank.routingNumber,
      bank.accountNumber,
      bank.accountType,
      bank.dda,
      bank.branch
    );
  }
}
