<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Confirm Delete</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    Are you sure you want to delete this?
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button (click)="cancel()" cdkFocusInitial oiq-loading [spinner]="false">Cancel</button>
    <button mat-raised-button color="accent" (click)="confirmDelete()" oiq-loading>
      Ok
    </button>
  </div>
</div>
