import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';

import { ApplicationStipulation } from '@core/models/';

@Component({
  selector: 'add-stipulation-dialog',
  templateUrl: './add-stipulation-dialog.component.html',
  styleUrls: ['./add-stipulation-dialog.component.scss']
})
export class AddStipulationDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddStipulationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.form = new ApplicationStipulation();
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

}
