import { map, switchMap } from 'rxjs/operators';
import { Observable, interval } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'exports/';
  }

  poll(id): Observable<any> {
    return interval(4000).pipe(
      switchMap(() => this.http.get(this.getUrl() + id)), map(res => {
        return res;
      }));
  }

  exportApplications(data: any) {
    const params = {};

    if (data.ids) { params['ids'] = data.ids.toString(); }
    if (data.description) { params['description'] = data.description.toString(); }
    if (data.clientId) { params['clientId'] = data.clientId; }
    if (data.clientName) { params['clientName'] = data.clientName; }
    if (data.phoneNumber) { params['phoneNumber'] = data.phoneNumber; }
    if (data.email) { params['email'] = data.email; }
    if (data.clientLegalName) { params['clientLegalName'] = data.clientLegalName; }
    if (data.whiteLabelId) { params['whiteLabelIds'] = data.whiteLabelId; }  // no need to convert to string since UI handles only one Id not list
    if (data.types) { params['types'] = data.types; }
    if (data.statuses) { params['statuses'] = data.statuses; }
    if (data.fundingStatuses) { params['fundingStatuses'] = data.fundingStatuses; }
    if (data.isoIds && data.isoIds.length > 0) { params['isoIds'] = data.isoIds.join(','); }
    if (data.isoManagerIds && data.isoManagerIds.length > 0) { params['isoManagerIds'] = data.isoManagerIds.join(','); }
    if (data.startDate) { params['startDate'] = data.startDate; }
    if (data.endDate) { params['endDate'] = data.endDate; }
    if (data.showHighPriorityDeals) { params['urgent'] = data.showHighPriorityDeals; }

    if (data.type) { params['type'] = data.type; }
    if (data.output) { params['output'] = data.output; }
    if (data.tenantUserIdRecipients) { params['tenantUserIdRecipients'] = data.tenantUserIdRecipients.join(','); }
    if (data.isoUserIdRecipients) { params['isoUserIdRecipients'] = data.isoUserIdRecipients.join(','); }
    if (data.isoEmailAddressRecipients) { params['isoEmailAddressRecipients'] = data.isoEmailAddressRecipients.join(','); }
    if (data.emailAddressRecipients) { params['emailAddressRecipients'] = data.emailAddressRecipients.join(','); }
    if (data.emailMessage) { params['emailMessage'] = data.emailMessage; }
    if (data.includeType) { params['includeType'] = data.includeType; }
    if (data.includeStatus) { params['includeStatus'] = data.includeStatus; }
    if (data.includeClientName) { params['includeClientName'] = data.includeClientName; }
    if (data.includeLegalClientName) { params['includeLegalClientName'] = data.includeLegalClientName; }
    if (data.includeDateSubmitted) { params['includeDateSubmitted'] = data.includeDateSubmitted; }
    if (data.includeDateApproved) { params['includeDateApproved'] = data.includeDateApproved; }
    if (data.includeApprovedAmount) { params['includeApprovedAmount'] = data.includeApprovedAmount; }
    if (data.includeDateFunded) { params['includeDateFunded'] = data.includeDateFunded; }
    if (data.includeFundingStatus) { params['includeFundingStatus'] = data.includeFundingStatus; }
    if (data.includePaymentFrequency) { params['includePaymentFrequency'] = data.includePaymentFrequency; }
    if (data.includeAchAmount) { params['includeAchAmount'] = data.includeAchAmount; }
    if (data.includePercentagePaidIn) { params['includePercentagePaidIn'] = data.includePercentagePaidIn; }
    if (data.includeBuyRate) { params['includeBuyRate'] = data.includeBuyRate; }
    if (data.includeFactorRate) { params['includeFactorRate'] = data.includeFactorRate; }
    if (data.includePosition) { params['includePosition'] = data.includePosition; }
    if (data.includePayBackAmount) { params['includePayBackAmount'] = data.includePayBackAmount; }
    if (data.includeBalanceWithFees) { params['includeBalanceWithFees'] = data.includeBalanceWithFees; }
    if (data.includeDateDeclined) { params['includeDateDeclined'] = data.includeDateDeclined; }
    if (data.includeDeclineReason) { params['includeDeclineReason'] = data.includeDeclineReason; }
    if (data.includeUnderwriterNotes) { params['includeUnderwriterNotes'] = data.includeUnderwriterNotes; }
    if (data.includeCreatedDate) { params['includeCreatedDate'] = data.includeCreatedDate; }
    if (data.includeStatusChangeDate) { params['includeStatusChangeDate'] = data.includeStatusChangeDate; }

    const paramsStr = Object.keys(params).map((row) => {
      return row + '=' + params[row];
    }).join('&');

    return this.http.get(this.getUrl() + `applications?apiKey=${localStorage.getItem('token')}&${paramsStr}`)
      .pipe(map(res => {
        return res;
      }));
  }

  exportIsos(data: any) {
    const params = {};

    if (data.ids) { params['ids'] = data.ids.toString(); }
    if (data.name) { params['name'] = data.name; }
    if (data.region) { params['region'] = data.region; }
    if (data.activity) { params['activity'] = data.activity; }
    if (data.isoManagerIds && data.isoManagerIds.length > 0) { params['isoManagerIds'] = data.isoManagerIds.join(','); }
    if (data.startDate) { params['startDate'] = data.startDate; }
    if (data.endDate) { params['endDate'] = data.endDate; }

    if (data.type) { params['type'] = data.type; }
    if (data.output) { params['output'] = data.output; }
    if (data.tenantUserIdRecipients) { params['tenantUserIdRecipients'] = data.tenantUserIdRecipients.join(','); }
    if (data.isoUserIdRecipients) { params['isoUserIdRecipients'] = data.isoUserIdRecipients.join(','); }
    if (data.isoEmailAddressRecipients) { params['isoEmailAddressRecipients'] = data.isoEmailAddressRecipients.join(','); }
    if (data.emailAddressRecipients) { params['emailAddressRecipients'] = data.emailAddressRecipients.join(','); }

    const paramsStr = Object.keys(params).map((row) => {
      return row + '=' + params[row];
    }).join('&');

    return this.http.get(this.getUrl() + `isos?apiKey=${localStorage.getItem('token')}&${paramsStr}`)
      .pipe(map(res => {
        return res;
      }));
  }
}
