<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Initiate Decision Logic</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-4">
          <mat-label>Email Target *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="notificationTarget" (selectionChange)="onNotificationTargetChanged()">
              <mat-option *ngFor="let row of ['ISO', 'Client', 'Both']" [value]="row">{{ row }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-label>Initiate for Contact *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="contactId" [ngModelOptions]="{ standalone: true }" (selectionChange)="contactSelected()" required>
              <mat-option *ngFor="let row of contacts" [value]="row.id" [disabled]="!row.email">
                {{ row.firstName }} {{ row.lastName }}<span *ngIf="!row.email"> - No email address</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-4" *ngIf="form.value.notificationTarget !== 'ISO'">
          <mat-label>
            Contact Email <span>*</span></mat-label>
          <div *ngIf="!loadingContact">
            <mat-form-field appearance="outline">
              <input matInput formControlName="emailAddress" [readonly]="loadingContact">
            </mat-form-field>
            <mat-error *ngIf="form.get('emailAddress').errors?.email">
              Please enter valid email
            </mat-error>
          </div>
          <div class="loader" *ngIf="loadingContact">
            <img src="/assets/images/loading-spinner-full.svg">
          </div>
        </div>
      </div>
      <div class="loading-iso-placeholder">
        <div class="loader" *ngIf="loadingIso">
          <img src="/assets/images/loading-spinner-full.svg"> Loading ISO email/s
        </div>
        <div *ngIf="form.value.notificationTarget !== 'Client' && isMissingIsoNotificationEmail && !loadingIso">
          <mat-error>
            Missing ISO notification email/s. <br>
            <a *ngIf="application.iso" class="link text-link" [routerLink]="['isos', application.iso.id, 'settings']" (click)="dialogRef.close()">Update ISO settings</a>
          </mat-error>
        </div>
        <div *ngIf="form.value.notificationTarget !== 'Client' && !isMissingIsoNotificationEmail && !loadingIso">
          <mat-label>ISO Email/s: </mat-label>
          <ul class="list-emails">
            <li *ngFor="let email of iso.emailAddressRecipients">{{ email }}</li>
          </ul>
          <a *ngIf="application.iso" class="link text-link" [routerLink]="['isos', application.iso.id, 'settings']" (click)="dialogRef.close()">Update ISO settings</a>
        </div>
      </div>
      <oiq-bank-selection [type]="'client'" [id]="application.client.id" (onPaymentBankSelected)="onBankSelected($event)" [fundingBankVisible]="false" [paymentBankVisible]="true" *ngIf="application"></oiq-bank-selection>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button cdkFocusInitial color="accent" type="submit" oiq-loading
            [buttonDisabled]="!form.valid || loadingContact || loadingIso || submiting || (form.value.notificationTarget !== 'Client' && isMissingIsoNotificationEmail)" (click)="submit()">
        Submit
      </button>
    </div>
  </form>
</div>
