

  <div class="row">
    <div class="col">
      <div class="loading-records" *ngIf="isLoadingSyndicators">Loading</div>
    </div>
  </div>
  <div class="row" *ngIf="syndicators && syndicators.length && !isEditMode" >
    <div class="col-sm-3">
          <mat-label>Select Syndicators</mat-label>
          <mat-form-field appearance="outline" *ngIf="!loadingSyndicators">
            <mat-select [(ngModel)]="selectedSyndicators" [ngModelOptions]="{standalone: true}" (selectionChange)="onSyndicatorSelected($event)"  multiple>
              <mat-option *ngFor="let syndicator of syndicators" [value]="syndicator">
                {{ syndicator.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>
  </div>
  <table>
      <thead>
      <tr>
    <th>Name</th>
    <th>Principal Syndicated *</th>
    <th>Min % *</th>
    <th>Max % *</th>
    <th>Principal To Be Paid *</th>
    <th>Management Fee Percentage *</th>
    <th>Commission *</th>
    <th>Factor Rate</th>
    <th>Total To Be Paid</th>
    <th>Payback Amount</th>
    <th>Syndication Calculation Logic</th>
    <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody >
      <ng-container *ngFor="let syndicationOfferForm of syndicationOfferForms"  [formGroup]="syndicationOfferForm">
      <tr>
        <td>
          <p class="syndicator-name"> {{ syndicationOfferForm.get('syndicatorName').value }}</p>
        </td>
        <td>
          <mat-form-field appearance="outline">
            <input matInput formControlName="maximumAmountSyndicated" (keyup)="onMaximumAmountSyndicatedChanged(syndicationOfferForm)" type="text"
              autocomplete="off" currencyMask>
          </mat-form-field>
        </td>

        <td>
          <mat-form-field appearance="outline">
            <input matInput formControlName="minimumPercentSyndicated" type="number" autocomplete="off">
          </mat-form-field>
        </td>

        <td>
          <mat-form-field appearance="outline">
            <input matInput formControlName="maximumPercentSyndicated" (input)="onMaximumPercentSyndicatedChanged(syndicationOfferForm)"
              type="number" autocomplete="off">
          </mat-form-field>
        </td>

        <td>
          <mat-form-field appearance="outline">
            <input matInput formControlName="principalToBePaid" [readonly]="true" type="text" autocomplete="off" currencyMask>
          </mat-form-field>
        </td>

        <td>
          <mat-form-field appearance="outline">
            <input matInput formControlName="managementFeePercent" type="number" autocomplete="off">
          </mat-form-field>
        </td>

        <td>
          {{commissionPercent}}
        </td>

        <td>
          {{ factorRate }}
        </td>

        <td>
          {{ syndicationOfferForm.get('maxTotalReceived').value | currency }}
        </td>

        <td>
          {{ syndicationOfferForm.get('maxPayback').value | currency }}
        </td>

        <td class="defaultSyndicationCalculationLogicOptionColumn">
          <mat-form-field appearance="outline">
            <mat-select formControlName="calculationLogic" required
              (selectionChange)="onCalculationLogicChanged($event, syndicationOfferForm)">
              <mat-option *ngFor="let calculationLogicOption of syndicationCalculationLogicOptions"
                [value]="calculationLogicOption.value">
                {{calculationLogicOption.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>

        <td *ngIf="!isEditMode">
          <button type="button" class="delete-btn" title="Remove Syndicator"
            (click)="onRemoveSyndicator(syndicationOfferForm)"></button>
        </td>

      </tr>
      <tr>
        <p class="error-text warn" *ngIf="syndicationOfferForm.get('minimumPercentSyndicated').errors?.min">
          Minimum Percent must be greater than 0
        </p>
        <p class="error-text warn" *ngIf="syndicationOfferForm.get('maximumPercentSyndicated').errors?.max">
          Maximum Percent must be less than 100
        </p>
      </tr>
    </ng-container>
    </tbody>
  </table>

