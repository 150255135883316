// truncate-filename.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFilename'
})
export class TruncateFilenamePipe implements PipeTransform {
  transform(filename: string, maxLength: number = 40): string {
    if (filename.length <= maxLength) {
      return filename;
    } else {
      const parts = filename.split('.');
      const fileExtension = parts[parts.length - 1];
      return filename.slice(0, maxLength) + '...' + fileExtension;
    }
  }
}
