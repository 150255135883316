import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DownloadReportsDialogComponent } from '@shared/download-reports-dialog/download-reports-dialog.component';

@Component({
  selector: 'oiq-select-generated-reports',
  templateUrl: './select-generated-reports.component.html',
  styleUrls: ['./select-generated-reports.component.scss']
})
export class SelectGeneratedReportsComponent {

  @Input() set reports(reports: []) {
    this._reports = reports;
    this.isLoadingReports = false;
  }
  @Output() onSelectClicked = new EventEmitter();

  _reports = [];
  isLoadingReports = false;

  constructor(public dialog: MatDialog) { }


  selectReport(event) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dialogTitle:'Syndication Report',
      report: event.value
    }
    dialogConfig.width = '950px';
    this.dialog.open(DownloadReportsDialogComponent, dialogConfig);
  }

  onSelectClick() {
    this.isLoadingReports = true;
    this.onSelectClicked.emit()
  }

}
