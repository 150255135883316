import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ESignatureTemplateService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'esignaturetemplates/';
  }

  listBrands(tenantId: number, whiteLabelId?: number) {
    return this.http.get(this.getUrl() + `brands?tenantId=${tenantId}`, { params: {whiteLabelId : whiteLabelId ? whiteLabelId.toString() : ''} })
      .pipe(map(res => {
        return res;
      }));
  }

  editTemplate(templateId: number, tenantId: number) {
    return this.http.post(this.getUrl() + `edit-template`, {}, {params: {templateId: templateId, tenantId: tenantId}})
      .pipe(map(res => {
        return res;
      }));
  }

  editWlTemplate(templateId: number, tenantId: number, whiteLabelId?: number) {
    return this.http.post(this.getUrl() + `edit-template`, {}, {params: {templateId: templateId, tenantId: tenantId, whiteLabelId: whiteLabelId ? whiteLabelId.toString() : ''}})
      .pipe(map(res => {
        return res;
      }));
  }

  getEsignatureTemplatesForWhiteLabel(tenantId: number, whiteLabelId: number) {
    return this.http.get(this.getUrl() + 'white-label', { params: {tenantId: tenantId.toString(), whiteLabelId: whiteLabelId.toString()} })
      .pipe(map(res => {
        return res;
      }));
  }
}
