<div id="floating-notes" [ngClass]="{ 'report-page': isReportPage }">
  <button class="btn" id="toggle-notes" (click)="toggleNotes()"></button>

  <div #notesListContainer class="notes-container" [ngClass]="{'popup': showNotes}">
    <div class="notes-header">
      Notes
    </div>

    <div class="notes-body" id="scrollable">
      <span *ngIf="totalPages > currentPage + 1" class="load-more" [ngClass]="{ 'loading': this.loading }"
        (click)="loadMoreNotes()">
        <mat-icon>arrow_downward</mat-icon>Load More<mat-icon>arrow_downward</mat-icon>
      </span>
      <div class="note-item" *ngFor="let note of notes">
        <div class="note-data">
          <div class="note-author">{{ note.createdBy | fullName }}</div>
          <div class="note-date">{{ note.createdDate | date: 'medium' }}</div>
        </div>
        <div class="note-text">{{ note.content }}</div>
      </div>
    </div>

    <div class="notes-footer">
      <textarea type="text" [initiateNote]="initiateNote" (heightChanged)="adjustPadding($event)" placeholder="New note..." txtAutosize [(ngModel)]="content" (keyup.enter)="addNote()" id="note-input"></textarea>
      <button class="btn" [disabled]="!content" (click)="addNote()"></button>
    </div>
  </div>
</div>