<div class="main-container" [ngClass]="{'banner': loggedInAs}">
  <div *ngIf="isLoggedIn$ | async as isLoggedIn">
    <div class="logged-in-as" *ngIf="isMimicUserLoggedIn$ | async as isMimicUserLoggedIn">
      You are logged in as {{ mimicUser?.name }} ({{ mimicUser?.title }}). To exit out of this mode, click LOGOUT.
      <button mat-raised-button color="accent" class="btn-small" (click)="logoutAsUser()">LOGOUT</button>
    </div>

    <oiq-navigation></oiq-navigation>
  </div>
  <router-outlet></router-outlet>
</div>
