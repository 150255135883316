import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[oiqLimitDecimalsForInput]'
})
export class LimitDecimalsForInputDirective {

  @Input() oiqLimitDecimalsForInput = 2;

  constructor(private el: ElementRef, private currentControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = new RegExp(`^-?\\d*\\.?\\d{0,${this.oiqLimitDecimalsForInput}}$`);

    if (!regex.test(inputValue)) {
      event.preventDefault();
      let inputvalueWithCorespodingNumberOfDecimals = this.removeExtraDecimalsFromInput(inputValue, this.oiqLimitDecimalsForInput)
      this.currentControl.control.setValue(parseFloat(inputvalueWithCorespodingNumberOfDecimals).toFixed(this.oiqLimitDecimalsForInput));
    }
  }

  removeExtraDecimalsFromInput(inputString: string, oiqLimitDecimalsForInput) {
    if (inputString) {
      let [integerPart, decimalPart] = inputString.split('.');
      if (decimalPart && decimalPart.length > oiqLimitDecimalsForInput) {
        decimalPart = decimalPart.slice(0, oiqLimitDecimalsForInput);
      }
       return integerPart + (decimalPart ? '.' + decimalPart : '');
    }
  }
}
