import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from './core/authentication/auth.guard';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BorrowingBaseMainComponent } from './borrowing-base/borrowing-base-main.component';
import { BorrowingBaseComponent } from './borrowing-base/view/borrowing-base.component';
import { BBReportsComponent } from './borrowing-base/bb-reports/bb-reports.component';
import { PlaidLinkComponentComponent } from './plaid-link-component/plaid-link-component.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'borrowing-base',
    component: BorrowingBaseMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BorrowingBaseComponent
      },
      {
        path: 'reports',
        component: BBReportsComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'plaid',
    component: PlaidLinkComponentComponent
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent
  },
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full',
  //   canActivate: [AuthGuard]
  // },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
