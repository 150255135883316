import { Directive, HostBinding, Input, HostListener, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { LoaderService } from '@core/services';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[oiq-loading]'
})
export class LoadingButtonDirective implements OnInit, OnDestroy, OnChanges {

  private loaderSubscription: Subscription;
  buttonClicked = false;
  @HostBinding('class.loading') isLoading = false;
  @HostBinding('disabled') isDisabled: boolean;
  @Input() spinner: boolean;
  @Input() buttonDisabled: boolean;
  @HostListener('click', ['$event.target'])
  onClick() {
    this.buttonClicked = true;
  }

  constructor(
    private loaderService: LoaderService
  ) {}

  ngOnInit() {

    this.loaderSubscription = this.loaderService.loader.subscribe((r: boolean) => {
      if (this.buttonClicked === true) {
        this.isLoading = r;
        this.isDisabled = r;
      }
    });
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  ngOnChanges() {
    this.isDisabled = this.buttonDisabled;
  }
}
