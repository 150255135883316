import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Report extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      name: new FormControl('', Validators.required),
      emailAddressRecipients: new FormControl(),
      tenantUserIdRecipients: new FormControl(),
      isoUserIdRecipients: new FormControl(),
      syndicatorUserIdRecipients: new FormControl(),
      lawFirmUserIdRecipients: new FormControl(),
      sendReport: new FormControl(),
      reportType: new FormControl(),
      cron: new Cron()
    });
  }
}

export class Cron extends FormGroup {
  constructor() {
    super({
      daily: new FormControl(false),
      weekly: new FormControl(false),
      monthly: new FormControl(false),
      lastDayOfMonth: new FormControl(),
      days: new Days(),
      daysOfMonth: new FormControl(),
      hours: new FormControl(),
      minute: new FormControl()
    });
  }
}


export class Days extends FormGroup {
  constructor() {
    super({
      monday: new FormControl(false),
      tuesday: new FormControl(false),
      wednesday: new FormControl(false),
      thursday: new FormControl(false),
      friday: new FormControl(false),
      saturday: new FormControl(false),
      sunday: new FormControl(false)
    });
  }
}
