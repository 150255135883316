<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>View Notification Template</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <p>From</p>
          <h4>{{ template.from }}</h4>
        </div>

        <div class="form-group" *ngIf="data.medium === 'Email'">
          <p>Subject</p>
          <h4>{{ template.subject }}</h4>
        </div>

        <div class="form-group">
          <p>Body</p>
          <div [innerHtml]="templateContent"></div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit" (click)="close()">
      Close
    </button>
  </div>
</div>
