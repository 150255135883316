<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>
      {{ data.title ? data.title : 'Confirmation'}}
    </h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          {{ data.message ? data.message : 'Please click Submit to confirm this action.' }}
        </div>
      </div>

      <oiq-application-event-recipient-list [application]="application" [event]="event" [form]="form" (onLoadingDone)="toggleLoading($event)" ></oiq-application-event-recipient-list>
    </div>

    <div mat-dialog-actions>
      <button mat-stroked-button (click)="cancel()" cdkFocusInitial>Cancel</button>
      <button mat-raised-button color="accent" type="submit" (click)="submit()" [disabled]="!submitEnabled">
        Submit
      </button>
    </div>
  </form>
</div>
