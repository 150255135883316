import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '@environments/environment';

@Component({
  selector: 'oiq-upload-attachment',
  templateUrl: './upload-attachment.component.html',
  styleUrls: ['./upload-attachment.component.scss']
})
export class UploadAttachmentComponent implements OnInit {

  @Input()
  type: string;

  @Output() uploaded = new EventEmitter();

  uploadConfig: any;

  constructor() { }

  ngOnInit() {
    this.uploadConfig = {
      url: `${environment.servicesUrl}/attachments/upload?type=${this.type}`,
      maxFilesize: 200,
      timeout: 0,
      createImageThumbnails: false,
      headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
      success: function (file, response) {
        this.removeFile(file);
      }
    };
  }

  onUploadSuccess(event) {
    this.uploaded.emit({
      upload: event[1]
    });
  }

}
