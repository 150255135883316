import { Component, Input, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

import { Email, ApplicationListExport } from '@core/models';
import { ExportService, TenantService, IsoService, SearchService, AttachmentService, ApplicationService } from '@core/services';
import { AuthService } from '@core/authentication/auth.service';

@Component({
  selector: 'oiq-export-applications-dialog',
  templateUrl: './export-applications-dialog.component.html',
  styleUrls: ['./export-applications-dialog.component.scss']
})
export class ExportApplicationsDialogComponent implements OnInit {

  form: ApplicationListExport;
  page: string = 'form';
  filters: any = {};

  tenant: any = {};
  tenantUsers: Array<any> = [];
  isos: Array<any> = [];
  isoUsers: Array<any> = [];
  isoEmailAddresses: Array<any> = [];
  types: Array<any> = [
    { name: 'Standard', value: 'Standard' },
    { name: 'Grouped By Status', value: 'Grouped_By_Status' },
    { name: 'Conversion Rate', value: 'Conversion_Rate' }
  ];

  submitEnabled = false;

  exportGenerated: boolean = false;
  searching = false;
  loadedIso: any;
  selectAllToggle: boolean = false;
  loading: boolean = false;

  interval: any;

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private exportService: ExportService,
    private attachmentService: AttachmentService,
    private tenantService: TenantService,
    private applicationService: ApplicationService,
    private isoService: IsoService,
    private searchService: SearchService,
    public dialogRef: MatDialogRef<ExportApplicationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  cancel(): void {
    this.dialogRef.close();
  }

  toggleLoading(event) {
    if (event) {
      this.submitEnabled = true;
    }
  }

  ngOnInit() {
    this.form = new ApplicationListExport();

    this.filters = this.data.filters;

    let defaultColumns: any = {};

    const statuses = this.filters.statuses;

    if (statuses.indexOf('Application_Approved') !== -1 || statuses.indexOf('Offer_Applied') !== -1 || statuses.indexOf('Funded') !== -1) {
      defaultColumns = Object.assign(defaultColumns, {
        includeDateApproved: true,
        includeApprovedAmount: true,
        includeBuyRate: true,
        includeFactorRate: true,
        includePosition: true,
        includePayBackAmount: true,
        includeBalanceWithFees: true
      });
    }

    if (statuses.indexOf('Funded') !== -1) {
      defaultColumns = Object.assign(defaultColumns, {
        includeDateFunded: true,
        includeFundingStatus: true,
        includePaymentFrequency: true,
        includeAchAmount: true,
        includePercentagePaidIn: true
      });
    }

    if (statuses.indexOf('Declined') !== -1) {
      defaultColumns = Object.assign(defaultColumns, {
        includeDateDeclined: true,
        includeDeclineReason: true
      });
    }

    if (statuses.indexOf('Send_For_Revision') !== -1) {
      defaultColumns = Object.assign(defaultColumns, {
        includeDateDeclined: true,
        includeDeclineReason: true
      });
    }

    this.form.patchValue(defaultColumns);

    this.getTenant();
    this.getTenantUsers();
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

  onOutputChanged(event) {

  }

  onIsoSelected(item) {
    this.getIsoUsers(item.id);
    this.getIsoSettings(item.id);
  }

  formatter = (x: {name: string}) => x.name;

  searchIsos = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.searchService.performIsosTypeahead(term).pipe(
        catchError(() => {
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  );

  addEmailAddress(type: string) {
    let emailAddresses = this.form.get(type) as FormArray;

    const email = new Email();

    emailAddresses.push(email);
  }

  removeEmailAddress(index: number, type: string) {
    let emailAddresses = this.form.get(type) as FormArray;
    emailAddresses.removeAt(index);

    this.form.markAsDirty();
  }

  close() {
    this.dialogRef.close();
  }

  toggleSelectAll() {
    if (this.selectAllToggle) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  selectAll() {
    this.form.patchValue({
      includeStatus: true,
      includeClientName: true,
      includeClientLegalName: true,
      includeDateSubmitted: true,
      includeDateApproved: true,
      includeApprovedAmount: true,
      includeDateFunded: true,
      includeFundingStatus: true,
      includePaymentFrequency: true,
      includeAchAmount: true,
      includePercentagePaidIn: true,
      includeBuyRate: true,
      includeFactorRate: true,
      includePosition: true,
      includePayBackAmount: true,
      includeBalanceWithFees: true,
      includeDateDeclined: true,
      includeDeclineReason: true,
      includeUnderwriterNotes: true,
      includeCreatedDate: true,
      includeStatusChangeDate: true
    });
  }

  deselectAll() {
    this.form.patchValue({
      includeStatus: true,
      includeClientName: true,
      includeClientLegalName: true,
      includeDateSubmitted: true,
      includeDateApproved: false,
      includeApprovedAmount: false,
      includeDateFunded: false,
      includeFundingStatus: false,
      includePaymentFrequency: false,
      includeAchAmount: false,
      includePercentagePaidIn: false,
      includeBuyRate: false,
      includeFactorRate: false,
      includePosition: false,
      includePayBackAmount: false,
      includeBalanceWithFees: false,
      includeDateDeclined: false,
      includeDeclineReason: false,
      includeUnderwriterNotes: false,
      includeCreatedDate: false,
      includeStatusChangeDate: false
    });
  }

  getTenant() {
    this.tenantService.getById(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
          this.tenant = r;
        });
  }

  getTenantUsers() {
    this.tenantService.getTenantUsersByIdMinimal(this.authService.getUser().tenantId)
      .subscribe((r: any) => {
          this.tenantUsers = r;
        });
  }

  getIsoUsers(isoId) {
    this.isoService.listUsersMinimal(isoId).subscribe((r: any) => {
      this.isoUsers = r.content;
    });
  }

  getIsoSettings(isoId) {
    this.isoService.getSettings(isoId).subscribe((r: any) => {
      this.isoEmailAddresses = r.emailAddressRecipients;
    });
  }

  submit() {
    let isoIds;
    let isoManagerIds;

    if (this.filters.selectedIsos) {
      isoIds = this.filters.selectedIsos.map((row) => row.id);
    }

    if (this.filters.selectedIsoManagers) {
      isoManagerIds = this.filters.selectedIsoManagers.map((row) => row);
    }

    const data = this.form.value;

    data.ids = this.filters.ids;
    data.phoneNumber = this.filters.phoneNumber;
    data.email = this.filters.email;
    data.clientName = this.filters.clientName;
    data.clientLegalName = this.filters.clientLegalName;
    data.types = this.filters.types;
    data.whiteLabelId = this.filters.whiteLabelId;
    data.statuses = this.filters.statuses;
    data.fundingStatuses = this.filters.fundingStatuses;
    data.isoIds = isoIds;
    data.isoManagerIds = isoManagerIds;
    data.startDate = this.filters.startDate;
    data.endDate = this.filters.endDate;
    data.showHighPriorityDeals = this.filters.showHighPriorityDeals;

    if (data.emailAddressRecipients && data.emailAddressRecipients.length > 0) {
      data.emailAddressRecipients = data.emailAddressRecipients.map((row) => row.email);
    }

    this.exportService.exportApplications(data).subscribe((r: any) => {
      this.loading = true;

      if (r.config.output === 'Excel') {
        this.pollReport(r.id);
      } else {
        this.page = 'confirmation';
      }
    });
  }

  pollReport(id: number) {
    this.interval = this.exportService.poll(id).subscribe((r: any) => {
      if (r.completed) {
        this.loading = false;
        this.exportGenerated = true;

        this.interval.unsubscribe();

        this.attachmentService.openInWindowById(r.attachmentId);

        this.dialogRef.close();
      }
    }, (error) => {

      this.interval.unsubscribe();
    });
  }
}
