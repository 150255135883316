import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[numberFormat]'
})
export class NumberFormatDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;

    // Replace commas and dots with dots for uniform decimal handling
    let formattedValue = inputValue.replace(/[,.]/g, '.');

    // Ensure the value is non-negative
    if (formattedValue.startsWith('-')) {
      formattedValue = formattedValue.substring(1);
    }

    // Check if the value has actually changed
    if (formattedValue !== inputValue) {
      // Update the input value
      inputElement.value = formattedValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
