import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

import { Address } from './address';

export class Contact extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      title: new FormControl(),
      email: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
      cellNumber: new FormControl(),
      workNumber: new FormControl(),
      faxNumber: new FormControl(),
      homeNumber: new FormControl(),
      // address: new Address(),
      addresses: new FormArray([new Address()]),
      ssn: new FormControl(),
      fico: new FormControl(),
      dob: new FormControl(),
      percent: new FormControl(),
      driversLicense: new FormControl(),
      externalSource: new FormControl()
    });
  }
}

export class BasicContact extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      email: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
      cellNumber: new FormControl()
    });
  }
}

export class QuickContact extends FormGroup {
  constructor() {
    super({
      firstName: new FormControl(),
      lastName: new FormControl()
    });
  }
}

export class ExtendedContact extends FormGroup {
  constructor() {
    super({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      cellNumber: new FormControl(),
      email: new FormControl(),
      ssn: new FormControl(),
      addresses: new FormArray([new Address()])
    });
  }
}
