import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

import { SyndicatorService, ApplicationService, SettingService, FundingService } from '@core/services';

import { SYNDICATIONS_CALCULATION_LOGIC_OPTIONS, SyndicationOffer, SyndicationOffers } from '@core/models';


///
// THIS COMPONENT IS OBSOLETE PLEASE USE AddSyndicationOffersComponent
///


@Component({
  selector: 'oiq-syndication-offer-dialog',
  templateUrl: './syndication-offer-dialog.component.html',
  styleUrls: ['./syndication-offer-dialog.component.scss']
})
export class SyndicationOfferDialogComponent implements OnInit {
  private applicationSubscription: Subscription;
  private fundingSubscription: Subscription;

  application: any
  funding: any

  form: SyndicationOffers;
  tenantFinancialSettings: any = {};
  syndicator: any;
  searching = false;

  fieldUpdating: string;

  syndicators: Array<any> = [];
  syndicatorList = [];
  syndicatorSelection: Array<any>;
  loadingSyndicators: boolean;

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;

  // @ViewChild('instance', { static: false }) instance: NgbTypeahead;
  // focus$ = new Subject<string>();
  // click$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<SyndicationOfferDialogComponent>,
    // private searchService: SearchService,
    private syndicatorService: SyndicatorService,
    private applicationService: ApplicationService,
    private fundingService: FundingService,
    private settingService: SettingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form = new SyndicationOffers();
    this.getSyndicators();

    this.fundingSubscription = this.fundingService.funding.subscribe(funding=> this.funding = funding);

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;

      this.getSettings();
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
    this.fundingSubscription.unsubscribe();
  }

  // searchSyndicators = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     tap(() => this.searching = true),
  //     switchMap(term =>
  //       this.searchService.performSyndicatorsTypeahead(term).pipe(
  //         catchError(() => {
  //           return of([]);
  //         }))
  //     ),
  //     tap(() => this.searching = false)
  //   );

  // formatter = (x: {name: string}) => x.name;

  populateSyndicators(event) {
    event.value.forEach(item => {
      const event = {item: item};
      this.populateSyndicator(event);
    });

    const tableArray = this.form.get('syndicationOffers').value.map(item => item.syndicatorId);
    const selectArray = event.value.map(item => item.id);
    const result = tableArray.length > selectArray.length ? tableArray.filter(item => !selectArray.includes(item)) : '';
    const index = tableArray.indexOf(result[0])
    if (result.length) {
      this.removeSyndicator(index);
    }
  }

  populateSyndicator(event, input?) {
    const item = event.item;

    // console.log(this.application)

    const syndicationOffer = new SyndicationOffer();
    syndicationOffer.addControl('calculationLogic', new FormControl());
    syndicationOffer.addControl('principalToBePaid', new FormControl({value: null, disabled: true}));

    syndicationOffer.patchValue({
      syndicatorId: item.id,
      syndicatorName: item.name,
      commissionPercent: this.application.appliedFundingOffer.commissionPercent,
      calculationLogic: item.syndicator.defaultSyndicationCalculationLogic
    });

    syndicationOffer.get('minimumPercentSyndicated').setValidators([Validators.required, (control: AbstractControl): ValidationErrors => this.validateMinimumPercent(syndicationOffer, control)]);
    syndicationOffer.get('maximumPercentSyndicated').setValidators([Validators.required, (control: AbstractControl): ValidationErrors => this.validateMaximumPercent(syndicationOffer, control)]);

    this.getSyndictorSettings(syndicationOffer, item.id);

    for (let i = 0; i < this.form.get('syndicationOffers')['controls'].length; i++) {
      if (this.form.get('syndicationOffers')['controls'][i].get('syndicatorId').value === item.id) {
        return false;
      }
    }

    syndicationOffer.get('maximumAmountSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'maximumAmountSyndicated') {
        return;
      }

      this.fieldUpdating = 'maximumPercentSyndicated';

      this.onMaximumAmountSyndicatedChange(syndicationOffer);

      this.fieldUpdating = null;
    });

    syndicationOffer.get('maximumPercentSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'maximumPercentSyndicated') {
        return;
      }

      this.fieldUpdating = 'maximumAmountSyndicated';

      this.onMaximumPercentSyndicatedChange(syndicationOffer);

      this.fieldUpdating = null;
    });

    (this.form.get('syndicationOffers') as FormArray).push(syndicationOffer);
  }

  onMaximumPercentSyndicatedChange(row) {
    if (row.get('maximumPercentSyndicated').value !== '') {
      let maximumPercentSyndicated = parseFloat(row.get('maximumPercentSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      row.get('maximumAmountSyndicated').setValue(fundedAmount * (maximumPercentSyndicated / 100));

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(fundedAmount * (maximumPercentSyndicated / 100));
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }


  onMaximumAmountSyndicatedChange(row) {
    if (row.get('maximumAmountSyndicated').value !== '') {
      const maximumAmountSyndicated = parseFloat(row.get('maximumAmountSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      let maximumPercentSyndicated = this.roundPercent((maximumAmountSyndicated / fundedAmount) * 100);
      row.get('maximumPercentSyndicated').setValue(maximumPercentSyndicated);

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(maximumAmountSyndicated);
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  recalculate(row) {
    const paybackAmount = parseFloat(this.application.appliedFundingOffer.paybackAmount);

    if (row.get('principalToBePaid').value !== '' && row.get('commissionPercent').value !== '') {
      const principalToBePaid = parseFloat(row.get('principalToBePaid').value);
      const commissionPercent = parseFloat(row.get('commissionPercent').value);
      const maximumAmountSyndicated = parseFloat(row.get('maximumAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (maximumAmountSyndicated * commissionPercent / 100);
      row.get('maxTotalReceived').setValue(maxTotalReceived);
    }

    if (row.get('maximumPercentSyndicated').value !== '') {
      const maximumPercentSyndicated = parseFloat(row.get('maximumPercentSyndicated').value);
      row.get('maxPayback').setValue(paybackAmount * (maximumPercentSyndicated / 100));
    }
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  validateMinimumPercent(form: FormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const minimumPercentSyndicated = parseFloat(control.value);

    if (isNaN(minimumPercentSyndicated)) {
      const result = <ValidationErrors> {
        minimumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (minimumPercentSyndicated < 0) {
      const result = <ValidationErrors> {
        minimumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    const maximumPercentSyndicated = parseFloat(form.get('maximumPercentSyndicated').value);

    if (!isNaN(maximumPercentSyndicated)) {
      if (minimumPercentSyndicated > maximumPercentSyndicated) {
        const result = <ValidationErrors> {
          minimumPercentSyndicatedTooLarge: true
        };

        return result;
      }
    }

    return null;
  }

  validateMaximumPercent(form: FormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const maximumPercentSyndicated = parseFloat(control.value);

    if (isNaN(maximumPercentSyndicated)) {
      const result = <ValidationErrors> {
        maximumPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (maximumPercentSyndicated > 100) {
      const result = <ValidationErrors> {
        maximumPercentSyndicatedTooLarge: true
      };

      return result;
    }

    form.get('minimumPercentSyndicated').updateValueAndValidity();

    return null;
  }

  getSettings() {
    this.settingService.getTenantFinancialSettings()
      .subscribe((r: any) => {
        this.tenantFinancialSettings = r;
      });
  }

  getSyndictorSettings(row, id) {
    this.syndicatorService.getSettings(id)
      .subscribe((r: any) => {
        row.patchValue({ managementFeePercent: r.defaultManagementFeePercent });
      });
  }

  removeSyndicator(i) {
    // remove syndicator from dropdown
    const syndicatorId = this.form.get('syndicationOffers')['controls'][i].get('syndicatorId').value;
    const newSelection = this.syndicatorSelection.filter(item => item.syndicatorId !== syndicatorId);
    this.syndicatorSelection = newSelection;

    (this.form.get('syndicationOffers') as FormArray).removeAt(i);
  }

  save() {
    const observables = [];

    for (let i = 0; i < this.form.get('syndicationOffers')['controls'].length; i++) {

      let syndicationOfferValue = this.form.get('syndicationOffers')['controls'][i].value
      if(syndicationOfferValue && syndicationOfferValue.syndicator && syndicationOfferValue.syndicator.repaymentPlan ) {
        delete syndicationOfferValue.syndicator.repaymentPlan;
      }
      observables.push(this.applicationService.createAutomaticSyndicationOffer(this.application.id, syndicationOfferValue));
    }

    forkJoin(observables).subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  getSyndicators() {
    this.loadingSyndicators = true;
    this.syndicatorService.list(this.data.tenantId, null, 300, 'name', 'asc')
      .subscribe((r: any) => {
        this.syndicators = r;
        r.content.forEach(item => {
          this.syndicatorList.push({id: item.id, name:item.name, syndicatorId: item.id, tenantId: this.data.tenantId, syndicator: item});
        });
        this.loadingSyndicators = false;
      });
  }


  onSelectedSyndicationCalculationLogic(event, syndicationOfferControl) {
    syndicationOfferControl.get('calculationLogic').setValue(event.value);
    syndicationOfferControl.get('maximumPercentSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    syndicationOfferControl.get('maximumAmountSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    syndicationOfferControl.get('principalToBePaid').setValue(null, {emitEvent: false, onlySelf: true});

    syndicationOfferControl.get('maximumPercentSyndicated').setErrors({'incorrect': true} )
    syndicationOfferControl.get('maximumAmountSyndicated').setErrors({'incorrect': true})
  }
}
