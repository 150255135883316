<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Credit</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div mat-dialog-content class="pt-0">
    <form [formGroup]="form" autocomplete="off">
      <div class="row">
        <div class="form-group col-sm-6">
          <mat-label>Amount *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="amount" type="text" currencyMask required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-label>Credit Date *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="creditDate" placeholder="01 / 01 / 2020" formControlName="creditDate" oiqValidateDate>
            <mat-datepicker-toggle matPrefix [for]="creditDate"></mat-datepicker-toggle>
            <mat-datepicker #creditDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-label>Select Transmission Method *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="transmissionMethod" (selectionChange)="onTransmissionMethodChanged($event)">
              <mat-option *ngFor="let row of transmissionMethodList" [value]="row.value">
                {{ row.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-6" *ngIf="form.value.transmissionMethod === 'ACH'">
          <mat-label>Select Transmission Processor</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="transmissionProcessor">
              <mat-option *ngFor="let row of transmissionProcessors" [value]="row">
                {{ row }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mt-1">
          <oiq-bank-selection [type]="'client'" [id]="funding.client.id" [fundingBankId]="form.value.bankId" (onFundingBankSelected)="onBankSelected($event)" [fundingBankVisible]="form.value.transmissionMethod === 'ACH'" [paymentBankVisible]="false" *ngIf="funding"></oiq-bank-selection>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="cancel()" cdkFocusInitial oiq-loading [spinner]="false">Cancel</button>
    <button mat-raised-button color="accent" [buttonDisabled]="!formValid" (click)="submit()" oiq-loading>
      Submit
    </button>
  </div>
</div>
