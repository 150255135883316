import { Component, OnInit, Input } from '@angular/core';
import { ClientService } from '@core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oiq-funding-notifications-sms',
  templateUrl: './funding-notifications-sms.component.html',
  styleUrls: ['./funding-notifications-sms.component.scss']
})
export class FundingNotificationsSmsComponent implements OnInit {

  @Input() funding: any;

  contacts = [];

  threadId: number;

  messages = [];

  newMessage = '';

  private interval: Subscription;

  selectedContactId: number;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnInit() {
    this.listContacts();
  }

  listContacts() {
    this.clientService.listContacts(this.funding.client.id)
    .subscribe((r: any) => {
     this.contacts = r.content;
    });
  }

  showSmsThread(value) {
    if (this.interval) {
      this.interval.unsubscribe();
    }

    this.interval = this.clientService.pollSmsMessages(this.funding.client.id, value)
    .subscribe((r: any) => {
      if (JSON.stringify(this.messages) === JSON.stringify(r.content) === false) {
        this.messages = r.content;   
      }
      this.getSmsThreads(value);
    },
      (error) => {
        this.interval.unsubscribe();
      });
  }

  getSmsThreads(contactId: number) {
    this.clientService.getSmsThreads(this.funding.client.id, contactId)
    .subscribe((r: any) => {
      if (JSON.stringify(this.threadId) === JSON.stringify(r.id) === false) {
        this.threadId = r.id;
      }
     });
  }

  sendMessage(data: any) {
    this.newMessage = '';
    this.clientService.postSmsMessage(this.funding.client.id, this.threadId, {body: data })
    .subscribe((r: any) => {
      this.messages.push(r);
     });
  }

  ngOnDestroy() {
    if (this.interval) {
      this.interval.unsubscribe();
    }
  }

}
