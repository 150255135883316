<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Add Syndication Offer</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row" [formGroup]="form">
      <div class="col-sm-12">
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Principal Syndicated *</th>
            <th>Min % *</th>
            <th>Max % *</th>
            <th>Principal To Be Paid *</th>
            <th>Management Fee Percentage *</th>
            <th>Commission *</th>
            <th>Factor Rate</th>
            <th>Total To Be Paid</th>
            <th>Payback Amount</th>
            <th>Syndication Calculation Logic</th>
            <th>&nbsp;</th>
          </tr>
          </thead>
          <tbody formArrayName="syndicationOffers">
          <ng-container *ngFor="let item of form.get('syndicationOffers')['controls']; let i = index;" [formGroupName]="i">
            <tr>
              <td class="oneline emphasis">{{ item.controls['syndicatorName'].value }}</td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="maximumAmountSyndicated" type="text" autocomplete="off" currencyMask>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="minimumPercentSyndicated" type="number" autocomplete="off">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="maximumPercentSyndicated" type="number" autocomplete="off">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="principalToBePaid" [readonly]="true" type="text" autocomplete="off" currencyMask>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="managementFeePercent" type="number" autocomplete="off">
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="commissionPercent" type="number" autocomplete="off">
                </mat-form-field>
              </td>
              <td>{{ application?.appliedFundingOffer?.factorRate }}</td>
              <td>{{ item.controls['maxTotalReceived'].value | currency }}</td>
              <td>{{ item.controls['maxPayback'].value | currency }}</td>
              <td class="defaultSyndicationCalculationLogicOptionColumn">
                  <mat-form-field appearance="outline">
                    <mat-select (selectionChange)="onSelectedSyndicationCalculationLogic($event, item)" [value]="item.get('calculationLogic').value">
                      <mat-option *ngFor="let option of syndicationCalculationLogicOptions" [value]="option.value">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </td>
              <td>
                <button type="button" class="delete-btn" title="Remove Syndicator" (click)="removeSyndicator(i)"></button>
              </td>
            </tr>
            <tr>
              <td colspan="12">
                <div class="error-text warn" *ngIf="item.get('minimumPercentSyndicated').errors?.minimumPercentSyndicatedTooSmall">Minimum Percent must be greater than 0</div>
                <div class="error-text warn" *ngIf="item.get('minimumPercentSyndicated').errors?.minimumPercentSyndicatedTooLarge">Minimum Percent must be less than Maximum Percent</div>
                <div class="error-text warn" *ngIf="item.get('maximumPercentSyndicated').errors?.maximumPercentSyndicatedTooLarge">Maximum Percent must be less than 100</div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>

      <!-- <div class="col-sm-3" *ngIf="!data.fundingOnly">
        <div>
          {{data.fundingOnly ? 'true' : 'false'}}
          <mat-form-field appearance="outline" class="select-typeahead">
            <input
                matInput
                required
                type="text"
                placeholder="Start typing to search for Syndicators"
                [ngbTypeahead]="searchSyndicators"
                (selectItem)="populateSyndicator($event, instance)"
                [inputFormatter]="formatter"
                [resultFormatter]="formatter"
                [value]="syndicator?.name"
                [editable]="false"
                #instance="ngbTypeahead"/>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          </mat-form-field>
        </div>
      </div> -->

      <div class="col-sm-3">
          <mat-label>Select Syndicators</mat-label>
          <div class="loading-records" *ngIf="loadingSyndicators">Loading</div>
          <mat-form-field appearance="outline" *ngIf="!loadingSyndicators">
            <mat-select [(ngModel)]="syndicatorSelection" [ngModelOptions]="{standalone: true}" (selectionChange)="populateSyndicators($event)" multiple>
              <mat-option *ngFor="let row of syndicatorList" [value]="row">
                {{ row.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>


    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent"  type="submit" (click)="save()" [buttonDisabled]="!form.valid" oiq-loading cdkFocusInitial>
      Send
    </button>
  </div>
</div>
