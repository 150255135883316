import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelsService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'white-labels/';
  }

  getWhiteLabelSettings(id: number) {
    return this.http.get(this.getUrl() + `settings`, { params: { id: id.toString() } })
      .pipe(map(res => {
        return res;
      }));
  }

  patchWhiteLabelSettings(id: number, data: any) {
    return this.http.patch(this.getUrl() + `settings/${id}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  saveWhiteLabelIntegrations(id: number, data: any) {
    return this.http.put(this.getUrl() + `settings/${id}/integrations`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  postWhiteLabelSettings(id: number, data: any) {
    const body = {
      whiteLabelId: id,
      ...data
    }
    return this.http.post(this.getUrl() + `settings`, body)
      .pipe(map(res => {
        return res;
      }));
  }


  setDefault(id: number) {
    return this.http.put(this.getUrl() + `${id}/default`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  getWhiteLabelsMinimal(tenantId? : number) {
    return tenantId ? 
      this.http.get(this.getUrl() + 'minimal', {params: {tenantId: tenantId}})
      .pipe(map(res => {
        return res;
      })) : 
      this.http.get(this.getUrl() + 'minimal')
        .pipe(map(res => {
          return res;
        }))
  }

}
