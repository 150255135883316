import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'contacts/';
  }

  getContactsByClientId(clientId?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (clientId) { params['clientId'] = clientId.toString(); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getNotes(id: number, category?: string) {
    let url = this.getUrl() + `${id}/notes`;

    if (category) {
      url += '?category=' + category;
    }

    return this.http.get(url)
      .pipe(map(res => {
        return res;
      }));
  }

  saveNote(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/notes`, data)
      .pipe(map(res => {
        return res;
      }));
  }

}
