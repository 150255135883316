import { Directive, OnInit, Input, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Directive({
    selector: '[authorizePrivilege]'
})
export class AuthorizePrivilegeDirective implements OnInit, OnDestroy {

    @Input() authorizePrivilege: Array<string>;

    stop = new Subject();

    isVisible = false;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.authService.returnRolesArrayObs().pipe(
            takeUntil(this.stop)
        ).subscribe(roles => {
            if (!roles) {
                this.viewContainerRef.clear();
            }
            if (roles.some(r => this.authorizePrivilege.includes(r))) {
                if (!this.isVisible) {
                    this.isVisible = true;
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                }
            } else {
                this.isVisible = false;
                this.viewContainerRef.clear();
            }
        });
    }
    ngOnDestroy() {
        this.stop.next();
    }
}
