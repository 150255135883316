import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'documenttemplates/';
  }

  getDocumentTemplatesForWhiteLabel(tenantId: number, whiteLabelId: number) {
    return this.http.get(this.getUrl() + 'white-label', { params: {tenantId: tenantId.toString(), whiteLabelId: whiteLabelId.toString()} })
      .pipe(map(res => {
        return res;
      }));
  }

  openInWindowByType(type: string) {
    window.open(this.updateUrl(this.getUrl()) + `${type}/download?apiKey=${localStorage.getItem('token')}`, '_blank');
  }

  updateUrl(req: string) {
    return environment.servicesUrl + req;
  }

}
