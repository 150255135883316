import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'oiq-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public confirm: boolean,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  cancel(): void {
    this.dialogRef.close();
  }

  confirmDelete() {
    this.loading = true;
    if (Array.isArray(this.data.ids)) {
      const items = [];

      this.data.ids.forEach(id => {
        items.push(this.data.service[this.data.action](id));
      });

      forkJoin(items).subscribe(results => {},
        (error) => {
          console.log('ERROR', error);
        },
        () => {
          this.loading = false;
          this.dialogRef.close('Success');
        }
      );
    } else {
      this.data.service[this.data.action](this.data.ids).subscribe(results => {},
        (error) => {
          console.log('ERROR', error);
        },
        () => {
          this.loading = false;
          this.dialogRef.close('Success');
        }
      );
    }
  }

}
