import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssn'
})
export class SsnPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
    	return '';
    }

    value = value.trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
    	return value;
    }

    let three = value.slice(0, 3);
    let six = value.slice(3);

    if (six) {
      if (six.length > 2) {
      	six = six.slice(0, 2) + '-' + six.slice(2,6);
      }

      return (three + "-" + six).trim();

    } else {
    	return three;
    }
  }

}
