<div class="upload-wrapper">
  <h4>Upload</h4>
  <p>Upload an attachment file (png, jpg and tiff accepted).</p>

  <dropzone
      [config]="uploadConfig"
      [message]="'Drag files to upload'"
      (success)="onUploadSuccess($event)"
  >
  </dropzone>
</div>