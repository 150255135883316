import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BorrowingBaseService } from '@core/services';

@Component({
  selector: 'oiq-exemption-dialog',
  templateUrl: './exemption-dialog.component.html',
  styleUrls: ['./exemption-dialog.component.scss']
})
export class ExemptionDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private bbService: BorrowingBaseService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ExemptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      reason: ['', Validators.required],
      tenantId: [''],
      fundingId: [''],
      exemptionType: ['']
    });
  }

  submit() {
    this.form.get('tenantId').setValue(this.data.tenantId);
    this.form.get('fundingId').setValue(this.data.fundingId);
    this.form.get('exemptionType').setValue(this.data.exemptionType);
    this.bbService.addExemption(this.form.value)
    .subscribe((r: any) => {
      this.dialogRef.close(r);
    });
  }

}
