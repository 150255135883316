import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IndustryPickerDialogComponent } from './industry-picker-dialog/industry-picker-dialog.component';

@Component({
  selector: 'oiq-sic-naics',
  templateUrl: './sic-naics.component.html',
  styleUrls: ['./sic-naics.component.scss']
})
export class SicNaicsComponent implements OnInit {

  @Input() clientId: number;

  @Input() sicCode: number;

  @Input() sicDescription: string;

  @Input() sicLegacy: any;

  @Input() naicsCode: number;

  @Input() naicsDescription: string;

  @Input() industryType: any;

  @Output() onIndustrySave: EventEmitter<any> = new EventEmitter<any>();

  sicId: number;
  naicsId: number;

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  editValues(): void {
    const dialogRef = this.dialog.open(IndustryPickerDialogComponent, {
      width: '80%',
      data: {
        clientId: this.clientId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        this.onIndustrySave.emit({
          sic: result.sic,
          naics: result.naics
        });

        this.sicDescription = result.sic.description;
        this.sicCode = result.sic.code;

        this.naicsDescription = result.naics.description;
        this.naicsCode = result.naics.code;
      }
    });
  }
}
