import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class CrsService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'crs/';
  }

  getConsumerExperianReport(applicationId: number, contactId: number, clientId: number, ssn: string) {
    const data = {
      applicationId: applicationId,
      contactId: contactId,
      clientId: clientId,
      ssn: ssn
    };

    return this.http.post(this.getUrl() + `consumer/experian`, data)
    .pipe(map(res => {
      return res;
    }));
  }

  getReportsList(applicationId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (applicationId) { params['applicationId'] = applicationId; }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getMockFile() {
    return this.http.get('../assets/mock-data/crs-personal-report.json')
    .pipe(map(res => {
      return res;
    }));
  }
}
