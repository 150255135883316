<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>View Notification</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <p>Medium</p>
          <h4>{{ notification.medium }}</h4>
        </div>

        <div class="form-group">
          <p>From</p>
          <h4>{{ notification.from }}</h4>
        </div>

        <div class="form-group">
          <p>To</p>
          <h4>{{ notification.to }}</h4>
        </div>

        <div class="form-group" *ngIf="notification.medium === 'Email'">
          <p>Subject</p>
          <h4>{{ notification.subject }}</h4>
        </div>

        <div class="form-group">
          <p>Body</p>
          <div [innerHtml]="notificationBody"></div>
        </div>
      </div>

      <div class="form-group" *ngIf="attachments?.length > 0">
        <p>Attachments</p>

        <div *ngFor="let row of attachments">
          <a href="#" (click)="downloadFile(row.id)">{{ row.filename }}</a>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" type="submit" (click)="close()">
      Close
    </button>
  </div>
</div>
