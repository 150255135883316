import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaterialDesignService {

  private openPanelSubject = new BehaviorSubject<string>(null);
  public openPanel$ = this.openPanelSubject.asObservable();
  private lastOpenedPanel: string;

  constructor() {}

  public openPanel(id: string): void {
    if (this.lastOpenedPanel) {
      this.closePanel(this.lastOpenedPanel);
    }
    this.openPanelSubject.next(id);
    this.lastOpenedPanel = id; // Update the last opened panel
  }

  public closePanel(id: string): void {
    this.openPanelSubject.next(null); // Set the subject to null to close the panel
    this.lastOpenedPanel = null; // Reset the last opened panel
  }
}
