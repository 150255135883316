import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BorrowingBaseService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'borrowing-base/';
  }

  getData(type: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    params['type'] = type;
    return this.http.get(this.getUrl() + `data`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSummaryData() {
    return this.http.get(this.getUrl() + `summary-table`)
    .pipe(map(res => {
      return res;
    }));
  }

  addExemption(data: any) {
    return this.http.post(this.getUrl() + `exemption`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteExemption(fundingId: number) {
    return this.http.delete(this.getUrl() + `exemption/${fundingId}`)
      .pipe(map(res => {
        return res;
      }));
  }
}
