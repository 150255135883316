import { FormControl, FormGroup, FormArray } from '@angular/forms';

export class WhiteLabel extends FormGroup {
  constructor(name?: string, id?: number) {
    super({
      whiteLabelId: new FormControl(id),
      whiteLabelName: new FormControl(name),
      reasons: new FormArray([])
    });
  }
}

export class Reason extends FormGroup {
  constructor(name?: string) {
    super({
      name: new FormControl(name),
      methods: new FormArray([])
    });
  }
}

export class Method extends FormGroup {
  constructor(name?: string) {
    super({
      name: new FormControl(name),
      processors: new FormArray([])
    });
  }
}

export class Processor extends FormGroup {
  constructor(name?: string) {
    super({
      name: new FormControl(name),
      sameDayEnabled: new FormControl(),
      sameDayCutoffTime: new FormControl(),
      daysToSettlement: new FormControl(),
      accountName: new FormControl(),
      defaultAccount: new FormControl(false),
      fields: new FormGroup({
      })
    })
  }
}
