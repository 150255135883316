import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading:boolean;
  private loaderSubj = new Subject<boolean>();
  loader = this.loaderSubj.asObservable();
  constructor() { }
  startLoader() {
    this.isLoading = true;
    this.loaderSubj.next(true);
  }
  stopLoader() {
    this.isLoading = false;
    this.loaderSubj.next(false);
  }
}
