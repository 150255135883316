import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oiq-borrowing-base-main',
  templateUrl: './borrowing-base-main.component.html'
})
export class BorrowingBaseMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
