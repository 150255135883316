import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { DropZoneFile, FileS3Response } from '@core/models/files';
import { AttachmentService } from '@core/services';
import { environment } from '@environments/environment';
import { DropzoneDirective } from 'ngx-dropzone-wrapper';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'oiq-upload-files-list',
  templateUrl: './upload-files-list.component.html',
  styleUrls: ['./upload-files-list.component.scss'],
})
export class UploadFilesListComponent implements OnInit, AfterViewInit {

  dropzone;
  dropzoneFilesDataSource: MatTableDataSource<DropZoneFile> = new MatTableDataSource([]);
  dropzoneConfiguration;
  dropzoneFilesColumns: Array<string> = ['fileName', 'fileSize', 'status', 'description', 'progress'];
  @ViewChild(DropzoneDirective, { static: false }) directiveRef?: DropzoneDirective;

  filesDataSource = new MatTableDataSource([]);
  pageIndex;
  totalFilesCount;

  selection = new SelectionModel<string>(true, []);
  isFileUploadingView = false;
  filesUploadedOnS3: FileS3Response[] = [];

  @Input() set files (data) {
    this.filesDataSource = new MatTableDataSource(data);
   }
  @Input() uploadMoreDocumentsBtnSide: 'right' | 'left' = 'left';
  @Input() isUploadEnabled = true;
  @Input() isSelectStipulationEnabled = false;
  @Input() isDeleteFileEnabled = true;
  @Input() isMultiSelectEnabled = true;
  @Input() isLoadingRecords = false;
  @Input() uploadUrl = `${environment.servicesUrl}/attachments/upload?type=application`;
  @Input() filesTableColumns: Array<string> = ['fileName', 'stipulation', 'createdBy', 'createdDate', 'actions'];
  @Input() maxFilesToUpload;
  @Input() maxFileSizeInMB = 199;
  @Input() authorizeRoles;
  @Input() pageSize = 25;
  @Input() filterByCategory;
  @Input() isPaginationEnabled = true;
  @Input() isFilesTableShown = true;
  @Input() uploadBtnText = 'UPLOAD MORE DOCUMENTS';
  @Input() pageEvent: PageEvent;
  @Output() paginationChanged = new EventEmitter<PageEvent>();
  @Output() filesUploadSuccess = new EventEmitter<FileS3Response[]>();
  @Output() deleteFile = new EventEmitter<number>();
  @Output() associateAttachmentToStipulation = new EventEmitter();

  constructor(public dialog: MatDialog,
    private attachmentService: AttachmentService) {
  }



  ngOnInit() {
    this.initDropzoneConfiguration();
  }

  ngAfterViewInit(): void {
    if (this.directiveRef) {
      const dropzone = this.directiveRef.dropzone();
      this.dropzone = dropzone;
    }
  }

  initDropzoneConfiguration() {
    const that = this;
    this.dropzoneConfiguration = {
      url: that.uploadUrl,
      //maxFiles: 5,
      maxFilesize: that.maxFileSizeInMB,
      timeout: 0,
      createImageThumbnails: false,
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      previewTemplate: "<div style='display:none'></div>"
    };
  }

  handlePageEvent(e: PageEvent) {
    this.filesDataSource.data = [];
    this.paginationChanged.emit(e);

  }

  onFileAddedToQueue(file: DropZoneFile) {
    this.isFileUploadingView = true;
    this.dropzoneFilesDataSource.data = [...this.dropzone.files];
  }

  onFileSuccess($event) {
    if ($event && $event[0] && $event[1]) {
      const fileS3Response: FileS3Response = $event[1] as FileS3Response;
      this.filesUploadedOnS3.push(fileS3Response);
      this.checkIfUploadQueueIsEmpty();
    }
  }

  onError($event) {
    if ($event && $event[0] && $event[1]) {
      const file: DropZoneFile = $event[0] as DropZoneFile;
      file.upload.progress = 0;
      if ($event[2]) {
        file.description = 'Something happened with network';
      } else {
        file.description = $event[1]
      }
    }
  }

  checkIfUploadQueueIsEmpty() {
    if (!this.isUploadingFiles()) {
        this.filesDataSource.data = [];
        this.filesUploadSuccess.emit(this.filesUploadedOnS3);
        this.isFileUploadingView = false;
        this.dropzone.removeAllFiles(true);
        this.dropzoneFilesDataSource.data = [];
        this.filesUploadedOnS3 = [];
    }
  }

  cancelUpload(file: DropZoneFile) {
    this.dropzone.cancelUpload(file);
    this.dropzone.removeFile(file);
    this.dropzoneFilesDataSource.data = [...this.dropzone.files]
    this.checkIfUploadQueueIsEmpty();
  }

  retryFileUpload(fileFailed: DropZoneFile) {
    this.isFileUploadingView = true;
    fileFailed.description = '';
    fileFailed.status = 'uploading';
    this.dropzone.uploadFile(fileFailed);
    this.dropzoneFilesDataSource.data = [...this.dropzone.files]
  }

  isUploadingFiles() {
    return this.dropzone.getUploadingFiles().length !== 0;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filesDataSource.data.length;
    return numSelected === numRows;
  }

  toggleAll() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.filesDataSource.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  deleteSelectedFiles() {
    console.log('DELETE->', this.selection);
  }

  removeFile(attachmentId) {
     this.deleteFile.emit(attachmentId);
  }

  downloadFile(attachmentId) {
    this.attachmentService.openInWindowById(attachmentId);
  }

  openFile(attachmentId) {
    return this.attachmentService.openInWindowById(attachmentId, true);
  }

  selectStipulationDialog(attachment) {
    this.associateAttachmentToStipulation.emit(attachment);
  }
}
