<div class="container">
	<div class="content">
			<div class="logo">
					<img [src]="imagePath">
			</div>
      <h3 class="my-4">Seamlessly Link Your Financial Accounts Using Plaid Integration.</h3>
      <p>Your online security is our top priority!
        Our Link feature prioritizes your security with robust 256-bit encryption, symbolized by a lock icon.
        Your credentials remain private as our app doesn't access them.</p>
      <div *ngIf="errorMessage" class="mt-2">
        <p class="error-text mb-1">{{ errorMessage }}</p>
        <p class="mt-1">Please contact customer support.</p>
      </div>
			<div class="button-container">
					<button *ngIf="disableButton === false" class="add-bank-button" mat-raised-button mat-primary color="primary" (click)="addBank()">
							ADD A BANK
					</button>
			</div>
	</div>
</div>
