<div class="auth-container">

  <div class="pattern">
    <div class="onyx-text"></div>
  </div>

  <div class="auth-content">
    <div class="content-float">
      <h1>Log in</h1>

      <form *ngIf="!mfaToken" id="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-label>Email</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="userName" type="email" placeholder="Email" autofocus>
          </mat-form-field>
          <span class="error-text" *ngIf="isFieldInvalid('userName')">Please enter your email</span>
        </div>
        <div class="form-group">
          <mat-label>Password</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="password" type="password" placeholder="Password">
          </mat-form-field>
          <span class="error-text field-error" *ngIf="isFieldInvalid('password')">Please enter your password</span>
        </div>

        <button mat-raised-button type="submit" color="red" class="btn-block submit"
                oiq-loading [buttonDisabled]="!form.valid">
          Submit
        </button>

        <div *ngIf="loginError" class="error-text login-error">{{ loginError }}</div>

        <a href="#" routerLink="/resetpassword">Forgot Password</a>
      </form>

      <form *ngIf="mfaToken" id="login-form" [formGroup]="otpForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <mat-label>MFA Code</mat-label>
          <mat-form-field appearance="outline">
            <input class="mfa" matInput formControlName="otp" type="text" autofocus>
          </mat-form-field>

          <input matInput formControlName="token" type="text" hidden>
        </div>

        <button mat-raised-button type="submit" color="red" class="btn-block submit"
                oiq-loading [buttonDisabled]="!form.valid">
          Submit
        </button>

        <div *ngIf="loginError" class="error-text login-error">{{ loginError }}</div>

        <a *ngIf="!mfaToken" href="#" routerLink="/resetpassword">Forgot Password</a>
      </form>
    </div>
  </div>
</div>