<div class="mat-dialog-title-wrapper mt-2">
  <h1 mat-dialog-title class="d-flex align-items-center font-heading">
    <svg class="me-2" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7467 8L16 9.25333L3.89333 21.3333H2.66667V20.1067L14.7467 8ZM19.5467 0C19.2133 0 18.8667 0.133333 18.6133 0.386667L16.1733 2.82667L21.1733 7.82667L23.6133 5.38667C24.1333 4.86667 24.1333 4 23.6133 3.50667L20.4933 0.386667C20.2267 0.12 19.8933 0 19.5467 0ZM14.7467 4.25333L0 19V24H5L19.7467 9.25333L14.7467 4.25333Z" fill="#15427C"/>
    </svg>
    Edit Industry</h1>
  <button mat-button class="close-dialog" mat-dialog-close>
    <i class="material-icons">clear</i>
  </button>
</div>

<hr class="mx-3 my-0">

<div mat-dialog-content>
  <div class="tabs mt-2">
    <div class="heading">
      <h5 class="title" [ngClass]="{'active': activeTable === 'sic'}" (click)="selectTable('sic')">SIC</h5>
      <h5 class="title" [ngClass]="{'active': activeTable === 'naics'}" (click)="selectTable('naics')">NAICS</h5>
    </div>

    <div class="my-5">
      <ng-container *ngIf="activeTable === 'sic'">
        <div class="row">
          <div class="col-sm-12">
            <div class="row search-box">
              <div class="col-sm-10">
                <div class="d-flex gap-2 cursor-pointer">
                  <mat-label [ngClass]="{'text-primary': !isSearchBySicCode}" (click)="setIsSearchByCode(false)">Search SIC Industry</mat-label>
                  <mat-label [ngClass]="{'text-primary': isSearchBySicCode}" (click)="setIsSearchByCode(true)">Search SIC Code</mat-label>
                </div>
                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="sicSearch" type="text" [ngModelOptions]="{ standalone: true }" [placeholder]="sicPlaceholderMessage">
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <button class="button-v2 btn-table mat-raised-button mat-primary" [ngClass]="{'loading': searching }" mat-raised-button color="primary" [disabled]="!sicSearch" (click)="searchSic(sicSearch)">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <table class="table-v2" mat-table [dataSource]="sicSearchResults" *ngIf="sicSearchResults?.length > 0">
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef class="narrow">Code</th>
              <td mat-cell *matCellDef="let row" class="narrow">{{ row.code }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row"> {{ row.description }} </td>
            </ng-container>
            <ng-container matColumnDef="division">
              <th mat-header-cell *matHeaderCellDef class="narrow">Division</th>
              <td mat-cell *matCellDef="let row" class="narrow">{{ row.division }}</td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="type">Type</th>
              <td mat-cell *matCellDef="let row" class="type"> {{ row.type | status }} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="narrow">Actions</th>
              <td mat-cell *matCellDef="let row" class="narrow">
                <button mat-raised-button color="blue" [disabled]="row.selected" class="button-v2 bg-primary-gradient text-white text-sm" [style.max-width.%]="50" (click)="selectIndustry(row)">
                  Select
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [ngClass]="{'selected': row.selected }"></tr>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <mat-label>SIC Division</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': sicDivisionsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(sicDivisions, sicDivision)}}" [(ngModel)]="sicDivision" [ngModelOptions]="{standalone: true}" [disabled]="!sicDivisions.length"
              [placeholder]="sicDivisions.length ? 'Select one' : ''" (selectionChange)="onDivisionSelect($event.value)">
                <mat-option *ngFor="let division of sicDivisions" [value]="division.code">{{ division.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>Major Groups</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': majorGroupsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(majorGroups, majorGroup)}}" [(ngModel)]="majorGroup" [ngModelOptions]="{standalone: true}" [disabled]="!majorGroups.length"
              [placeholder]="majorGroups.length ? 'Select one' : ''" (selectionChange)="onMGSelect($event.value)">
                <mat-option *ngFor="let group of majorGroups" [value]="group.code">{{ group.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>Industry Groups</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': sicIndustryGroupsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(sicIndustryGroups, sicIndustryGroup)}}" [(ngModel)]="sicIndustryGroup" [ngModelOptions]="{standalone: true}" [disabled]="!sicIndustryGroups.length"
              [placeholder]="sicIndustryGroups.length ? 'Select one' : ''" (selectionChange)="onSicIGSelect($event.value)">
                <mat-option *ngFor="let group of sicIndustryGroups" [value]="group.code">{{ group.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>Industries</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': industriesLoading }">
              <mat-select matTooltip="{{getToolTipDEata(industries, industry)}}" [(ngModel)]="industry" [ngModelOptions]="{standalone: true}" [disabled]="!industries.length"
              [placeholder]="industries.length ? 'Select one' : ''" (selectionChange)="onIndustrySelect($event.value)">
                <mat-option *ngFor="let industry of industries" [value]="industry">{{ industry.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-offset-4 col-sm-4">
            <mat-label>NAICS Industry</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': naicsMatchesLoading }">
              <mat-select matTooltip="{{getToolTipDEata(naicsMatches, naics)}}" [(ngModel)]="naics" [ngModelOptions]="{standalone: true}" [disabled]="!naicsMatches.length"
              [placeholder]="naicsMatches.length ? 'Select one' : ''" (selectionChange)="selectNaicsCrossRef($event.value)">
                <mat-option *ngFor="let naics of naicsMatches" [value]="naics">{{ naics.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="activeTable === 'naics'">
        <div class="row">
          <div class="col-sm-12">
            <div class="row search-box">
              <div class="col-sm-10">
                <mat-label>Search NAICS Industry</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput [(ngModel)]="naicsSearch" type="text" [ngModelOptions]="{ standalone: true }">
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <button class="button-v2 btn-table mat-raised-button mat-primary" [ngClass]="{'loading': searching }" mat-raised-button color="primary" [disabled]="!naicsSearch" (click)="searchNaics(naicsSearch)">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <table class="table-v2" mat-table [dataSource]="naicsSearchResults" *ngIf="naicsSearchResults?.length > 0">
            <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef class="narrow">Code</th>
              <td mat-cell *matCellDef="let row" class="narrow">{{ row.code }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let row"> {{ row.description }} </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="type">Type</th>
              <td mat-cell *matCellDef="let row" class="type"> {{ row.type | status }} </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="narrow">Actions</th>
              <td mat-cell *matCellDef="let row" class="narrow">
                <button mat-raised-button color="blue" [disabled]="row.selected" class="button-v2 bg-primary-gradient text-white text-sm" [style.max-width.%]="50" (click)="selectNaicsIndustry(row)">
                  Select
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [ngClass]="{'selected': row.selected }"></tr>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-3">
            <mat-label>NAICS Sector</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': naicsSectorsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(naicsSectors, naicsSector)}}" [(ngModel)]="naicsSector" [ngModelOptions]="{standalone: true}" [disabled]="!naicsSectors.length"
              [placeholder]="naicsSectors.length ? 'Select one' : ''" (selectionChange)="onSectorSelect($event.value)">
                <mat-option *ngFor="let sector of naicsSectors" [value]="sector.code">{{ sector.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">

          <div class="col-sm-3">
            <mat-label>Subsector</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': subSectorsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(subSectors, subSector)}}" [(ngModel)]="subSector" [ngModelOptions]="{standalone: true}" [disabled]="!subSectors.length"
              [placeholder]="subSectors.length ? 'Select one' : ''" (selectionChange)="onSubSectorSelect($event.value)">
                <mat-option *ngFor="let subsector of subSectors" [value]="subsector.code">{{ subsector.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>Industry Groups</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': industryGroupsLoading }">
              <mat-select matTooltip="{{getToolTipDEata(industryGroups, industryGroup)}}" [(ngModel)]="industryGroup" [ngModelOptions]="{standalone: true}" [disabled]="!industryGroups.length"
              [placeholder]="industryGroups.length ? 'Select one' : ''" (selectionChange)="onIGSelect($event.value)">
                <mat-option *ngFor="let group of industryGroups" [value]="group.code">{{ group.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>NAICS Industy</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': naicsIndustriesLoading }">
              <mat-select matTooltip="{{getToolTipDEata(naicsIndustries, naicsIndustry)}}" [(ngModel)]="naicsIndustry" [ngModelOptions]="{standalone: true}" [disabled]="!naicsIndustries.length"
              [placeholder]="naicsIndustries.length ? 'Select one' : ''" (selectionChange)="onNaicsIndustrySelect($event.value)">
                <mat-option *ngFor="let industry of naicsIndustries" [value]="industry.code">{{ industry.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-3">
            <mat-label>National Industry</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': nationalIndustriesLoading }">
              <mat-select matTooltip="{{getToolTipDEata(nationalIndustries, nationalIndustry)}}" [(ngModel)]="nationalIndustry" [ngModelOptions]="{standalone: true}" [disabled]="!nationalIndustries.length"
              [placeholder]="nationalIndustries.length ? 'Select one' : ''" (selectionChange)="onNationalIndustrySelect($event.value)">
                <mat-option *ngFor="let industry of nationalIndustries" [value]="industry">{{ industry.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-offset-4 col-sm-4">
            <mat-label>SIC Industry</mat-label>
            <mat-form-field appearance="outline" [ngClass]="{'loading': sicMatchesLoading }">
              <mat-select matTooltip="{{getToolTipDEata(sicMatches, sic)}}" [(ngModel)]="sic" [ngModelOptions]="{standalone: true}" [disabled]="!sicMatches.length"
              [placeholder]="sicMatches.length ? 'Select one' : ''" (selectionChange)="selectSicCrossRef($event.value)">
                <mat-option *ngFor="let sic of sicMatches" [value]="sic">{{ sic.description }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div mat-dialog-actions class="app-footer bg-white p-3 border-top border-gray mt-auto">
  <div class="w-100 d-md-flex justify-content-between mx-2">
    <button
      color="none"
      class="button-v2 my-2"
      mat-dialog-close>
      Cancel
    </button>

    <button
      mat-raised-button
      color="accent"
      class="button-v2 shadow bg-orange text-white my-2"
      type="submit"
      oiq-loading
      [buttonDisabled]="(activeTable === 'sic' && !sicCode) || (activeTable === 'naics' && !naicsCode)"
      (click)="save()" cdkFocusInitial>
      Save
    </button>
  </div>
</div>
