import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { Address } from './address';

const numberPattern = /^\d+$/;

export class Bank extends FormGroup {
  constructor(id?: number, name?: string, branch?: string, routingNumber?: string, accountNumber?: string, accountType?: string, dda?: string, bankStatements?: [], attachments?: [], completeBankInfo?: boolean, deletable?: boolean) {
    super({
      id: new FormControl(id),
      name: new FormControl(name, Validators.required),
      branch: new FormControl(branch),
      routingNumber: new FormControl(routingNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountNumber: new FormControl(accountNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountType: new FormControl(accountType, Validators.required),
      aba: new FormControl(),
      number: new FormControl(),
      dda: new FormControl(),
      // address: new Address(),
      clientId: new FormControl(),
      applicationId: new FormControl(),
      bankStatements: new FormArray(bankStatements ? bankStatements : []),
      attachments: new FormArray(attachments ? attachments : []),
      completeBankInfo: new FormControl(completeBankInfo),
      deletable: new FormControl(deletable)
    });
  }
}

export class BankInfo extends FormGroup {
  constructor(id?: number, name?: string, routingNumber?: string, accountNumber?: string, accountType?: string, dda?: string, branch?: string, address?: string) {
    super({
      id: new FormControl(id),
      name: new FormControl(name, Validators.required),
      routingNumber: new FormControl(routingNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountNumber: new FormControl(accountNumber, {validators: Validators.compose([Validators.pattern(numberPattern)])}),
      accountType: new FormControl(accountType, Validators.required),
      dda: new FormControl(dda),
      branch: new FormControl(branch),
      address: new Address(),
    });
  }
}

export class BankStatement extends FormGroup {
  constructor(id?: number, month?: string, deposits?: number, endBalance?: number, averageLedgers?: number, negativeDays?: number, nsfs?: number, overdrafts?: number, numberDeposits?: number, transfers?: number, loans?: number, adjustedDeposits?: number, year?: number, positionIds?: []) {
    super({
      id: new FormControl(id),
      month: new FormControl(month, Validators.required),
      year: new FormControl(year, Validators.required),
      deposits: new FormControl(deposits, [Validators.required]),
      endBalance: new FormControl(endBalance, [Validators.required]),
      averageLedgers: new FormControl(averageLedgers, [Validators.required]),
      negativeDays: new FormControl(negativeDays, [Validators.required]),
      nsfs: new FormControl(nsfs, [Validators.required]),
      overdrafts: new FormControl(overdrafts, [Validators.required]),
      numberDeposits: new FormControl(numberDeposits, [Validators.required]),
      transfers: new FormControl(transfers || 0, [Validators.required]),
      loans: new FormControl(loans || 0, [Validators.required]),
      adjustedDeposits: new FormControl(adjustedDeposits, [Validators.required]),
      positionIds: new FormControl(positionIds),
      deleted: new FormControl(false)
    });
  }
}
