<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Add Custom Stipulation</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Name" formControlName="name" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <input matInput placeholder="Notes" formControlName="notes">
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="close()">Cancel</button>
      <button mat-raised-button mat-dialog-close cdkFocusInitial color="accent" type="submit" [disabled]="!form.valid"
              (click)="save()">Save
      </button>
    </div>
  </form>
</div>
