<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ editUser ? 'Edit' : 'Add' }} User</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-5">
          <mat-label>First Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="firstName" required>
          </mat-form-field>
        </div>
        <div class="col-sm-7">
          <mat-label>Last Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="lastName" required>
          </mat-form-field>
        </div>
      </div>

      <mat-label>Email *</mat-label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="email" type="email" autocomplete="nope" required>
      </mat-form-field>

      <ng-container *ngIf="form.value.loginEnabled === true">
        <mat-label>Cell Number</mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="cellNumber" mask="(000)-000-0000" autocomplete="nope">
        </mat-form-field>
      </ng-container>

      <mat-label *ngIf="type === 'tenant'">Roles</mat-label>
      <mat-form-field appearance="outline" *ngIf="type === 'tenant'">
        <mat-select formControlName="roleIds" (selectionChange)="userTypeChange()">
          <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container>
        <mat-label>White Labels</mat-label>
        <mat-form-field appearance="outline" [ngClass]="{'disabled-dropdown': !nonAdminUser || whiteLabels?.content?.length <= 0}">
          <mat-select multiple [formControl]="whiteLabelIdsForm" (selectionChange)="onWhiteLabelSelectionChange($event)">
            <mat-option *ngFor="let whiteLabel of whiteLabels?.content" [value]="whiteLabel.id">{{ whiteLabel.name }}</mat-option>
          </mat-select>
        </mat-form-field>

      </ng-container>
      <!--non admin user is selected and roleIds formControl is touched -->
      <div class="info-text" *ngIf="!nonAdminUser && form.get('roleIds').touched">
        * Admin users already have all White Label privileges, and can't have White Labels assigned.
      </div>

      <div class="info-text" *ngIf="whiteLabels?.content?.length <= 0 && this.type !== 'sales-rep'">
        * No White Labels are selected for {{ type }}, you can add White Labels in the settings option in the subnav menu.
      </div>

      <div class="info-text" *ngIf="whiteLabels?.content?.length <= 0 && this.type === 'sales-rep'">
        * No White Labels are selected for the selected Referring ISO.
      </div>

      <mat-label *ngIf="type === 'tenant'">Resource Access List</mat-label>
      <mat-form-field appearance="outline" *ngIf="type === 'tenant'">
        <mat-select formControlName="resources" multiple>
          <mat-option [value]="row.value" *ngFor="let row of resourceTypes">{{ row.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-radio-group formControlName="loginEnabled" *ngIf="type !== 'tenant' && type !== 'iso' && data.action !== 'newApp' && type !== 'law firm'">
        <mat-radio-button color="primary" class="me-3" [value]="true">
          Login Enabled
        </mat-radio-button>
        <mat-radio-button color="primary" class="me-3" [value]="false">
          Login Disabled
        </mat-radio-button>
      </mat-radio-group>

      <ng-container *ngIf="form.value.loginEnabled === true">
        <mat-checkbox color="primary" formControlName="mfaEnabled">Multi Factor Authentication</mat-checkbox>
        <ng-container *ngIf="form.get('mfaEnabled').value">
          <mat-label [ngClass]="{ 'invalid': !form.get('mfaDestination').valid }">Authentication Method</mat-label>
          <mat-radio-group formControlName="mfaDestination">
            <mat-radio-button color="primary" class="me-3" [value]="'Email'">
              Email
            </mat-radio-button>
            <mat-radio-button [disabled]="!form.get('cellNumber').valid || !form.get('cellNumber').value"
            color="primary" class="me-3" [value]="'SMS'"
            [matTooltip]="!form.get('cellNumber').valid || !form.get('cellNumber').value ? 'Please enter a valid cell number for this user first.' : null"
            [matTooltipClass]="'tooltip-red'">
              SMS
            </mat-radio-button>
          </mat-radio-group>
        </ng-container>

      </ng-container>

      <ng-container *ngIf="form.value.loginEnabled === true">
        <mat-label>Password</mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="password" type="password" autocomplete="new-password">
        </mat-form-field>
        <div style="max-width: 500px;" *ngIf="form.get('password').value.length > 0">
          <oiq-password-strength [passwordToCheck]="form.value.password"
            (passwordStrength)="onPasswordStrengthChanged($event)"></oiq-password-strength>
          <div class="error-text" *ngIf="form.value.password.length < this.minimumPasswordLength">Password must be at least {{this.minimumPasswordLength}} characters long
          </div>
          <div class="error-text" *ngIf="form.value.password.length > this.maximumPasswordLength">Password must be no more than {{this.maximumPasswordLength}} characters in
            length.</div>
          <span class="error-text" *ngIf="!strongPassword">At least one number, one lowercase character, one upper case
            and one special character (, $, *, #, etc.) is required.</span>
        </div>
        <div *ngIf="form.get('password').value.length > 0">
          <mat-label>Repeat Password</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="passwordConfirm" type="password">
          </mat-form-field>
          <div class="error-text" *ngIf="form.value.password !== form.value.passwordConfirm">Passwords have to match
          </div>
        </div>

        <div class="info-text" *ngIf="!form.value.password && !editUser">* If you choose not to set a password, reset password link
          will be sent to user email.</div>

      </ng-container>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button oiq-loading [spinner]="false" (click)="cancel()">Cancel</button>
      <button mat-raised-button oiq-loading mat-dialog-close color="accent" type="submit"
        [buttonDisabled]="!form.valid || (form.value.loginEnabled && form.value.password !== form.value.passwordConfirm) || (form.value.loginEnabled && form.get('password').value.length > 0 && !strongPassword)"
        (click)="onSubmit()">{{editUser ? 'Save' : 'Add'}}
      </button>
    </div>
  </form>
</div>
