import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BankService, ContactService, ApplicationService, IsoService } from '@core/services';

import { DecisionLogicRequest } from '@core/models';

@Component({
  selector: 'oiq-decision-logic-request-dialog',
  templateUrl: './decision-logic-request-dialog.component.html',
  styleUrls: ['./decision-logic-request-dialog.component.scss']
})
export class DecisionLogicRequestDialogComponent implements OnInit {

  loadingContact = false;

  form: FormGroup;

  application: any;

  contactId: number;

  contact: any;

  contacts: Array<any> = [];

  iso: any;
  isMissingIsoNotificationEmail: Boolean;
  loadingIso: Boolean;
  submiting: Boolean;

  constructor(
    private applicationService: ApplicationService,
    private bankService: BankService,
    private contactService: ContactService,
    private isoService: IsoService,
    public dialogRef: MatDialogRef<DecisionLogicRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.form = new DecisionLogicRequest();
    this.form.get('emailAddress').setValidators([Validators.required, Validators.email]);

    this.application = this.data.application;
    this.contacts = this.data.contacts;
  }

  contactSelected() {
    this.getContact();
  }

  onNotificationTargetChanged() {
    if (this.form.value.notificationTarget === 'ISO') {
      this.form.get('emailAddress').clearValidators();
      this.getIso();
    } else {
      this.form.get('emailAddress').setValidators([Validators.required, Validators.email]);
      if (this.form.value.notificationTarget === 'Both') {
        this.getIso();
      }
    }

    this.form.get('emailAddress').updateValueAndValidity();
  }

  onBankSelected(event) {
    if (event !== null) {
      this.form.get('bankId').setValue(event);

      this.bankService.getById(event).subscribe((data) => {
        this.form.patchValue({
          routingNumber: data.routingNumber,
          accountNumber: data.accountNumber
        });
      });
    }
  }

  getContact() {
    this.loadingContact = true;
    this.contactService.getById(this.contactId)
      .subscribe((r: any) => {
        this.contact = r;

        this.form.patchValue(this.contact);

        this.form.get('contactId').setValue(this.contact.id);
        this.form.get('emailAddress').setValue(this.contact.email);
      }, (error) => {
        this.loadingContact = false;
      },
        () => {
          this.loadingContact = false;
        });
  }

  getIso() {
    this.loadingIso = true
    this.isMissingIsoNotificationEmail = true;
    this.isoService.getSettings(this.application.iso.id)
      .subscribe((r: any) => {
        this.iso = r;
        if (this.iso && this.iso.emailAddressRecipients && this.iso.emailAddressRecipients.length > 0) {
          this.isMissingIsoNotificationEmail = false;
        }
      }, (error) => {
        this.loadingIso = false;
      },
        () => {
          this.loadingIso = false;
        });
  }

  submit() {
    if (this.application.tenantId === 7) {
      this.dialogRef.close(true);
    } else {
    let data = this.form.value;
    this.submiting = true;

    this.applicationService.generateDecisionLogicRequest(this.application.id, data)
      .subscribe((r: any) => {
        this.dialogRef.close();
      },
        (error) => {
          this.submiting = false;
        },
        () => {
          this.submiting = false;
        }
      );
    }
  }
}
