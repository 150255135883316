<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Quick Add Contact</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-4">
          <mat-label>First Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="firstName" required>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>Last Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="lastName" required>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>Cell Phone Number</mat-label>
          <mat-form-field appearance="outline">
            <input matInput mask="(000) 000-0000" formControlName="cellNumber">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-label>Email</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="email" required>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>SSN</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="ssn"  mask="000-00-0000" required>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <oiq-address [horizontal]="true" [address]="form.get('addresses')['controls'][0]" [editMode]="true"></oiq-address>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close oiq-loading [spinner]="false">Cancel</button>
      <button oiq-loading mat-raised-button cdkFocusInitial color="accent" type="submit" (click)="onSubmit()" [buttonDisabled]="!form.valid || !form.get('addresses')['controls'][0].valid">
        Save as New Contact
      </button>
    </div>
  </form>
</div>
