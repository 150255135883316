<div class="clearfix">
  <div [ngClass]="{'float-end': uploadMoreDocumentsBtnSide === 'right'}"
       [ngClass]="{'float-start': uploadMoreDocumentsBtnSide === 'left'}"  *authorizeRole="authorizeRoles">
    <button
    *ngIf="isUploadEnabled"
      class="mb-1"
      mat-stroked-button
      [dropzone]="dropzoneConfiguration"
      (addedFile)="onFileAddedToQueue($event)"
      (error)="onError($event)"
      (success)="onFileSuccess($event)"
      >
      {{uploadBtnText}}
    </button>
    <!-- <button
    *ngIf="isDeleteFileEnabled"
      mat-raised-button
      color="warn"
      (click)="deleteSelectedFiles()"
      [disabled]="selection.selected.length === 0"
    >
      DELETE SELECTED FILES(S)
    </button> -->

    <div *ngIf="maxFilesToUpload" class="small">* Maximum {{maxFilesToUpload}} simultaneous uploads</div>
  </div>
</div>
<div class="scrollable">
  <table  aria-describedby="List of uploading files"  *ngIf="isFileUploadingView && dropzoneFilesDataSource?.data.length" mat-table [dataSource]="dropzoneFilesDataSource">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>Filename</th>
      <td mat-cell *matCellDef="let file" class="file-name">
        {{ file.upload.filename }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fileSize">
      <th mat-header-cell *matHeaderCellDef>File Size</th>
      <td mat-cell *matCellDef="let file" class="file-size" >{{ file.upload.total | formatFileSize }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let file">

        <div *ngIf="file.status === 'error'" class="file-status file-status-error" >Error</div>
        <div *ngIf="file.status === 'uploading' || file.status === 'queued'" class="file-status file-status-uploading">Uploading</div>
        <div *ngIf="file.status === 'success'" class="file-status file-status-success">Success</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let file" class="file-error-description">{{ file?.description }}</td>
    </ng-container>
    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>File Upload Progress</th>
      <td mat-cell *matCellDef="let file">
        <div class="d-flex flex-row">
          <p class="d-inline-block align-self-center m-0 p-2">{{ file.upload.progress | number: '1.0-0' }}</p>
          <p class="d-inline-block align-self-center m-0 p-2">%</p>
            <mat-progress-bar
              class="align-self-center file-progress-bar me-1"
              color="primary"
              [value]="file.upload.progress"
            >
            </mat-progress-bar>
            <div class="align-self-center btn-group">

            <button [disabled]="file.status === 'success'" mat-button (click)="cancelUpload(file)">
              <i class="material-icons file-action">clear</i>
            </button>
            <button [disabled]="file.status !== 'error' || !file.accepted" mat-button (click)="retryFileUpload(file)">
              <i class="material-icons file-action">refresh</i>
            </button>
          </div>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="dropzoneFilesColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: dropzoneFilesColumns"></tr>
  </table>
</div>

<div  class="scrollable">
  <table *ngIf="isFilesTableShown" aria-describedby="List of files" mat-table [dataSource]="filesDataSource" >
    <ng-container matColumnDef="select" *ngIf="isMultiSelectEnabled" >
      <th mat-header-cell *matHeaderCellDef >
        <mat-checkbox
          class="select-all"
          color="primary"
          (change)="$event ? toggleAll() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" >
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef class="me-auto">Filename</th>
      <td mat-cell *matCellDef="let row"  [matTooltip]="row.filename" class="pe-3">
        {{ row.filename | truncateFilename: 50 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="stipulation">
      <th mat-header-cell *matHeaderCellDef>Associated Stipulation</th>
      <td mat-cell *matCellDef="let row">
        {{ row.stipulation ? row.stipulation.name : "n/a" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created By</th>
      <td mat-cell *matCellDef="let row" class="mx-3">
        {{ row.createdByFirstName + " " + row.createdByLastName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell *matHeaderCellDef>Created Date</th>
      <td mat-cell *matCellDef="let row" class="mx-3">
        {{ row.createdDate | date : "medium" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="text-end align-middle">
        <div class="btn-group">
          <oiq-open-file-button [data]="row" [matTooltip]="'Open: ' + row.filename"></oiq-open-file-button>
          <button
            mat-stroked-button
            class="btn-table me-2"
            (click)="downloadFile(row.id)"
            [matTooltip]="'Download: ' + row.filename"
          >
            DOWNLOAD
          </button>
          <button
          *ngIf="isSelectStipulationEnabled"
            mat-raised-button
            color="primary"
            class="btn-table me-2"
            (click)="selectStipulationDialog(row)"
          >
            ASSOCIATE TO STIPULATION
          </button>
          <button
          *ngIf="isDeleteFileEnabled"
            mat-raised-button
            color="warn"
            class="btn-table"
            (click)="removeFile(row.id)"
          >
            DELETE
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="filesTableColumns"></tr>
    <ng-container  *ngIf="isFilesTableShown && filesDataSource && filesDataSource.data && filesDataSource.data.length >= 0">
      <tr  mat-row *matRowDef="let row; columns: filesTableColumns"></tr>
    </ng-container>
  </table>
  <div class="text-center font-italic" color="secondary" *ngIf="filesDataSource && filesDataSource.data && !filesDataSource.data.length && isFilesTableShown && !isLoadingRecords">No attachments.</div>
  <div  *ngIf="isLoadingRecords" class="row justify-content-center loading-records font-italic"><p>Loading records</p></div>
  <mat-paginator
  *ngIf="pageEvent && isPaginationEnabled"
    [length]="pageEvent.length"
    [pageSize]="pageEvent.pageSize"
    (page)="handlePageEvent($event)"
    [pageSizeOptions]="[5, 10, 25, 100]"
  ></mat-paginator>
</div>
