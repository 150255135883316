import { AuthService } from '@core/authentication/auth.service';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { EditTemplateDialogComponent } from './edit-template-dialog/edit-template-dialog.component';
import { ViewTemplateDialogComponent } from './view-template-dialog/view-template-dialog.component';

import { NotificationTemplateService } from '@core/services';
import { DeleteConfirmationDialogComponent } from '@shared/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Subscription } from 'rxjs';
import { MaterialDesignService } from '@core/services/material-design.service';

@Component({
  selector: 'oiq-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})

export class TemplatesComponent implements OnInit, AfterViewChecked, OnDestroy {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  type: string;
  medium: string;
  templateTypes: Array<any> = [];
  templates: Array<any> = [];
  templatesArray: any;
  userRole: string;
  expandedElement: any = null;
  panelOpenState: boolean;
  selection = new SelectionModel(true, []);
  // tslint:disable-next-line:max-line-length
  displayedColumns: string[] = this.isUserAdmin() === true ? ['select', 'type', 'from', 'description'] : ['type', 'from', 'description'];
  activeTab: string = 'email';
  marked = {};
  flagActive = {};
  isUserWallfundingTenant: boolean;
  public currentPanelIndex: number = 0; // Default to the first panel

  public panelSubscription: Subscription;
  public lastOpenedPanel: string;


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private notificationTemplateService: NotificationTemplateService,
    private cookieService: CookieService,
    private authService: AuthService,
    private materialDesignService: MaterialDesignService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isUserAdmin();
    this.getTemplateTypes();
    this.getTemplates();

    this.isUserWallfundingTenant = this.isWallFundingTenant();
  }

  //using this hook as ngFor is somehow messing with indexes of each panel until they are completely rendered
  ngAfterViewChecked(): void {
    this.panelSubscription = this.materialDesignService.openPanel$.subscribe((eventType: string) => {
      this.openPanel(eventType);
    })
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.panelSubscription) {
      this.panelSubscription.unsubscribe();
      this.materialDesignService.closePanel(null);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.templatesArray.data.length;
    return numSelected === numRows;
  }

  applyFilter(filterValue: string) {
    this.templatesArray.filter = filterValue.trim().toLowerCase();

    if (this.templatesArray.paginator) {
      this.templatesArray.paginator.firstPage();
    }
  }

  deleteTemplate(row) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '500px',
      data: {
        action: 'delete',
        service: this.notificationTemplateService,
        ids: row.id
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.loadTemplates(row.type, row.medium);
      }
    });
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.templatesArray.data.forEach(row => this.selection.select(row));
  }

  loadTemplates(type: string, medium: string) {
    this.panelOpenState = true;

    this.type = type;
    this.medium = type;

    this.getTemplatesByTypeAndMedium(type, medium);
  }

  editTemplate(row: any, event) {
    event.stopPropagation();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditTemplateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTemplatesByTypeAndMedium(row.type, row.medium);
      }
    });
  }

  addTemplate(type, medium) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type,
      medium
    };
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditTemplateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTemplatesByTypeAndMedium(type, medium);
      }
    });
  }

  viewTemplate(row: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row;
    dialogConfig.width = '1000px';
    const dialogRef = this.dialog.open(ViewTemplateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTemplatesByTypeAndMedium(row.type, row.medium);
      }
    });

    return false;
  }

  getTemplateTypes() {
    this.notificationTemplateService.listTypes()
      .subscribe((r: any) => {
        this.templateTypes = r;
      });
  }

  getTemplates() {
    this.notificationTemplateService.list()
      .subscribe((r: any) => {
        this.templatesArray = new MatTableDataSource(r.content);
        this.templatesArray.sort = this.sort;
      });
  }

  getTemplatesByTypeAndMedium(type: string, medium: string) {
    this.notificationTemplateService.listByTypeAndMedium(type, medium)
      .subscribe((r: any) => {
        this.templates = r;
        this.flagActive = {};
      });
  }

  markAsDefault(row, event) {
    event.stopPropagation();

    this.notificationTemplateService.markAsDefault(row.id)
      .subscribe((r: any) => {
        this.getTemplatesByTypeAndMedium(row.type, row.medium);
      });
  }

  isUserAdmin() {
    return this.cookieService.get('currentUser') &&
      this.authService.getUser().roles.filter(role => role.name === 'SUPER_ADMIN').length > 0;
  }

  isWallFundingTenant() {
    return this.cookieService.get('currentUser') &&
      this.authService.getUser().tenantId === 1;
  }

  selectTab(tab) {
    this.activeTab = tab;
  }

  setFlag(flag, index) {
    this.flagActive[index] = flag;
    setTimeout(() => {
      delete this.flagActive[index];
    }, 2000);
  }

  toggleType(event, id, index, typeIndex){
    this.marked[index] ? this.marked[index][typeIndex] = event.target.checked : this.marked[index] = {}; this.marked[index][typeIndex] = event.target.checked;
    if (event.target.checked) {
      this.notificationTemplateService.enableNotification(id)
        .subscribe(
          null,
          (error) => {
            this.setFlag('error', typeIndex);
          },
          () => {
            this.setFlag('success', typeIndex)
          }
        );
    } else {
      this.notificationTemplateService.disableNotification(id)
        .subscribe(
          null,
          (error) => {
            this.setFlag('error', typeIndex)
          },
          () => {
            this.setFlag('success', typeIndex)
          }
        );
    }
  }

  private scrollIntoPanel(panelName: string): void {
    const panelElement:HTMLElement = document.getElementById(panelName);
    if (panelElement) {
      panelElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setTimeout(() => {
      this.materialDesignService.closePanel(null);
    }, 1500);

  }

  private openPanel(eventType: string): void {
    if (eventType !== null) {
      // If the panel being opened is not the current panel, then scroll to it
      if (this.lastOpenedPanel !== eventType) {
        this.currentPanelIndex = this.templateTypes.findIndex(type => type === eventType);
        this.scrollIntoPanel(eventType);
      }
    }
  }

}
