import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { filter, switchMap, map } from 'rxjs/operators';
import { AuthService } from '@core/authentication/auth.service';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.isLoggedIn$ = this.authService.isLoggedIn;
  }

  isLoggedIn$: Observable<boolean>;

  public initialize() {
    this.onRouteChange();

    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticsKey}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${environment.googleAnalyticsKey}', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  private onRouteChange() {
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap((event: NavigationEnd) => this.authService.isLoggedIn.pipe(
            map(isLoggedIn => ({
                isLoggedIn, 
                url: event.urlAfterRedirects,
                userId: isLoggedIn ? this.authService.getUserId() : null
            }))
        ))
    ).subscribe(({ isLoggedIn, url, userId }) => {
        gtag('config', environment.googleAnalyticsKey, {
            page_path: url
        });

        if (isLoggedIn && userId) {
            gtag('config', environment.googleAnalyticsKey, {
                'app_user_id': userId
            });
        }
    });
}

  // generic method to take in any possible events that need tracking
  public event(action: string, eventCategory?: string, eventLabel?: string, value?: string) {
    gtag('event', action, {
      ...(eventCategory && { event_category: eventCategory }),
      ...(eventLabel && { event_label: eventLabel }),
      ...(value && { value: value })
    });
  }
}
