<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ bankId ? 'Edit' : 'Add' }} Bank Info</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <form [formGroup]="bankForm">
      <div class="row">
        <div class="col-sm-3">
          <mat-label>Bank Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="name" required>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>Branch</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="branch">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <mat-label>Routing Number *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" pattern="[0-9]*" required formControlName="routingNumber">
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>Account Type *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="accountType" required>
              <mat-option *ngFor="let type of accountTypes" [value]="type.value">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>Account Number *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text"formControlName="accountNumber"pattern="[0-9]*" required>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-label>DDA</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="dda" type="text">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <oiq-address class="address-wrapper col-sm-12" [address]="bankForm.get('address')" [editMode]="editMode"></oiq-address>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button mat-stroked-button class="btn-small" oiq-loading [spinner]="false" (click)="close()">CANCEL</button>
          <button mat-raised-button color="accent" oiq-loading class="btn-small ms-3" (click)="updateBank()" [buttonDisabled]="!bankForm.valid">UPDATE BANK INFO</button>
        </div>
      </div>
  </form>

  </div>
</div>
