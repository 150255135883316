<mat-form-field appearance="outline">
  <mat-select [placeholder]="'Select Report'" (click)="onSelectClick()" (selectionChange)="selectReport($event)">

    <mat-option *ngIf="isLoadingReports">
      <div class="select-placeholder-container">
        <mat-spinner class="spinner" diameter="20"></mat-spinner>
      </div>
    </mat-option>
    <ng-container *ngIf="!isLoadingReports">
      <mat-option *ngFor="let report of _reports" [value]="report">
        Generated {{ report.config.sendReport ? ' and Sent' : '' }} on <strong>{{ report.config.runDate |
          date:'shortDate' }}</strong>
      </mat-option>
      <mat-option *ngIf="_reports && _reports.length === 0" disabled>
        No Generated Reports
      </mat-option>
    </ng-container>

  </mat-select>
</mat-form-field>
