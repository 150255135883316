
<div class="d-flex justify-content-between">
  <div class="content-container me-2">
    <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      <span style="text-transform:capitalize">{{ title }}</span> Event <strong>{{getFormatFromNow(calendarEvent.eventDate)}}</strong>
    </div>
    <div *ngIf="message && options.enableHtml" role="alertdialog" aria-live="polite"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div class="entity" matTooltip="Click for the link overview" (click)="openEntityLink(calendarEvent,$event)" *ngIf="showEntityLink && calendarEvent && calendarEvent.entityName && calendarEvent.entityId && calendarEvent.entityType" role="alertdialog" aria-live="polite">
      {{calendarEvent.entityName}}
    </div>
    <div *ngIf="message && !options.enableHtml" role="alertdialog" aria-live="polite"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  </div>
  <div class="toastr-buttons d-flex flex-column justify-content-between border-left">
    <button *ngIf="options.closeButton" (click)="closeToast(calendarEvent.id)" class="toast-close-button" matTooltip="Close and set read" aria-label="Close and set read">
      <span aria-hidden="true" class="mx-auto">&times;</span>
    </button>
    <div class="mb-1 mx-2 border-top"></div>
    <button mat-button [matMenuTriggerFor]="menu" class="mx-1 ms-2 font-size-1" matTooltip="Set postpone interval"
    aria-label="Set postopne interval">Postpone</button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item (click)="postponeReminder(calendarEvent,15,'minutes')">15 minutes</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,30,'minutes')">30 minutes</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,45,'minutes')">45 minutes</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,60,'minutes')">1 hour</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,360,'minutes')">6 hour</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,720,'minutes')">12 hours</button>
      <button mat-menu-item (click)="postponeReminder(calendarEvent,1400,'minutes')">24 hours</button>
    </mat-menu>
  </div>
</div>
