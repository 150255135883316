import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

import { ApplicationService, FundingService, SettingService, SyndicatorService } from '@core/services';

import { ManualSyndicationOffer, ManualSyndicationOffers, SYNDICATIONS_CALCULATION_LOGIC_OPTIONS } from '@core/models';


///
// THIS COMPONENT IS OBSOLETE PLEASE USE AddSyndicationsComponent
///


@Component({
  selector: 'oiq-edit-manual-syndication-offer-dialog',
  templateUrl: './edit-manual-syndication-offer-dialog.component.html',
  styleUrls: ['./edit-manual-syndication-offer-dialog.component.scss']
})
export class EditManualSyndicationOfferDialogComponent implements OnInit {
  private applicationSubscription: Subscription;
  private fundingSubscription: Subscription;

  application: any;
  funding: any

  form: ManualSyndicationOffers;
  tenantFinancialSettings: any = {};
  syndicator: any;

  fieldUpdating: string;

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<EditManualSyndicationOfferDialogComponent>,
    private applicationService: ApplicationService,
    private fundingService: FundingService,
    private settingService: SettingService,
    private syndicatorService: SyndicatorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form = new ManualSyndicationOffers();

    this.fundingSubscription = this.fundingService.funding.subscribe(funding=> this.funding = funding);

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;

      this.getSettings();
      this.populateSyndicationOffer();
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
  }

  onAcceptedPercentSyndicatedChange(row) {
    if (row.get('acceptedPercentSyndicated').value !== '') {
      let acceptedPercentSyndicated = parseFloat(row.get('acceptedPercentSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      row.get('acceptedAmountSyndicated').setValue(fundedAmount * (acceptedPercentSyndicated / 100));

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(fundedAmount * (acceptedPercentSyndicated / 100));
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  onAcceptedAmountSyndicatedChange(row) {
    if (row.get('acceptedAmountSyndicated').value !== '') {
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      const acceptedAmountSyndicated = parseFloat(row.get('acceptedAmountSyndicated').value);
      const acceptedPercentSyndicated = this.roundPercent((acceptedAmountSyndicated / fundedAmount) * 100);
      row.get('acceptedPercentSyndicated').setValue(acceptedPercentSyndicated);

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(acceptedAmountSyndicated);
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  recalculate(row) {
    const paybackAmount = parseFloat(this.application.appliedFundingOffer.paybackAmount);

    if (row.get('principalToBePaid').value !== '' && row.get('commissionPercent').value !== '') {
      const principalToBePaid = parseFloat(row.get('principalToBePaid').value);
      const commissionPercent = parseFloat(row.get('commissionPercent').value);
      const acceptedAmountSyndicated = parseFloat(row.get('acceptedAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (acceptedAmountSyndicated * commissionPercent / 100);
      row.get('maxTotalReceived').setValue(maxTotalReceived);
    }

    if (row.get('acceptedPercentSyndicated').value !== '') {
      let acceptedPercentSyndicated = parseFloat(row.get('acceptedPercentSyndicated').value);
      row.get('maxPayback').setValue(paybackAmount * (acceptedPercentSyndicated / 100));
    }
  }

  populateSyndicationOffer() {
    const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);

    const manualSyndicationOffer = new ManualSyndicationOffer();
    manualSyndicationOffer.addControl('calculationLogic', new FormControl());
    manualSyndicationOffer.addControl('principalToBePaid', new FormControl({value: null, disabled: true}));

    manualSyndicationOffer.patchValue({
      syndicatorId: this.data.syndicationOffer.syndicatorId,
      syndicatorName: this.data.syndicationOffer.syndicator.name,
      acceptedAmountSyndicated: this.data.syndicationOffer.acceptedAmountSyndicated,
      acceptedPercentSyndicated: this.data.syndicationOffer.acceptedPercentSyndicated,
      commissionPercent: this.data.syndicationOffer.commissionPercent,
      managementFeePercent: this.data.syndicationOffer.managementFeePercent,
      calculationLogic: this.data.syndicationOffer.calculationLogic
    });


    manualSyndicationOffer.get('acceptedAmountSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'acceptedAmountSyndicated') {
        return;
      }

      this.fieldUpdating = 'acceptedPercentSyndicated';

      this.onAcceptedAmountSyndicatedChange(manualSyndicationOffer);

      this.fieldUpdating = null;
    });

    manualSyndicationOffer.get('acceptedPercentSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'acceptedPercentSyndicated') {
        return;
      }

      this.fieldUpdating = 'acceptedAmountSyndicated';

      this.onAcceptedPercentSyndicatedChange(manualSyndicationOffer);

      this.fieldUpdating = null;
    });

    //This will start recalculation and fill fields that are calculated on the fly
    manualSyndicationOffer.get('acceptedAmountSyndicated').setValue(this.data.syndicationOffer.acceptedAmountSyndicated);

    (this.form.get('manualSyndicationOffers') as FormArray).push(manualSyndicationOffer);
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  validateAcceptedPercentSyndicated(form: FormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const acceptedPercentSyndicated = parseFloat(control.value);

    if (isNaN(acceptedPercentSyndicated)) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (acceptedPercentSyndicated < 0) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (acceptedPercentSyndicated > 100) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooLarge: true
      };

      return result;
    }

    return null;
  }

  getSettings() {
    this.settingService.getTenantFinancialSettings()
      .subscribe((r: any) => {
        this.tenantFinancialSettings = r;
      });
  }

  removeSyndicator(i) {
    (this.form.get('manualSyndicationOffers') as FormArray).removeAt(i);
  }

  save() {
    const observables = [];

    for (let i = 0; i < this.form.get('manualSyndicationOffers')['controls'].length; i++) {
      observables.push(this.applicationService.updateSyndicationOffer(this.application.id, this.data.syndicationOffer.id, this.form.get('manualSyndicationOffers')['controls'][i].value));
    }

    forkJoin(observables).subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }
  onSelectedSyndicationCalculationLogic(event, manualSyndicationOffersControl) {
    manualSyndicationOffersControl.get('calculationLogic').setValue(event.value);

    manualSyndicationOffersControl.get('acceptedPercentSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    manualSyndicationOffersControl.get('acceptedAmountSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    manualSyndicationOffersControl.get('principalToBePaid').setValue(null, {emitEvent: false, onlySelf: true});

    manualSyndicationOffersControl.get('acceptedPercentSyndicated').setErrors({'incorrect': true} )
    manualSyndicationOffersControl.get('acceptedAmountSyndicated').setErrors({'incorrect': true})
  }
}
