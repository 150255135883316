import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { ErrorDialogComponent } from '@shared/error-dialog/error-dialog.component';

import { AuthService } from '@core/authentication/auth.service';

@Component({
  selector: 'oiq-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  passForm: FormGroup;
  resetRequestSuccess = false;
  resetVerified = false;
  passResetSuccess = false;
  recordNotFound = false;
  resetToken: string;
  loading: boolean = false;
  strongPassword = false;
  minPasswordLength = 8;
  maxPasswordLength = 16;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.resetToken = this.activatedRoute.snapshot.queryParams['token'];
    if (this.activatedRoute.snapshot.queryParams['passMinLength'] && this.activatedRoute.snapshot.queryParams['passMaxLength']) {
      this.minPasswordLength = this.activatedRoute.snapshot.queryParams['passMinLength'];
      this.maxPasswordLength = this.activatedRoute.snapshot.queryParams['passMaxLength'];
    }
    if (this.resetToken) {
      this.authService.verifyPasswordResetToken(this.resetToken)
        .subscribe((r: any) => {
            if (r === true) {
              this.resetVerified = true;
            }
          });
    }
    this.form = this.fb.group({
      email: ['', Validators.required]
    });
    this.passForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(this.minPasswordLength), Validators.maxLength(this.maxPasswordLength)]]
    });

  }

  requestResetLink() {
    this.authService.requestPasswordReset(this.form.value)
      .subscribe((r: any) => {
          if (r === true) {
            this.form.reset();
            this.resetRequestSuccess = true;
          }
        },
        (error) => {
          if (error === 'Record not found') {
            this.recordNotFound = true;
          }
        });
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event;
  }

  newPassSubmit() {
    this.authService.submitNewPassword(this.resetToken, this.passForm.value.password)
      .subscribe((r: any) => {
          this.passResetSuccess = true;
        },
        (error) => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.width = '500px';
          error === 'Password already previously used' ? dialogConfig.data = [error] : dialogConfig.data = error;
          const errorDialogRef = this.dialog.open(ErrorDialogComponent, dialogConfig);
        });
  }

}
