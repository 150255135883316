import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'search/';
  }

  searchApplications(query: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    params['query'] = query;

    return this.http.get(this.getUrl() + 'applications/search', { params }).pipe(
      map(response => {
        return response;
        }
      ));
  }

  searchClients(query: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    params['query'] = query;

    return this.http.get(this.getUrl() + 'clients/search', { params }).pipe(
      map(response => {
        return response;
        }
      ));
  }

  performClientsTypeahead(term: string, searchType?: string): Observable<any> {
    if (term === '') {
      return of([]);
    }

    const params = {}
    if(searchType){
      params['searchType']=searchType
    }

    return this.http.get(this.getUrl() + `clients/name/typeahead/${term}`, {params}).pipe(
      map(response => {
        return response;
        }
      ));
  }

  performIsosTypeahead(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get(this.getUrl() + `isos/name/typeahead/${term}`).pipe(
      map(response => {
        return response;
        }
      ));
  }

  performSyndicatorsTypeahead(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get(this.getUrl() + `syndicators/name/typeahead/${term}`).pipe(
      map(response => {
        return response;
        }
      ));
  }

  performLawFirmsTypeahead(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get(this.getUrl() + `law-firms/name/typeahead/${term}`).pipe(
      map(response => {
        return response;
        }
      ));
  }

}
