import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

export class CalendarEvent extends FormGroup {
  constructor(id?: number, date?: string, time?: string, interval?: string, interval2?: string, title?: string, description?: string, entityId?: number, entityName?: string, entityType?: string) {
    super({
      id: new FormControl(id),
      date: new FormControl(date, Validators.required),
      time: new FormControl(time, Validators.required),
      interval: new FormControl(interval, Validators.required),
      interval2: new FormControl(interval2),
      title: new FormControl(title, Validators.required),
      description: new FormControl(description),
      entityId: new FormControl(entityId),
      entityName: new FormControl(entityName),
      entityType: new FormControl(entityType)
    });
  }
}
