import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SYNDICATIONS_CALCULATION_LOGIC_OPTIONS } from '@core/models';
import { SyndicatorService } from '@core/services';
import { notEmptyFormArrayValidator } from '@shared/utils-custom-form-validators';

@Component({
  selector: 'oiq-add-syndications',
  templateUrl: './add-syndications.component.html',
  styleUrls: ['./add-syndications.component.scss']
})
export class AddSyndicationsComponent implements OnInit {


  @Input() parentForm: FormGroup;
  @Input() disbursementAmount = 0;
  @Input() fundedAmount = 0;
  @Input() factorRate = 0;
  @Input() commissionPercent = 0;
  @Input() paybackAmount = 0;
  @Input() syndications = [];
  @Input() whiteLabelId: number;

  isEditMode = false;
  isLoadingSyndicators = false;

  syndicators: any[];
  selectedSyndicators: any;
  syndicationForms: FormGroup[] = [];

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;

  constructor(private syndicatorService: SyndicatorService) {}

  ngOnInit() {
    if(this.syndications && this.syndications.length){
      this.populateForEdit();
    } else {
      this.getSyndicators();
    }
  }

  getSyndicators() {
    this.isLoadingSyndicators = true;
    if (this.whiteLabelId) {
      this.syndicatorService.getAllSyndicatorsWithIdAndName(this.whiteLabelId)
        .subscribe((r: any) => {
          this.syndicators = r;
          this.isLoadingSyndicators = false;
        });
    }
  }

  onSyndicatorSelected(event) {
    if (event && event.value) {
      this.mapSelectedSyndicatorsToSyndicationOfferForms(event.value);
    }
    this.updateParentForm();
  }

  onRemoveSyndicator(syndicationForm: FormGroup) {
    this.selectedSyndicators = this.selectedSyndicators.filter(syndicator => syndicator.id !== syndicationForm.get('syndicatorId').value);
    this.syndicationForms = this.syndicationForms.filter(form => form.get('syndicatorId').value !== syndicationForm.get('syndicatorId').value);
    this.updateParentForm();
  }

  onPercentSyndicatedChanged(syndicationForm) {
    if (syndicationForm.get('acceptedPercentSyndicated').value !== '') {
      const acceptedPercentSyndicated = parseFloat(syndicationForm.get('acceptedPercentSyndicated').value);
      syndicationForm.get('acceptedAmountSyndicated').setValue(this.fundedAmount * (acceptedPercentSyndicated / 100));

      if (syndicationForm.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        syndicationForm.get('principalToBePaid').setValue(this.fundedAmount * (acceptedPercentSyndicated / 100));
      } else {
        syndicationForm.get('principalToBePaid').setValue(this.disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.calculateMaxTotalReceived(syndicationForm);
      this.calculateMaxPayback(syndicationForm);
    }
  }

  onAmountSyndicatedChanged(syndicationForm) {
    if (syndicationForm.get('acceptedAmountSyndicated').value !== '') {
      const acceptedAmountSyndicated = parseFloat(syndicationForm.get('acceptedAmountSyndicated').value);
      const acceptedPercentSyndicated = this.roundPercent((acceptedAmountSyndicated / this.fundedAmount) * 100);
      syndicationForm.get('acceptedPercentSyndicated').setValue(acceptedPercentSyndicated);

      if (syndicationForm.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        syndicationForm.get('principalToBePaid').setValue(acceptedAmountSyndicated);
      } else {
        syndicationForm.get('principalToBePaid').setValue(this.disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.calculateMaxTotalReceived(syndicationForm);
      this.calculateMaxPayback(syndicationForm);
    }
  }

  onCalculationLogicChanged(event, syndicationForm: FormGroup) {
    syndicationForm.get('principalToBePaid').reset();
    syndicationForm.get('acceptedAmountSyndicated').reset();
    syndicationForm.get('acceptedPercentSyndicated').reset();
    syndicationForm.get('maxTotalReceived').reset();
    syndicationForm.get('maxPayback').reset();
  }

  calculateMaxTotalReceived(syndicationForm) {
    if (syndicationForm.get('principalToBePaid').value !== '') {
      const principalToBePaid = parseFloat(syndicationForm.get('principalToBePaid').value);
      const acceptedAmountSyndicated = parseFloat(syndicationForm.get('acceptedAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (acceptedAmountSyndicated * this.commissionPercent) / 100;
      syndicationForm.get('maxTotalReceived').setValue(maxTotalReceived);
    }
  }

  calculateMaxPayback(syndicationForm) {
    if (syndicationForm.get('acceptedPercentSyndicated').value !== '') {
      let acceptedPercentSyndicated = parseFloat(syndicationForm.get('acceptedPercentSyndicated').value);
      syndicationForm.get('maxPayback').setValue(this.paybackAmount * (acceptedPercentSyndicated / 100));
    }
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  mapSelectedSyndicatorsToSyndicationOfferForms(selectedSyndicators: []) {
    if (selectedSyndicators && selectedSyndicators.length) {
      this.syndicationForms = selectedSyndicators.map((syndicator: any) =>
      this.syndicationForms.find((form: FormGroup) => form.value.syndicatorId === syndicator.id)
          ? this.syndicationForms.find((form: FormGroup) => form.value.syndicatorId === syndicator.id)
          : new FormGroup({
            syndicatorId: new FormControl(syndicator.id),
            syndicatorName: new FormControl(syndicator.name),
            acceptedAmountSyndicated: new FormControl('', Validators.required),
            acceptedPercentSyndicated: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
            principalToBePaid: new FormControl({value: '', disabled: true}),
            managementFeePercent: new FormControl(syndicator.defaultManagementFeePercent ? syndicator.defaultManagementFeePercent : 0, Validators.required),
            maxTotalReceived: new FormControl('', Validators.required),
            maxPayback: new FormControl('', Validators.required),
            calculationLogic: new FormControl(syndicator.defaultSyndicationCalculationLogic),
            type: new FormControl('Manual')
          }));
    } else {
      this.syndicationForms = []
    }
  }

  updateParentForm() {
    this.parentForm.removeControl('syndicationForms');
    this.parentForm.addControl('syndicationForms', new FormArray(this.syndicationForms, notEmptyFormArrayValidator()));
    this.parentForm.updateValueAndValidity();
  }

  populateForEdit(){
    this.isEditMode = true;
    this.syndications.forEach((syndication: any) => {
      const form = new FormGroup({
        id: new FormControl(syndication.id),
        syndicatorId: new FormControl(syndication.syndicator.id),
        syndicatorName: new FormControl(syndication.syndicator.name),
        acceptedAmountSyndicated: new FormControl(syndication.acceptedAmountSyndicated, Validators.required),
        acceptedPercentSyndicated: new FormControl(syndication.acceptedPercentSyndicated, [Validators.required, Validators.min(0), Validators.max(100)]),
        principalToBePaid: new FormControl({value: '', disabled: true}),
        managementFeePercent: new FormControl(syndication.managementFeePercent, Validators.required),
        maxTotalReceived: new FormControl('', Validators.required),
        maxPayback: new FormControl('', Validators.required),
        calculationLogic: new FormControl(syndication.calculationLogic),
        type: new FormControl(syndication.type)
      })
      this.onPercentSyndicatedChanged(form)
      this.syndicationForms.push(form);
      this.updateParentForm();
    })
  }
}
