import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'oiq-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {

  searchTerm: string;

  @Input() mobileNavOpen = new EventEmitter(false);
  @Output() closeMobileNav = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(param => {
      this.searchTerm = param.keyword;
    });
  }

  openSearch() {
    this.router.navigate(['/search-results'], { queryParams: { keyword: this.searchTerm} });
    if(this.mobileNavOpen) {
      this.closeMobileNav.emit(true);
    }
  }
}
