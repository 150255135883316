import { Injectable } from '@angular/core';
import { AuthService } from '@core/authentication/auth.service';
import { environment } from '@environments/environment';

declare global {
  interface Window {
    hj: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HotjarService {

  constructor(private authService: AuthService) { }

  public init(): void {
    const userId = this.authService.isLoggedIn ? this.authService.getUserId() : null;
    const existingScript = document.getElementById('hotjar-script');

    try {
      
      if (!environment.hotJarEnabled) {
        existingScript ? existingScript.remove() : null; // Remove the existing Hotjar script from the DOM
        return; // Exit the method
      } else {
        const hotjarScript = document.createElement('script');
        hotjarScript.id = 'hotjar-script';
        hotjarScript.async = true;
        hotjarScript.innerHTML = `
        (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:${environment.hotJarId},hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(hotjarScript);
      }

      this.initHotjarWithUserData(userId);
    } catch (e) {
      console.error("Error while adding hotjar ", e)
    }
  }

  initHotjarWithUserData(userId) {
    window.hj('identify', userId, {});
  }

  public recordEvent(eventName: string, eventProperties?: any): void {
    // Record custom event
    window.hj('event', eventName, eventProperties);
  }
}
