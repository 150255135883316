import { AuthService } from '@core/authentication/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationService, StipulationService } from '@core/services';

import { AddStipulationDialogComponent } from '../add-stipulation-dialog/add-stipulation-dialog.component';

import { forkJoin } from 'rxjs';

@Component({
  selector: 'oiq-request-docs-dialog',
  templateUrl: './request-docs-dialog.component.html',
  styleUrls: ['./request-docs-dialog.component.scss']
})
export class RequestDocsDialogComponent implements OnInit {

  stipulations: any[];
  tenantId: number;
  addDisabled: boolean = false;

  constructor(
    public dialog: MatDialog,
    public docsDialog: MatDialogRef<RequestDocsDialogComponent>,
    private stipulationService: StipulationService,
    private applicationService: ApplicationService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.tenantId = this.authService.getUser().tenantId;
    this.getStipulations();
  }

  close(): void {
    this.docsDialog.close();
  }

  stipulationNotesKeyUp(stipulation): void {
    if (stipulation.notes !== null && stipulation.notes !== '') {
      stipulation.selected = true;
    }
  }

  addStipulations() {
    const stipulations = this.stipulations.filter(row => row.selected).map(row => {
      this.addDisabled = true;
      return {
        name: row.name,
        stipulationId: row.id,
        notes: row.notes
      };
    });

    if (stipulations.length == 0) {
      return;
    }

    const observers = [];

    stipulations.forEach(row => {
      observers.push(this.applicationService.addStipulation(this.docsDialog.componentInstance.data, row));
    });

    forkJoin(observers).subscribe(results => {
      console.log(results);

      this.docsDialog.close();
    });
  }

  getStipulations() {
    this.stipulationService.list(this.tenantId)
      .subscribe((r: any) => {
        const stipulations = r.content;
        this.stipulations = stipulations.filter(stip => stip.required === false);
      });
  }

  addCustomStipulation() {
    const docsDialog = this.dialog.open(AddStipulationDialogComponent, {
      width: '800px',
      data: this.data
    });

    docsDialog.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

      if (result) {
        result.selected = true;

        this.stipulations.push(result);
      }
    });
  }

}
