import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinals'
})
export class OrdinalsPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return value.toString();

    const suffixes = ['th', 'st', 'nd', 'rd'];
    const modulo10 = value % 10;
    const modulo100 = value % 100;
    let suffix = suffixes[0];

    if (modulo10 === 1 && modulo100 !== 11) {
      suffix = suffixes[1];
    } else if (modulo10 === 2 && modulo100 !== 12) {
      suffix = suffixes[2];
    } else if (modulo10 === 3 && modulo100 !== 13) {
      suffix = suffixes[3];
    }

    return `${suffix}`;
  }
}
