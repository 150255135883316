/// <reference types="@types/googlemaps" />
import { Directive, AfterViewInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[oiq-address-autocomplete]'
})
export class AddressAutocompleteDirective implements AfterViewInit {
  @Output() onAddressSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() googleAPIKeyFailure: EventEmitter<any> = new EventEmitter<any>();

  constructor(private elmenetRef: ElementRef) { }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    if (this.isGoogleMapsAPIKeyValid()) {
      const autocomplete = new google.maps.places.Autocomplete(this.elmenetRef.nativeElement, {
        // types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        this.onAddressSelected.emit(place);
        this.googleAPIKeyFailure.emit(false)
      });
    } else{
      this.googleAPIKeyFailure.emit(true)
    }
  }

  private isGoogleMapsAPIKeyValid() {
    return !sessionStorage.getItem('googleAPIKeyFailure');
  }
}
