<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ data && data.name ? 'Update White Label' : 'Create White Label' }}</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row">
        <div class="form-group col-sm-6">
          <mat-form-field appearance="outline">
            <input matInput placeholder="White Label Name" formControlName="name">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="Memo" formControlName="memoField" maxlength="10">
          </mat-form-field>

          <div class="mb-2">
            <mat-label>Logo</mat-label>
            <div class="logo">
              <oiq-upload-attachment [type]="'public-assets'" (uploaded)="logoUploaded($event)" *ngIf="!form.value.logoId"></oiq-upload-attachment>

              <div class="logo-wrapper" *ngIf="form.value.logoId">
                <img [src]="logoBase64" *ngIf="logoBase64">
                <span class="close" (click)="removeLogo()" *ngIf="form.value.logoId"></span>
              </div>
            </div>
          </div>

          <div class="mb-2">
            <mat-label>Email Logo</mat-label>
              <div class="logo">
              <oiq-upload-attachment [type]="'public-assets'" (uploaded)="emailLogoUploaded($event)" *ngIf="!form.value.emailLogoId"></oiq-upload-attachment>

              <div class="logo-wrapper" *ngIf="form.value.emailLogoId">
                <img [src]="emailLogoBase64" *ngIf="emailLogoBase64">
                <span class="close" (click)="removeEmailLogo()" *ngIf="form.value.emailLogoId"></span>
              </div>
            </div>
          </div>

          <mat-form-field appearance="outline">
            <input matInput formControlName="emailFromAddress" placeholder="From Email Address">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Master Email Notification Template</mat-label>
            <textarea matInput formControlName="masterEmailNotificationTemplate"></textarea>
          </mat-form-field>

          <mat-checkbox *ngIf="isUserTenantAdmin" color="primary" formControlName="tenantDefault" [value]="true">Tenant Default</mat-checkbox>
        </div>
      </div>

    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial type="submit" [disabled]="!form.valid"
        (click)="onSubmit()">{{ data && data.name ? 'Update' : 'Create' }}
      </button>
    </div>
  </form>
</div>
