<div *ngIf="plaidReport && (plaidReport?.status === 'Pending' || plaidReport?.status === 'Completed')">
  <div class="form-row-wrapper">

    <div class="row">
      <div class="col-sm-12">
        <h3>REQUEST (INFORMATION ENTERED)</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>Asset Request Code</mat-label>
        <div class="field-value">{{ plaidReport.results?.asset?.request_id }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>Name</mat-label>
        <div class="field-value">{{ plaidReport.contact.firstName }} {{ plaidReport.contact.lastName }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Email Address</mat-label>
        <div class="field-value">{{ plaidReport.contact.email }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Profile</mat-label>
        <div class="field-value">n/a</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>ONYXIQ Contact Id</mat-label>
        <div class="field-value">{{ plaidReport.contact.id }}</div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-4">
        <mat-label>Institution Name</mat-label>
        <div class="field-value">n/a</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Routing Number</mat-label>
        <div class="field-value">n/a</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Account Number</mat-label>
        <div class="field-value">n/a</div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-sm-12">
        <div class="info-box">
          <h3>Asset report warnings</h3>

          <p *ngIf="plaidReport.results?.asset?.warnings && !plaidReport.results.asset?.warnings.length">
            No warnings.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <h2>RESPONSE (INFORMATION RETURNED BY BANK)</h2>
      </div>
    </div>

    <div class="row" *ngIf="selectedReportDetail.selectedOwner">
      <div class="col-sm-6">
        <mat-label>Owners Identity</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedReportDetail.selectedOwner" (selectionChange)="reportDetailOwnerSelected($event)">
            <mat-option *ngFor="let owner of selectedReportDetail.owners" [value]="owner">
              {{ owner.names[0] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-5 ms-5">
        <mat-label>Status</mat-label>
        <div class="field-value">
          <div class="status-indicator green" *ngIf="plaidReport?.status === 'Completed' || plaidReport?.status === 'Successful'">Bank Verified</div>
          <div class="status-indicator red" *ngIf="plaidReport?.status !== 'Completed' && selectedReportDetail?.status !== 'Successful'">Bank Not Verified</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>Owner Addresses</mat-label>
        <div class="my-2" *ngFor="let address of selectedReportDetail.selectedOwner?.addresses">
          <div class="p-2" [ngClass]="{ 'fw-bold bg-light border border-rounded': address.primary }">
            <span class="italic" *ngIf="address.primary">Primary address</span>
            <div class="field-value">
              {{ address.data.street }}<br>
              {{ address.data.city }}, {{ address.data.region }} {{address.data.postal_code}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <mat-label>Owner Emails</mat-label>
        <div class="my-2" *ngFor="let email of selectedReportDetail.selectedOwner?.emails">
          <div class="field-value">
            {{ email.data }} - {{ email.type}} <span *ngIf="email.primary && email.type !== 'primary'">/ Primary</span>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <mat-label>Owner Phone numbers</mat-label>
        <div class="my-2" *ngFor="let phoneNumber of selectedReportDetail.selectedOwner?.phone_numbers">
          <div class="field-value">
            {{ phoneNumber.data }} - {{ phoneNumber.type}} <span *ngIf="phoneNumber.primary && phoneNumber.type !== 'primary'">/ Primary</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>Institution Name</mat-label>
        <div class="field-value">{{ plaidReport.results?.asset?.report.items[0].institution_name }}</div>
      </div>

      <div class="col-sm-4">
        <mat-label>Account Name</mat-label>
        <div class="field-value">{{ plaidReport.results?.asset?.report.items[0].accounts[0].name }}</div>
      </div>
    </div>

    <div class="row" *ngIf="selectedReportDetail && plaidReport.results?.asset">
      <div class="col-sm-4">
        <mat-label>Account Type</mat-label>
        <div class="field-value">{{ selectedReportDetail.type }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Account Subtype</mat-label>
        <div class="field-value">{{ selectedReportDetail.subtype }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Account Number</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [(ngModel)]="selectedReportDetail" (selectionChange)="reportDetailSelected()">
            <mat-option *ngFor="let reportDetail of plaidReport.results.asset?.report.items[0].accounts" [value]="reportDetail">
              XXXX{{ reportDetail.mask }} {{ reportDetail.type }} - {{ reportDetail.subtype }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <h3>SUMMARY</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>Available Balance</mat-label>
        <div class="field-value">{{ selectedReportDetail.balances?.available | currency }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Current Balance</mat-label>
        <div class="field-value">{{ selectedReportDetail.balances?.current | currency }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Limits</mat-label>
        <div class="field-value">{{ (selectedReportDetail.balances?.limit | currency) || 'Not&nbsp;set' }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <mat-label>As of Date</mat-label>
        <div class="field-value">{{ selectedReportDetail.asOfDate | date }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Average Balance</mat-label>
        <div class="field-value">{{ plaidReport.averageBalance | currency }}</div>
      </div>
    </div>

    <div class="row">
      <table mat-table matSort [dataSource]="analyticsTable">
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let row">{{ row.category || 'n/a' }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let row">{{ row.transactionType }}</td>
        </ng-container>
        <ng-container matColumnDef="transactions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Recent Transactions</th>
          <td mat-cell *matCellDef="let row">{{ row.lastMonthTransactions }}</td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transactions</th>
          <td mat-cell *matCellDef="let row">{{ row.totalTransactions }}</td>
        </ng-container>
        <ng-container matColumnDef="transactionsLastMonth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Transactions Last Month
          </th>
          <td mat-cell *matCellDef="let row">{{ -row.lastMonthTransactionsAmount | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="totalLastMonth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Transactions Total <br>
            ({{ activityStartDate }} - {{ activityEndDate }})
          </th>
          <td mat-cell *matCellDef="let row">{{ -row.totalTransactionsAmount | currency }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedAnalyticsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedAnalyticsColumns;"></tr>
      </table>
      <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="analyticsTable?.data?.length === 0"></oiq-loading-message>
    </div>
  </div>
  <!-- SUMMARY -->

  <div class="form-row-wrapper" *ngIf="transactions">
    <div class="row">
      <div class="col-sm-12">
        <h3>TRANSACTIONS</h3>
      </div>
      <div class="col-sm-12">
        <div class="date-filter mb-3">
          <!-- <mat-form-field appearance="outline">
            <input matInput placeholder="Start date" [matDatepicker]="startDateInput" [(ngModel)]="startDate">
            <mat-datepicker-toggle matPrefix [for]="startDateInput"></mat-datepicker-toggle>
            <mat-datepicker #startDateInput></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <input matInput placeholder="End date" [matDatepicker]="endDateInput" [(ngModel)]="endDate">
            <mat-datepicker-toggle matPrefix [for]="endDateInput"></mat-datepicker-toggle>
            <mat-datepicker #endDateInput></mat-datepicker>
          </mat-form-field>

          <button mat-raised-button color="primary" oiq-loading (click)="applyFilter()">APPLY DATE FILTER</button>
          <button mat-raised-button color="accent" oiq-loading (click)="resetFilter()">RESET</button> -->

          <div *ngIf="!startDate && !endDate && total" class="my-2 text-black-50">
            Showing (<strong>{{ total }}</strong>) transactions from <strong>{{ activityStartDate }} &ndash;  {{ activityEndDate }}</strong>.
          </div>
        </div>
      </div>
      <!-- date-filter -->
    </div>

    <div class="row">
      <div class="col-sm-12">
        <table mat-table matSort [dataSource]="transactionsTable">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">{{ row.date | date }}</td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let row">{{ row.category && row.category.join(', ') || 'n/a' }}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
          </ng-container>
          <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">{{ row.pending ? 'Pending' : 'Posted' }}</td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let row">{{ -row.amount | currency }}</td>
          </ng-container>
          <ng-container matColumnDef="balance">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
            <td mat-cell *matCellDef="let row">{{ (row.payment_meta?.balance | currency) || 'n/a' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedTransactionsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedTransactionsColumns;" [ngClass]="{ 'highlighted-row': row.payment_meta?.endOfDayBalance === true, 'fw-bold': row.amount < 0  }"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="transactionsTable?.data?.length === 0"></oiq-loading-message>
      </div>
    </div>
    <!-- transactionsTable -->
  </div>
</div>

<button class="scroll-top" color="primary" (click)="scrollToTop()">
  <mat-icon>keyboard_arrow_up</mat-icon>
  <span>Scroll to top</span>
</button>
