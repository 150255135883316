<div class="content">
  <div class="content-wrapper">
    <div class="clearfix heading">
      <h2>Borrowing Base</h2>
      <button mat-stroked-button class="exhibit-report-btn float-end btn-wide ms-2" (click)="runBBPayments()">BB Payments List</button>
      <mat-form-field appearance="outline" class="report-select">
        <mat-select class="hide-selected" #report [placeholder]="'Run Report'"
                    (selectionChange)="runReport(report.value, $event)">
          <mat-option [value]="{'value': 'all'}">
            All
          </mat-option>
          <mat-option *ngFor="let option of navItems" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-stroked-button class="exhibit-report-btn float-end btn-wide me-2" (click)="runExhibit()">Run Exhibit Report</button>

    </div>

    <div>
      <div class="body mb-5" *ngIf="summaryData?.totalElements !== 0">
        <div>
          <table mat-table class="summary-table" [dataSource]="summaryData?.data" matSort>
            <ng-container [matColumnDef]="column" *ngFor="let column of summaryDisplayedColumns">
              <th mat-header-cell *matHeaderCellDef>{{ getSummaryColumnName(column) }}</th>
              <td mat-cell *matCellDef="let element" [ngClass]="'right-aligned'">
                <ng-container *ngIf="fieldBelongsToType(currenciesArray, column);else percent">
                  {{ element[column] | currency }}
                </ng-container>
                <ng-template #percent>
                  <ng-container *ngIf="fieldBelongsToType(percentsArray, column);else date">
                    {{ element[column] !== null ? (element[column] * 100 | number: '1.2-2') + '%' : '' }}
                  </ng-container>
                </ng-template>
                <ng-template #date>
                  <ng-container *ngIf="fieldBelongsToType(datesArray, column);else noType">
                    {{ element[column] | date }}
                  </ng-container>
                </ng-template>
                <ng-template #noType>{{ element[column] }}</ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="disclaimer">
              <td mat-footer-cell *matFooterCellDef>
                <strong>Totals:</strong>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="summaryColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: summaryColumnsToDisplay;"></tr>
          </table>
          <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="summaryData?.totalElements === 0"></oiq-loading-message>
        </div>
      </div>



      <div class="body tabbed-tables scrollable-tabs">
        <div class="heading">
          <h5 *ngFor="let item of navItems" [ngClass]="{'active': selectedItem?.value === item.value}" (click)="selectItem(item)">{{ item.label }}</h5>
        </div>

        <div class="body scrollable">
          <table mat-table [dataSource]="data" matSort class="mat-elevation-z8">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{ 'highlighted': getColumnName(column) === highlightedColumn }">{{ getColumnName(column) }}</th>
              <td mat-cell *matCellDef="let element" [ngClass]="{ 'highlighted': getColumnName(column) === highlightedColumn }">
                <ng-container *ngIf="fieldBelongsToType(currenciesArray, column);else percent">
                  {{ element[column] | currency }}
                </ng-container>
                <ng-template #percent>
                  <ng-container *ngIf="fieldBelongsToType(percentsArray, column);else date">
                    {{ element[column] | number: '1.2-2' }}%
                  </ng-container>
                </ng-template>
                <ng-template #date>
                  <ng-container *ngIf="fieldBelongsToType(datesArray, column);else actions">
                    {{ element[column] | date }}
                  </ng-container>
                </ng-template>
                <ng-template #actions>
                  <ng-container  *ngIf="column === 'Actions';else reason">
                    <mat-form-field appearance="outline">
                      <mat-select class="hide-selected" #dealAction [placeholder]="'Action'"
                        (selectionChange)="invokeDealAction(element, dealAction.value, $event)">
                        <mat-option [value]="'Excluded'" [disabled]="element.exemption || selectedItem.value !== 'Total_Eligible'">
                          EXCLUDE
                        </mat-option>
                        <mat-option [value]="'Included'" [disabled]="element.exemption || selectedItem.value !== 'All_Ineligible'">
                          INCLUDE
                        </mat-option>
                        <mat-option [value]="'delete'" [disabled]="!element.exemption">
                          DELETE
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </ng-container>
                </ng-template>
                <ng-template #reason>
                  <ng-container  *ngIf="column === 'reason';else noType">
                    {{ element[column] | ellipsis:[30, '...'] }}
                    <span *ngIf="element[column]?.length > 30" class="full-content">{{ element[column] }}</span>
                  </ng-container>
                </ng-template>
                <ng-template #noType>{{ element[column] }}</ng-template>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
          </table>
          <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="data?.length === 0"></oiq-loading-message>
        </div>
        <mat-paginator [length]="totalElements" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
