import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[txtAutosize]'
})
export class TextAreaAutosizeDirective {

  @Output() heightChanged = new EventEmitter<number>();

  @Input() initiateNote: boolean = false;

  constructor(private elementRef: ElementRef<HTMLTextAreaElement>) {
    this.setOverflow('hidden');
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes.initiateNote.currentValue) {
      this.adjustHeight();
    }
  }

  @HostListener('keydown', ['$event.target'])
  onKeydown(): void {
    this.adjustHeight();
  }

  private setOverflow(value: string): void {
    this.elementRef.nativeElement.style.overflowY = value;
  }

  private adjustHeight(): void {
    const textarea = this.elementRef.nativeElement;
    let height = textarea.style.height.replace('px', '');
    if (textarea.scrollHeight > 120) {
      this.setOverflow('scroll');
      textarea.style.height = '120px';
      textarea.removeEventListener('keydown', this.onKeydown.bind(this));
      if (height !== textarea.style.height) {
        this.heightChanged.emit(120);
      }
    } else {
      setTimeout(() => {
        textarea.style.height = 'auto';
        this.setOverflow('hidden');
        textarea.style.height = `${textarea.scrollHeight}px`;
        if (height !== textarea.style.height) {
          this.heightChanged.emit(textarea.scrollHeight);
        }
      }, 0);
    }
    
  }
}
