import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { PlaidService } from '@core/services';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

import * as moment from 'moment';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'oiq-plaid-report',
  templateUrl: './plaid-report.component.html',
  styleUrls: ['./plaid-report.component.scss']
})
export class PlaidReportComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  application: any;

  @Input()
  contactId: any;

  @Input()
  plaidReportId: number;


  plaidId: number;
  plaidRequests: Array<any> = [];
  plaidRequest: any = {};
  selectedReportDetail: any = {};
  activityStartDate: string;
  activityEndDate: string;

  // transactions: Array<any> = [
  //   {id: 1, date: '10/16/2019', codes: 'ov', description: 'Overdraft fee-Overdraft fee', amount: '($284.60)', balance: '($3,611.20)'},
  //   {id: 2, date: '10/16/2019', codes: 'py,dp', description: 'Direct Deposit-COMMONWEALTH CAP CLNT ADVNC 1922', amount: '$1,970.00', balance: '($3,216.76)'}
  // ];
  transactionsTable: any;
  displayedTransactionsColumns: Array<any> = [
    'date', 'category', 'description', 'pending', 'amount', 'balance'
  ];
  analytics: Array<any> = [
    {id: 1, description: 'Payroll', code: 'py', transactions: 26, total: '$66,997.09', transactionsLastMonth: 12, totalLastMonth: '$24,857.65'},
    {id: 2, description: 'Loan Debit', code: 'ld', transactions: 53, total: '($29,835.41)', transactionsLastMonth: 22, totalLastMonth: '($12,491.84)'}
  ];
  analyticsTable: any;
  displayedAnalyticsColumns: Array<any> = [
    'description', 'code', 'transactions', 'total', 'transactionsLastMonth', 'totalLastMonth'
  ];

  plaidReport: any;

  plaidRequestStatus: string;
  plaidInitiated: boolean;
  accounts = [];
  transactions = [];
  accountsList = [];
  identities = [];
  assets = [];
  assetReportId: string;
  assetReportToken: string;
  // accountBalances = [];
  // investmentHoldings = [];
  // securities = [];
  // investmentTransactions = [];
  // totalInvestmentTransactions: number;
  // liabilities = [];

  startDate: any;
  endDate: any;

  total: number;
  currentPage: number;
  pageSize = 100;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private plaidService: PlaidService,
    private router: Router,
    private viewPortScroller: ViewportScroller
  ) { }

  ngOnInit() {
    this.analyticsTable = new MatTableDataSource([]);
    this.analyticsTable.sort = this.sort;
    this.transactionsTable = new MatTableDataSource([]);

    this.getPlaidReport(this.plaidReportId);
  }

  ngAfterViewInit() {
    this.analyticsTable.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plaidReportId && changes.plaidReportId.firstChange !== true) {
      delete this.plaidReport;
      this.getPlaidReport(this.plaidReportId);
    }
  }

  applyFilter() {
  }

  resetFilter() {
    this.startDate = '';
    this.endDate = '';
  }

  reportDetailSelected() {
    this.activityStartDate = moment(this.plaidReport.startDate).format('MM/DD/YYYY');
    this.activityEndDate = moment(this.plaidReport.endDate).format('MM/DD/YYYY');

    const selectedAccountId = this.selectedReportDetail.account_id;
    const transactions  = this.transactions.filter((transaction) => transaction.account_id === selectedAccountId)
    this.total = transactions.length;
    this.transactionsTable = new MatTableDataSource(transactions);
    this.transactionsTable.sort = this.sort;
    this.selectedReportDetail.selectedOwner = this.selectedReportDetail.owners && this.selectedReportDetail.owners.length ? this.selectedReportDetail.owners[0] : '';

    const transactionAnalysisSummaries = this.calculateSummaryMetrics(transactions);
    this.analyticsTable = new MatTableDataSource(transactionAnalysisSummaries);
    this.analyticsTable.sort = this.sort;
  }

  reportDetailOwnerSelected(event) {
    this.selectedReportDetail.selectedOwner = event.value;
  }

  getPlaidReport(id: number) {
    this.plaidService.getPlaidReport(id).subscribe((data: any) => {
      this.plaidReport = data;
      if (this.plaidReport.results && this.plaidReport.results.asset) {
        this.selectedReportDetail = this.plaidReport.results.asset.report.items[0].accounts[0];
        this.selectedReportDetail['asOfDate'] = this.plaidReport.results.asset.report.items[0].date_last_updated;
        this.selectedReportDetail['selectedOwner'] = this.selectedReportDetail.owners[0];
      }

      if (data) {
        this.identities = data.results && data.results.identity ? data.results.identity : [];
        this.plaidInitiated = true;
        this.accounts = data.results && data.results.transactions ? data.results.transactions.accounts : [];
        this.accounts.map(row => {
          return this.accountsList[row.account_id] = row.name;
        });

        this.transactions = data.results && data.results.transactions ? data.results.transactions.transactions : [];

        this.assets = [];
        this.assetReportId = '';
        this.assetReportToken = '';
        // this.accountBalances = data.results.accountBalance ? data.results.accountBalance.accounts : [];
        // this.investmentHoldings = [];
        // this.securities = [];
        // this.investmentTransactions = data.results.investmentTransactions ? data.results.investmentTransactions.holdings : [];
        // this.totalInvestmentTransactions = data.results.investmentTransactions ? data.results.investmentTransactions.holdings.length : 0;
        // this.liabilities = [];
        data.results && this.reportDetailSelected();
      }
    });
  }

  calculateSummaryMetrics(transactions) {
    const categoryData = {};

    // Process each transaction in the provided transactions data
    transactions.forEach((transaction) => {
      const categoryId = transaction.category_id;

      // Initialize variables for calculations per category
      if (!categoryData[categoryId]) {
        categoryData[categoryId] = {
          categoryId,
          transactionType: transaction.transaction_type,
          category: transaction.category && transaction.category.join(', '), // Assuming category is an array
          lastMonthTransactions: 0,
          totalTransactions: 0,
          lastMonthTransactionsAmount: 0,
          totalTransactionsAmount: 0,
        };
      }

      // Update metrics per category
      categoryData[categoryId].totalTransactions += 1;
      categoryData[categoryId].totalTransactionsAmount += transaction.amount;

      // Check if the transaction is from the previous month
      const currentDate = new Date();
      const lastMonth = new Date(currentDate);
      lastMonth.setMonth(currentDate.getMonth() - 1);

      // Now format the lastMonth variable as needed
      const formattedLastMonth = `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1).toString().padStart(2, '0')}`;

      // Update metrics per category in the last month
      if (transaction.date.substring(0, 7) === formattedLastMonth) {
        categoryData[categoryId].lastMonthTransactions += 1;
        categoryData[categoryId].lastMonthTransactionsAmount += transaction.amount;
      }
    });

    // Convert the object to an array of category metrics
    const result = Object.values(categoryData);

    return result;
  }

  scrollToTop() {
    this.router.navigate([], {});
    this.viewPortScroller.scrollToPosition([0, 0])
  }

}
