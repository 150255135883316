import { Address } from '@core/models/address';
import { Component, OnInit, Input, Output, EventEmitter,ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';

@Component({
  selector: 'oiq-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() address: Address;
  @Input() index: number;
  @Input() editMode: boolean;
  @Input() addressRequired: boolean;
  @Input() horizontal: boolean;
  @Input() showSaveButton: boolean;
  @Input() showAsterisks: boolean = true;
  @Output() onRemove: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
  isGoogleAPIKeyFailed = false;

  constructor(private elRef:ElementRef) { }

  ngOnInit() {
    if (this.address && this.addressRequired) {
      this.address.get('address1').setValidators([Validators.required]);
      this.address.updateValueAndValidity();
    }
  }

  populateAddress(place) {

    const state = place.address_components.filter(mark => mark.types.includes('administrative_area_level_1'));
    const zip = place.address_components.filter(mark => mark.types.includes('postal_code'));
    const formattedAddressArray = place.formatted_address.split(',');

    this.address.get('address1').setValue(formattedAddressArray[0]);
    this.address.get('city').setValue(formattedAddressArray[1]);
    this.address.get('state').setValue(state[0].long_name);
    this.address.get('zipCode').setValue(zip && zip[0] ? zip[0].long_name : '');
    this.elRef.nativeElement.querySelector('#address2').focus();

    this.address.updateValueAndValidity();
  }

  removeAddress() {
    this.onRemove.emit(this.index);
  }

  saveAddresses() {
    this.onSave.emit(true);
  }

  handleGoogleAPIKeyFailure($event){
    this.isGoogleAPIKeyFailed = $event;
  }
}
