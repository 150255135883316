import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getNotifications(unread?:string) {
    const params:any = [];

    if(unread == 'true'){
      params['unread'] = 'true';
    }

    // params['unread'] = unread ? unread : 'false';
    return this.http.get(this.getUrl() + 'calendar-events/updates-count', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  eventsList(unread?:string, sortDir?: string, offset?: number, limit?: number, total?: number): Observable<any> {
    const params = this.prepareParams(unread, sortDir, offset, limit, total );

    return this.http.get(this.getUrl() + 'calendar-events', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  upcomingEventsList(upcomingOnly?:string, unread?:string, sortDir?: string, offset?: number, limit?: number, total?: number): Observable<any> {
    let params = this.prepareParams(unread, sortDir, offset, limit, total );

    if (upcomingOnly) { params['upcomingOnly'] = upcomingOnly; }

    return this.http.get(this.getUrl() + 'calendar-events', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  futureEventsList(futureOnly?:string, unread?:string, sortDir?: string, offset?: number, limit?: number, total?: number): Observable<any> {
    let params = this.prepareParams(unread, sortDir, offset, limit, total );

    if (futureOnly) { params['futureOnly'] = futureOnly; }

    return this.http.get(this.getUrl() + 'calendar-events', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  pastEventsList(pastOnly?:string, unread?:string, sortDir?: string, offset?: number, limit?: number, total?: number): Observable<any> {
    let params = this.prepareParams(unread, sortDir, offset, limit, total );

    if (pastOnly) { params['pastOnly'] = pastOnly; }

    return this.http.get(this.getUrl() + 'calendar-events', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  create(data: any): Observable<any> {
    return this.http.post(this.getUrl() + 'calendar-events', data)
      .pipe(map(res => {
        return res;
      }));
  }

  toggleEventRead(id: number): Observable<any> {
    return this.http.put(this.getUrl() + `calendar-events/${id}/toggle-event-read`, null)
      .pipe(map(res => {
        return res;
      }));
  }

  update(id: number, data: any) {
    return this.http.put(this.getUrl() + `calendar-events/${id}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  delete(id: number) {
    return this.http.delete(this.getUrl() + `calendar-events/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  createCalendarReminder(calendarEventId: number, data: any): Observable<any> {
    return this.http.post(this.getUrl() + `calendar-events/${calendarEventId}/calendar-reminders`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateCalendarReminder(calendarEventId: number, id: number, data: any) {
    return this.http.put(this.getUrl() + `calendar-events/${calendarEventId}/calendar-reminders/${id}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteCalendarReminder(calendarEventId: number, id: number): Observable<any> {
    return this.http.delete(this.getUrl() + `calendar-events/${calendarEventId}/calendar-reminders/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getUrl(): string {
    return 'calendar/';
  }

  prepareParams(unread?:string, sortDir?: string, offset?: number, limit?: number, total?: number) {
    const params: any = {};

    if (unread) { params['unread'] = unread; }
    if (sortDir) { params['sortDir'] = sortDir.toString(); } else { params['sortDir'] = 'DESC'; }
    if (offset) { params['offset'] = offset.toString(); }
    if (limit) { params['limit'] = limit.toString(); }
    if (total) { params['total'] = total.toString(); }

    return params;
  }

}
