<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Edit Notification Template</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group" *ngIf="!form.value.id">
            <mat-label>Base Template From</mat-label>
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="template" [ngModelOptions]="{ standalone: true }" (selectionChange)="templateChanged()">
                <mat-option *ngFor="let row of templates" [value]="row">
                  {{ row.type | type | titlecase }} - {{ (!row.name) ? '[SYSTEM DEFAULT]' : row.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-label>Name</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="name" formControlName="name">
            </mat-form-field>
          </div>

          <div class="form-group">
            <mat-label>From</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="from" formControlName="from">
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="form.value.medium === 'Email'">
            <mat-label>Subject</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="email" formControlName="subject">
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="form.value.medium === 'Email'">
            <mat-checkbox color="primary" formControlName="useMasterTemplate">
              Use Master Template
            </mat-checkbox>
          </div>

          <div class="form-group">
            <mat-label>Body</mat-label>
            <mat-form-field appearance="outline" *ngIf="form.value.medium === 'Email'">
              <textarea matInput name="body" formControlName="body"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="form.value.medium === 'SMS'">
              <textarea matInput formControlName="body"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid" (click)="save()">
        Save
      </button>
    </div>
  </form>
</div>
