<section class="small-headlines my-5">

  <div class="row">
    <div class="col-sm-12">
      <mat-label><strong>{{ row.subscriberName }}</strong> / <span>{{ account }} Account</span></mat-label>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 scrolable">
      <table>
        <thead class="bg-onyx-light">
          <tr>
            <th>Open Date</th>
            <th *ngIf="row.amount1 && row.amount1Qualifier === 'O'">Original Amount</th>
            <th *ngIf="row.amount1 && row.amount1Qualifier === 'L'">Credit Limit</th>
            <th *ngIf="row.amount2 && row.amount2Qualifier === 'H'">High Balance</th>
            <th *ngIf="row.amount2 && row.amount2Qualifier === 'C'">Charge Off Amount</th>
            <th>Status Date</th>
            <th>Past Due</th>
            <th>Last Payment Attempt</th>
            <th *ngIf="row.monthlyPaymentAmount">Scheduled Payment</th>
            <th>Balance Date</th>
            <th>Current Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ row.openDate | experianDate }}</td>
            <td *ngIf="row.amount1 && row.amount1Qualifier === 'O'">{{ row.amount1 | currency }}</td>
            <td *ngIf="row.amount1 && row.amount1Qualifier === 'L'">{{ row.amount1 | currency }}</td>
            <td *ngIf="row.amount2 && row.amount2Qualifier === 'H'">{{ row.amount2 | currency }}</td>
            <td *ngIf="row.amount2 && row.amount2Qualifier === 'C'">{{ row.amount2 | currency }}</td>
            <td>{{ row.statusDate | experianDate }}</td>
            <td>{{ row.amountPastDue | currency }}</td>
            <td>{{ (row.lastPaymentDate | experianDate) || 'n/a' }}</td>
            <td *ngIf="row.monthlyPaymentAmount">{{ row.monthlyPaymentAmount | currency }}</td>
            <td>{{ row.balanceDate | experianDate }}</td>
            <td>{{ row.balanceAmount | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-xl-8">
      <div class="d-flex">
        <div class="col-sm-3 bg-light pt-3">
          <mat-label>Account Condition:</mat-label>
        </div>
        <div class="col-sm-9 pt-3">
          <div class="field-value">
            {{ row.enhancedPaymentData.enhancedAccountConditionDescription || mapToDescription('enhancedAccountCondition', row.enhancedPaymentData.enhancedAccountCondition) || '&ndash;' }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-4">
      <div class="d-flex">
        <div class="col-sm-3 col-xl-5 bg-light pt-xl-3">
          <mat-label>Account #:</mat-label>
        </div>
        <div class="col-sm-9 col-xl-7 pt-xl-3">
          <div class="field-value">
            {{ row.accountNumber  || '&ndash;' }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-8">
      <div class="d-flex">
        <div class="col-sm-3 bg-light">
          <mat-label>Payment Status:</mat-label>
        </div>
        <div class="col-sm-9">
          <div class="field-value">
            {{ row.enhancedPaymentData.enhancedPaymentStatusDescription || mapToDescription('enhancedPaymentStatus', row.enhancedPaymentData.enhancedPaymentStatus)  || '&ndash;' }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-4">
      <div class="d-flex">
        <div class="col-sm-3 col-xl-5 bg-light">
          <mat-label>Responsibility:</mat-label>
        </div>
        <div class="col-sm-9 col-xl-7">
          <div class="field-value">
            {{ row.ecoaDescription || mapToDescription('ecoa', row.ecoa) }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-8">
      <div class="d-flex">
        <div class="col-sm-3 bg-light">
          <mat-label>Account Type:</mat-label>
        </div>
        <div class="col-sm-9">
          <div class="field-value">
            {{ row.accountTypeDescription || mapToDescription('accountType', row.accountType) }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-4">
      <div class="d-flex">
        <div class="col-sm-3 col-xl-5 bg-light">
          <mat-label>Account Terms:</mat-label>
        </div>
        <div class="col-sm-9 col-xl-7">
          <div class="field-value">
            {{ row.enhancedPaymentData.enhancedTermsDescription || (row.enhancedPaymentData.enhancedTerms === 'REV' ? mapToDescription('enhancedTerms', row.enhancedPaymentData.enhancedTerms) : row.enhancedPaymentData.enhancedTerms) }} {{ row.enhancedPaymentData.enhancedTermsFrequencyDescription || mapToDescription('enhancedTermsFrequency', row.enhancedPaymentData.enhancedTermsFrequency) }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xl-8">
      <div class="d-flex">
        <div class="col-sm-3 bg-light">
          <h3>PAYMENT HISTORY</h3>
          <p class="info-text">(Up to 25 months)</p>
        </div>
        <div class="col-sm-9 scrollable">
          <table mat-table [dataSource]="row.paymentHistoryDatasource">
            <ng-container matColumnDef="year">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td class="bg-light" mat-cell *matCellDef="let row">{{ row.year }}</td>
            </ng-container>
            <ng-container *ngFor="let month of months" [matColumnDef]="month">
              <th mat-header-cell *matHeaderCellDef>{{ month.toUpperCase() }}</th>
              <td mat-cell *matCellDef="let row">{{ row[month] }}</td>
            </ng-container>
            <tr class="bg-light" mat-header-row *matHeaderRowDef="paymentHistoryColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: paymentHistoryColumns;"></tr>
          </table>
          <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="row.delinquencyDatasource?.data?.length === 0"></oiq-loading-message>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-xl-4">
      <div class="d-flex">
        <div class="col-sm-3 col-xl-5 bg-light">
          <h3>DELINQUENCY COUNTER</h3>
          <p class="info-text">(Past 7 years)</p>
          <div class="delinquency-data">
            <div class="d-flex">
              <mat-label>Worst Delinquency:</mat-label>
              <div class="field-value">{{ row.enhancedPaymentData.maxDelinquencyCodeDescription || '&ndash;' }}</div>
            </div>
            <div class="d-flex">
              <mat-label>Worst Delinq Date:</mat-label>
              <div class="field-value">{{ (row.maxDelinquencyDate | experianDate) || '&ndash;' }}</div>
            </div>
            <div class="d-flex">
              <mat-label>Months Reviewed:</mat-label>
              <div class="field-value">{{ (row.monthsHistory | number) || '&ndash;' }}</div>
            </div>
          </div>
          <div>&nbsp;</div>
        </div>
        <div class="col-sm-9 col-xl-7">
          <table mat-table [dataSource]="row.delinquencyDatasource">
            <ng-container matColumnDef="thirty">
              <th mat-header-cell *matHeaderCellDef>30</th>
              <td mat-cell *matCellDef="let row">{{ row.thirty | number }}</td>
            </ng-container>
            <ng-container matColumnDef="sixty">
              <th mat-header-cell *matHeaderCellDef>60</th>
              <td mat-cell *matCellDef="let row">{{ row.sixty | number }}</td>
            </ng-container>
            <ng-container matColumnDef="ninety">
              <th mat-header-cell *matHeaderCellDef>90+</th>
              <td mat-cell *matCellDef="let row">{{ row.ninety | number }}</td>
            </ng-container>
            <ng-container matColumnDef="derog">
              <th mat-header-cell *matHeaderCellDef>Derog</th>
              <td mat-cell *matCellDef="let row">{{ row.derog | number }}</td>
            </ng-container>
            <tr class="bg-light" mat-header-row *matHeaderRowDef="delinquencyColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: delinquencyColumns;"></tr>
          </table>
          <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="row.delinquencyDatasource?.data?.length === 0"></oiq-loading-message>
        </div>
      </div>
    </div>
  </div>
</section>
