import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { merge } from "rxjs";
import { tap } from 'rxjs/operators';

import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';

import { Subscription } from 'rxjs';

import { ApplicationService, FundingService, SyndicatorService } from '@core/services';

@Component({
  selector: 'oiq-funding-syndications',
  templateUrl: './funding-syndications.component.html',
  styleUrls: ['./funding-syndications.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FundingSyndicationsComponent implements OnInit, AfterViewInit {
	private applicationSubscription: Subscription;

	funding: any;
  application: any;

  rows: Array<any> = [];
  datasource: any = new MatTableDataSource([]);
  total: number;
  currentPage: number;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  repayments: Array<any> = [];

  expandedElement: any = null;

  columns: string[] = ['view', 'status', 'syndicator', 'syndicatedPercent', 'principalSyndicated'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    private applicationService: ApplicationService,
    private fundingService: FundingService,
    private syndicatorService: SyndicatorService
  ) { }

  ngOnInit() {
  	this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;

    	if (this.application && this.application.status === 'Funded') {
        this.getFundingSyndications();
      }
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();;
          this.getFundingSyndications();
        })
      )
      .subscribe();
  }

  toggleRepaymentsRow(row, event) {
    event.stopPropagation();

    this.expandedElement = this.expandedElement === row ? null : row;

    if (this.expandedElement) {
      this.syndicatorService.listRepayments(row.syndicatorId, this.application.fundingId).subscribe((data) => {
        this.repayments = data.content;
      });
    }
  }

  initiateRepayment(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = 'Are you sure you want to initiate syndicator repayment?';
    dialogConfig.width = '650px';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.syndicatorService.initiateRepayment(row.syndicatorId).subscribe((data) => {

        });
      }
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
  }

  getFundingSyndications() {
    this.fundingService.listSyndications(
      this.application.fundingId,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir
    ).subscribe((r: any) => {
      this.total = r.totalElements;
      this.rows = r.content;

      let observables = [];

      this.rows.forEach((row) => {
        row.principalSyndicated = row.fundedAmountSyndicated;

        this.syndicatorService.getSettings(row.syndicatorId).subscribe((data) => {
          row.automaticAch = data.automaticAch;
        });
      });

      this.datasource = new MatTableDataSource(r.content);
      this.datasource.sort = this.sort;
    });
  }
}

