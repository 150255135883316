import { Component, OnInit, Input } from '@angular/core';

import { ApplicationService } from '@core/services';

@Component({
  selector: 'inline-notes',
  templateUrl: './inline-notes.component.html',
  styleUrls: ['./inline-notes.component.scss']
})
export class InlineNotesComponent implements OnInit {

  @Input() applicationId: number;
  @Input() category: string;
  @Input() notes: Array<any> = [];
  @Input() disabled: boolean;

  addingNote: boolean = false;

  newNote: string;

  notesLimit: number = 2;

  constructor(private applicationService: ApplicationService) { }

  ngOnInit() {
    this.notes.reverse();
  }

  toggleAddNote() {
    this.addingNote = !this.addingNote;
  }

  toggleNotesLimit() {
    this.notesLimit > 2 ? this.notesLimit = 2 : this.notesLimit = this.notes.length;
  }

  addNote() {

    this.applicationService.saveNote(this.applicationId, { category: this.category, content: this.newNote })
      .subscribe((r: any) => {
      },
        null,
        () => {
          this.applicationService.getNotes(this.applicationId, this.category).subscribe((results: any) => {
            this.notes = results.content.reverse();
          });

          this.newNote = '';
          this.toggleAddNote();
        }
      );
  }

}
