import { Component, OnInit, Inject } from '@angular/core';
import { NaicsService, SicService, ClientService } from '@core/services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'oiq-industry-picker-dialog',
  templateUrl: './industry-picker-dialog.component.html',
  styleUrls: ['./industry-picker-dialog.component.scss']
})
export class IndustryPickerDialogComponent implements OnInit {

  activeTable: string = 'sic';

  searching = false;

  sicSearch: string;
  sicSearchResults = [];

  naicsSearch: string;
  naicsSearchResults = [];

  displayedColumns = ['code', 'description', 'division', 'type', 'actions'];

  sicDivisionsLoading = false;
  sicDivisions = [];
  sicDivision: string;

  majorGroupsLoading = false;
  majorGroups = [];
  majorGroup: string;

  sicIndustryGroupsLoading = false;
  sicIndustryGroups = [];
  sicIndustryGroup: string;

  industriesLoading = false;
  industries = [];
  industry: any;

  naicsMatchesLoading = false;
  naicsMatches = [];
  naics: any;


  naicsSectorsLoading = false;
  naicsSectors = [];
  naicsSector: string;

  subSectorsLoading = false;
  subSectors = [];
  subSector: string;

  industryGroupsLoading = false;
  industryGroups = [];
  industryGroup: string;

  naicsIndustriesLoading = false;
  naicsIndustries = [];
  naicsIndustry: string;

  nationalIndustriesLoading = false;
  nationalIndustries = [];
  nationalIndustry: string;

  sicMatchesLoading = false;
  sicMatches = [];
  sic: string;

  sicCode: string;
  sicDescription: string;

  naicsCode: string;
  naicsDescription: string;

  sicId = null;
  naicsId = null;

  isSearchBySicCode: boolean = false;
  sicPlaceholderMessage: string = 'Type to search by industry term...';

  constructor(
    private sicService: SicService,
    private naicsService: NaicsService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<IndustryPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getSicDivisions();
  }

  selectTable(table) {
    this.activeTable = table;
    if (this.activeTable === 'sic') {
      this.displayedColumns = ['code', 'description', 'division', 'type', 'actions'];
      this.resetNaics();
      this.getSicDivisions();
    } else {
      this.displayedColumns = ['code', 'description', 'type', 'actions'];
      this.resetSic();
      this.getNaicsSectors();
    }
  }

  resetSic() {
    this.sicDivision = '';
    this.sicDivisions = [];
    
    this.majorGroup = '';
    this.majorGroups = [];
    
    this.sicIndustryGroup = '';
    this.sicIndustryGroups = [];

    this.industry = '';
    this.industries = [];

    this.sic = '';
    this.naics = '';
    this.naicsMatches = [];

    this.sicCode = '';
    this.sicDescription = '';

    this.sicId = null;
  }

  resetNaics() {
    this.naicsSector = '';
    this.naicsSectors = [];

    this.subSector = '';
    this.subSectors = [];

    this.industryGroup = '';
    this.industryGroups = [];

    this.naicsIndustry = '';
    this.naicsIndustries = [];

    this.nationalIndustry = '';
    this.nationalIndustries = [];

    this.naics = '';
    this.sic = '';
    this.sicMatches = [];

    this.naicsCode = '';
    this.naicsDescription = '';

    this.naicsId = null;
  }

  getToolTipDEata(entity, code) {
    if (entity.length) {
      if (code && !code.id) {
        let msg = '';
        msg = entity.filter(r => r.code === code)[0];
        if (msg) {
          return msg['description'] ? msg['description'] : msg;
        } else {
          return '';
        }
      } else if (code && code.description) {
        let msg = '';
        msg = code;
        return msg['description'];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  searchSic(term: string) {
    this.searching = true;
    if (this.isSearchBySicCode) {
      this.sicService.searchByCode(term)
        .subscribe((r: any) => {
          this.searching = false;
          this.sicSearchResults = r;
        });
    } else {
      this.sicService.search(term)
      .subscribe((r: any) => {
        this.searching = false;
        this.sicSearchResults = r;
      });
    }
  }

  setIsSearchByCode(flag: boolean) {
    this.isSearchBySicCode = flag;
    this.sicSearch = '';
    if (flag) {
      this.sicPlaceholderMessage = 'Enter a 4-digit SIC code...';
    } else {
      this.sicPlaceholderMessage = 'Type to search by industry term...';
    }
  }

  searchNaics(term: string) {
    this.searching = true;
    this.naicsService.search(term)
    .subscribe((r: any) => {
      this.searching = false;
      this.naicsSearchResults = r;
    });
  }

  populateSicSelections(division, code, description) {
    let observables = [];
    this.sicDivision = division;
    observables.push(this.sicService.getMajorGroups(division));
    this.majorGroupsLoading = true;
    if (code.length > 1) {
      observables.push(this.sicService.getIndustryGroups(code.substring(0, 2)));
      this.sicIndustryGroupsLoading = true;
      if (code.length > 2) {
        observables.push(this.sicService.getIndustry(code.substring(0, 3)));
        this.industriesLoading = true;
      }
    }

    forkJoin(observables).subscribe((data) => {
      this.majorGroupsLoading = false;
      this.majorGroups = data[0] as Array<any>;
      this.majorGroup = code.substring(0, 2);
      this.sicIndustryGroups = [];
      this.sicIndustryGroup = '';
      this.industries = [];
      this.industry = '';
      this.sicDescription = '';
      this.sicCode = '';
      this.sicId = '';
      this.naicsMatches = [];
      this.naics = '';

      if (data.length > 1) {
        
        this.sicIndustryGroups = [];
        this.sicIndustryGroup = '';
        this.industries = [];
        this.industry = '';
        this.sicDescription = '';
        this.sicCode = '';
        this.sicId = '';
        this.naicsMatches = [];
        this.naics = '';
        
        this.sicIndustryGroupsLoading = false;
        this.sicIndustryGroups = data[1] as Array<any>;

        if (data.length > 2) {
          this.sicIndustryGroup = code.substring(0, 3);
          this.industries = [];
          this.industry = '';
          this.sicDescription = '';
          this.sicCode = '';
          this.sicId = '';
          this.naicsMatches = [];
          this.naics = '';
          if (data[2]['length']) {
            this.industriesLoading = false;
            this.industries = data[2] as Array<any>;
            
            if (code.length > 3) {
              this.industry = this.industries.filter(industry => industry.description === description)[0];

              this.sicDescription = this.industry.description;
              this.sicCode = this.industry.code;
              this.sicId = this.industry.id;

              this.naicsMatchesLoading = true;
  
              this.naicsService.getNaicsCrossReference(this.sicCode)
              .subscribe((r: any) => {
                this.naicsMatchesLoading = false;
                this.naicsMatches = r;
              })
            }     
          }
        }
      }
    })
  }

  populateNaicsSelections(naics, code) {
    let observables = [];
    this.naicsSector = code.substring(0, 2);
    observables.push(this.naicsService.getSubSectors(this.naicsSector));
    this.subSectorsLoading = true;
    if (code.length > 2) {
      observables.push(this.naicsService.getIndustryGroups(code.substring(0, 3)));
      this.industryGroupsLoading = true;
      if (code.length > 3) {
        observables.push(this.naicsService.getNaicsIndustry(code.substring(0, 4)));
        this.naicsIndustriesLoading = true;
        if (code.length > 4) {
          observables.push(this.naicsService.getNationalIndustry(code.substring(0, 5)));
          this.nationalIndustriesLoading = true;
        }
      }
    }

    forkJoin(observables).subscribe((data) => {
      this.subSectorsLoading = false;
      this.subSectors = data[0] as Array<any>;
      this.subSector = code.substring(0, 3);
      this.industryGroups = [];
      this.industryGroup = '';
      this.naicsIndustries = [];
      this.naicsIndustry = '';
      this.nationalIndustries = [];
      this.nationalIndustry = '';
      this.naicsDescription = '';
      this.naicsCode = '';
      this.naicsId = '';
      this.sicMatches = [];
      this.sic = '';

      if (data.length > 1) {
        
        this.industryGroups = [];
        this.industryGroup = '';
        this.naicsIndustries = [];
        this.naicsIndustry = '';
        this.nationalIndustries = [];
        this.nationalIndustry = '';
        this.naicsDescription = '';
        this.naicsCode = '';
        this.naicsId = '';
        this.sicMatches = [];
        this.sic = '';
        
        this.industryGroupsLoading = false;
        this.industryGroups = data[1] as Array<any>;

        if (data.length > 2) {
          this.industryGroup = code.substring(0, 4);
          this.naicsIndustries = [];
          this.naicsIndustry = '';
          this.nationalIndustries = [];
          this.nationalIndustry = '';
          this.naicsDescription = '';
          this.naicsCode = '';
          this.naicsId = '';
          this.sicMatches = [];
          this.sic = '';

          if (data[2]['length']) {
            this.naicsIndustriesLoading = false;
            this.naicsIndustries = data[2] as Array<any>;
            
            if (data.length > 3) {
              this.naicsIndustry = code.substring(0, 5);
              this.nationalIndustries = [];
              this.nationalIndustry = '';
              this.naicsDescription = '';
              this.naicsCode = '';
              this.naicsId = '';
              this.sicMatches = [];
              this.sic = '';
    
              if (data[3]['length']) {
                this.nationalIndustriesLoading = false;
                this.nationalIndustries = data[3] as Array<any>;

                if (code.length > 5) {
                  this.nationalIndustry = this.nationalIndustries.filter(industry => industry.description === naics.description)[0];
                  
                  this.naicsDescription = this.nationalIndustry['description'];
                  this.naicsCode = this.nationalIndustry['code'];
                  this.naicsId = this.nationalIndustry['id'];

                  this.sicMatchesLoading = true;

                  this.sicService.getSicCrossReference(this.naicsCode)
                  .subscribe((r: any) => {
                    this.sicMatchesLoading = false;
                    this.sicMatches = r;
                  })
                }
              }     
            }
          }
        }
      }
    })
  }

  disableTooltip(titleElement: HTMLElement) {
    return titleElement.scrollWidth === titleElement.clientWidth;
  }

  selectIndustry(sic) {
    this.sicSearchResults.forEach(element => {
      element['selected'] = false;
    });

    sic['selected'] = true;

    this.sicDivision = '';
    this.majorGroups =[];
    this.majorGroup = '';
    this.sicIndustryGroups = [];
    this.sicIndustryGroup = '';
    this.industries = [];
    this.industry = '';

    this.populateSicSelections(sic.division, sic.code, sic.description);
  }

  selectNaicsIndustry(naics) {
    this.naicsSearchResults.forEach(element => {
      element['selected'] = false;
    });

    naics['selected'] = true;

    this.naicsSector = '';

    this.subSector = '';
    this.subSectors = [];

    this.industryGroup = '';
    this.industryGroups = [];

    this.naicsIndustry = '';
    this.naicsIndustries = [];

    this.nationalIndustry = '';
    this.nationalIndustries = [];

    this.populateNaicsSelections(naics, naics.code);
  }

  getSicDivisions() {
    this.resetSic();
    this.sicDivisionsLoading = true;

    this.sicService.getDivisions()
    .subscribe((r: any) => {
      this.sicDivisionsLoading = false;
      this.sicDivisions = r;
    });
  }

  onDivisionSelect(division) {
    this.majorGroups = [];
    this.majorGroup = '';
    this.sicIndustryGroups = [];
    this.sicIndustryGroup = '';
    this.industries = [];
    this.industry = '';
    this.naicsMatches = [];
    this.naics = '';
    this.majorGroupsLoading = true;
    this.sicService.getMajorGroups(division)
      .subscribe((r: any) => {
        this.majorGroupsLoading = false;
        this.majorGroups = r;
      }
    );
  }

  onMGSelect(group) {
    this.sicIndustryGroups = [];
    this.sicIndustryGroup = '';
    this.industries = [];
    this.industry = '';
    this.naicsMatches = [];
    this.naics = '';
    this.sicIndustryGroupsLoading = true;
    this.sicService.getIndustryGroups(group)
     .subscribe((r: any) => {
        this.sicIndustryGroupsLoading = false;
        this.sicIndustryGroups = r;
      }
    );
  }

  onSicIGSelect(group) {
    this.industries = [];
    this.industry = '';
    this.naicsMatches = [];
    this.naics = '';
    this.industriesLoading = true;
    this.sicService.getIndustry(group)
    .subscribe((r: any) => {
      this.industriesLoading = false;
      this.industries = r;
     }
   );
  }

  onIndustrySelect(industry) {
    this.naicsMatches = [];
    this.naics = '';
    this.naicsMatchesLoading = true;
    this.sicDescription = industry.description;
    this.sicCode = industry.code;
    this.sicId = industry.id;

    this.naicsService.getNaicsCrossReference(this.sicCode)
      .subscribe((r: any) => {
        this.naicsMatchesLoading = false;
        this.naicsMatches = r;
      }
    );
  }

  selectNaicsCrossRef(naics) {
    this.naicsCode = naics.code;

    this.naicsId = naics.id;
  }

  getNaicsSectors() {
    this.resetNaics();
    this.naicsSectorsLoading = true;

    this.naicsService.getSectors()
    .subscribe((r: any) => {
      this.naicsSectorsLoading = false;
      this.naicsSectors = r;
    });
  }

  onSectorSelect(sector) {
    this.subSectors = [];
    this.subSector = '';
    this.industryGroups = [];
    this.industryGroup = '';
    this.naicsIndustries = [];
    this.naicsIndustry = '';
    this.nationalIndustries = [];
    this.nationalIndustry = '';
    this.sicMatches = [];
    this.sic = '';
    this.subSectorsLoading = true;
    this.naicsService.getSubSectors(sector)
      .subscribe((r: any) => {
        this.subSectorsLoading = false;
        this.subSectors = r;
      }
    );
  }

  onSubSectorSelect(subSector) {
    this.industryGroups = [];
    this.industryGroup = '';
    this.naicsIndustries = [];
    this.naicsIndustry = '';
    this.nationalIndustries = [];
    this.nationalIndustry = '';
    this.sicMatches = [];
    this.sic = '';
    this.industryGroupsLoading = true;
    this.naicsService.getIndustryGroups(subSector)
      .subscribe((r: any) => {
        this.industryGroupsLoading = false;
        this.industryGroups = r;
      }
    );
  }

  onIGSelect(group) {
    this.naicsIndustries = [];
    this.naicsIndustry = '';
    this.nationalIndustries = [];
    this.nationalIndustry = '';
    this.sicMatches = [];
    this.sic = '';
    this.naicsIndustriesLoading = true;
    this.naicsService.getNaicsIndustry(group)
     .subscribe((r: any) => {
        this.naicsIndustriesLoading = false;
        this.naicsIndustries = r;
      }
    );
  }

  onNaicsIndustrySelect(industry) {
    this.nationalIndustries = [];
    this.nationalIndustry = '';
    this.sicMatches = [];
    this.sic = '';
    this.nationalIndustriesLoading = true;
    this.naicsService.getNationalIndustry(industry)
     .subscribe((r: any) => {
        this.nationalIndustriesLoading = false;
        this.nationalIndustries = r;
      }
    );
  }

  onNationalIndustrySelect(industry) {
    this.sicMatches = [];
    this.sic = '';
    this.sicMatchesLoading = true;
    this.naicsDescription = industry.description;
    this.naicsCode = industry.code;
    this.naicsId = industry.id;

    this.sicService.getSicCrossReference(this.naicsCode)
      .subscribe((r: any) => {
        this.sicMatchesLoading = false;
        this.sicMatches = r;
      }
    );
  }

  selectSicCrossRef(sic) {
    this.sicCode = sic.code;

    this.sicId = sic.id;
  }

  save() {

    const data = {};

    data['sicId'] = this.sicId;

    data['naicsId'] = this.naicsId;

    this.clientService.patchClientData(this.data.clientId, data)
      .subscribe((r: any) => {
        this.clientService.setClientObject(r)
        this.dialogRef.close(r);
      }
    );
  }

}
