import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'date', // Override the built-in 'date' pipe
  pure: true // The pipe is pure, so it only needs to be re-evaluated when the input value changes
})
export class ExtendedDatePipe extends DatePipe implements PipeTransform {

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;

  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    // Check if the value is an array and convert it to a date object
    if (Array.isArray(value) && value.length === 3) {
      const [year, month, day] = value;
      value = new Date(year, month - 1, day);
    }

    // Call the original DatePipe transform method
    return super.transform(value, format, timezone, locale);
  }
}
