import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { Address } from './address';

export class Events extends FormGroup {
  constructor() {
    super({
      events: new FormArray([])
    });
  }
}

export class Event extends FormGroup {
  constructor() {
    super({
      event: new FormControl(),
      emailTemplateId: new FormControl(),
      smsTemplateId: new FormControl(),
      active: new FormControl(false),
      client: new EventNotification(),
      applicationSubmitter: new EventNotification(),
      underwriter: new EventNotification(),
      tenantUsers: new EventNotification(),
      tenantEmailAddresses: new EventNotification(),
      isoSalesRepresentative: new EventNotification(),
      isoUsers: new EventNotification(),
      isoEmailAddresses: new EventNotification(),
      isoManager: new EventNotification(),
      createStipulations: new FormArray([]),
      updateStipulations: new FormArray([]),
      type: new FormControl()
    });
  }
}

export class EventNotification extends FormGroup {
  constructor() {
    super({
      medium: new FormControl(),
      enabled: new FormControl(false),
      emailTemplateId: new FormControl(),
      smsTemplateId: new FormControl(),
      targetValues: new FormControl()
    });
  }
}

export class StipulationAction extends FormGroup {
  constructor() {
    super({
      name: new FormControl(),
      enabled: new FormControl(false),
      stipulationId: new FormControl(),
      status: new FormControl()
    });
  }
}
