import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApplicationService, FundingService, SyndicatorService } from '@core/services';
import { forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'oiq-claim-deal-dialog',
  templateUrl: './make-offer-dialog.component.html',
  styleUrls: ['./make-offer-dialog.component.scss']
})
export class MakeOfferDialogComponent implements OnInit {
  form: FormGroup;
  application: any;
  syndicationOffer: any;
  fieldUpdating: string;
  funding;
  totalOwed = 0;
  paybackAmount = 0;
  commission = 0;

  constructor(
    private fb: FormBuilder,
    private applicationService: ApplicationService,
    private syndicatorService: SyndicatorService,
    private fundingService: FundingService,
    public dialogRef: MatDialogRef<MakeOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      percentSyndicated: ['', [Validators.required, (control) => this.validatePercentSyndicated(this.syndicationOffer, control)]],
      principalSyndicated: ['', Validators.required]
    });

    this.form.get('percentSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'percentSyndicated') {
        return;
      }

      this.fieldUpdating = 'principalSyndicated';

      this.onPercentSyndicatedChange();

      this.fieldUpdating = null;
    });

    this.form.get('principalSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'principalSyndicated') {
        return;
      }

      this.fieldUpdating = 'percentSyndicated';

      this.onPrincipalSyndicatedChange();

      this.fieldUpdating = null;
    });

    this.getApplication();
  }

  onPercentSyndicatedChange() {
    if (this.form.get('percentSyndicated').value !== '' && this.application) {
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      const percentSyndicated = parseFloat(this.form.get('percentSyndicated').value);

      if (this.syndicationOffer.calculationLogic === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        this.form.get('principalSyndicated').setValue(fundedAmount * (percentSyndicated / 100));
      } else {
        this.form.get('principalSyndicated').setValue(disbursementAmount * (percentSyndicated / 100));
      }

      this.calculatePaybackAmount();
      this.calculateCommission();
      this.calculateTotalOwed();
    }
  }

  onPrincipalSyndicatedChange() {
    if (this.form.get('principalSyndicated').value !== '' && this.application) {
      const principalSyndicated = parseFloat(this.form.get('principalSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);

      if (this.syndicationOffer.calculationLogic === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        const percentSyndicated = this.roundPercent((principalSyndicated * 100) / fundedAmount);
        this.form.get('percentSyndicated').setValue(percentSyndicated);
      } else {
        const percentSyndicated = this.roundPercent((principalSyndicated * 100) / disbursementAmount);
        this.form.get('percentSyndicated').setValue(percentSyndicated);
      }

      this.calculatePaybackAmount();
      this.calculateCommission();
      this.calculateTotalOwed();
    }
  }

  calculatePaybackAmount() {
    const paybackAmount = parseFloat(this.application.appliedFundingOffer.paybackAmount);
    const percentSyndicated = parseFloat(this.form.get('percentSyndicated').value);
    this.paybackAmount = paybackAmount * (percentSyndicated / 100);
  }

  calculateCommission() {
    const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
    const percentSyndicated = parseFloat(this.form.get('percentSyndicated').value);
    this.commission = fundedAmount * (this.syndicationOffer.commissionPercent / 100) * (percentSyndicated/ 100);
  }

  calculateTotalOwed() {
    const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
    const percentSyndicated = parseFloat(this.form.get('percentSyndicated').value);
    const principalSyndicated = parseFloat(this.form.get('principalSyndicated').value);
    this.totalOwed =  principalSyndicated + fundedAmount * (this.syndicationOffer.commissionPercent / 100) * (percentSyndicated/ 100);
  }

  validatePercentSyndicated(syndicationOffer, control: AbstractControl) {
    if (!syndicationOffer) {
      return null;
    }

    if (parseFloat(control.value) < syndicationOffer.minimumPercentSyndicated) {
      return {
        minimumPercentSyndicated: true
      };
    } else if (parseFloat(control.value) > syndicationOffer.maximumPercentSyndicated) {
      return {
        maximumPercentSyndicated: true
      };
    }

    return null;
  }

  getApplication() {
    this.applicationService
      .getById(this.data.applicationId)
      .pipe(
        mergeMap(application => {
          this.application = application;
          return forkJoin([
            this.fundingService.getById(application.fundingId),
            this.syndicatorService.getSyndicationOfferById(this.data.syndicatorId, this.data.id),
          ]);
        })
      )
      .subscribe((r: any) => {
        this.funding = r[0];
        this.syndicationOffer = r[1];
      });
  }

  submit() {
    this.applicationService
      .acceptOffer(this.data.applicationId, this.data.id, parseFloat(this.form.get('percentSyndicated').value))
      .subscribe((r: any) => {
        this.dialogRef.close();
      });
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  geTitleBasedOnCalculationObject() {
    if (this.syndicationOffer && this.syndicationOffer.calculationLogic === 'PRINCIPAL_SYNDICATED_AMOUNT') {
      return 'Calculated from principal amount';
    } else {
      return 'Calculated from disbursement amount';
    }
  }
}
