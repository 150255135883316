<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Create new credit report</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-4" *ngIf="showContacts">
          <mat-label>&nbsp;</mat-label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="contactId" [ngModelOptions]="{ standalone: true }"
              (selectionChange)="contactSelected()">
              <mat-option *ngFor="let row of contacts" [value]="row.id">
                {{ row.firstName }} {{ row.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>First Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="firstName" [readonly]="loading || !showContacts">
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>Last Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="lastName" [readonly]="loading || !showContacts">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-label>Social Security Number *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput mask="000-00-0000" formControlName="ssn" [readonly]="loading">
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-label>Date of Birth *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="picker" formControlName="dob" [readonly]="loading" oiqValidateDate>
            <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="form-row-wrapper">
        <div class="row">
          <div class="col-sm-12">
            <h3>ADDRESS</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="selectedAddress" placeholder="Add New Address"
                (selectionChange)="addressSelected()" [ngModelOptions]="{ standalone: true }">
                <mat-option [value]="'add'">Add New Address</mat-option>
                <mat-option *ngFor="let address of form.get('addresses')['controls']" [value]="address.value">
                  {{ address.value.address1 }} {{ address.value.address2 }},
                  {{ address.value.city }}, {{ address.value.state }} {{ address.value.zipCode }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div formArrayName="addresses">
          <ng-container *ngFor="let address of form.get('addresses')['controls']; let i = index;">
            <oiq-address class="address-wrapper"
              *ngIf="selectedAddress?.id === address.value.id || (selectedAddress === null && address.id === null)"
              [address]="address" [index]="i"
              [showSaveButton]="(address.dirty || addressModified || !addressesSaving) && form.valid"
              (onSave)="saveAddresses()" (onRemove)="removeAddress($event)" [class]="'col-sm-4 ps-0'"></oiq-address>
          </ng-container>

        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button cdkFocusInitial color="accent" type="submit" oiq-loading
        [buttonDisabled]="!form.valid || addressModified || !form.get('address').value.id" (click)="submit()">
        Submit
      </button>
      <div class="error-text warn">
        <ng-container *ngIf="addressModified || !form.get('address').value.id">
          You have to save addresses first
        </ng-container>
        <br>
        <ng-container
          *ngIf="!form.valid && (!form.get('addresses').valid || !form.get('addresses').pristine || !form.get('address').value.id)">
          Fill in all required fields
        </ng-container>
      </div>
    </div>
  </form>
</div>