<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Request Additional Stipulations</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div mat-dialog-content>
    <div class="additional-docs-wrapper">
      <div class="clearfix mb-1">
        <div class="float-start w-50"></div>
        <small class="float-end w-50"><b>Add Notes</b></small>
      </div>

      <div class="clearfix" *ngFor="let stipulation of stipulations">
        <div class="float-start w-25">{{stipulation.name}}</div>
        <mat-checkbox class="w-10 float-end ms-2 mt-3" color="primary" [(ngModel)]="stipulation.selected"></mat-checkbox>
        <div class="float-start w-50">
          <mat-form-field appearance="outline">
            <input matInput #stipNote [(ngModel)]="stipulation.notes" (keyup)="stipulationNotesKeyUp(stipulation)">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="link blue und" (click)="addCustomStipulation()">+ Add Additional Stipulation</div>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="close()">Close</button>
    <button mat-raised-button color="accent" cdkFocusInitial (click)="addStipulations()" [disabled]="addDisabled">Add</button>
  </div>
</div>
