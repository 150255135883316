import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';

import { SyndicatorService } from '@core/services';
import { ActivatedRoute, Router } from '@angular/router';

import { merge } from "rxjs";
import { finalize, tap } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'oiq-syndicator-fundings-table',
  templateUrl: './syndicator-fundings-table.component.html',
  styleUrls: ['./syndicator-fundings-table.component.scss']
})
export class SyndicatorFundingsTableComponent implements OnInit {

  @Input() syndicatorId

  @Input() activeTab: any;

  @Input() currentPage: number;
  @Input() pageSize = 5;
  @Input() sortBy: string;
  @Input() sortDir = 'DESC';

  isLoadingRecords = false;
  dataTable: any;
  tableTotal: number = 0;
  tab: string;

  columns = [
    'fundingName',
    'date',
    'amountFunded',
    'paybackAmount',
    'termLength',
    'amountSyndicated',
    'paymentAmountSyndicated',
    'percentageSyndicated',
    'commission',
    'managementFee',
    'totalAmountSent',
    'synRtr',
    'percentageToPrincipal',
    'percentToRrt'
  ];

  selection = new SelectionModel(true, []);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private syndicatorService: SyndicatorService,
    private router: Router,
    private route: ActivatedRoute,
    ) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {

      if (params.tab) {
        this.tab = params.tab;
        this.activeTab = params.tab;
      }

      if (params.currentPage) {
        this.currentPage = params.currentPage;
      } else {
        this.currentPage = 0;
        this.tableTotal = 0;
      }

      if (params.pageSize) {
        this.pageSize = params.pageSize;
      }

      if (params.sortBy) {
        this.sortBy = params.sortBy;
      }

      if (params.sortDir) {
        this.sortDir = params.sortDir;
      }
      this.getFundings();
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();
          this.updateQueryParams();
        })
      )
      .subscribe();
  }

  getFundings() {
    this.isLoadingRecords = true;
    this.dataTable = new MatTableDataSource([]);
    this.syndicatorService.getSyndicatorFundings(
      this.activeTab,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir,
      this.syndicatorId
    )
    .pipe(finalize(()=> this.isLoadingRecords = false))
      .subscribe((r: any) => {
        this.tableTotal = r.totalElements;
        this.dataTable = new MatTableDataSource(r.content);
        this.selection.clear();
      });
  }

  updateQueryParams() {
    const queryParams: any = {};

    if (this.tab) {
      queryParams.tab = this.tab;
    }

    if (this.currentPage) {
      queryParams.currentPage = this.currentPage;
    }

    if (this.pageSize) {
      queryParams.pageSize = this.pageSize;
    }

    if (this.sortBy) {
      queryParams.sortBy = this.sortBy;
    }

    if (this.sortDir) {
      queryParams.sortDir = this.sortDir;
    }

    this.router.navigate([], { queryParams });
  }

  showPayments(row, type) {
    if(this.isTenantView()) {
      this.router.navigate(['/fundings/' + row.fundingId + '/syndications']);
    } else {
      this.router.navigate(['home', 'dashboard', 'payments'], { queryParams: {type: type, fundingId: row.fundingId}});
    }
  }

  isTenantView() {
    return this.syndicatorId;
  }
}
