<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Add {{ data.exemptionType === 'Excluded' ? 'Exclude' : 'Include' }} Exemption</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <h4>{{ data.name }}</h4><br>
      <div class="row">
        <div class="col-sm-12">
          <mat-label>Enter a reason for exemption</mat-label>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="reason" required></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button cdkFocusInitial color="accent" type="submit" [disabled]="!form.valid"
        (click)="submit()">
        Submit
      </button>
    </div>
  </form>
</div>