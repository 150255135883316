import { Directive, ElementRef, HostListener, OnInit, Optional, Renderer2 } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatFormField } from '@angular/material/form-field';

@Directive({
  selector: '[oiqValidateDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateDateDirective,
      multi: true
    }
  ]
})
export class ValidateDateDirective implements OnInit, Validator {

  constructor(
    private datepicker: MatDatepickerInput<Date>, 
    private el: ElementRef, 
    private renderer: Renderer2,
    @Optional() private matFormField: MatFormField, 
   ) {}

  private errorContainer: HTMLElement;

  validate(control: AbstractControl): ValidationErrors | null {
    
    const value = control.value ? this.formatDate(control.value) : null;

    /** Empty input */
    if (!value || value.trim() === '') {
      this.removeErrorMessage();
      return null; // No validation errors if input is empty
    }

    control.valueChanges.pipe().subscribe(() => {
      if(control.errors !== null) {
        this.showErrorMessage(control.errors);
        return;
      }
      this.removeErrorMessage();
      return null;
    });
  }

  

  ngOnInit() {
    this.datepicker.max = new Date('9999-12-31')
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab', 'Escape', 'Enter', '/'
    ];

    if (
      allowedKeys.indexOf(event.key) !== -1 ||
      (event.key === 'a' && (event.ctrlKey === true || event.metaKey === true)) ||
      (event.key === 'c' && (event.ctrlKey === true || event.metaKey === true)) ||
      (event.key === 'v' && (event.ctrlKey === true || event.metaKey === true)) ||
      (event.key === 'x' && (event.ctrlKey === true || event.metaKey === true))
    ) {
      return;
    }

    if ((event.key && !/^[0-9\/]$/.test(event.key))) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    
    // We are sure that the pasted content only contains numbers or '/'
    if (pastedText && !/^[0-9\/]*$/.test(pastedText)) {
      event.preventDefault();
    }
  }

  //Doing conversion here, as mat-datepicker default value is ex. 
  //Thu Jan 02 2025 00:00:00 GMT+0100 (Central European Standard Time) and chars will fail validation always
  private formatDate(value: any): string { 
    if (value instanceof Date) {
      const day = value.getDate().toString().padStart(2, '0');
      const month = (value.getMonth() + 1).toString().padStart(2, '0');
      const year = value.getFullYear().toString();
      return `${month}/${day}/${year}`;
    }
    return value;
  }

  private showErrorMessage(errors: ValidationErrors | null) {
    
    if (!errors) {
      this.removeErrorMessage();
      return;
    }

    this.removeErrorMessage(); // Clear previous error messages

    const parent = this.matFormField ? this.matFormField._elementRef.nativeElement : this.renderer.parentNode(this.el.nativeElement);

    this.errorContainer = this.renderer.createElement('mat-error');
    this.renderer.addClass(this.errorContainer, 'mat-error');

    const ul = this.renderer.createElement('ul');

    Object.keys(errors).forEach(errorKey => {
      if(errorKey === 'required') return;
      const error = errors[errorKey];
      const message = this.formatErrorMessage(errorKey, error);
      const li = this.renderer.createElement('li');
      const text = this.renderer.createText(message);
      this.renderer.appendChild(this.errorContainer, text);

      this.renderer.appendChild(li, text);
      this.renderer.appendChild(ul, li);
    });
    
    this.renderer.appendChild(this.errorContainer, ul);
    this.renderer.appendChild(parent, this.errorContainer);
  }

  private formatErrorMessage(errorKey: string, error: any): string {
    console.log('format: ', [errorKey, error])
    switch (errorKey) {
      case 'matDatepickerMin':
        return `Minimum date: ${this.formatDate(error.min)}`;
      case 'matDatepickerMax':
        return `Maximum date: ${this.formatDate(error.max)}`;
      case 'matDatepickerParse':
        return `Invalid date: ${error.text}`;
      case 'matDatepickerFilter':
        return 'Chosen date is either weekend or holiday'
      default:
        return `${errorKey}: ${JSON.stringify(error)}`;
    }
  }

  private removeErrorMessage() {
    if (this.errorContainer) {
      const parent = this.matFormField ? this.matFormField._elementRef.nativeElement : this.renderer.parentNode(this.el.nativeElement);
      this.renderer.removeChild(parent, this.errorContainer);
      this.errorContainer = null;
    }
  }
}
