import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
  pure: true
})
export class DurationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

      if (seconds < 29) {
        return 'New';
      }

      const intervals = {
        year: 365 * 24 * 60 * 60,
        month: (52 * 7 * 24 * 60 * 60) / 12,
        week: 7 * 24 * 60 * 60,
        day: 24 * 60 * 60,
        hour: 60 * 60,
        minute: 60,
        second: 1
      };

      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);

        if (counter > 0) {
          const secondsRemain = seconds - counter * intervals[i];
          let remainder;
          for (const r in intervals) {
            remainder = Math.floor(secondsRemain / intervals[r]);
            if (remainder > 0) {
              if (counter === 1 && remainder === 1) {
                return counter + ' ' + i + ', ' + remainder + ' ' + r;
              } else if (counter === 1 && remainder > 1) {
                return counter + ' ' + i + ', ' + remainder + ' ' + r + 's';
              } else if (counter > 1 && remainder === 1) {
                return counter + ' ' + i + 's, ' + remainder + ' ' + r;
              } else {
                return counter + ' ' + i + 's, ' + remainder + ' ' + r + 's';
              }
            }

          }
        }
      }
    }
    return value;
  }

}
