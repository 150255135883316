import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'oiq-view-template-dialog',
  templateUrl: './view-template-dialog.component.html',
  styleUrls: ['./view-template-dialog.component.scss']
})
export class ViewTemplateDialogComponent implements OnInit {

  template: any;
  templateContent: SafeHtml

  constructor(
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ViewTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.template = this.data;
    this.templateContent = this.domSanitizer.bypassSecurityTrustHtml(this.template.body);
  }

  close(): void {
    this.dialogRef.close();
  }

}
