import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class TransmissionService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'transmissions/';
  }

  getTransmissionProcessor(whiteLabelId: number, reason: string, method: string, processor: string) {

    return this.http.get(this.getUrl() + `white-labels/${whiteLabelId}/reasons/${reason}/methods/${method}/processors/${processor}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSameDayACHStatus(whiteLabelId: number, reason: string, method: string, processor: string, processorAccountName: string) {
    const params:any = [];

    if(processorAccountName){
      params['processorAccountName'] = processorAccountName;
    }
    return this.http.get(this.getUrl() + `white-labels/${whiteLabelId}/reasons/${reason}/methods/${method}/processors/${processor}/same-day-allowed`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getSettlementDate(whiteLabelId: number, reason: string, method: string, processor: string, date: any) {
    const params = date;

    return this.http.get(this.getUrl() + `white-labels/${whiteLabelId}/reasons/${reason}/methods/${method}/processors/${processor}/settlement-dates`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getEffectiveDate(method: string, processor: string) {
    return this.http.get(this.getUrl() + `methods/${method}/processors/${processor}/effective-date`)
    .pipe(map(res => {
      return res;
    }));
  }

  getProcessors(reason: string) {
    return this.http.get(this.getUrl() + `reasons/${reason}/methods/processors`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorRepaymentAutomatedProcessors() {
    return this.http.get(this.getUrl() + `reasons/Syndicator_Repayment/methods/automated-processors`)
      .pipe(map(res => {
        return res;
      }));
  }

  getProcessorsByWhiteLabel(whiteLabelId: number,reason: string) {
    return this.http.get(this.getUrl() + `white-labels/${whiteLabelId}/reasons/${reason}/methods/processors`)
      .pipe(map(res => {
        return res;
      }));
  }

  getProcessorsWithMultipleAccounts(whiteLabelId: number,reason: string) {
    return this.http.get(this.getUrl() + `white-labels/${whiteLabelId}/reasons/${reason}/methods/processors/new`)
      .pipe(map(res => {
        return res;
      }));
  }

}
