import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelToLabel'
})
export class CamelToLabelPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (!value) {
      return value;
    }

    // Adds a space before all capital letters that are preceded by lowercase letters
    let result = value.replace(/([a-z])([A-Z])/g, '$1 $2');

    // Capitalizes the first letter of each word
    result = result.replace(/\b\w/g, char => char.toUpperCase());

     // Add a space around numbers
     result = result.replace(/([a-zA-Z])(\d+)/g, '$1 $2');
     result = result.replace(/(\d+)([a-zA-Z])/g, '$1 $2');

    return result;
  }

}
