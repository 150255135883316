import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

import { SyndicatorService, ApplicationService } from '@core/services';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MakeOfferDialogComponent } from 'src/app/user/home/make-offer-dialog/make-offer-dialog.component';

@Component({
  selector: 'oiq-available-fundings-for-syndication-table',
  templateUrl: './available-fundings-for-syndication-table.component.html',
  styleUrls: ['./available-fundings-for-syndication-table.component.scss']
})
export class AvailableFundingsForSyndicationTableComponent implements OnInit {

  @Input() syndicatorId;

  rows: Array<any> = [];
  total: number;
  currentPage: number;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  // Range slider
  minValue: number = 0;
  maxValue: number = 100;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    public claimDialog: MatDialog,
    private syndicatorService: SyndicatorService,
    private applicationService: ApplicationService
  ) {
  }

  datasource: any;
  columns: string[] = ['date', 'merchant', 'status', 'amount', 'factorRate', 'range', 'minAmount', 'maxAmount', 'maximumPaybackAmount', 'term', 'position',];

  ngOnInit() {
    if(!this.isTenantView()){
      this.columns.push('actions');
    }
    this.datasource = new MatTableDataSource([]);

    this.getSyndicationOffers();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();;
          this.getSyndicationOffers();
        })
      )
      .subscribe();
  }

  rejectOffer(row: any): void {
    this.applicationService.rejectOffer(row.applicationId, row.id)
      .subscribe((r: any) => {
        this.getSyndicationOffers();
      });
  }

  openMakeOfferDialog(row: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = row;
    dialogConfig.width = '400px';
    const dialogRef = this.claimDialog.open(MakeOfferDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


      this.getSyndicationOffers();
    });
  }

  getSyndicationOffers() {
    this.syndicatorService.listOffers(
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir,
      this.syndicatorId
    ).subscribe((r: any) => {
        this.rows = r.content;
        this.total = r.totalElements;
        this.datasource = new MatTableDataSource(this.rows);
      });
  }

  returnIfPercentFixed(row) {
    if (row.maximumPercentSyndicated === row.minimumPercentSyndicated) {
      return true;
    } else {
      return false;
    }
  }

  isNullOrUndefined(value) {
    return value === null || value === undefined;
  }

  isTenantView(){
    return this.syndicatorId;
  }

}
