import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

export class SyndicationOffer extends FormGroup {
  constructor() {
    super({
      syndicatorId: new FormControl(),
      syndicatorName: new FormControl(),
      minimumPercentSyndicated: new FormControl('', Validators.required),
      maximumAmountSyndicated: new FormControl('', Validators.required),
      maximumPercentSyndicated: new FormControl('', Validators.required),
      managementFeePercent: new FormControl('', Validators.required),
      commissionPercent: new FormControl({ value: '', disabled: true }, Validators.required),
      maxTotalReceived: new FormControl('', Validators.required),
      maxPayback: new FormControl('', Validators.required)
    });
  }
}

export class SyndicationOffers extends FormGroup {
  constructor() {
    super({
      syndicationOffers: new FormArray([])
    });
  }
}

export class ManualSyndicationOffer extends FormGroup {
  constructor() {
    super({
      fundingId: new FormControl(),
      syndicatorId: new FormControl(),
      syndicatorName: new FormControl(),
      acceptedAmountSyndicated: new FormControl('', Validators.required),
      acceptedPercentSyndicated: new FormControl('', Validators.required),
      managementFeePercent: new FormControl('', Validators.required),
      commissionPercent: new FormControl({ value: '', disabled: true }, Validators.required),
      maxTotalReceived: new FormControl('', Validators.required),
      maxPayback: new FormControl('', Validators.required)
    });
  }
}

export class ManualSyndicationOffers extends FormGroup {
  constructor() {
    super({
      manualSyndicationOffers: new FormArray([])
    });
  }
}

export const SYNDICATIONS_CALCULATION_LOGIC_OPTIONS = [{
  value: 'PRINCIPAL_SYNDICATED_AMOUNT',
  name: 'Principal Syndicated Amount'
},
{
  value: 'DISBURSEMENT_AMOUNT',
  name: 'Disbursement amount'
}]
