<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Edit Lookup Value</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <mat-label>Value *</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text" formControlName="value" required>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid" (click)="save()">
        Save
      </button>
    </div>
  </form>
</div>
