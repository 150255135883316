import { AuthService } from '@core/authentication/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { EditLookupListDialogComponent } from './edit-lookup-list-dialog/edit-lookup-list-dialog.component';
import { DeleteConfirmationDialogComponent } from '@shared/delete-confirmation-dialog/delete-confirmation-dialog.component';

import { LookupListService } from '@core/services';

@Component({
  selector: 'oiq-lookup-lists',
  templateUrl: './lookup-lists.component.html',
  styleUrls: ['./lookup-lists.component.scss']
})

export class LookupListsComponent implements OnInit {

  types: Array<string> = [];

  rows: Array<any> = [];
  datasource: any = new MatTableDataSource([]);
  total: number;

  selection = new SelectionModel(true, []);

  activeTab: string = 'email';

  columns: string[] = ['value', 'actions'];

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private lookupListService: LookupListService
  ) { }

  ngOnInit() {
    this.getLookupListTypes();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.datasource.data.filter(row => row.tenantId).length;

    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.datasource.data.filter(row => row.tenantId).forEach(row => this.selection.select(row));
  }

  editLookupList(row?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type: this.activeTab,
      lookupList: row
    };
    dialogConfig.width = '1000px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditLookupListDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLookupListByType(this.activeTab);
      }
    });
  }

  deleteValues() {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '500px',
      data: {
        action: 'delete',
        service: this.lookupListService,
        ids: this.selection.selected.map(row => row.id)
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.getLookupListByType(this.activeTab);
      }
    });
  }

  deletePayment(row) {
    row.deleting = true;
  }

  confirmDelete(row) {
    this.lookupListService.delete(row.id)
      .subscribe((r: any) => {
        row.deleting = false;

        this.getLookupListByType(this.activeTab);
      });
  }

  cancelDelete(row) {
    row.deleting = false;
  }

  getLookupListTypes() {
    this.lookupListService.listTypes()
      .subscribe((r: any) => {
        this.types = r;

        this.selectTab(this.types[0]);
      });
  }

  getLookupListByType(type: string) {
    this.lookupListService.listByType(type)
      .subscribe((r: any) => {
        this.total = r.totalElements;
        this.datasource = new MatTableDataSource(r.content);
      });
  }

  selectTab(tab) {
    this.activeTab = tab;

    this.getLookupListByType(this.activeTab);
  }
}
