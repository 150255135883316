<div class="mb-3" [ngClass]="layout === 'vertical' ? 'mt-3' : ''">
  <div class="row" *ngIf="!layout && (fundingBankVisible || paymentBankVisible)">
    <div class="col-sm-12">
      <h3>BANK<span *ngIf="!singleBank">S</span></h3>
    </div>
  </div>

  <div class="row" *ngIf="singleBank">
    <div class="col-sm-12">
      <mat-form-field appearance="outline" *ngIf="!fundingBankId || showFundingBankSelection">
        <mat-select [disabled]="disabled" [(ngModel)]="fundingBankId" (selectionChange)="bankSelectionChanged('f', $event)"
                    placeholder="Select Bank" [required]="repaymentBankRequired">
          <mat-option [value]="null">--</mat-option>
          <mat-option [value]="-1">Add New Bank</mat-option>
          <mat-option *ngFor="let bank of banks" [value]="bank.id">
            {{ bank.name }} {{ bank.branch ? ' - ' + bank.branch : '' }} ({{ bank.routingNumber }} / {{ bank.accountNumber }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span class="ps-2 float-start" *ngIf="fundingBankId && fundingBankId !== -1 && !showFundingBankSelection">{{ getBankInfo(fundingBankId)?.name }} {{ getBankInfo(fundingBankId)?.branch ? ' - ' + getBankInfo(fundingBankId)?.branch : '' }} ({{ getBankInfo(fundingBankId)?.routingNumber }} / {{ getBankInfo(fundingBankId)?.accountNumber }})</span>
      <button class="btn btn-light float-start edit-btn" (click)="toggleFundingBank()" *ngIf="fundingBankId && !showFundingBankSelection && fundingBankId !== -1"></button>
      <div *ngIf="fundingBankWithMissingInfo">
        <span class="error-text warn">This client bank has account with missing routing/account numbers, please update bank info.</span>

        <div id="update-bank-info">
          <form class="my-3" [formGroup]="fundingBank.form" autocomplete="off">
            <div class="row">
              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Bank Name *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="name" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Routing Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="routingNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="accountNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Type *</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="accountType" required>
                    <mat-option *ngFor="let type of accountTypes" [value]="type.value">{{ type.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Branch</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="branch">
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>DDA</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="dda">
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col">
                <button mat-raised-button [buttonDisabled]="!fundingBank.form.valid" color="accent" oiq-loading class="btn-wide" (click)="updateBank('f',fundingBank)">
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

  <div class="row" *ngIf="!singleBank">
    <div [ngClass]="!paymentBankVisible && !layout ? 'col-sm-12' : 'p-0 col-sm-12'" *ngIf="fundingBankVisible">
      <mat-label>{{ fundingBankLabel }} Bank * <small><strong>( Routing No. / Account No. )</strong></small></mat-label>
      <mat-form-field appearance="outline" *ngIf="!fundingBankId || showFundingBankSelection">
        <mat-select [(ngModel)]="fundingBankId" (selectionChange)="bankSelectionChanged('f', $event)"
                    placeholder="Select {{ fundingBankLabel }} Bank" [disabled]="fundingBankDisabled" required>
          <mat-option [value]="null">--</mat-option>
          <mat-option [value]="-1">Add New {{ fundingBankLabel }} Bank</mat-option>
          <mat-option *ngFor="let bank of banks" [value]="bank.id">
            {{ bank.name }} {{ bank.branch ? ' - ' + bank.branch : '' }} ({{ bank.routingNumber }} / {{ bank.accountNumber }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="showFundingBankDeletedText && !fundingBankWithMissingInfo" class="error-text warn">Previously selected bank was deleted, please select another from the list or add a new one.</p>

      <span class="ps-2" *ngIf="fundingBankId && fundingBankId !== -1 && !showFundingBankSelection">{{ getBankInfo(fundingBankId)?.name }} {{ getBankInfo(fundingBankId)?.branch ? ' - ' + getBankInfo(fundingBankId)?.branch : '' }} ({{ getBankInfo(fundingBankId)?.routingNumber }} / {{ getBankInfo(fundingBankId)?.accountNumber }})</span>
      <button class="btn btn-light edit-btn" (click)="toggleFundingBank()" *ngIf="fundingBankId && !showFundingBankSelection && fundingBankId !== -1"></button>
      <div *ngIf="fundingBankWithMissingInfo  && fundingBankId !== -1">
        <span class="error-text warn">This client bank has account with missing routing/account numbers, please update {{ fundingBankLabel | lowercase }} bank info.</span>

        <div id="update-bank-info">
          <form class="my-3" [formGroup]="fundingBank.form" autocomplete="off">
            <div class="row">
              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Bank Name *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="name" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Routing Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="routingNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="accountNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Type *</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="accountType" required>
                    <mat-option *ngFor="let type of accountTypes" [value]="type.value">{{ type.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Branch</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="branch">
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>DDA</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="dda">
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col">
                <button mat-raised-button [buttonDisabled]="!fundingBank.form.valid" color="accent" oiq-loading class="btn-wide" (click)="updateBank('f', fundingBank)">
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>

    <div class="mt-2" [ngClass]="!fundingBankVisible && !layout ? 'col-sm-12' : 'p-0 col-sm-12'" *ngIf="paymentBankVisible">
      <mat-label>Repayment Bank * <small><strong>( Routing No. / Account No. )</strong></small></mat-label>
      <mat-form-field appearance="outline" *ngIf="!paymentBankId || showPaymentBankSelection">
        <mat-select [(ngModel)]="paymentBankId" (selectionChange)="bankSelectionChanged('p', $event)"
                    placeholder="Select Repayment Bank" [disabled]="paymentBankDisabled" [required]="repaymentBankRequired">
          <mat-option [value]="null">--</mat-option>
          <mat-option [value]="-1">Add New Repayment Bank</mat-option>
          <mat-option *ngFor="let bank of banks" [value]="bank.id">
            {{ bank.name }} {{ bank.branch ? ' - ' + bank.branch : '' }} ({{ bank.routingNumber }} / {{ bank.accountNumber }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p *ngIf="showPaymentBankDeletedText && !paymentBankWithMissingInfo" class="error-text warn">Previously selected bank was deleted, please select another from the list or add a new one.</p>
      <span class="ps-2" *ngIf="paymentBankId && paymentBankId !== -1 && !showPaymentBankSelection">{{ getBankInfo(paymentBankId)?.name }} {{ getBankInfo(paymentBankId)?.branch ? ' - ' + getBankInfo(paymentBankId)?.branch : '' }} ({{ getBankInfo(paymentBankId)?.routingNumber }} / {{ getBankInfo(paymentBankId)?.accountNumber }})</span>
      <button class="btn btn-light edit-btn" (click)="togglePaymentBank()" *ngIf="paymentBankId && !showPaymentBankSelection && paymentBankId !== -1"></button>
      <div *ngIf="paymentBankWithMissingInfo && paymentBankId !== -1">
        <span class="error-text warn">This client bank has account with missing routing/account numbers, please update repayment bank info.</span>

        <div id="update-bank-info">
          <form class="my-3" [formGroup]="paymentBank.form" autocomplete="off">
            <div class="row">
              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Bank Name *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="name" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Routing Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="routingNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Number *</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="accountNumber" required>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Account Type *</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="accountType" required>
                    <mat-option *ngFor="let type of accountTypes" [value]="type.value">{{ type.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>Branch</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="branch">
                </mat-form-field>
              </div>

              <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
                <mat-label>DDA</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput formControlName="dda">
                </mat-form-field>
              </div>
            </div>

            <div class="row mb-5">
              <div class="col">
                <button mat-raised-button [buttonDisabled]="!paymentBank.form.valid" color="accent" oiq-loading class="btn-wide" (click)="updateBank('p',paymentBank)">
                  SAVE
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

  <div class="row my-4" *ngIf="fundingBankId === -1 || paymentBankId === -1">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-12">
          <h3 *ngIf="singleBank && fundingBankId === -1">ADD BANK</h3>
          <h3 *ngIf="!singleBank && fundingBankId === -1">ADD NEW {{ fundingBankLabel | uppercase }} BANK</h3>
          <h3 *ngIf="!singleBank && paymentBankId === -1">ADD NEW REPAYMENT BANK</h3>
        </div>
      </div>

      <div class="row">
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>Bank Name *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="newBank.name" required #bankName="ngModel">
          </mat-form-field>
        </div>
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>Branch</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [(ngModel)]="newBank.branch" #branch="ngModel">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>Routing Number *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput #routingNum="ngModel" type="text" pattern="[0-9]*" required [(ngModel)]="newBank.routingNumber">
          </mat-form-field>
          <mat-error *ngIf="routingNum.value && routingNum.invalid">
            Please enter a number
          </mat-error>
        </div>
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>Account Type *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="newBank.accountType" required #accType="ngModel">
              <mat-option *ngFor="let type of accountTypes" [value]="type.value">{{ type.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>Account Number *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="text" #accountNum="ngModel" pattern="[0-9]*" required [(ngModel)]="newBank.accountNumber">
          </mat-form-field>
          <mat-error *ngIf="accountNum.value && accountNum.invalid">
            Please enter a number
          </mat-error>
        </div>
        <div [ngClass]="!layout ? 'col-sm-3' : 'col-sm-6'">
          <mat-label>DDA</mat-label>
          <mat-form-field appearance="outline">
            <input matInput #dda="ngModel" type="text" [(ngModel)]="newBank.dda">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <button mat-stroked-button class="btn-small" oiq-loading [spinner]="false" (click)="cancelAddBank()">CANCEL</button>
          <button mat-raised-button color="accent" oiq-loading class="btn-small ms-3" (click)="saveBank()" [buttonDisabled]="bankName.invalid || routingNum.invalid || accountNum.invalid || accType.invalid">SAVE BANK</button>
        </div>
      </div>
    </div>
  </div>
</div>
