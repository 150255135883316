<table mat-table [dataSource]="stipulationsTable" class="documents-table" multiTemplateDataRows>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="button-cells">
      <button mat-stroked-button class="btn-small mb-2 me-2" (click)="additionalDocsDialog()"
              *ngIf="!disabled && application.status !== 'Declined' && application.status !== 'Funded'">
        REQUEST MORE DOCUMENTS
      </button>

      <button mat-stroked-button class="btn-small mb-2" (click)="sendUpdatedStipulationsList()"
              *ngIf="!disabled && application.status !== 'Declined' && application.status !== 'Funded' && stipulationsAwaitingUpload > 0">
        SEND UPDATED STIPULATIONS LIST
      </button>
    </th>
    <td mat-cell *matCellDef="let item">
      <div class="doc-name">{{ item.name }}</div>

      <div class="expand-cell" (click)="toggleStipulationRow(item)"
           *ngIf="item.attachments.length && application.status !== 'Declined' && application.status !== 'Funded'">
        <i class="material-icons" *ngIf="item !== expandedStipulationRow">arrow_right</i>
        <i class="material-icons" *ngIf="item === expandedStipulationRow">arrow_drop_down</i>
        <span>View Attachments</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="button-cells"></th>
    <td mat-cell *matCellDef="let item" class="text-end">
      <oiq-upload-files-list
      [uploadUrl]="uploadUrl"
      (filesUploadSuccess)="onUploadSuccess(item,$event)"
      [isFilesTableShown]="false"
      [isMultiSelectEnabled]="false"
      [isDeleteFileEnabled]="true"
      >
    </oiq-upload-files-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="notes">
    <th mat-header-cell *matHeaderCellDef>Notes</th>
    <td mat-cell *matCellDef="let item" class="text-end">
      {{ item.notes | ellipsis:[30, '...'] }}
      <span *ngIf="item.notes?.length > 30" class="full-content">{{ item.notes }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="status">Status</th>
    <td mat-cell *matCellDef="let item">
      <mat-form-field *ngIf="!disabled" appearance="outline">
        <mat-select #stipValue [value]="item.status"
                    (selectionChange)="changeStipStatus(application.id, item.id, stipValue.value)"
                    [disabled]="application.status === 'Declined' || application.status === 'Funded'">
          <mat-option *ngFor="let status of stipStatuses" [value]="status.value">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="excludeFromEmail">
    <th mat-header-cell *matHeaderCellDef class="exclude-cell">Exclude from email</th>
    <td mat-cell *matCellDef="let item" class="text-center">
      <mat-checkbox color="primary" [(ngModel)]="item.excludeFromEmail"
                    (change)="toggleExcludeFromEmail(item, $event)"
                    [disabled]="disabled || application.status === 'Declined' || application.status === 'Funded'"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let item" [attr.colspan]="displayedStipulationsColumns.length">
      <div class="row-details" [@detailExpand]="item == expandedStipulationRow ? 'expanded' : 'collapsed'">
        <table class="inner-table">
          <thead>
          <tr>
            <th>File Name</th>
            <th>Created By</th>
            <th>Created Date</th>
            <th>Size</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let attachment of item.attachments">
            <td>{{ attachment.filename }}</td>
            <td>{{ attachment.createdByFirstName }} {{ attachment.createdByLastname }}</td>
            <td>{{ (attachment.createdDate | date) || 'n/a' }}</td>
            <td>{{ attachment.size }}</td>
            <td class="text-end">
              <button mat-raised-button color="primary" class="btn-table" (click)="downloadFile(attachment.id)">
                DOWNLOAD
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedStipulationsColumns"></tr>
  <tr mat-row *matRowDef="let item; columns: displayedStipulationsColumns;" class="element-row"></tr>
  <tr mat-row *matRowDef="let item; columns: ['expandedDetail']" class="detail-row"></tr>
</table>
<oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="stipulationsTable?.data?.length === 0"></oiq-loading-message>
