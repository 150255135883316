import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

	constructor(
    private domSanitizer: DomSanitizer
  ) { }

  transform(value: any, ...args: any[]): any {
    let address = '';

    if (value.address1) {
    	address += value.address1 + '<br>';
    }

    if (value.address2) {
    	address += value.address2 + '<br>';
    }

    if (value.city) {
    	address += value.city + '<br>';
    }

    if (value.state) {
    	address += value.state + '<br>';
    }

    if (value.zipCode) {
    	address += value.zipCode;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(address);
  }

}
