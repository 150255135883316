import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable, interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ClearService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'clear/';
  }

  getClearPersonsList(applicationId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-person-list.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.get(this.getUrl() + `persons/reports?applicationId=${applicationId}`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  getClearBusinessesList(applicationId: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-business-list.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.get(this.getUrl() + `businesses/reports?applicationId=${applicationId}`)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  generatePersonAttachment(id: number) {

    return this.http.get(this.getUrl() + `persons/reports/${id}/results?type=pdf`)
    .pipe(map(res => {
      return res;
    }));
  }

  generateBusinessAttachment(id: number) {

    return this.http.get(this.getUrl() + `businesses/reports/${id}/results?type=pdf`)
    .pipe(map(res => {
      return res;
    }));
  }

  searchPersons(data: any, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-person-search.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.post(this.getUrl() + `persons`, data)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  generatePersonReport(groupId: string, applicationId: number, name: string, contactId: number) {

    return this.http.post(this.getUrl() + `persons/reports?groupId=${groupId}&applicationId=${applicationId}&name=${name}&contactId=${contactId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  pollPersonReport(id: number): Observable<any> {

    return interval(5000).pipe(
      switchMap(() => this.http.get(this.getUrl() + `persons/reports/${id}`)), map(res => {
        return res;
      }));
  }

  getPersonReport(id: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-person-report.html', {responseType: 'text'})
      .pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.get(this.getUrl() + `persons/reports/${id}/results?type=html`, {responseType: 'text'})
      .pipe(map(res => {
        return res;
      }));
    }
  }

  searchBusinesses(data: any, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-business-search.json')
      .pipe(map(res => {
        return res;
      }));
    } else {
    return this.http.post(this.getUrl() + `businesses`, data)
      .pipe(map(res => {
        return res;
      }));
    }
  }

  generateBusinessReport(groupId: string, applicationId: number, name: string, clientId: number) {

    return this.http.post(this.getUrl() + `businesses/reports?groupId=${groupId}&applicationId=${applicationId}&name=${name}&clientId=${clientId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  pollBusinessReport(id: number): Observable<any> {

    return interval(5000).pipe(
      switchMap(() => this.http.get(this.getUrl() + `businesses/reports/${id}`)), map(res => {
        return res;
      }));
  }

  getBusinessReport(id: number, mockData?: boolean) {
    if (mockData) {
      return this.http.get('https://app.onyxiq.com/assets/mock-data/clear-business-report.html', {responseType: 'text'})
      .pipe(map(res => {
        return res;
      }));
    } else {
      return this.http.get(this.getUrl() + `businesses/reports/${id}/results?type=html`, {responseType: 'text'})
      .pipe(map(res => {
        return res;
      }));
    }
  }
}
