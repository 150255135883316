import { Component, OnInit, ViewChild } from '@angular/core';
import { BorrowingBaseService } from '@core/services';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ExemptionDialogComponent } from '../exemption-dialog/exemption-dialog.component';
import { DeleteConfirmationDialogComponent } from '@shared/delete-confirmation-dialog/delete-confirmation-dialog.component';

@Component({
  selector: 'oiq-borrowing-base',
  templateUrl: './borrowing-base.component.html',
  styleUrls: ['./borrowing-base.component.scss']
})
export class BorrowingBaseComponent implements OnInit {

  navItems = [
    { value: 'Active_RTR', label: 'Active RTR' },
    { value: 'Syndicated_Active_RTR', label: 'Syndicated Active RTR' },
    { value: 'Tenant_Active_RTR', label: 'Tenant Active RTR' },
    // { value: 'Active_Tenant_Funded_Amount', label: 'Active Tenant Funded Amount' },
    { value: 'Active_Tenant_Unpaid_Principal', label: 'Wall Funding Unpaid Principal'},
    { value: 'Eligible_Through_BB', label: 'Max Potential BB' },
    { value: 'Missed_0_Count', label: '0 Missed Count' },
    { value: 'Missed_0_UP', label: '0 Missed BB'},
    { value: 'Missed_1_Count', label: '1-10(D) or 1-2(W) Count' },
    { value: 'Missed_1_Funded', label: '1-10(D) or 1-2(W) BB' },
    { value: 'Missed_2_Count', label: '11-20(D) or 3-4(W) Count' },
    { value: 'Missed_2_Funded', label: '11-20(D) or 3-4(W) BB' },
    { value: 'Missed_3_Count', label: 'Over 20(D) or 4(W) Count' },
    { value: 'Missed_3_Funded', label: 'Over 20(D) or 4(W) BB' },
    { value: 'Total_Eligible', label: 'Total Eligible' },
    { value: 'All_Ineligible', label: 'All Ineligible' },
    { value: 'Largest', label: 'Largest' },
    { value: 'White_Label', label: 'White Label' }
  ];

  currentPage: number;
  pageSize = 25;
  sortBy: string;
  sortDir: string;

  totalBorrowed = 0;

  currenciesArray = [
    'missed0UPShare',
    'missed1UPShare',
    'missed2UPShare',
    'missed3UPShare',
    'totalPaidToPayback',
    'fundedAmount',
    'paybackAmount',
    'rtr',
    'syndicatedRtr',
    'tenantFundedAmount',
    'tenantRtr',
    'unpaidPrincipal',
    'tenantUnpaidPrincipal',
    'totalEligible',
    'baseEligible',
    'totalSyndicated',
    'uPAdjustment',
    'adjustedUP',
    'afterBankFee',
    'expectedPaidToDate',
    'originalPaymentAmount',
    'bankFee',
    'amountOverCap'
  ];

  percentsArray = [
    'percentOfGross',
    'percentSyndicated',
    'adjustmentPercent',
    'percentBehindPaid'
  ];

  datesArray = [
    'dateBusinessStarted',
    'dateOfLastPayment',
    'fundingDate'
  ];

  summaryData: any;
  data = [];

  summaryHeaders = [];
  summaryHighlightedColumn: string;
  summaryTotalElements: string;

  headers = [];
  highlightedColumn: string;
  totalElements: number;

  selectedItem: any;

  summaryDisplayedColumns: string[] = [];
  summaryColumnsToDisplay: string[] = [];

  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  constructor(
    private borrowingBaseService: BorrowingBaseService,
    private router: Router,
    public dialog: MatDialog
  ) { }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.getSummaryData();
    this.selectItem(this.navItems[0]);
  }

  selectItem(item) {
    this.selectedItem = item;
    this.getData();
  }

  mapTableData() {
    this.columnsToDisplay = Object.values(this.headers).filter(item => item !== null);
    this.displayedColumns = this.columnsToDisplay.slice().filter(item => item !== null);
  }

  mapSummaryTableData() {
    this.summaryColumnsToDisplay = Object.values(this.summaryHeaders).filter(item => item !== null);
    this.summaryDisplayedColumns = this.summaryColumnsToDisplay.slice().filter(item => item !== null);
  }

  resetTableData() {
    this.data = [];
    this.columnsToDisplay = [];
    this.displayedColumns = this.columnsToDisplay.slice();
  }

  getSummaryData() {
    this.borrowingBaseService.getSummaryData()
    .subscribe((r: any) => {
      this.summaryData = r;

      this.summaryHeaders = r.headers;
      this.summaryHighlightedColumn = r.highlightColumn;
      this.summaryTotalElements = r.totalElements;
      this.mapSummaryTableData();
    });
  }

  invokeDealAction(item, exemptionType, event) {
    switch (exemptionType) {
      case 'Excluded':

        this.openExemptionDialog(item, exemptionType);
        break;

        case 'Included':

          this.openExemptionDialog(item, exemptionType);
          break;

      case 'delete':

        this.openDeleteDialog(item.fundingId);
        break;

      default:
        break;
    }
    event.source.value = '';
  }

  openExemptionDialog(item, exemptionType) {
    const dialog = this.dialog.open(ExemptionDialogComponent, {
      width: '800px',
      data: {
        tenantId: item.tenantId,
        fundingId: item.fundingId,
        name: item.name,
        exemptionType: exemptionType
      }
    });

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  openDeleteDialog(fundingId) {
    const dialogRef = this.dialog.open(DeleteConfirmationDialogComponent, {
      width: '500px',
      data: {
        action: 'deleteExemption',
        service: this.borrowingBaseService,
        ids: [fundingId]
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }

  getData() {
    this.resetTableData();
    this.borrowingBaseService.getData(
      this.selectedItem.value,
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDir
      )
    .subscribe((r: any) => {
      this.headers = r.headers;
      this.headers['Actions'] = 'Actions';
      this.data = r.data;
      this.highlightedColumn = r.highlightColumn;
      this.totalElements = r.totalElements;
      this.mapTableData();
    });
  }

  checkIfInArray(array, field) {
    if (array.includes(field)) {
      return true;
    }
  }

  fieldBelongsToType(array, field) {
    if (array.includes(field)) {
      return true;
    }
  }

  getColumnName(value) {
    if (this.headers) {
      return Object.keys(this.headers).find(key => this.headers[key] === value);
    }
  }

  getSummaryColumnName(value) {
    if (this.summaryHeaders) {
      return Object.keys(this.summaryHeaders).find(key => this.summaryHeaders[key] === value);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.currentPage = this.paginator.pageIndex;
          this.pageSize = this.paginator.pageSize;
          this.sortBy = this.sort.active;
          this.sortDir = this.sort.direction.toUpperCase();
          this.getData();
        })
      )
      .subscribe();
  }

  runReport(option, event) {
    this.router.navigate(['borrowing-base/reports'], { queryParams: { type: option.value, name: option.label } });
    event.source.value = '';
  }

  runExhibit() {
    this.router.navigate(['borrowing-base/reports'], { queryParams: { type: 'exhibit', name: 'Exhibit' } });
  }

  runBBPayments() {
    this.router.navigate(['borrowing-base/reports'], { queryParams: { type: 'Borrowing_Base_Payments', name: 'BB Payments List' } });
  }

}
