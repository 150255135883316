export const environment = {
  production: false,
  staging: false,
  development: true,
  servicesUrl: 'https://services.dev.onyxiq.com/api/',
  cdnUrl: 'https://cdn.onyxiq.com',
  googleAnalyticsKey: undefined,
  hotJarId: 4951808,
  hotJarEnabled: false
};
