<div class="content">
  <div class="content-wrapper">
    <div class="heading">
      <h2>
        {{ templateId ? 'Manage' : 'Add' }} Notification Template
        <button *ngIf="templateId && !editMode" class="edit-btn" (click)="toggleEditTemplate(true)"></button>
      </h2>
    </div>

    <div class="body body-padding">
      <form [formGroup]="form">
        <div class="row form-group">
          <div class="col-sm-6">
            <mat-label>Template Name</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="name" required>
            </mat-form-field>
          </div>

          <div class="col-sm-6">
            <mat-label>Select Type</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="type">
                <mat-option *ngFor="let type of notificationTypes" [value]="type.value">{{type.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-6">
            <mat-label>From</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="from" required>
            </mat-form-field>
          </div>

          <div class="col-sm-6" *ngIf="form.value.type === 'Email'">
            <mat-label>Subject</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="subject" required>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-12">
            <mat-label>Body</mat-label>
            <mat-form-field appearance="outline">
              <textarea matInput rows="4" formControlName="body" required></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-6">
            <mat-label>Date Created</mat-label>
            <mat-form-field appearance="outline">
              <input matInput formControlName="createdDate" required>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!templateId || editMode">
          <button mat-stroked-button *ngIf="templateId" class="me-4 btn-wide" (click)="toggleEditTemplate(false)" oiq-loading [spinner]="false">
            CANCEL
          </button>
          <button mat-raised-button color="accent" class="btn-wide" (click)="saveTemplate()" oiq-loading>SAVE TEMPLATE</button>
        </div>
      </form>
    </div>
  </div>
</div>