<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Export Results</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off" *ngIf="page === 'form'">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-4">
          <mat-label>Type</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="type">
              <mat-option *ngFor="let row of types" [value]="row.value">
                {{ row.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-label>Export As</mat-label>
          <mat-radio-group aria-label="Select an option" formControlName="output" (click)="onOutputChanged($event)">
            <mat-radio-button value="Excel">
              Excel
            </mat-radio-button>

            <mat-radio-button value="Email">
              Email
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div class="row" *ngIf="form.value.output === 'Email'">
        <div class="col-sm-12">
          <mat-label>{{ tenant.name }} User Recipients</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="tenantUserIdRecipients" multiple>
              <mat-option *ngFor="let user of tenantUsers" [value]="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="form.value.output === 'Email'">
        <div class="col-sm-12">
          <mat-label>Email Message</mat-label>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="emailMessage">Client Owners</textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="form.value.output === 'Email'">
        <div class="col-sm-4">
          <mat-label>Search by ISO</mat-label>
          <mat-form-field appearance="outline" class="select-typeahead">
            <input
                matInput
                [ngModelOptions]="{standalone: true}"
                (ngModel)="loadedIso"
                [value]="loadedIso?.name"
                type="text"
                placeholder="Start typing to search for ISO"
                [ngbTypeahead]="searchIsos"
                (focus)="focus$.next($event.target.value)"
                (click)="click$.next($event.target.value)"
                (selectItem)="onIsoSelected($event.item)"
                [inputFormatter]="formatter"
                [resultFormatter]="formatter"
                #instance="ngbTypeahead"/>
            <mat-icon matSuffix>arrow_drop_down</mat-icon>
          </mat-form-field>
        </div>

        <div class="col-sm-4" *ngIf="isoUsers && isoUsers.length > 0">
          <mat-label>ISO User Recipients</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="isoUserIdRecipients" multiple>
              <mat-option *ngFor="let user of isoUsers" [value]="user.id">
                {{ user.firstName }} {{ user.lastName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-4" *ngIf="isoEmailAddresses && isoEmailAddresses.length > 0">
          <mat-label>ISO Email Address Recipients</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="isoEmailAddressRecipients" multiple>
              <mat-option *ngFor="let row of isoEmailAddresses" [value]="row">
                {{ row }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="form.value.output === 'Email'">
        <div class="col-sm-12">
          <mat-label>Email Addresses</mat-label>
          <div formArrayName="emailAddressRecipients">
            <div class="row mt-2" *ngFor="let email of form.get('emailAddressRecipients')['controls']; let index = index" [formGroup]="email">
              <div class="col-sm-11">
                <mat-form-field appearance="outline">
                  <input matInput formControlName="email">
                </mat-form-field>
              </div>
              <div class="col-sm-1 text-end">
                <button type="button" class="delete-btn" title="Remove Email" (click)="removeEmailAddress(index, 'emailAddressRecipients')"></button>
              </div>
            </div>

            <div class="mt-2 link blue" (click)="addEmailAddress('emailAddressRecipients')">+ Add New Email Address</div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <mat-label>Columns to Export</mat-label>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <mat-checkbox color="primary" [(ngModel)]="selectAllToggle" [ngModelOptions]="{standalone: true}" (change)="toggleSelectAll()">Select All</mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <mat-checkbox color="primary" formControlName="includeType">Type (New / Renewal)</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeCreatedDate">Date Submitted</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeStatusChangeDate">Status Change Date</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeDateApproved">Date Approved</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeApprovedAmount">Approved Amount</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeDateFunded">Date Funded</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeFundingStatus">Funding Status</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includePaymentFrequency">Payment Frequency</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeAchAmount">ACH Amount</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includePercentagePaidIn">Percentage Paid In</mat-checkbox>
          </div>
        </div>

        <div class="col-sm-6">
          <div>
            <mat-checkbox color="primary" formControlName="includeBuyRate">Buy Rate</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeFactorRate">Factor Rate</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includePosition">Position</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includePayBackAmount">Payback Amount</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeBalanceWithFees">Balance With Fees</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeDateDeclined">Date Declined</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeDeclineReason">Decline Reason</mat-checkbox>
          </div>
          <div>
            <mat-checkbox color="primary" formControlName="includeUnderwriterNotes">Underwriter Notes</mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button color="accent" class="btn-wide" [ngClass]="{ 'loading': loading }" [disabled]="!form.valid || loading" (click)="submit()">
        <mat-spinner [diameter]="20"></mat-spinner>{{ loading ? 'PROCESSING' : 'SUBMIT' }}
      </button>
    </div>
  </form>

  <form [formGroup]="form" autocomplete="off" *ngIf="page === 'confirmation'">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <p>Report will be emailed to the selected recipients</p>
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button color="accent" class="btn-wide" (click)="close()">
        OK
      </button>
    </div>
  </form>
</div>
