<div class="form-row-wrapper">
  <div class="row">
    <div class="col-sm-12">
      <h3>REFINANCE</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <mat-slide-toggle [disabled]="application?.status === 'Funded'" color="primary" [(ngModel)]="refinance" [ngModelOptions]="{standalone: true}"></mat-slide-toggle>

      <ng-container *ngIf="refinance">
        <button mat-raised-button color="primary" class="float-end btn-table" *ngIf="!editingRefinancedFundings && application.status !== 'Declined' && application.status !== 'Funded'" (click)="editRefinancedFundings()">EDIT
        </button>
        <button mat-raised-button color="accent" class="float-end btn-table" *ngIf="editingRefinancedFundings" [class.disabled]="!form.valid || form.value.externalFundings.length === 0" [buttonDisabled]="!form.valid || !form.dirty" oiq-loading (click)="form.valid && saveRefinancedFundings()">SAVE
        </button>
        <button mat-stroked-button class="float-end btn-table me-3" *ngIf="editingRefinancedFundings" (click)="cancelEditRefinancedFundings()">CANCEL
        </button>
      </ng-container>
    </div>
  </div>

  <form *ngIf="refinance" [formGroup]="form" autocomplete="off">
    <h3>INTERNAL FUNDINGS:</h3>

    <div class="scrollable">
      <table mat-table matSort [dataSource]="refinanceDatasource" class="refinance-table" multiTemplateDataRows>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Funded</th>
          <td mat-cell *matCellDef="let row" class="date-expand">
            {{ row.createdDate | date }}
            <div class="expand-cell" (click)="toggleInternalFundingRow(row)">
              <i class="material-icons" *ngIf="row !== expandedPaymentsRow">arrow_right</i>
              <i class="material-icons" *ngIf="row === expandedPaymentsRow">arrow_drop_down</i>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="installment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Installment</th>
          <td mat-cell *matCellDef="let row">{{ row.installment }}</td>
        </ng-container>
        <ng-container matColumnDef="amountFunded">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount Funded</th>
          <td mat-cell *matCellDef="let row">{{ row.fundingOffer?.fundedAmount | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="paybackAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Payback Amount</th>
          <td mat-cell *matCellDef="let row">{{ row.fundingOffer?.paybackAmount | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="buyRate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Buy Rate</th>
          <td mat-cell *matCellDef="let row">{{ row.fundingOffer?.buyRate }}</td>
        </ng-container>
        <ng-container matColumnDef="factoringRate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Factoring Rate</th>
          <td mat-cell *matCellDef="let row">{{ row.fundingOffer?.factorRate }}</td>
        </ng-container>
        <ng-container matColumnDef="term">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Term</th>
          <td mat-cell *matCellDef="let row">{{ row.termInMonths }} Months</td>
        </ng-container>
        <ng-container matColumnDef="position">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
          <td mat-cell *matCellDef="let row">{{ row.fundingOffer?.position }}</td>
        </ng-container>
        <ng-container matColumnDef="fees">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Fees</th>
          <td mat-cell *matCellDef="let row" class="text-nowrap">
            <div *ngFor="let fee of row?.fundingOffer.fees">
              <strong>{{ fee.type !== 'Other' ? fee.type.replace('_', ' ') + ' Fee' : fee.name}}:</strong> {{ fee.amount | currency }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Balance</th>
          <td mat-cell *matCellDef="let row">{{ row.balance | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="balanceWithFees">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Balance with Fees</th>
          <td mat-cell *matCellDef="let row">{{ row.balanceWithFees | currency }}</td>
        </ng-container>
        <ng-container matColumnDef="paidWithFees">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>% Paid w/Fees</th>
          <td mat-cell *matCellDef="let row">{{ row.percentPaidWithFees }}%</td>
        </ng-container>
        <ng-container matColumnDef="include">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Include</th>
          <td mat-cell *matCellDef="let row">
            <div class="link blue und" *ngIf="row.added" (click)="removeFunding(row)">Remove</div>
            <div class="link blue und" *ngIf="!row.added" (click)="addFunding(row)">Add</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="fundingColumns.length">
            <div class="row-details vertical-details" [@detailExpand]="row == expandedPaymentsRow ? 'expanded' : 'collapsed'">
              <div class="if-table">
                <table class="inner-table">
                  <thead>
                    <tr>
                      <th>View a Payment</th>
                      <th>Hit Date</th>
                      <th>Status</th>
                      <th>Settlement Date</th>
                      <th>Return Date</th>
                      <th>Total Amount Paid</th>
                      <!-- <th>Fee</th> -->
                      <th>Response Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of internalFundingPayments">
                      <td>
                        <a href="#" class="link blue und" target="_blank">
                          {{ application?.client.name }}
                        </a>
                      </td>
                      <td>{{ row.hitDate | date }}</td>
                      <td>{{ row.status | status }}</td>
                      <td>{{ row.settlementDate | date }}</td>
                      <td>{{ row.returnDate | date }}</td>
                      <td>{{ row.amount | currency }}</td>
                      <!-- <td></td> -->
                      <td>{{ row.responseDescription }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <mat-paginator (page)="changePage($event)" [length]="IFTotal" [pageIndex]="IFPageNumber" [pageSize]="IFSize" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fundingColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: fundingColumns;" [ngClass]="element.added ? 'selected' : ''" class="element-row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </table>
    </div>
    <div class="empty-table" *ngIf="refinanceDatasource?.data?.length === 0">
      No records found.
    </div>

    <div class="mt-4 heading-with-info">
      <h3>3<sup>rd</sup> PARTY FUNDINGS:</h3>
    </div>

    <div class="scrollable">
      <table class="table my-3">
        <thead>
          <tr>
            <th>Funding Company</th>
            <th>Payoff Amount</th>
            <th width="13%">Payment Method</th>
            <th>Payment Processor</th>
            <th>Routing Number</th>
            <th>Account Number</th>
            <th width="17%">Bank Name/Address</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="editingRefinancedFundings" formArrayName="externalFundings">
            <tr *ngFor="let item of form.get('externalFundings').controls; let i = index;" [formGroupName]="i">
              <ng-container *ngIf="!item.value.deleted">
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="companyName">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="payoffAmount" currencyMask>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="transmissionMethod" (selectionChange)="onTransmissionMethodChanged($event,i)">
                      <mat-option *ngFor="let row of transmissionMethodList" [value]="row.value">
                        {{ row.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline" *ngIf="transmissionProcessors[i]?.length">
                    <mat-select formControlName="transmissionProcessor">
                      <mat-option *ngFor="let row of  transmissionProcessors[i]" [value]="row">
                        {{ row }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <span *ngIf="!transmissionProcessors[i]?.length">-</span>
                </td>
                <td>
                  <span *ngIf="item.get('transmissionMethod').value === 'ACH' || item.get('transmissionMethod').value === 'Wire_Transfer'; else noRoutingNumber">
                    {{item.get('routingNumber').value}}
                  </span>
                  <ng-template #noRoutingNumber>
                    <span *ngIf="!transmissionProcessors[i]?.length">-</span>
                  </ng-template>
                </td>
                <td>
                  <span *ngIf="item.get('transmissionMethod').value === 'ACH' || item.get('transmissionMethod').value === 'Wire_Transfer'; else noAccountNumber">
                    {{item.get('accountNumber').value}}
                  </span>
                  <ng-template #noAccountNumber>
                    <span *ngIf="!transmissionProcessors[i]?.length">-</span>
                  </ng-template>
                </td>

                <td>
                  <div *ngIf="item.get('transmissionMethod').value === 'ACH' || item.get('transmissionMethod').value === 'Wire_Transfer'">
                    <div *ngIf="item.get('bank')">
                      <div>{{ item.get('bank').value.name }}</div>
                      <address class="font-italic" *ngIf="item.get('bank').value.address.address1">
                        <div>{{ item.get('bank').value.address.address1 }}</div>
                        <div>{{ item.get('bank').value.address.address2 }}</div>
                        <div>{{ item.get('bank').value.address.city }}, {{ item.get('bank').value.address.state }} {{ item.get('bank').value.address.zipCode }}</div>
                      </address>
                    </div>
                    <div *ngIf="item.get('bank') && (!item.get('bank').valid || item.get('bank').controls.address.status !== 'VALID')" class="text-danger">Missing Bank Info</div>
                  </div>
                </td>
                <td>
                  <button *ngIf="item.get('bank') && (item.get('transmissionMethod').value === 'ACH' || item.get('transmissionMethod').value === 'Wire_Transfer')" class="btn-sm link" (click)="openBankInfoDialog(item,i)">{{ item.get('bank') ? 'Edit&nbsp;Bank&nbsp;Info' : 'Add&nbsp;Bank&nbsp;Info' }}</button>
                </td>
                <td>
                  <button type="button" class="delete-btn" title="Remove" (click)="removeExternalFunding(item, i)"></button>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <ng-container *ngIf="!editingRefinancedFundings">
            <tr *ngFor="let row of form.get('externalFundings').controls">
              <ng-container *ngIf="!row.value.deleted">
                <td>{{ row.get('companyName').value }}</td>
                <td>{{ row.get('payoffAmount').value | currency }}</td>
                <td>{{ row.get('transmissionMethod').value | status }}</td>
                <td *ngIf="row.get('transmissionMethod').value === 'ACH'; else noTransmissionProcessor">{{ row.get('transmissionProcessor').value | status }}</td>
                <ng-template #noTransmissionProcessor>
                  <td>-</td>
                </ng-template>

                <td *ngIf="row.get('transmissionMethod').value === 'ACH' || row.get('transmissionMethod').value === 'Wire_Transfer'; else noRoutingNumber">
                  {{ row.get('routingNumber').value }}
                </td>
                <ng-template #noRoutingNumber>
                  <td>-</td>
                </ng-template>

                <td *ngIf="row.get('transmissionMethod').value === 'ACH' || row.get('transmissionMethod').value === 'Wire_Transfer'; else noAccountNumber">
                  {{ row.get('accountNumber').value }}
                </td>
                <ng-template #noAccountNumber>
                  <td>-</td>
                </ng-template>

                <td *ngIf="row.get('transmissionMethod').value === 'ACH' || row.get('transmissionMethod').value === 'Wire_Transfer'; else noBankInfo">
                  <div>{{ row.get('bank').value.name }}</div>
                  <address class="font-italic">
                    <div>{{ row.get('bank').value.address.address1 }}</div>
                    <div>{{ row.get('bank').value.address.address2 }}</div>
                    <div>{{ row.get('bank').value.address.city }}, {{ row.get('bank').value.address.state }} {{ row.get('bank').value.address.zipCode }}</div>
                  </address>
                </td>
                <ng-template #noBankInfo>
                  <td>-</td>
                </ng-template>

              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class="link blue" (click)="addExternalFunding()" *ngIf="editingRefinancedFundings && application.status !== 'Declined'">
      + Add A Row
    </div>

    <div class="row mt-5">
      <div class="col-sm-3">
        <mat-label>Use Which Value</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="refinanceBalanceSource" (selectionChange)="onRefinanceSourceChange()">
            <mat-option *ngFor="let row of refinanceSources" [value]="row.value">
              {{ row.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-2">
        <mat-label>Include Fees</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="refinanceBalanceSourceIncludeFees" (selectionChange)="onRefinanceSourceChange()">
            <mat-option [value]="false">No</mat-option>
            <mat-option [value]="true">Yes</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-3">
        <mat-label>Advance Amount</mat-label>
        <div class="field-value">{{ application?.appliedFundingOffer.fundedAmount | currency }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Fees</mat-label>
        <table class="field-value">
          <tbody>
            <tr class="field-value" [ngClass]="{'bg-light': i%2}" *ngFor="let fee of application?.appliedFundingOffer.fees; let i = index">
              <td>{{ fee.type !== 'Other' ? fee.type.replace('_', ' ') + ' Fee' : fee.name}}{{ !fee.includeInBalance && !fee.recurring ? '*' : '' }}</td>
              <td class="text-end">{{ fee.amount | currency }}</td>
            </tr>
          </tbody>
        </table>
        <div><small>* Fees that are deducted at funding</small></div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="scrollable" *ngIf="!hideRefinanceSummary">
        <table class="text-end">
          <thead>
            <tr>
              <th class="text-start">Source</th>
              <th *ngIf="!hideOutstandingBalance && !hidePendingBalance">Outstanding Balance</th>
              <th *ngIf="!hideOutstandingBalance">Outstanding Balance With Fees</th>
              <th *ngIf="!hidePendingBalance && !hideOutstandingBalance">Pending Balance</th>
              <th *ngIf="!hidePendingBalance">Pending Balance With Fees</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="this.form.value.externalFundings?.length" class="bg-light">
              <td class="text-start">3<sup>rd</sup> Party</td>
              <td *ngIf="!hideOutstandingBalance && !hidePendingBalance">{{ form.get('externalBalance').value | currency }}</td>
              <td *ngIf="!hideOutstandingBalance">{{ form.get('externalBalance').value | currency }}</td>
              <td *ngIf="!hidePendingBalance && !hideOutstandingBalance">{{ form.get('externalBalance').value | currency }}</td>
              <td *ngIf="!hidePendingBalance">{{ form.get('externalBalance').value | currency }}</td>
            </tr>
            <tr [ngClass]="{'bg-light' : !this.form.value.externalFundings?.length}">
              <td class="text-start">Internal</td>
              <td *ngIf="!hideOutstandingBalance && !hidePendingBalance">{{ (form.get('outstandingBalance').value - form.get('externalBalance').value) | currency }}</td>
              <td *ngIf="!hideOutstandingBalance">{{ (form.get('outstandingBalanceWithFees').value - form.get('externalBalance').value) | currency }}</td>
              <td *ngIf="!hidePendingBalance && !hideOutstandingBalance">{{ (form.get('pendingBalance').value - form.get('externalBalance').value) | currency }}</td>
              <td *ngIf="!hidePendingBalance">{{ (form.get('pendingBalanceWithFees').value - form.get('externalBalance').value) | currency }}</td>
            </tr>
            <tr class="bg-light font-bold fw-semibold">
              <td class="text-start">Total Payoff</td>
              <td *ngIf="!hideOutstandingBalance && !hidePendingBalance" [ngClass]="{'bg-onyx-light' : this.form.get('refinanceBalanceSource').value === 'Outstanding_Balance' && this.form.get('refinanceBalanceSourceIncludeFees').value === false}">{{ form.get('outstandingBalance').value | currency }}</td>
              <td *ngIf="!hideOutstandingBalance" [ngClass]="{'bg-onyx-light' : this.form.get('refinanceBalanceSource').value === 'Outstanding_Balance' && this.form.get('refinanceBalanceSourceIncludeFees').value === true}">{{ form.get('outstandingBalanceWithFees').value | currency }}</td>
              <td *ngIf="!hidePendingBalance && !hideOutstandingBalance" [ngClass]="{'bg-onyx-light' : this.form.get('refinanceBalanceSource').value === 'Pending_Balance' && this.form.get('refinanceBalanceSourceIncludeFees').value === false}">{{ form.get('pendingBalance').value | currency }}</td>
              <td *ngIf="!hidePendingBalance" [ngClass]="{'bg-onyx-light' : this.form.get('refinanceBalanceSource').value === 'Pending_Balance' && this.form.get('refinanceBalanceSourceIncludeFees').value === true}">{{ form.get('pendingBalanceWithFees').value | currency }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <mat-label>Refinance Amount</mat-label>
        <div class="field-value">{{ form.get('refinanceAmount').value | currency }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Disbursement Amount</mat-label>
        <div class="field-value">{{ form.get('disbursementAmount').value | currency }}</div>
      </div>
      <div class="col-sm-4">
        <mat-label>Refinance Notes</mat-label>
        <div class="field-value">
          <div *ngIf="!editingRefinancedFundings">
            {{ form.get('refinanceNotes').value }}
          </div>

          <mat-form-field appearance="outline" *ngIf="editingRefinancedFundings">
            <textarea matInput formControlName="refinanceNotes" rows="4"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!refinance">
  <div class="row">
    <div class="col-sm-4">
      <mat-label>Advance Amount</mat-label>
      <div class="field-value">{{ application?.appliedFundingOffer.fundedAmount | currency }}</div>
    </div>

    <div class="col-sm-4">
      <mat-label>Fees</mat-label>
      <div class="row">
        <div class="col-sm-11 col-lg-10">
          <table class="field-value me-5">
            <tbody>
              <tr class="field-value" [ngClass]="{'bg-light': i%2}" *ngFor="let fee of application?.appliedFundingOffer.fees; let i = index">
                <td>{{ fee.type !== 'Other' ? fee.type.replace('_', ' ') + ' Fee' : fee.name}}{{ !fee.includeInBalance && !fee.recurring ? '*' : '' }}</td>
                <td class="text-end">{{ fee.amount | currency }}</td>
              </tr>
            </tbody>
          </table>
          <div><small>* Fees that are deducted at funding</small></div>
        </div>
      </div>
    </div>

    <div class="col-sm-4">
      <mat-label>Disbursement Amount</mat-label>
      <div class="field-value">
        {{ application?.appliedFundingOffer.fundedAmount -
        (calculateFees(application?.appliedFundingOffer.fees) + (application && application.refinance ? application.refinanceAmount : 0)) | currency }}
      </div>
    </div>
  </div><br>
</div>
