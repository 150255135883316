<div [formGroup]="address" [ngClass]="{'col-sm-12 row m-0 p-0': horizontal=== true}">
  <div [ngClass]="horizontal ? 'col-sm-6' : 'col-sm-12 p-0'">
    <span *ngIf="isGoogleAPIKeyFailed" class="text-red">Due to a network error, address autofill is not available. Please enter address manually.</span>
    <div class="col-sm-12 p-0 mt-1">
      <mat-label>Address <span *ngIf="showAsterisks" class="text-red">*</span></mat-label>
      <mat-form-field appearance="outline">
        <input matInput
              oiq-address-autocomplete
              (onAddressSelected)="populateAddress($event)"
              (googleAPIKeyFailure)="handleGoogleAPIKeyFailure($event)"
              formControlName="address1"
              [placeholder]="editMode ? 'Search for address..' : ''"
              autocomplete="new-password"
              type="search">
      </mat-form-field>
    </div>
    <div class="col-sm-12 p-0 mt-1">
      <mat-label>Address 2</mat-label>
      <mat-form-field appearance="outline">
        <input id="address2" matInput formControlName="address2" [placeholder]="editMode ? 'Address 2' : ''">
      </mat-form-field>
    </div>
  </div>

  <div class="col-sm-12 mt-1">
    <div class="row" [ngClass]="{'col-sm-6 pe-0': horizontal === true}">
      <div class="col-sm-6" [ngClass]="horizontal ? 'm-0' : 'mt-3'">
        <mat-label>City&nbsp;<span *ngIf="showAsterisks" class="text-red">*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="city" [placeholder]="editMode ? 'City' : ''">
        </mat-form-field>
        <div *ngIf="address.get('city').errors?.invalidCity" class="error-text login-error">This is a requied field</div>
      </div>

      <div class="col-sm-3" [ngClass]="horizontal ? 'm-0' : 'mt-3'">
        <mat-label>State&nbsp;<span *ngIf="showAsterisks" class="text-red">*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="state" [placeholder]="editMode ? 'State' : ''">
        </mat-form-field>
        <div *ngIf="address.get('state').errors?.invalidState" class="error-text login-error">This is a requied field</div>
      </div>

      <div class="col-sm-3 p-0" [ngClass]="horizontal ? 'm-0' : 'mt-3'">
        <mat-label>ZIP&nbsp;<span *ngIf="showAsterisks" class="text-red">*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput formControlName="zipCode" [placeholder]="editMode ? 'ZIP' : ''">
        </mat-form-field>
        <div *ngIf="address.get('zipCode').errors?.invalidZipCode" class="error-text login-error">This is a requied field</div>
      </div>
    </div>
  </div>
  <button mat-stroked-button class="btn-table remove-address" *ngIf="index" (click)="removeAddress()">Remove address</button>
  <button mat-stroked-button class="btn-table remove-address" *ngIf="showSaveButton" (click)="saveAddresses()">Save addresses</button>
</div>
