<div>
  <div class="mat-dialog-title-wrapper">
    <div class="d-inline-flex align-items-center">
      <mat-icon size="small" color="primary">calendar_today</mat-icon>
      <div class="mx-2 fw-bold text-lg">{{data.id ? data.entityName : "New"}} Event</div>
    </div>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>

  <div mat-dialog-content class="pt-0">
    <form [formGroup]="form" autocomplete="off">

      <div class="row my-1">
        <div class="form-group col-sm-3">
          <mat-label>Date *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [matDatepicker]="date" [min]="minDate" placeholder="--/--/----" formControlName="date" oiqValidateDate>
            <mat-datepicker-toggle matPrefix [for]="date"></mat-datepicker-toggle>
            <mat-datepicker #date></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="form-group col-sm-3">
          <mat-label>Time *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput type="time" step="900" formControlName="time">
          </mat-form-field>
        </div>

        <div class="form-group col-sm-3">
          <mat-label>Remind me *</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="interval" (selectionChange)="onIntervalChange($event)" required>
              <mat-option *ngFor="let row of reminderIntervals" [value]="row.value">{{row.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group col-sm-3">
          <mat-label>2nd reminder:</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="interval2">
              <mat-option [value]="''">--</mat-option>
              <mat-option *ngFor="let row of reminderIntervals2" [value]="row.value">{{row.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row mb-0" *ngIf="!isCollectionDetail">
        <div class="form-group col-sm-3">
          <mat-label>Entity</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="entityType" (selectionChange)="onEntityTypeChange($event)">
              <mat-option [value]="''">--</mat-option>
              <mat-option *ngFor="let row of entityTypes" [value]="row.value">{{row.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-group col-sm-7" *ngIf="form.value.entityType">
          <mat-label>Search</mat-label>
          <mat-form-field appearance="outline" class="select-typeahead">
            <input matInput placeholder="Start typing to search for Entity" [(ngModel)]="model" [ngModelOptions]="{standalone: true}" [ngbTypeahead]="searchEntity" [focusFirst]="false" (selectItem)="onEntitySelect($event)" [inputFormatter]="formatter" [resultFormatter]="formatter" [editable]='false'>
            <mat-icon matPrefix>search</mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="isCollectionDetail">
        <div class="col offset-sm-3">
          <span class="text-capitalize me-2">Entity link:</span>
          <a routerLink="/{{form.value.entityType}}/{{form.value.entityId}}/overview" (click)="closeDialog(true)">{{ form.value.entityName }}</a>
        </div>
      </div>

      <div class="row" *ngIf="!isCollectionDetail && form.value.entityType">
        <div class="col offset-sm-3">
          <span class="text-capitalize me-2">Entity link:</span> <span *ngIf="!form.value.entityName">- not set -</span>
          <a *ngIf="form.value.entityName && form.value.entityId" routerLink="/{{form.value.entityType}}/{{form.value.entityId}}/{{form.value.entityType == 'law-firms' || form.value.entityType == 'isos' ? 'general-info' : 'overview'}}" (click)="closeDialog(true)">{{ form.value.entityName }}</a>
        </div>
      </div>

      <div class="row mt-2 mb-0">
        <div class="form-group col-sm-12">
          <mat-label>Title *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="title">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <mat-label>Enter notes</mat-label>
          <mat-form-field appearance="outline">
            <textarea rows="12" matInput formControlName="description"></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>

  <div mat-dialog-actions>
    <button mat-stroked-button (click)="closeDialog(false)" cdkFocusInitial oiq-loading [spinner]="false">Cancel</button>
    <button mat-raised-button color="accent" (click)="onSubmit()" [buttonDisabled]="!form.valid" oiq-loading>
      Save
    </button>
  </div>
</div>
