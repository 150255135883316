import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class PlaidService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'plaid/';
  }

  generateLinkToken(data: any) {
    return this.http.post(this.getUrl() + `link`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  exchangePublicToken(data: any) {
    return this.http.post(this.getUrl() + `exchange`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  // initiate all plaid sub reports in a single call
  sendRequest(data: any) {
    return this.http.post(this.getUrl() + `request`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  // get the results of an existing plaid report
  getPlaidReport(id: number) {
    return this.http.get(this.getUrl() + id)
      .pipe(map(res => {
        return res;
      }));
  }

  // get a list of plaid reports for a given applicationId
  getApplicationRequests(applicationId: number) {
    return this.http.get(this.getUrl() + `?applicationId=${applicationId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  // get the aggregation of an existing report
  getAggregate(id: number) {
    return this.http.get(this.getUrl() + `${id}/aggregate`)
      .pipe(map(res => {
        return res;
      }));
  }

  // this creates a plaid report and sets it to link pending status, it sends out the email
  initiateLinkToken(data: any) {
    return this.http.post(this.getUrl() + `link/initiate`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  // this completes the whole linking process and then starts retrieving reports
  completeLinkToken(data: any) {
    return this.http.post(this.getUrl() + `link/complete`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  // this resends the email for the passed in {"plaidReportId": 1234}, a new token will get created most likely
  resendLinkToken(data: any) {
    return this.http.post(this.getUrl() + `link/resend`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  // new endpoint, similar to the /link/resend but now it passes the plaidReportId in a case when the linkToken is known but expired
  resendLinkTokenByReportId(plaidReportId: any) {
    return this.http.post(this.getUrl() + `link/resend/${plaidReportId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }
}
