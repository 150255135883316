<div *ngIf="syndicationOffer">
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>{{ syndicationOffer.type === 'Manual' ? 'Accept the Offer' : 'Make an Offer' }}</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form">
    <div mat-dialog-content>

      <div class="row">
        <div class="col-sm-10">
          <h3>{{geTitleBasedOnCalculationObject()}}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10" *ngIf="syndicationOffer.type !== 'Manual' ">
          <mat-label>Percent Syndicated *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="percentSyndicated" autocomplete="off" type="number" step="0.01" required>
          </mat-form-field>
          <div class="info-text info">Allowed range: {{ syndicationOffer?.minimumPercentSyndicated }}% to {{ syndicationOffer?.maximumPercentSyndicated }}%</div>
          <div class="error-text warn" *ngIf="form.get('percentSyndicated').errors?.minimumPercentSyndicated">Below allowed range</div>
          <div class="error-text warn" *ngIf="form.get('percentSyndicated').errors?.maximumPercentSyndicated">Above allowed range</div>
        </div>
      </div>

      <div class="row" *ngIf="syndicationOffer.type !== 'Manual' ">
        <div class="col-sm-10">
          <mat-label>Principal To Be Paid *</mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="principalSyndicated" autocomplete="off" currencyMask required>
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="syndicationOffer.type === 'Manual' ">
        <div class="col-sm-10">
          <mat-label>Percent Syndicated</mat-label>
          {{ form.get('percentSyndicated').value | number: '1.2-2' }}%
        </div>
      </div>

      <div class="row" *ngIf="syndicationOffer.type === 'Manual' ">
        <div class="col-sm-10">
          <mat-label>Principal To Be Paid</mat-label>
          {{ form.get('principalSyndicated').value | currency }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-label>Payback Amount</mat-label>
          {{ paybackAmount | currency }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-label>Commission</mat-label>
          {{ commission | currency }}
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-label>Management Fee Percent</mat-label>
          {{ syndicationOffer?.managementFeePercent }}%
        </div>
      </div>

      <div class="row">
        <div class="col-sm-10">
          <mat-label>Total Owed</mat-label>
          {{ totalOwed | currency }}
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button cdkFocusInitial color="accent" type="submit"
      [buttonDisabled]="!form.valid && syndicationOffer.type === 'Automatic'" (click)="submit()" oiq-loading>
      Syndicate
      </button>
    </div>
  </form>
</div>

<div *ngIf="!syndicationOffer">
  <div mat-dialog-content>
    <oiq-loading-message></oiq-loading-message>
  </div>
</div>
