import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import {Address} from "@core/models/address";
import { PaymentDays }from './contract';

export class GeneralSettings extends FormGroup {
  constructor() {
    super({
      allowFundingPaymentSelectionOnFinalApproval: new FormControl(false),
      statusSyndicationCanBeginAt: new FormControl('Offer_Applied'),
      eSignatureProvider: new FormControl(),
      minimumPasswordLength: new FormControl('', Validators.compose([Validators.min(8), Validators.required])),
      maximumPasswordLength: new FormControl('', Validators.compose([Validators.min(16), Validators.max(50), Validators.required])),
      useGlobalESignatureProviderSettings: new FormControl(true),
      emailFromAddress: new FormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      contractsEmailAddress: new FormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      renewalsEmailAddress: new FormControl({ value: '', validators: Validators.compose([Validators.email]), updateOn: 'blur' }),
      emailPhoneNumber: new FormControl(),
      useDefaultMasterEmailNotificationTemplate: new FormControl(true),
      masterEmailNotificationTemplate: new FormControl(),
      whiteLabelNotificationSettings: new FormControl(),
      emailAddressRecipients: new FormArray([]),
      paymentReturnedRecipients: new FormArray([]),
      missingAutomatedPaymentEventRecipients: new FormArray([]),
      missingManualPaymentEventRecipients: new FormArray([]),
      memoField: new FormControl(),
      processSetting: new ProcessSetting(),
      docuSignIntegratorKey: new FormControl(''),
      docuSignUserId: new FormControl(''),
      docuSignPrivateKey: new FormControl(''),
      helloSignApiKey: new FormControl(''),
      decisionLogicServiceKey: new FormControl(''),
      decisionLogicSiteUserGuid: new FormControl(''),
      decisionLogicProfileId: new FormControl(''),
      clearAccountNumber: new FormControl(''),
      clearUserId: new FormControl(''),
      clearPassword: new FormControl(''),
      crsUsername: new FormControl(''),
      crsRiskModel: new FormControl(''),
      crsPassword: new FormControl(''),
      plaidClientId: new FormControl(''),
      plaidSecret: new FormControl(''),
      plaidProducts: new FormArray([]),
      workflowBankInsightProviderUsed: new FormControl('DecisionLogic'),
      fundingOnly: new FormControl(false),
      mfaEnforced: new FormControl(false),
      features: new TenantFeatures(),
      moneyThumbTierPricings: new FormArray([]),
      creditCheckProvider: new FormControl(''),
      excludeFromCreditCheck: new FormControl(true),
      maximumContractOwners: new FormControl(2)
    });
  }
}

export class ProcessSetting extends FormGroup {
  constructor() {
    super({
      includeUnderwritingReview: new FormControl()
    });
  }
}

export class FinancialSettings extends FormGroup {
  constructor() {
    super({
      daysInMonth: new FormControl('', [Validators.required, Validators.max(31), Validators.min(20)]),
      weeksInMonth: new FormControl('', [Validators.required, Validators.max(5), Validators.min(3)]),
      defaultBankFeePercents: new FormArray([]),
      defaultTermLength: new FormControl(''),
      defaultBounceFee: new FormControl(''),
      defaultStopPaymentFee: new FormControl(''),
      defaultFrozenAccountFee: new FormControl(''),
      defaultBuyRate: new FormControl(''),
      defaultDefaultFee: new FormControl(''),
      defaultAccountClosedFee: new FormControl(''),
      defaultCommission: new FormControl(''),
      defaultPaymentFrequency: new FormControl(''),
      minimumRenewalPercent: new FormControl(''),
      defaultBankFeePercent: new FormControl(''),
      defaultWireFee: new FormControl(''),
      defaultPrepayBuyRates: new FormArray([]),
      defaultCustomFees: new FormArray([]),
      syndicatorMinimumFundedAmount: new FormControl(''),
      defaultPrepays: new FormArray([]),
      createHolidayMakeUpPaymentAutomatically: new FormControl(false),
    });
  }
}

export class DefaultPrepayBuyRate extends FormGroup {
  constructor() {
    super({
      value: new FormControl(0, Validators.required)
    });
  }
}

export class DefaultPrepays extends FormGroup {
  constructor() {
    super({
      buyRate: new FormControl('', [Validators.required, Validators.min(1.00)]),
      startDay: new FormControl(''),
      endDay: new FormControl(''),
      factorRate: new FormControl('', [Validators.min(1.00)])
    });
  }
}

export class OCRTier extends FormGroup {
  constructor() {
    super({
      start: new FormControl(null),
      end: new FormControl(null),
      price: new FormControl(null)
    });
  }
}

export class CustomFee extends FormGroup {
  constructor() {
    super({
      type: new FormControl('', Validators.required),
      name: new FormControl(''),
      feeDate: new FormControl(''),
      recurring: new FormControl(false),
      includeInBalance: new FormControl(false),
      amount: new FormControl(0)
    });
  }
}

export class DefaultFee extends FormGroup {
  constructor() {
    super({
      type: new FormControl('', Validators.required),
      name: new FormControl(''),
      feeDate: new FormControl(''),
      recurring: new FormControl(false),
      includeInBalance: new FormControl(true),
      amount: new FormControl(0),
      paymentFrequency: new FormControl(''),
      paymentDays: new PaymentDays(),
      paymentDay: new FormControl('')
    });
  }
}

export class DefaultBankFeePercent extends FormGroup {
  constructor() {
    super({
      position: new FormControl('', Validators.required),
      bankFeePercent: new FormControl(0, Validators.required),
      fundedAmount: new FormControl(0, Validators.required)
    });
  }
}

export class TenantSyndicatorSettings extends FormGroup {
  constructor() {
    super({
      automaticAch: new FormControl(false, Validators.required),
      paymentFrequency: new FormControl('Weekly', Validators.required),
      paymentDay: new FormControl('Monday', Validators.required),
      defaultManagementFeePercent: new FormControl(0),
      emailAddressRecipients: new FormArray([])
    });
  }
}

export class TenantFeatures extends FormGroup {
  constructor() {
    super({
      BorrowingBase: new FormControl(false),
      Clear: new FormControl(false),
      DecisionLogic: new FormControl(false),
      Experian: new FormControl(false),
      MoneyThumb: new FormControl(false),
      Crs: new FormControl(false),
      Plaid: new FormControl(false),
      OfferRange: new FormControl(false),
      PreUnderwriting: new FormControl(false)
    });
  }
}

export class WhiteLabelData extends FormGroup {
  constructor() {
    super({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      address: new Address(),
      email: new FormControl( null, Validators.compose([Validators.email, Validators.required])),
      memoField: new FormControl(null, Validators.required),
      emailLogoId: new FormControl(null),
      logoId: new FormControl(null),
      tenantId: new FormControl(null),
      tenantDefault: new FormControl(false),
      businessName: new FormControl(null),
      dbaName: new FormControl(null),
      phoneNumber: new FormControl(null)
    })
  }
}

export class WhiteLabelSettings extends FormGroup {
  constructor() {
    super({
      docuSignIntegratorKey: new FormControl(null),
      docuSignPrivateKey: new FormControl(null),
      docuSignUserId: new FormControl(null),
      emailFromAddress: new FormControl( null, Validators.compose([Validators.email, Validators.required])),
      contractsEmailAddress: new FormControl( null, Validators.compose([Validators.email, Validators.required])),
      renewalsEmailAddress: new FormControl( null, Validators.compose([Validators.email, Validators.required])),
      overrideDefaultSettingsWithWhiteLabelSettings: new FormControl(false),
      masterEmailNotificationTemplate: new FormControl(null),
      tenantId: new FormControl(null),
      useGlobalESignatureProviderSettings: new FormControl(true),
      whiteLabelId: new FormControl(null),
      bankInfo: new FormGroup({
        accountNumber: new FormControl(null, Validators.required),
        accountType: new FormControl(null, Validators.required),
        accountName: new FormControl(null, Validators.required),
        address: new FormControl(null),
        branch: new FormControl(null),
        dda: new FormControl(null),
        name: new FormControl(null, Validators.required),
        routingNumber: new FormControl(null, Validators.required),
      })
    })
  }
}

export class IntegrationsData extends FormGroup {
  constructor() {
    super({
      decisionLogicProfileId: new FormControl(""),
      decisionLogicServiceKey: new FormControl(""),
      decisionLogicSiteUserGuid: new FormControl(""),
      clearAccountNumber: new FormControl(""),
      clearPassword: new FormControl(""),
      clearUserId: new FormControl(""),
      creditCheckProvider: new FormControl(null),
      crsRiskModel: new FormControl(null),
      crsUsername: new FormControl(""),
      crsPassword: new FormControl(""),
      plaidClientId: new FormControl(""),
      plaidSecret: new FormControl(""),
      plaidProducts: new FormArray([]),
      globalHoldingsUsername: new FormControl(""),
      globalHoldingsPassword: new FormControl(""),
      moneyThumbTierPricings: new FormArray([]),
      workflowBankInsightProviderUsed: new FormControl(null),
    })
  }
}

export class BBSettings extends FormGroup {
  constructor() {
    super({
      fundingDateStart: new FormControl(''),
      originationDate: new FormControl(''),
      maxTermLength: new FormControl(''),
      unpaidPrincipalLimit: new FormControl(''),
      minFactorRate: new FormControl(''),
      maxPercentBehind: new FormControl(''),
      dailyMissedLimit1: new FormControl(''),
      weeklyMissedLimit1: new FormControl(''),
      dailyMissedLimit2: new FormControl(''),
      weeklyMissedLimit2: new FormControl(''),
      levelOnePercent: new FormControl(''),
      levelTwoPercent: new FormControl(''),
      levelThreePercent: new FormControl(''),
      minFico: new FormControl(null),
      maxPosition: new FormControl(null),
      weeksInYear: new FormControl(''),
      daysInYear: new FormControl(''),
      whiteLabels: new FormControl(''),
    })
  }
}
