import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return '';
    }

    if (value.firstName && value.lastName) {
    	return value.firstName + ' ' + value.lastName;
  	} else if (value.firstName && value.surname) {
    	return value.firstName + (value.middleName ? ' ' + value.middleName + ' ' : ' ') + value.surname;
  	} else if (value.firstName) {
  		return value.firstName;
  	} else {
  		return '';
  	}
  }

}
