import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { default as tradeLine } from './../../../../assets/mappings/experian-mappings.json';

type PaymentHistoryYear = {
  year: string;
  jan?: string | null;
  feb?: string | null;
  mar?: string | null;
  apr?: string | null;
  may?: string | null;
  jun?: string | null;
  jul?: string | null;
  aug?: string | null;
  sep?: string | null;
  oct?: string | null;
  nov?: string | null;
  dec?: string | null;
};

@Component({
  selector: 'oiq-tradeline',
  templateUrl: './tradeline.component.html',
  styleUrls: ['./tradeline.component.scss']
})
export class TradelineComponent implements OnInit {

  @Input()
  row: any;

  @Input()
  account: string;

  tradeLineData: any = tradeLine;

  scoreSummaryColumns: Array<any> = ['riskModel', 'score', 'code', 'scoreFactorDescription'];
  months: Array<any> = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  paymentHistoryColumns: Array<any> = ['year'];
  delinquencyColumns: Array<any> = ['thirty', 'sixty', 'ninety', 'derog'];

  constructor() { }

  ngOnInit() {
    this.paymentHistoryColumns = this.paymentHistoryColumns.concat(this.months);

    if (this.row.paymentHistoryYears) {
      this.row.paymentHistoryDatasource = new MatTableDataSource(this.row.paymentHistoryYears);
    } else {
      const paymentYears = this.createTemplate(this.row.statusDate, this.row.openDate, this.row.paymentHistory, this.row.monthsHistory);
      this.row.paymentHistoryDatasource = new MatTableDataSource(paymentYears);
    }

    this.row.delinquencyDatasource = new MatTableDataSource([{
      thirty: this.row.delinquencies30Days,
      sixty: this.row.delinquencies60Days,
      ninety: this.row.delinquencies90to180Days,
      derog: this.row.derogCounter
    }]);
  }

  parseDate(dateStr: string): Date {
    const month = parseInt(dateStr.substring(0, 2), 10);
    const day = parseInt(dateStr.substring(2, 4), 10);
    const year = parseInt(dateStr.substring(4, 8), 10);
    return new Date(year, month - 1, day);
  }

  createTemplate(statusDate: string, openDate: string, paymentHistory: string, monthsHistory: number): PaymentHistoryYear[] {
    const monthNames = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    const endDate = this.parseDate(statusDate);
    const startDate = new Date(endDate);
    startDate.setMonth(endDate.getMonth() - monthsHistory + 1);

    paymentHistory = paymentHistory.split("").reverse().join("");

    let paymentHistoryIndex = paymentHistory.length - 1;
    let currentMonth = endDate.getMonth();
    let currentYear = endDate.getFullYear();

    const result: PaymentHistoryYear[] = [];

    while (paymentHistoryIndex >= 0) {
      const yearIndex = result.findIndex(item => item.year === currentYear.toString());
      let yearObj: PaymentHistoryYear;

      if (yearIndex === -1) {
        yearObj = { year: currentYear.toString() };
        result.push(yearObj);
      } else {
        yearObj = result[yearIndex];
      }

      yearObj[monthNames[currentMonth]] = paymentHistory[paymentHistoryIndex];

      currentMonth--;
      if (currentMonth < 0) {
        currentMonth = 11;
        currentYear--;
      }

      paymentHistoryIndex--;
    }

    return result.reverse();
  }

  mapToDescription(field, code) {
    return this.tradeLineData[field][code];
  }
}
