<table mat-table [dataSource]="dataTable" matSort matSortDirection="desc">
  <ng-container matColumnDef="fundingName">
    <th mat-header-cell *matHeaderCellDef>Funding Name</th>
    <td mat-cell *matCellDef="let row">
      <span>{{ row.clientName }}: <br><strong>{{ row.installment }}</strong></span>
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let row">{{ row.fundingDate | date:'shortDate' }}</td>
  </ng-container>
  <ng-container matColumnDef="amountFunded">
    <th mat-header-cell *matHeaderCellDef>Amount Funded</th>
    <td mat-cell *matCellDef="let row">{{ row.fundedAmount | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="paybackAmount">
    <th mat-header-cell *matHeaderCellDef>Payback Amount</th>
    <td mat-cell *matCellDef="let row">{{ row.payback | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="termLength">
    <th mat-header-cell *matHeaderCellDef>Term Length (months)</th>
    <td mat-cell *matCellDef="let row">{{ row.termLength }}</td>
  </ng-container>
  <ng-container matColumnDef="amountSyndicated">
    <th mat-header-cell *matHeaderCellDef>Funded Amount Syndicated</th>
    <td mat-cell *matCellDef="let row">{{ row.syndicatedAmount | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="paymentAmountSyndicated">
    <th mat-header-cell *matHeaderCellDef>Payback Amount Syndicated</th>
    <td mat-cell *matCellDef="let row">{{ row.syndicatedPayback | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="percentageSyndicated">
    <th mat-header-cell *matHeaderCellDef>Percentage Syndicated</th>
    <td mat-cell *matCellDef="let row">{{ row.syndicatedPercent | number : '1.2-2' }}%</td>
  </ng-container>
  <ng-container matColumnDef="synRtr">
    <th mat-header-cell *matHeaderCellDef>SYN-RTR</th>
    <td mat-cell *matCellDef="let row">{{ row.rtrOwed | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="commission">
    <th mat-header-cell *matHeaderCellDef>Commission</th>
    <td mat-cell *matCellDef="let row">{{ row.syndicatedCommission | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="managementFee">
    <th mat-header-cell *matHeaderCellDef>Management Fee to Date</th>
    <td mat-cell *matCellDef="let row">{{ row.managementFeeAmount | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="totalAmountSent">
    <th mat-header-cell *matHeaderCellDef>Total Received Amount</th>
    <td mat-cell *matCellDef="let row">{{ row.totalCollected | currency }}</td>
  </ng-container>
  <ng-container matColumnDef="percentageToPrincipal">
    <th mat-header-cell *matHeaderCellDef>Percentage to&nbsp;Principal</th>
    <td mat-cell *matCellDef="let row">{{ row.percentToPrincipal | number : '1.2-2' }}%</td>
  </ng-container>
  <ng-container matColumnDef="percentToRrt">
    <th mat-header-cell *matHeaderCellDef>Percentage to&nbsp;RTR</th>
    <td mat-cell *matCellDef="let row">{{ row.percentCompleted | number : '1.2-2' }}%</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" (click)="showPayments(row, 'syndicator')"></tr>
</table>
<div class="ps-4 pt-1 loading-records" *ngIf="isLoadingRecords">Loading records</div>
<!-- <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="dataTable?.length === 0"></oiq-loading-message> -->
<mat-paginator [length]="tableTotal" [pageIndex]="currentPage" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
