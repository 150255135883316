import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LookupListService } from '@core/services';

import { AuthService } from '@core/authentication/auth.service';

@Component({
  selector: 'oiq-edit-lookup-list-dialog',
  templateUrl: './edit-lookup-list-dialog.component.html',
  styleUrls: ['./edit-lookup-list-dialog.component.scss']
})
export class EditLookupListDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private lookupListService: LookupListService,
    public dialogRef: MatDialogRef<EditLookupListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      type: ['', Validators.required],
      value: ['', Validators.required],
      tenantId: ['']
    });

    if (this.data.lookupList) {
      this.form.patchValue(this.data.lookupList);
    }

    this.form.patchValue({
      type: this.data.type,
      tenantId: this.authService.getUser().tenantId
    });
  }

  save() {
    let observable;

    if (this.form.value.id) {
      observable = this.lookupListService.update(this.form.value.id, this.form.value);
    } else {
      observable = this.lookupListService.create(this.form.value);
    }

    observable.subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

}
