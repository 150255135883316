<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Edit Syndication</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="row" [formGroup]="form">
      <div class="col-sm-12">
        <table class="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Principal Syndicated *</th>
            <th>Percent Syndicated *</th>
            <th>Principal To Be Paid *</th>
            <th>Management Fee Percentage *</th>
            <th>Commission *</th>
            <th>Total To Be Paid</th>
            <th>Max Payback</th>
            <th>Syndication Calculation Logic</th>
          </tr>
          </thead>
          <tbody formArrayName="manualSyndicationOffers">
            <ng-container *ngFor="let item of form.get('manualSyndicationOffers')['controls']; let i = index;" [formGroupName]="i">
              <tr>
                <td class="oneline emphasis">{{ item.controls['syndicatorName'].value }}</td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="acceptedAmountSyndicated" type="text" autocomplete="off" currencyMask>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="acceptedPercentSyndicated" type="number" autocomplete="off">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="principalToBePaid" [readonly]="true" type="text" autocomplete="off" currencyMask>
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="managementFeePercent" type="number" autocomplete="off">
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">
                    <input matInput formControlName="commissionPercent" type="number" autocomplete="off">
                  </mat-form-field>
                </td>
                <td>{{ item.controls['maxTotalReceived'].value | currency }}</td>
                <td>{{ item.controls['maxPayback'].value | currency }}</td>
                <td class="defaultSyndicationCalculationLogicOptionColumn">
                  <mat-form-field appearance="outline">
                    <mat-select (selectionChange)="onSelectedSyndicationCalculationLogic($event, item)" [value]="item.get('calculationLogic').value">
                      <mat-option *ngFor="let option of syndicationCalculationLogicOptions" [value]="option.value">
                        {{ option.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </td>
              </tr>
              <tr>
                <td colspan="8">
                  <div class="error-text warn" *ngIf="item.get('acceptedPercentSyndicated').errors?.acceptedPercentSyndicatedTooSmall">Percent must be greater than 0</div>
                  <div class="error-text warn" *ngIf="item.get('acceptedPercentSyndicated').errors?.acceptedPercentSyndicatedTooLarge">Percent must be less than 100</div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent" type="submit" (click)="save()"[disabled]="!form.valid" cdkFocusInitial>
      Save
    </button>
  </div>
</div>
