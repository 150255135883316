import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Credit } from '@core/models';

import { FundingService, TransmissionService } from '@core/services';

@Component({
  selector: 'oiq-edit-credit-dialog',
  templateUrl: './edit-credit-dialog.component.html',
  styleUrls: ['./edit-credit-dialog.component.scss']
})
export class EditCreditDialogComponent implements OnInit {
  form: FormGroup;

  funding: any;

  formValid: boolean;

  // @Input() fundingCredit: boolean;

  transmissionProcessors = [];
  transmissionMethods = [];
  transmissionMethodList = [];

  constructor(
    private transmissionService: TransmissionService,
    private fundingService: FundingService,
    public dialogRef: MatDialogRef<EditCreditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    if (this.data.funding) {
      this.funding = this.data.funding;
    }

    this.getTransmissionProcessors();

    this.form = new Credit();

    if (this.data.credit) {
      this.form.patchValue(this.data.credit);
    }

    this.form.valueChanges.subscribe(data => {
      if (data.amount > 0) {
        this.form.get('amount').setValue(-data.amount);
      }
      setTimeout(() => {
        this.formValid = this.form.valid;
      }, 0);
    });
  }

  getTransmissionProcessors() {
    this.transmissionService.getProcessorsByWhiteLabel(this.funding.whiteLabelId, 'Funding_Credit')
      .subscribe((r: any) => {
        this.transmissionMethods = r;
        this.setTransmissionMethodList();
      })
  }

  onTransmissionMethodChanged(event) {
    if (this.form.value.transmissionMethod === 'ACH') {
      this.form.get('transmissionProcessor').setValidators([Validators.required]);
      this.form.get('bankId').setValidators([Validators.required]);
      this.setTransmissionMethodProcessors(event.value)

      this.form.patchValue({
        transmissionProcessor: ''
      });
    } else {
      this.form.get('transmissionProcessor').clearValidators();
      this.form.get('bankId').clearValidators();

      // used until BE removes hardcoded 'Manual' value for manual processors
      this.form.patchValue({
        transmissionProcessor: 'Manual',
        bankId: ''
      });
    }

    this.form.get('transmissionProcessor').updateValueAndValidity();
  }

  setTransmissionMethodProcessors(methodName: string) {
    this.transmissionProcessors = this.transmissionMethods.filter((method: any) => method.name === methodName)[0].processors;
  }

  setTransmissionMethodList() {
    this.transmissionMethods.map((obj:any) => {
      this.transmissionMethodList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
    this.setTransmissionMethodProcessors(this.form.get('transmissionMethod').value);
  }

  onBankSelected(event) {
    this.form.get('bankId').setValue(event);
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const data = this.form.value;

    let observable;

    if (this.data.credit && this.data.credit.id) {
      observable = this.fundingService.updateCredit(this.data.funding.id, this.data.credit.id, data);
    } else {
      observable = this.fundingService.createCredit(this.data.funding.id, data);
    }

    observable.subscribe((r: any) => {
      this.dialogRef.close(true);
    });
  }

}
