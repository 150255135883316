import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { AddSyndicationOffersComponent } from '../add-syndication-offers/add-syndication-offers.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '@core/services';
import { notEmptyFormArrayValidator } from '@shared/utils-custom-form-validators';

@Component({
  selector: 'oiq-add-syndication-offers-dialog',
  templateUrl: './add-syndication-offers-dialog.component.html',
  styleUrls: ['./add-syndication-offers-dialog.component.scss']
})
export class AddSyndicationOffersDialogComponent implements OnInit {

  form = new FormGroup({syndicationOfferForms: new FormArray([], notEmptyFormArrayValidator())});

  constructor(
    public dialogRef: MatDialogRef<AddSyndicationOffersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private applicationService: ApplicationService
    ) { }

  ngOnInit() {
  }

  save() {
    let payload;
    if (this.data.isEdit && this.form.value.syndicationOfferForms && this.form.value.syndicationOfferForms.length) {
      payload = this.form.value.syndicationOfferForms[0]
      this.applicationService.updateSyndicationOffer(this.data.application.id, payload.id, payload).subscribe(r => this.dialogRef.close(true));
    } else {
      let payload = this.form.value.syndicationOfferForms
      this.applicationService.createBulkSyndications(this.data.application.id, payload).subscribe(r => this.dialogRef.close(true));
    }
  }
}
