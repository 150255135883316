import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { ApiKeyValueResponse, ApiResponse } from '@core/models/shared';
import { Application } from '@core/models';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'tasks/';
  }

  listUnderwriting(offset?: number, limit?: number, sortBy?: string, sortDir?: string, isHighPriorityApplication?: boolean) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if(isHighPriorityApplication !== null && isHighPriorityApplication !== undefined ){
      params['urgent'] = isHighPriorityApplication;
    }

    return this.http.get(this.getUrl() + `underwriting`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  public listPreUnderwriting(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<ApiResponse<Application>> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get<ApiResponse<Application>>(this.getUrl() + `pre-underwriting`, { params: params });
  }

  public listPreUnderwritingTasks(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<ApiKeyValueResponse> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get<ApiKeyValueResponse>(this.getUrl() + `pre-underwriting-users`, { params: params });
  }

  public listPreUnderwritingAll(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<ApiKeyValueResponse> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    return this.http.get<ApiKeyValueResponse>(this.getUrl() + `pre-underwriting/all`, { params: params });
  }

  listOperations(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `operations`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listFinalFundingApproval(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `final-funding-approval`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listSendToSyndicators(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `send-to-syndicators`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listActionRequired(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listMyWork(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `my-work`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listUnderwritingTasks(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `underwriting-users`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  updateQueue(id: string, data?: any) {
    return this.http.put(this.getUrl() + id, data)
      .pipe(map(res => {
        return res;
      }));
  }

  listAwaitingContracts(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `awaiting-contract`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  updateQueueForSingleApplication(id: string, applicationId: number, data?: any) {
    return this.http.put(this.getUrl() + id + `/${applicationId}` , data)
      .pipe(map(res => {
        return res;
      }));
  }
}
