import { Directive, ElementRef, HostListener, Renderer2, Injector } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, NgControl } from "@angular/forms";
import { Subscription } from 'rxjs';

@Directive({
  selector: '[specialCharactersFirstAndLast]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: SpecialCharactersFirstAndLastDirective,
    multi: true
  }]
})
export class SpecialCharactersFirstAndLastDirective implements Validator {
  private controlSubscription: Subscription;
  private control: NgControl;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const hasSpecialChars = this.checkFirstAndLastChars(control.value);
    if (hasSpecialChars) {
      return { 'specialChars': true };
    } else {
      return null;
    }
  }

  private errorMessage: string = "Please do not use special characters at the beginning or end of the field.";
  private readonly errorElement: HTMLElement;
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private injector: Injector
  ) {
    this.errorElement = this.renderer.createElement('mat-error');
  }

  ngOnInit() {
    const parentElement = this.renderer.parentNode(this.el.nativeElement.parentNode.parentNode);
    this.renderer.appendChild(parentElement, this.errorElement);

    this.control = this.injector.get(NgControl, null);
    if (this.control && this.control.valueChanges) {
      this.controlSubscription = this.control.valueChanges.subscribe(value => {
        this.displayError(value);
      });
    }
  }

  private checkFirstAndLastChars(input: string): boolean {
    if (input) {
      const firstChar = input.charAt(0);
      const lastChar = input.charAt(input.length - 1);
      const pattern = /[^\w\s]/;
      return pattern.test(firstChar) || pattern.test(lastChar);
    }
  }

  private displayError(value) {
    const hasSpecialChars = this.checkFirstAndLastChars(value);
    if (hasSpecialChars) {
      this.renderer.setStyle(this.errorElement, 'display', 'block');
      this.renderer.setStyle(this.errorElement, 'color', 'red');
      this.errorElement.textContent = this.errorMessage;
    } else {
      this.el.nativeElement.classList.remove('ng-invalid');
      this.renderer.setStyle(this.errorElement, 'display', 'none');
    }
  }

  @HostListener('blur') onBlur() {
    this.el.nativeElement.value = this.el.nativeElement.value.trim();
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    this.displayError(value);
  }

  @HostListener('input') onInput() {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const value = inputElement.value;
    this.displayError(value);
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData ? event.clipboardData.getData('text/plain') : '';
    const trimmedText = pastedText.trim();

    if (this.control && this.control.control) {
      this.control.control.setValue(trimmedText, { emitEvent: true });
    }

    this.displayError(trimmedText);
  }
  
  ngOnDestroy() {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

}
