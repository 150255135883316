import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { Fee } from '@core/models';

@Component({
  selector: 'oiq-payment-fees',
  templateUrl: './payment-fees.component.html',
  styleUrls: ['./payment-fees.component.scss']
})
export class PaymentFeesComponent implements OnInit {

  @Input()
  form: FormGroup;

  @Input()
  fees: Array<any> = [];

  @Input()
  isLoading: boolean;

  constructor() { }

  ngOnInit() {

    this.form.get('feePayments').setValidators([(control: AbstractControl): ValidationErrors => this.validateFees(control)]);

    this.form.get('amount').valueChanges.subscribe(res => {
      this.form.get('amountPaidToPayback').setValue(res);

      this.form.get('feePayments').updateValueAndValidity();
    });

    this.form.get('feePayments').valueChanges.subscribe(res => {
      const feesFormArray = (this.form.get('feePayments') as FormArray);

      let totalFees = 0;

      feesFormArray.controls.forEach((row) => {
        totalFees += parseFloat(row.value.amount);
      });

      const value = this.form.get('amount').value - totalFees;

      if (value < 0) {
        return;
      }

      this.form.get('amountPaidToPayback').setValue(value);
    });
  }

  ngOnChanges(changes) {
    this.fees = this.fees.filter((row) => {
      return row.includeInBalance === true;
    });
  }

  countUnpaidFees() {
    const feesWithPayments = this.fees.filter((row) => {
      return row.feePayments.length > 0;
    });
    const unpaidFees = feesWithPayments.filter((element) => {
      let paidFeeAmount = 0;
      element.feePayments.forEach(feePayment => {
        paidFeeAmount = paidFeeAmount + feePayment.amount;
      })
      return element.amount > paidFeeAmount;
    });

    const addedFees = this.fees.filter((row) => {
      return row.hasOwnProperty('selectIndex');
    })

    return unpaidFees.length + addedFees.length;
  }

  calcPaid(payments) {
    const paid = payments.reduce((a, b) => {
      return a + b.amount;
    }, 0);
    return paid;
  }

  validateFees(control: AbstractControl): ValidationErrors {
    if (!this.form || this.form.get('amount') === null) {
      return null;
    }

    const feesFormArray = (this.form.get('feePayments') as FormArray);

    let totalFees = 0;

    feesFormArray.controls.forEach((row) => {
      totalFees += parseFloat(row.value.amount);
    });

    if (totalFees > parseFloat(this.form.get('amount').value) && (totalFees !== 0 && (parseFloat(this.form.get('amount').value) > 0))) {
      const result = <ValidationErrors>{
        amountPaidToFees: true
      };
      return result;
    }

    return null;
  }

  validateFee(control: AbstractControl): ValidationErrors {
    const rows = this.fees.filter((row) => row.id === control.value.feeId);

    if (rows.length === 0) {
      return null;
    }

    const paid = rows[0].feePayments.reduce((a, b) => {
      1
      return a + b.amount;
    }, 0);

    const remaining = +(rows[0].amount - paid).toFixed(2);

    if (control.value.amount > remaining) {
      const result = <ValidationErrors>{
        amountPaidToFee: true
      };

      return result;
    }

    return null;
  }

  onSelectionChange(control, i, event) {
    const rows = this.fees.filter((row) => row.id === parseInt(event.value));

    const paid = rows[0].feePayments.reduce((a, b) => {
      return a + b.amount;
    }, 0);

    rows[0].selectIndex = i;

    control.get('amount').setValue(+(rows[0].amount - paid).toFixed(2));
    control.get('feeId').disable();
  }

  removeFee(index) {
    const feeId = (this.form.get('feePayments') as FormArray).at(index).get('feeId').value;

    (this.form.get('feePayments') as FormArray).removeAt(index);

    if (feeId) {
      const rows = this.fees.filter((row) => row.id === feeId);

      delete rows[0].selectIndex;
    }
  }

  addFee() {
    const fee = new Fee();
    fee.setValidators([(control: AbstractControl): ValidationErrors => this.validateFee(control)]);

    (this.form.get('feePayments') as FormArray).push(fee);
  }

}
