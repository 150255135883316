import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

export class User extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', { validators: Validators.compose([Validators.required, Validators.email]), updateOn: 'blur' }),
      password: new FormControl('', [ Validators.minLength(8), Validators.maxLength(16)]),
      passwordConfirm: new FormControl(''),
      roleIds: new FormControl('', Validators.required),
      resources: new FormControl([]),
      loginEnabled: new FormControl(true),
      mfaEnabled: new FormControl(false),
      mfaDestination: new FormControl(null),
      cellNumber: new FormControl(null)
    });
  }
}
