import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, of } from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';

import { SearchService, SyndicatorService, ApplicationService, SettingService, FundingService } from '@core/services';

import { ManualSyndicationOffer, ManualSyndicationOffers, SYNDICATIONS_CALCULATION_LOGIC_OPTIONS } from '@core/models';



///
// THIS COMPONENT IS OBSOLETE PLEASE USE AddSyndicationOffersComponent
///



@Component({
  selector: 'oiq-manual-syndication-offer-dialog',
  templateUrl: './manual-syndication-offer-dialog.component.html',
  styleUrls: ['./manual-syndication-offer-dialog.component.scss']
})
export class ManualSyndicationOfferDialogComponent implements OnInit {
  private applicationSubscription: Subscription;
  private fundingSubscription: Subscription;

  application: any
  funding: any

  form: ManualSyndicationOffers;
  tenantFinancialSettings: any = {};
  syndicator: any;
  searching = false;

  fieldUpdating: string;

  syndicators: Array<any> = [];
  syndicatorList = [];
  syndicatorSelection: Array<any>;
  loadingSyndicators: boolean;

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;


  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    public dialogRef: MatDialogRef<ManualSyndicationOfferDialogComponent>,
    private searchService: SearchService,
    private syndicatorService: SyndicatorService,
    private applicationService: ApplicationService,
    private fundingService: FundingService,
    private settingService: SettingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.form = new ManualSyndicationOffers();

    this.fundingSubscription = this.fundingService.funding.subscribe(funding=> this.funding = funding);

    this.applicationSubscription = this.applicationService.application.subscribe((application) => {
      this.application = application;
      this.getSyndicators();
      this.getSettings();
    });
  }

  ngOnDestroy() {
    this.applicationSubscription.unsubscribe();
    this.fundingSubscription.unsubscribe();
  }

  // searchSyndicators = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     tap(() => this.searching = true),
  //     switchMap(term =>
  //       this.searchService.performSyndicatorsTypeahead(term).pipe(
  //         catchError(() => {
  //           return of([]);
  //         }))
  //     ),
  //     tap(() => this.searching = false)
  //   );

  // formatter = (x: {name: string}) => x.name;

  populateSyndicators(event) {
    event.value.forEach(item => {
      const event = {item: item};
      this.populateSyndicator(event);
    });

    const tableArray = this.form.get('manualSyndicationOffers').value.map(item => item.syndicatorId);
    const selectArray = event.value.map(item => item.id);
    const result = tableArray.length > selectArray.length ? tableArray.filter(item => !selectArray.includes(item)) : '';
    const index = tableArray.indexOf(result[0])
    if (result.length) {
      this.removeSyndicator(index);
    }
  }

  populateSyndicator(event, input?) {
    const item = event.item;

    const manualSyndicationOffer = new ManualSyndicationOffer();
    manualSyndicationOffer.addControl('calculationLogic', new FormControl());
    manualSyndicationOffer.addControl('principalToBePaid', new FormControl({value: null, disabled: true}));

    manualSyndicationOffer.patchValue({
      syndicatorId: item.id,
      syndicatorName: item.name,
      commissionPercent: this.application.appliedFundingOffer.commissionPercent,
      calculationLogic: item.syndicator.defaultSyndicationCalculationLogic
    });

    manualSyndicationOffer.get('acceptedPercentSyndicated').setValidators([Validators.required, (control: AbstractControl): ValidationErrors => this.validateAcceptedPercentSyndicated(manualSyndicationOffer, control)]);

    this.getSyndictorSettings(manualSyndicationOffer, item.id);

    for (let i = 0; i < this.form.get('manualSyndicationOffers')['controls'].length; i++) {
      if (this.form.get('manualSyndicationOffers')['controls'][i].get('syndicatorId').value === item.id) {
        return false;
      }
    }

    manualSyndicationOffer.get('acceptedAmountSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'acceptedAmountSyndicated') {
        return;
      }

      this.fieldUpdating = 'acceptedPercentSyndicated';

      this.onAcceptedAmountSyndicatedChange(manualSyndicationOffer);

      this.fieldUpdating = null;
    });

    manualSyndicationOffer.get('acceptedPercentSyndicated').valueChanges.subscribe((value) => {
      if (this.fieldUpdating === 'acceptedPercentSyndicated') {
        return;
      }

      this.fieldUpdating = 'acceptedAmountSyndicated';

      this.onAcceptedPercentSyndicatedChange(manualSyndicationOffer);

      this.fieldUpdating = null;
    });

    (this.form.get('manualSyndicationOffers') as FormArray).push(manualSyndicationOffer);
  }

  onAcceptedPercentSyndicatedChange(row) {
    if (row.get('acceptedPercentSyndicated').value !== '') {
      let acceptedPercentSyndicated = parseFloat(row.get('acceptedPercentSyndicated').value);
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      row.get('acceptedAmountSyndicated').setValue(fundedAmount * (acceptedPercentSyndicated / 100));

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(fundedAmount * (acceptedPercentSyndicated / 100));
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  onAcceptedAmountSyndicatedChange(row) {
    if (row.get('acceptedAmountSyndicated').value !== '') {
      const fundedAmount = parseFloat(this.application.appliedFundingOffer.fundedAmount);
      const disbursementAmount = parseFloat(this.funding.disbursementAmount);
      const acceptedAmountSyndicated = parseFloat(row.get('acceptedAmountSyndicated').value);
      const acceptedPercentSyndicated = this.roundPercent((acceptedAmountSyndicated / fundedAmount) * 100);
      row.get('acceptedPercentSyndicated').setValue(acceptedPercentSyndicated);

      if(row.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        row.get('principalToBePaid').setValue(acceptedAmountSyndicated);
      } else {
        row.get('principalToBePaid').setValue(disbursementAmount * (acceptedPercentSyndicated / 100));
      }
      this.recalculate(row);
    }
  }

  recalculate(row) {
    const paybackAmount = parseFloat(this.application.appliedFundingOffer.paybackAmount);

    if (row.get('principalToBePaid').value !== '' && row.get('commissionPercent').value !== '') {
      const principalToBePaid = parseFloat(row.get('principalToBePaid').value);
      const commissionPercent = parseFloat(row.get('commissionPercent').value);
      const acceptedAmountSyndicated = parseFloat(row.get('acceptedAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (acceptedAmountSyndicated * commissionPercent / 100);
      row.get('maxTotalReceived').setValue(maxTotalReceived);

    }

    if (row.get('acceptedPercentSyndicated').value !== '') {
      let acceptedPercentSyndicated = parseFloat(row.get('acceptedPercentSyndicated').value);
      row.get('maxPayback').setValue(paybackAmount * (acceptedPercentSyndicated / 100));
    }
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  validateAcceptedPercentSyndicated(form: FormGroup, control: AbstractControl): ValidationErrors {
    if (!control.value || control.value === null) {
      return null;
    }

    const acceptedPercentSyndicated = parseFloat(control.value);

    if (isNaN(acceptedPercentSyndicated)) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (acceptedPercentSyndicated < 0) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooSmall: true
      };

      return result;
    }

    if (acceptedPercentSyndicated > 100) {
      const result = <ValidationErrors> {
        acceptedPercentSyndicatedTooLarge: true
      };

      return result;
    }

    return null;
  }

  getSettings() {
    this.settingService.getTenantFinancialSettings()
      .subscribe((r: any) => {
        this.tenantFinancialSettings = r;
      });
  }

  getSyndictorSettings(row, id) {
    this.syndicatorService.getSettings(id)
      .subscribe((r: any) => {
        row.patchValue({ managementFeePercent: r.defaultManagementFeePercent });
      });
  }

  removeSyndicator(i) {
    // remove syndicator from dropdown
    const syndicatorId = this.form.get('manualSyndicationOffers')['controls'][i].get('syndicatorId').value;
    const newSelection = this.syndicatorSelection.filter(item => item.syndicatorId !== syndicatorId);
    this.syndicatorSelection = newSelection;

    (this.form.get('manualSyndicationOffers') as FormArray).removeAt(i);
  }

  save() {
    const observables = [];
    for (let i = 0; i < this.form.get('manualSyndicationOffers')['controls'].length; i++) {
      let manualSyndicationOfferValue = this.form.get('manualSyndicationOffers')['controls'][i].value;
      if(manualSyndicationOfferValue && manualSyndicationOfferValue.syndicator && manualSyndicationOfferValue.syndicator.repaymentPlan ) {
        delete manualSyndicationOfferValue.syndicator.repaymentPlan;
      }
      observables.push(this.applicationService.createManualSyndicationOffer(this.application.id, manualSyndicationOfferValue));
    }

    forkJoin(observables).subscribe((r: any) => {
        this.dialogRef.close(true);
      });
  }

  getSyndicators() {
    this.loadingSyndicators = true;
    this.syndicatorService.list(this.data.tenantId, null, 300, 'name', 'asc')
      .subscribe((r: any) => {
        this.syndicators = r;
        r.content.forEach(item => {
          this.syndicatorList.push({id: item.id, name:item.name, syndicatorId: item.id, tenantId: this.data.tenantId, syndicator: item});
        });
        this.loadingSyndicators = false;
      });
  }

  onSelectedSyndicationCalculationLogic(event, manualSyndicationOffersControl) {
    manualSyndicationOffersControl.get('calculationLogic').setValue(event.value);

    manualSyndicationOffersControl.get('acceptedPercentSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    manualSyndicationOffersControl.get('acceptedAmountSyndicated').setValue(null, {emitEvent: false, onlySelf: true});
    manualSyndicationOffersControl.get('principalToBePaid').setValue(null, {emitEvent: false, onlySelf: true});

    manualSyndicationOffersControl.get('acceptedPercentSyndicated').setErrors({'incorrect': true} )
    manualSyndicationOffersControl.get('acceptedAmountSyndicated').setErrors({'incorrect': true})
  }
}
