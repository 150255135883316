<div class="row" [ngClass]="category">
  <div class="col-sm-12 notes">
    <div class="notes-wrapper" *ngIf="notes.length">
      <div *ngIf="notes.length > 2" class="d-table mx-auto link text-center" (click)="toggleNotesLimit()">
        View All Notes
      </div>

      <div class="note-content" *ngFor="let note of notes | slice:0:notesLimit; let i = index; let last = last">
        <label class="mb-2 d-block" *ngIf="note.createdBy">
          {{ note.createdBy.firstName + ' ' + note.createdBy.lastName }} <span>Note</span>

          <span class="float-end">{{ note.createdDate | date }}</span>
        </label>

        <div class="note-text">{{ note.content }}</div>

        <div class="link mt-2" *ngIf="!disabled && last && !addingNote" (click)="toggleAddNote()">
          <hr>
          Click to Add Note
        </div>
      </div>
    </div>

    <div class="notes-wrapper" *ngIf="!notes.length && !addingNote">
      <div class="link" *ngIf="!disabled && !addingNote" (click)="toggleAddNote()">
        Click to Add Note
      </div>
    </div>

    <div class="notes-wrapper new-note-wrapper" *ngIf="addingNote">
      <label *ngIf="addingNote">Add Note</label>

      <div class="d-flex new-note" *ngIf="addingNote">
        <textarea placeholder="Click to Add a Note" [(ngModel)]="newNote" [ngModelOptions]="{standalone: true}"></textarea>
        <div class="d-inline-block">
          <button mat-stroked-button class="btn-table" (click)="toggleAddNote()" oiq-loading [spinner]="false">CANCEL</button>
          <button mat-raised-button color="primary" class="btn-table" (click)="addNote()" oiq-loading>
            SUBMIT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
