import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'settings/';
  }

  getGeneralTenantSettings() {
    return this.http.get(this.getUrl() + `tenants/general`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveGeneralTenantSettings(data) {
    return this.http.put(this.getUrl() + `tenants/general`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getTenantFinancialSettings() {
    return this.http.get(this.getUrl() + `tenants/financial`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveTenantFinancialSettings(data) {
    return this.http.put(this.getUrl() + `tenants/financial`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getBorrowingBaseSettings() {
    return this.http.get(this.getUrl() + `tenants/borrowing-base`)
      .pipe(map(res => {
        return res;
      }));
  }

  saveBorrowingBaseSettings(data) {
    return this.http.put(this.getUrl() + `tenants/borrowing-base`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getFeeTypes() {
    return this.http.get(this.getUrl() + `fee-types`)
    .pipe(map(res => {
      return res;
    }));
  }
}
