import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SicService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'sic/';
  }

  search(term: string) {
    return this.http.get(this.getUrl() + `search?searchInput=${term}`)
      .pipe(map(res => {
        return res;
      }));
  }

  searchByCode(code: string) {
    return this.http.get(this.getUrl() + `code?code=${code}`)
    .pipe(map(res => {
      return res;
    }));
  }

  getDivisions() {
    return this.http.get(this.getUrl() + `?type=Sic_Division`)
      .pipe(map(res => {
        return res;
      }));
  }

  getMajorGroups(divisionCode: string) {
    return this.http.get(this.getUrl() + `division?division=${divisionCode}&type=Major_Group`)
      .pipe(map(res => {
        return res;
      }));
  }

  getIndustryGroups(majorGroup) {
    return this.http.get(this.getUrl() + `major-group?majorCode=${majorGroup}&type=Industry_Group`)
      .pipe(map(res => {
        return res;
      }));
  }

  getIndustry(industryGroup) {
    return this.http.get(this.getUrl() + `industry?industryCode=${industryGroup}&type=Industry`)
    .pipe(map(res => {
      return res;
    }));
  }

  getSicCrossReference(naicsCode: string) {
    return this.http.get(this.getUrl() + `cross-reference?naicsCode=${naicsCode}`)
    .pipe(map(res => {
      return res;
    }));
  }
}
