import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class LookupListService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'lookup-lists/';
  }

  listTypes(): Observable<any> {
    return this.http.get(this.getUrl() + `types`)
      .pipe(map(res => {
        return res;
      }));
  }

  listByType(type: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `type/${type}`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }
}
