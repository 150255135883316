import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DefaultPrepays } from '@core/models';

@Component({
  selector: 'oiq-prepayment-dialog',
  templateUrl: './prepayment-dialog.component.html',
  styleUrls: ['./prepayment-dialog.component.scss']
})
export class PrepaymentDialogComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PrepaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.form = new DefaultPrepays();

    if (this.data?.prepayment?.id) {
      this.form.patchValue(this.data.prepayment);

      if (this.data?.prepayments.length > 0) {
        const min = this.data.prepayments[this.data.index - 1] ? this.data.prepayments[this.data.index - 1].endDay + 1 : 1;
        this.form.controls['startDay'].addValidators([Validators.min(min), Validators.max(min)]);
        this.form.controls['endDay'].addValidators(Validators.min(min+1));
      }
      const fundedAmount: number = this.data.prepayment.paybackAmount / this.data.prepayment.factorRate;
      this.form.valueChanges.subscribe((value) => {
        this.data.prepayment.commissionPercent = (value.factorRate - value.buyRate) * 100;
        this.data.prepayment.paybackAmount = fundedAmount * value.factorRate;
        this.data.prepayment.factorRate = value.factorRate;
      });
    } else {
      const lastPrepaymentIndex = this.data?.prepayments.length - 1;
      const min = this.data?.prepayments[lastPrepaymentIndex] ? this.data.prepayments[lastPrepaymentIndex].endDay + 1 : 1;
      this.form.controls['startDay'].setValue(min);
      this.form.controls['startDay'].addValidators([Validators.min(min), Validators.max(min)]);
      this.form.controls['endDay'].addValidators(Validators.min(min + 1));
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

}
