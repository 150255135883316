import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class NaicsService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'naics/';
  }

  getSectors() {
    return this.http.get(this.getUrl() + `?type=Sector`)
      .pipe(map(res => {
        return res;
      }));
  }

  getSubSectors(sector: string) {
    return this.http.get(this.getUrl() + `division?sector=${sector}&type=Subsector`)
      .pipe(map(res => {
        return res;
      }));
  }

  getIndustryGroups(subsector) {
    return this.http.get(this.getUrl() + `subsector?subsector=${subsector}&type=Industry_Group`)
      .pipe(map(res => {
        return res;
      }));
  }

  getNaicsIndustry(industryGroup) {
    return this.http.get(this.getUrl() + `industry-group?industryGroup=${industryGroup}&type=Naics_Industry`)
    .pipe(map(res => {
      return res;
    }));
  }

  getNationalIndustry(naicsIndustry) {
    return this.http.get(this.getUrl() + `naics-industry?naicsIndustry=${naicsIndustry}&type=National_Industry`)
    .pipe(map(res => {
      return res;
    }));
  }

  getNaicsCrossReference(sicCode: string) {
    return this.http.get(this.getUrl() + `cross-reference?sicCode=${sicCode}`)
    .pipe(map(res => {
      return res;
    }));
  }

  search(term: string) {
    return this.http.get(this.getUrl() + `search?searchInput=${term}`)
      .pipe(map(res => {
        return res;
      }));
  }
  
}
