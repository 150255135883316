import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

import { Address } from './address';
import { Contact } from './contact';

export class Client extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      name: new FormControl(),
      type: new FormControl(),
      legalName: new FormControl(),
      dbaName: new FormControl(),
      ein: new FormControl(),
      email: new FormControl('', { validators: Validators.email, updateOn: 'blur' }),
      phoneNumber: new FormControl(),
      cellNumber: new FormControl(),
      faxNumber: new FormControl(),
      startDate: new FormControl(),
      entityType: new FormControl(),
      sicCode: new FormControl('', [Validators.pattern('^[0-9]+$')]),
      businessType: new FormControl(),
      incorporationState: new FormControl(),
      industryType: new FormControl(),
      locationType: new FormControl(),
      productsSold: new FormControl(),
      grossMonthlySales: new FormControl(),
      contactId: new FormControl(),
      isoId: new FormControl(),
      isoSalesRepresentativeId: new FormControl(),
      tradeReferences: new FormArray([]),
      addresses: new FormArray([new Address()]),
      lengthOfOwnership: new FormControl(),
      incorporationDate: new FormControl(),
      primaryWebsite: new Website(),
      voidedCheckName: new FormControl(),
      sicId: new FormControl(),
      externalSource: new FormControl()
      // otherWebsites: new FormArray([new Website()])
    });
  }
}

export class Website extends FormGroup {
  constructor() {
    super({
      url: new FormControl(),
      description: new FormControl()
    })
  }
}

export class TradeReference extends FormGroup {
  constructor() {
    super({
      name: new FormControl('', Validators.required),
      contact: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required)
    });
  }
}
