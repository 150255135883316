<mat-label>{{label}}</mat-label>
<mat-form-field appearance="outline">
  <mat-select  multiple [formControl]="selectFormControl" (selectionChange)="onSelectionChange($event)">
    <mat-option disabled>
        <input  class="search-input" (keyup)="filterOptions($event)" placeholder="Search..." />
    </mat-option>
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{option}}
    </mat-option>
  </mat-select>
</mat-form-field>