<div class="header-navigation">
  <div class="mobile-overlay" [ngClass]="{'show': showMenu}" (click)="toggleMenu()"></div>

  <div class="mobile-navigation">
    <a class="navbar-brand" [routerLink]="userRole === 'admin' ? ['/admin'] : ['/']"></a>
    <button mat-button (click)="this.showNotifications = !this.showNotifications" class="nav-notifications">
      <mat-icon [ngClass]="{'hide-number': upcomingEvents === 0}" matBadge="{{upcomingEvents}}" [matBadgeHidden]="hidden" matBadgeSize="small" matBadgeOverlap="true" matBadgeColor="accent" color="primary">notifications</mat-icon>
    </button>
    <button class="hamburger" (click)="toggleMenu()"></button>
  </div>

  <nav class="navbar" [ngClass]="{'show': showMenu}">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="navbar-brand" [routerLink]="userRole === 'admin' ? ['/admin'] : ['/']" (click)="closeMenu()"></a>
      </li>
      <li class="nav-item" *authorizeRole="['SUPER_ADMIN']">
        <a class="nav-link" [routerLink]="['/admin']" routerLinkActive="active" (click)="closeMenu()">
          Home
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['SUPER_ADMIN']">
        <a class="nav-link" routerLink="/admin/tenants" routerLinkActive="active" (click)="closeMenu()">
          Tenants
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['SUPER_ADMIN']">
        <a class="nav-link" routerLink="/admin/settings" routerLinkActive="active" (click)="closeMenu()">
          Settings
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'UNDERWRITING', 'OPERATIONS', 'ISO', 'COLLECTIONS', 'ACCOUNTING', 'ISO MANAGER']">
        <a class="nav-link" [routerLink]="['/']" routerLinkActive="active" (click)="closeMenu()">
          Home
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['SYNDICATOR']">
        <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active" (click)="closeMenu()">
          Home
        </a>
      </li>
      <li class="nav-item" *ngIf="userHasAccessToResources('borrowing-base')">
        <a class="nav-link" routerLink="/borrowing-base" routerLinkActive="active" (click)="closeMenu()">
          Borrowing Base
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'COLLECTIONS', 'UNDERWRITING', 'OPERATIONS']">
        <a class="nav-link" routerLink="/clients" routerLinkActive="active" (click)="closeMenu()">
          Clients
        </a>
      </li>
      <!-- <li class="nav-item" *ngIf="userRole !== 'admin'">
        <a class="nav-link" routerLink="/contacts" routerLinkActive="active">
          Contacts
        </a>
      </li> -->
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'UNDERWRITING', 'OPERATIONS', 'ACCOUNTING', 'ISO MANAGER']">
        <a class="nav-link" routerLink="/applications" routerLinkActive="active" (click)="closeMenu()">
          Applications
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL','UNDERWRITING', 'OPERATIONS', 'COLLECTIONS', 'ACCOUNTING', 'ISO MANAGER']">
        <a class="nav-link" routerLink="/fundings" routerLinkActive="active" (click)="closeMenu()">
          Fundings
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL']">
        <a class="nav-link" routerLink="/syndicators" routerLinkActive="active" (click)="closeMenu()">
          Syndicators
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'OPERATIONS']">
        <a class="nav-link" routerLink="/isos" routerLinkActive="active" (click)="closeMenu()">
          ISOs
        </a>
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL']">
        <a class="nav-link" routerLink="/law-firms" routerLinkActive="active" (click)="closeMenu()">
          Law Firms
        </a>
      </li>
      <!-- <li class="nav-item" *authorizeRole="['SYNDICATOR']">
        <a class="nav-link" routerLink="/syndicators/profile" routerLinkActive="active" (click)="closeMenu()">
          Profile
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" routerLink="/reports" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'ACCOUNTING', 'SYNDICATOR']" routerLinkActive="active" (click)="closeMenu()">
          Reports
        </a>

        <!-- <a class="nav-link" routerLink="/reports/history" *authorizeRole="['OPERATIONS', 'UNDERWRITING', 'SYNDICATOR', 'ISO']" routerLinkActive="active" (click)="closeMenu()">
          Reports
        </a> -->
      </li>
      <li class="nav-item" *authorizeRole="['ADMIN','ONYXIQ_ADMIN']">
        <a class="nav-link" routerLink="/settings" routerLinkActive="active" (click)="closeMenu()">
          Settings
        </a>
      </li>
      <li class="nav-item">
        <a [class.disabled]="loggedInAs" class="nav-link" (click)="!loggedInAs && logout(); false;">
          <mat-icon>exit_to_app</mat-icon>
          Logout
        </a>
      </li>
    </ul>

    <button mat-button (click)="this.showNotifications = !this.showNotifications" class="nav-notifications">
      <mat-icon [ngClass]="{'hide-number': upcomingEvents === 0}" matBadge="{{upcomingEvents}}" [matBadgeHidden]="hidden" matBadgeSize="small" matBadgeOverlap="true" matBadgeColor="accent" color="primary">notifications</mat-icon>
    </button>
    <oiq-search [mobileNavOpen]="showMenu" (closeMobileNav)="toggleMenu($event)" *authorizeRole="['ADMIN', 'ONYXIQ_ADMIN', 'FULL', 'UNDERWRITING', 'OPERATIONS', 'SYNDICATOR', 'ISO', 'COLLECTIONS', 'ACCOUNTING', 'ISO MANAGER']"></oiq-search>
  </nav>

  <oiq-calendar-events *ngIf="showNotifications" [(showNotifications)]="showNotifications"></oiq-calendar-events>
</div>
