import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { SYNDICATIONS_CALCULATION_LOGIC_OPTIONS } from '@core/models';
import { SyndicatorService } from '@core/services';
import { notEmptyFormArrayValidator } from '@shared/utils-custom-form-validators';

@Component({
  selector: 'oiq-add-syndication-offers',
  templateUrl: './add-syndication-offers.component.html',
  styleUrls: ['./add-syndication-offers.component.scss']
})
export class AddSyndicationOffersComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() disbursementAmount = 0;
  @Input() fundedAmount = 0;
  @Input() factorRate = 0;
  @Input() commissionPercent = 0;
  @Input() paybackAmount = 0;
  @Input() syndicationOffers = [];
  @Input() whiteLabelId: number;

  isEditMode = false;
  isLoadingSyndicators: boolean;
  syndicators: any;
  selectedSyndicators: any;
  syndicationOfferForms: FormGroup[] = [];

  syndicationCalculationLogicOptions = SYNDICATIONS_CALCULATION_LOGIC_OPTIONS;

  constructor(private syndicatorService: SyndicatorService) {}

  ngOnInit() {
    if(this.syndicationOffers && this.syndicationOffers.length){
      this.populateForEdit();
    } else {
      this.getSyndicators();
    }
  }

  getSyndicators() {
    this.isLoadingSyndicators = true;
    if (this.whiteLabelId) {
      this.syndicatorService.getAllSyndicatorsWithIdAndName(this.whiteLabelId).subscribe((r: any) => {
        this.syndicators = r;
        this.isLoadingSyndicators = false;
      });
    }
  }

  onSyndicatorSelected(event) {
    if (event && event.value) {
      this.mapSelectedSyndicatorsToSyndicationOfferForms(event.value);
    }
    this.updateParentForm();
  }

  onRemoveSyndicator(syndicationOfferForm: FormGroup) {
    this.selectedSyndicators = this.selectedSyndicators.filter(syndicator => syndicator.id !== syndicationOfferForm.get('syndicatorId').value);
    this.syndicationOfferForms = this.syndicationOfferForms.filter(form => form.get('syndicatorId').value !== syndicationOfferForm.get('syndicatorId').value);
    this.updateParentForm();
  }

  onMaximumPercentSyndicatedChanged(syndicationOfferForm) {
    if (syndicationOfferForm.get('maximumPercentSyndicated').value !== '') {
      const maximumPercentSyndicated = parseFloat(syndicationOfferForm.get('maximumPercentSyndicated').value);
      syndicationOfferForm.get('maximumAmountSyndicated').setValue(this.fundedAmount * (maximumPercentSyndicated / 100));

      if (syndicationOfferForm.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        syndicationOfferForm.get('principalToBePaid').setValue(this.fundedAmount * (maximumPercentSyndicated / 100));
      } else {
        syndicationOfferForm.get('principalToBePaid').setValue(this.disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.calculateMaxTotalReceived(syndicationOfferForm);
      this.calculateMaxPayback(syndicationOfferForm);
    }
  }

  onMaximumAmountSyndicatedChanged(syndicationOfferForm) {
    if (syndicationOfferForm.get('maximumAmountSyndicated').value !== '') {
      const maximumAmountSyndicated = parseFloat(syndicationOfferForm.get('maximumAmountSyndicated').value);
      const maximumPercentSyndicated = this.roundPercent((maximumAmountSyndicated / this.fundedAmount) * 100);
      syndicationOfferForm.get('maximumPercentSyndicated').setValue(maximumPercentSyndicated);

      if (syndicationOfferForm.get('calculationLogic').value === 'PRINCIPAL_SYNDICATED_AMOUNT') {
        syndicationOfferForm.get('principalToBePaid').setValue(maximumAmountSyndicated);
      } else {
        syndicationOfferForm.get('principalToBePaid').setValue(this.disbursementAmount * (maximumPercentSyndicated / 100));
      }
      this.calculateMaxTotalReceived(syndicationOfferForm);
      this.calculateMaxPayback(syndicationOfferForm);
    }
  }

  onCalculationLogicChanged(event, syndicationOfferForm: FormGroup) {
    syndicationOfferForm.get('principalToBePaid').reset();
    syndicationOfferForm.get('maximumAmountSyndicated').reset();
    syndicationOfferForm.get('minimumPercentSyndicated').reset();
    syndicationOfferForm.get('maximumPercentSyndicated').reset();
    syndicationOfferForm.get('maxTotalReceived').reset();
    syndicationOfferForm.get('maxPayback').reset();
  }

  calculateMaxTotalReceived(syndicationOfferForm) {
    if (syndicationOfferForm.get('principalToBePaid').value !== '') {
      const principalToBePaid = parseFloat(syndicationOfferForm.get('principalToBePaid').value);
      const maximumAmountSyndicated = parseFloat(syndicationOfferForm.get('maximumAmountSyndicated').value);
      const maxTotalReceived = principalToBePaid + (maximumAmountSyndicated * this.commissionPercent) / 100;
      syndicationOfferForm.get('maxTotalReceived').setValue(maxTotalReceived);
    }
  }

  calculateMaxPayback(syndicationOfferForm) {
    if (syndicationOfferForm.get('maximumPercentSyndicated').value !== '') {
      let maximumPercentSyndicated = parseFloat(syndicationOfferForm.get('maximumPercentSyndicated').value);
      syndicationOfferForm.get('maxPayback').setValue(this.paybackAmount * (maximumPercentSyndicated / 100));
    }
  }

  roundPercent(input) {
    return Math.round((input + Number.EPSILON) * 100) / 100;
  }

  mapSelectedSyndicatorsToSyndicationOfferForms(selectedSyndicators: []) {
    if (selectedSyndicators && selectedSyndicators.length) {
      this.syndicationOfferForms = selectedSyndicators.map((syndicator: any) =>
      this.syndicationOfferForms .find((form: FormGroup) => form.value.syndicatorId === syndicator.id)
          ? this.syndicationOfferForms .find((form: FormGroup) => form.value.syndicatorId === syndicator.id)
          : new FormGroup({
              syndicatorId: new FormControl(syndicator.id),
              syndicatorName: new FormControl(syndicator.name),
              maximumAmountSyndicated: new FormControl('', Validators.required),
              minimumPercentSyndicated: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
              maximumPercentSyndicated: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
              principalToBePaid: new FormControl({value: '', disabled: true}),
              managementFeePercent: new FormControl(syndicator.defaultManagementFeePercent ? syndicator.defaultManagementFeePercent : 0, Validators.required),
              maxTotalReceived: new FormControl('', Validators.required),
              maxPayback: new FormControl('', Validators.required),
              calculationLogic: new FormControl(syndicator.defaultSyndicationCalculationLogic),
              type: new FormControl('Automatic')
            })
      );
    } else {
      this.syndicationOfferForms = []
    }
  }

  updateParentForm() {
    this.parentForm.removeControl('syndicationOfferForms');
    this.parentForm.addControl('syndicationOfferForms', new FormArray(this.syndicationOfferForms, notEmptyFormArrayValidator()));
    this.parentForm.updateValueAndValidity();
  }

  populateForEdit(){
    this.isEditMode = true;
    this.syndicationOffers.forEach((syndicationOffer: any) => {
      const form = new FormGroup({
        id: new FormControl(syndicationOffer.id),
        syndicatorId: new FormControl(syndicationOffer.syndicator.id),
        syndicatorName: new FormControl(syndicationOffer.syndicator.name),
        maximumAmountSyndicated: new FormControl(syndicationOffer.maximumAmountSyndicated, Validators.required),
        minimumPercentSyndicated: new FormControl(syndicationOffer.minimumPercentSyndicated, [Validators.required, Validators.min(0), Validators.max(100)]),
        maximumPercentSyndicated: new FormControl(syndicationOffer.maximumPercentSyndicated, [Validators.required, Validators.min(0), Validators.max(100)]),
        principalToBePaid: new FormControl({value: '', disabled: true}),
        managementFeePercent: new FormControl(syndicationOffer.managementFeePercent, Validators.required),
        maxTotalReceived: new FormControl('', Validators.required),
        maxPayback: new FormControl('', Validators.required),
        calculationLogic: new FormControl(syndicationOffer.calculationLogic),
        type: new FormControl('Automatic')
      })
      this.onMaximumPercentSyndicatedChanged(form)
      this.syndicationOfferForms.push(form);
      this.updateParentForm();
    })
  }
}
