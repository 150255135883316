<div class="row">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between align-items-center">
      <h3>
        INDUSTRY DETAILS
      </h3>
      <button
        mat-raised-button
        color="blue"
        class="button-v2 shadow bg-primary-gradient text-white text-sm my-2 me-4"
        (click)="editValues()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.545 4.75L9.25 5.455L2.44 12.25H1.75V11.56L8.545 4.75ZM11.245 0.25C11.0575 0.25 10.8625 0.325 10.72 0.4675L9.3475 1.84L12.16 4.6525L13.5325 3.28C13.825 2.9875 13.825 2.5 13.5325 2.2225L11.7775 0.4675C11.6275 0.3175 11.44 0.25 11.245 0.25ZM8.545 2.6425L0.25 10.9375V13.75H3.0625L11.3575 5.455L8.545 2.6425Z" fill="white"/>
        </svg> Edit</button>
    </div>
  </div>
</div>

<div class="row mb-3">
  <div class="col-md-3">
    <mat-label class="ms-0">SIC Description</mat-label>
    <span class="field-value ps-0">{{ sicDescription ? sicDescription : (industryType ? industryType : 'Not Set') }}</span>
  </div>

  <div class="col-md-3">
    <mat-label class="ms-0">SIC code</mat-label>
    <span class="field-value ps-0">{{ sicCode ? sicCode : (sicLegacy ? sicLegacy : 'Not Set') }}</span>
  </div>

  <div class="col-md-3">
    <mat-label class="ms-0">NAICS Description</mat-label>
    <span class="field-value ps-0">{{ naicsDescription ? naicsDescription : 'Not Set' }}</span>
  </div>

  <div class="col-md-3">
    <mat-label class="ms-0">NAICS Code</mat-label>
    <span class="field-value ps-0">{{ naicsCode ? naicsCode : 'Not Set' }}</span>
  </div>
</div>

