
<span *ngIf="applicationId" (click)="!disabled ? (applicationStatus === currentStatus?.name ? toggleStatusChangeDropdown($event) : '') : ''" class="status-indicator" [ngClass]="[applicationStatus.toLowerCase(), (loading ? 'loading' : ''), (showStatusChangeDropdown ? 'expanded' : ''), (applicationStatus === currentStatus?.name ? 'expandable' : ''), (disabled ? 'disabled' : '') ]">
  {{ applicationStatus | status }}
  <i class="arrow-down"></i>
  <div class="status-change-dropdown">
    <div class="drop-in">
      <span *ngFor="let status of currentStatus?.statuses" (click)="updateStatus(status.intercept, status.event)" class="status-indicator" [ngClass]="status.name.toLowerCase()">{{ status.name | status }}</span>
    </div>
  </div>
</span>

<span *ngIf="fundingId" (click)="!disabled ? (fundingStatus === currentStatus?.name ? toggleStatusChangeDropdown($event) : '') : ''" class="status-indicator" [ngClass]="[fundingStatus.toLowerCase(), (loading ? 'loading' : ''), (showStatusChangeDropdown ? 'expanded' : ''), (fundingStatus === currentStatus?.name ? 'expandable' : ''), (disabled ? 'disabled' : '') ]">
  {{ fundingStatus | status }}
  <i class="arrow-down"></i>
  <div class="status-change-dropdown">
    <div class="drop-in">
      <span *ngFor="let status of currentStatus?.statuses" (click)="updateStatus(status.intercept, status.event)" class="status-indicator" [ngClass]="status.name.toLowerCase()">{{ status.name | status }}</span>
    </div>
  </div>
</span>