import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '@core/authentication/auth.service';
import { Router } from '@angular/router';
import { CountdownConfig, CountdownEvent, CountdownComponent } from 'ngx-countdown';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'oiq-stay-alive-dialog',
  templateUrl: './stay-alive-dialog.component.html',
  styleUrls: ['./stay-alive-dialog.component.scss']
})
export class StayAliveDialogComponent implements OnInit {

  config: CountdownConfig = {
    leftTime: 30,
    format: 's',
    prettyText: (text) => {
      return text
    },
  };

  @ViewChild('cd') private countdown: CountdownComponent;

  constructor(
    private authService: AuthService,
    private router: Router,
    public dialogRef: MatDialogRef<StayAliveDialogComponent>,
  ) { }

  ngOnInit() {
  }

  extendSession() {
    this.countdown.stop();
    this.dialogRef.close('extend');
  }

  logoutUser(e?: CountdownEvent) {
    if (!e || e.action === 'done') {
      this.dialogRef.close('logout');
    }
  }

}
