<div class="body body-padding">
  <label>Select contact to see the thread:</label>
  <mat-form-field appearance="outline" class="col-sm-4">
    <mat-select (selectionChange)="showSmsThread($event.value)" [(ngModel)]="selectedContactId">
      <mat-option 
        *ngFor="let contact of contacts"
        [value]="contact.id"
        [disabled]="!contact.cellNumber"
        [ngClass]="{ 'disabled': !contact.cellNumber }"
        [matTooltip]="!contact.cellNumber ? 'Only contacts with cell numbers can be sent SMS messages.' : null"
        [matTooltipClass]="'tooltip-red'"
      >
        {{ contact.firstName }} {{ contact.lastName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="messages-container">
    <div class="message-row" *ngFor="let message of messages">
      <div class="message" [ngClass]="message.direction === 'Incoming' ? 'incoming' : 'outgoing'">
        {{ message.body }}
        <span>{{ message.createdDate | date: 'MMM d, yyyy | h:mma' }}</span>
      </div>
    </div>
    
    <mat-form-field class="text-area" appearance="outline" *ngIf="threadId">
      <textarea matInput [(ngModel)]="newMessage"></textarea>
    </mat-form-field>
    <button mat-raised-button color="primary" class="btn-table" *ngIf="threadId" (click)="sendMessage(newMessage)" [disabled]="!newMessage">
      SEND
    </button>
  </div>

 
</div>