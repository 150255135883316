import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiKeyValueResponse } from '@core/models/shared';

@Component({
  selector: 'oiq-user-task-list',
  templateUrl: './user-task-list.component.html',
  styleUrls: ['./user-task-list.component.scss']
})
export class UserTaskListComponent implements OnInit {
  @Input() userTasks: ApiKeyValueResponse[]
  constructor(private readonly router: Router) { }

  ngOnInit() {
  }
  
  public viewApplication(applicationId: number): void {
    this.router.navigate(['/applications', applicationId]);
  }

}