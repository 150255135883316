import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(number: any, ...args: any[]): any {
    if (!number) {
      return '';
    }
    number = String(number);
    number = number.replace(/[^0-9]*/g, '');
    let formattedNumber = number;

    const c = (number[0] == '1') ? '1' : '';
    number = number[0] == '1' ? number.slice(1) : number;

    const area = number.substring(0, 3);
    const front = number.substring(3, 6);
    const end = number.substring(6, 10);

    if (front) {
      formattedNumber = (c + "(" + area + ") " + front);
    }
    if (end) {
      formattedNumber += ("-" + end);
    }
    return formattedNumber;
  }

}
