<div class="notifications-background" (click)="this.showNotificationsChange.emit(this.showNotifications = !this.showNotifications)"></div>
<div class="nav-notifications">
  <div class="notifications-dropdown">
    <header class="header">
      <div class="title">Notifications</div>
      <!-- <div class="action">(mark all as read)</div> -->
      <button mat-button (click)="this.showNotificationsChange.emit(this.showNotifications = !this.showNotifications)" class="close">
        <mat-icon>close</mat-icon>
      </button>
    </header>
    <div class="body bg-light">
      <mat-tab-group [backgroundColor]="background" mat-align-tabs="start" disablePagination="true" animationDuration="0ms" class="events-tab">
        <mat-tab label="Upcoming ({{upcomingEventsTotal}})">
          <ng-template matTabContent>
            <div class="list-items">
              <div class="item rounded border border-dark-50 bg-white shadow-sm text-white my-2 p-2" *ngFor="let row of upcomingEvents?.rows; let i = index;">
                <mat-icon inline color="primary">calendar_today</mat-icon>
                <div class="notification mx-2">
                  <div (click)="openEditCalendarEventDialog(row)"><span *ngIf="row.entityName">{{row.entityType}}</span> <span *ngIf="!row.entityName">Calendar</span> Event</div>
                  <div class="small text-dark">
                    <div (click)="openEditCalendarEventDialog(row)">{{row.title}}</div>
                    <a *ngIf="row.entityName && row.entityType && row.entityId" routerLink="/{{ row.entityType }}/{{ row.entityId }}/{{row.entityType == 'law-firms' || row.entityType == 'isos' ? 'general-info' : 'overview'}}" (click)="this.showNotificationsChange.emit(this.showNotifications = !this.showNotifications)">
                      {{ row.entityName }}
                    </a>
                  </div>
                </div>
                <span class="datetime small d-flex flex-column">
                  <div><strong>{{getFormatFromNow(row.eventDate)}}</strong></div>
                  <button class="delete-button ms-auto mt-1" mat-stroked-button oiq-loading (click)="delete(row)" [buttonDisabled]="this.isDeleting" [spinner]="true">Delete</button>
                </span>
                <span class="status" [ngClass]="{'new': !row.eventRead}"></span>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Past ({{pastEventsTotal}})">
          <ng-template matTabContent>
            <div class="list-items">
              <div class="item rounded border border-dark-50 bg-white shadow-sm text-white my-2 p-2" *ngFor="let row of pastEvents?.rows; let i = index;">
                <mat-icon inline color="primary">calendar_today</mat-icon>
                <div class="notification mx-2">
                  <div (click)="openEditCalendarEventDialog(row)"><span *ngIf="row.entityName">{{row.entityType}}</span> <span *ngIf="!row.entityName">Calendar</span> Event</div>
                  <div class="small text-dark">
                    <div (click)="openEditCalendarEventDialog(row)">{{row.title}}</div>
                    <a *ngIf="row.entityName && row.entityType && row.entityId" routerLink="/{{ row.entityType }}/{{ row.entityId }}/{{row.entityType == 'law-firms' || row.entityType == 'isos' ? 'general-info' : 'overview'}}" (click)="this.showNotificationsChange.emit(this.showNotifications = !this.showNotifications)">
                      {{ row.entityName }}
                    </a>
                  </div>
                </div>
                <span class="datetime small d-flex flex-column">
                  <div>{{ row.eventDate | date: 'mediumDate' }} {{ row.eventDate | date: 'shortTime' }}</div>
                  <button class="delete-button ms-auto mt-1" mat-stroked-button oiq-loading (click)="delete(row)" [buttonDisabled]="this.isDeleting" [spinner]="true">Delete</button>
                </span>
                <span class="status" [ngClass]="{'new': !row.eventRead}"></span>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Future ({{futureEventsTotal}})">
          <ng-template matTabContent>
            <div class="list-items">
              <div class="item rounded border border-dark-50 bg-white shadow-sm text-white my-2 p-2" *ngFor="let row of futureEvents?.rows; let i = index;">
                <mat-icon inline color="primary">calendar_today</mat-icon>
                <div class="notification mx-2">
                  <div (click)="openEditCalendarEventDialog(row)"><span *ngIf="row.entityName">{{row.entityType}}</span> <span *ngIf="!row.entityName">Calendar</span> Event</div>
                  <div class="small text-dark">
                    <div (click)="openEditCalendarEventDialog(row)">{{row.title}}</div>
                    <a *ngIf="row.entityName && row.entityType && row.entityId" routerLink="/{{ row.entityType }}/{{ row.entityId }}/{{row.entityType == 'law-firms' || row.entityType == 'isos' ? 'general-info' : 'overview'}}" (click)="this.showNotificationsChange.emit(this.showNotifications = !this.showNotifications)">
                      {{ row.entityName }}
                    </a>
                  </div>
                </div>
                <span class="datetime small d-flex flex-column">
                  <div>{{ row.eventDate | date: 'mediumDate' }} {{ row.eventDate | date: 'shortTime' }}</div>
                  <button class="delete-button ms-auto mt-1" mat-stroked-button oiq-loading (click)="delete(row)" [buttonDisabled]="this.isDeleting" [spinner]="true">Delete</button>
                </span>
                <span class="status" [ngClass]="{'new': !row.eventRead}"></span>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>

    <footer class="footer">
      <!-- <hr> -->
      <button mat-button (click)="openEditCalendarEventDialog([])">
        +Add new
      </button>
    </footer>
  </div>
</div>
