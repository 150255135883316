import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export abstract class AbstractService {

  abstract getUrl(): string;

  constructor(
    protected http: HttpClient
  ) { }

  list(tenantId?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = this.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (tenantId) { params['tenantId'] = tenantId.toString(); }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getById(id: number): Observable<any> {
    return this.http.get(this.getUrl() + id)
      .pipe(map(res => {
        return res;
      }));
  }

  update(id: number, data: any) {
    return this.http.put(this.getUrl() + id, data)
      .pipe(map(res => {
        return res;
      }));
  }

  patch(id: number, data: any) {
    return this.http.patch(this.getUrl() + id, data)
      .pipe(map(res => {
        return res;
      }));
  }

  create(data: any): Observable<any> {
    return this.http.post(this.getUrl(), data)
      .pipe(map(res => {
        return res;
      }));
  }

  createOrUpdate(data: any): Observable<any> {
    if (!data.id) {
      return this.create(data);
    } else {
      return this.update(data.id, data);
    }
  }

  delete(id: number): Observable<any> {
    return this.http.delete(this.getUrl() + id)
      .pipe(map(res => {
        return res;
      }));
  }

  preparePaginationParams(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params: any = {};
    if (offset) { params['page'] = offset.toString(); }
    if (limit) { params['size'] = limit.toString(); }
    if (sortBy) { params['sortBy'] = sortBy; }
    if (sortDir) { params['sortDir'] = sortDir; }

    return params;
  }

}
