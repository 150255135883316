<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Select Stipulation</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form>
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-6">
          <mat-label>Stipulations</mat-label>
          <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="selectedApplicationStipulation" [ngModelOptions]="{ standalone: true }">
              <mat-option *ngFor="let row of applicationStipulations" [value]="row">
                {{ row.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>Cancel</button>
      <button mat-raised-button mat-dialog-close cdkFocusInitial color="accent" type="submit"
              [disabled]="!selectedApplicationStipulation" (click)="selectApplicationStipulation()">
        Confirm
      </button>
    </div>
  </form>
</div>
