<div class="content-wrapper">
    <div class="heading">
      <h2>Deals Available for Syndication</h2>
    </div>

    <div class="body">
      <div class="overflow-x-scroll">
        <table mat-table [dataSource]="datasource" matSort matSortDirection="desc">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
            <td mat-cell *matCellDef="let row">{{ row.createdDate | date }}</td>
          </ng-container>
          <ng-container matColumnDef="merchant">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Merchant</th>
            <td mat-cell *matCellDef="let row">{{ row.clientName }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let row">{{ row.status | status }}</td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let row">{{ row.fundedAmount | currency }}</td>
          </ng-container>
          <ng-container matColumnDef="factorRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factor rate</th>
            <td mat-cell *matCellDef="let row">{{ row.factorRate }}%</td>
          </ng-container>
          <ng-container matColumnDef="range">
            <th mat-header-cell *matHeaderCellDef>% Range</th>
            <td mat-cell *matCellDef="let row">
              {{ row.type === 'Manual' ? 'Fixed at ' + row.acceptedPercentSyndicated + '%' : row.minimumPercentSyndicated + '%' + ' - ' + row.maximumPercentSyndicated + '%'}}
            </td>
          </ng-container>
          <ng-container matColumnDef="minAmount">
            <th mat-header-cell *matHeaderCellDef>Min Amount</th>
            <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.minimumPercentSyndicated) ? ((row.fundedAmount * row.minimumPercentSyndicated / 100) | currency): 'n/a' }}</td>
          </ng-container>
          <ng-container matColumnDef="maxAmount">
            <th mat-header-cell *matHeaderCellDef>Max Amount</th>
            <td mat-cell *matCellDef="let row">{{ !isNullOrUndefined(row.maximumPercentSyndicated) ? ((row.fundedAmount * row.maximumPercentSyndicated / 100) | currency) : 'n/a'}}</td>
          </ng-container>
          <ng-container matColumnDef="maximumPaybackAmount">
            <th mat-header-cell *matHeaderCellDef>Payback Amount</th>
            <td mat-cell *matCellDef="let row">{{ row.type === 'Manual' ? (row.acceptedAmountSyndicated  | currency )  : (row.maximumPaybackAmount  | currency ) }}</td>
          </ng-container>
          <ng-container matColumnDef="term">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Term Length</th>
            <td mat-cell *matCellDef="let row">{{ row.termLength }}</td>
          </ng-container>
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
            <td mat-cell *matCellDef="let row">{{ row.position }}</td>
          </ng-container>
          <ng-container  *ngIf="!isTenantView()" matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" >
              <button *ngIf="row.type === 'Automatic' && row.status !== 'Rejected' && row.status !== 'Accepted' && row.status !== 'Pending_Payment'" mat-stroked-button class="btn-table me-2" (click)="rejectOffer(row)">NOT INTERESTED</button>
              <button *ngIf="row.type === 'Automatic' && row.status !== 'Rejected' && row.status !== 'Accepted' && row.status !== 'Pending_Payment'" mat-raised-button color="primary" class="btn-table" (click)="openMakeOfferDialog(row)">
                MAKE AN OFFER
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns;"></tr>
        </table>
      </div>
      <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="datasource?.data?.length === 0"></oiq-loading-message>
      <mat-paginator [length]="total" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
    </div>
  </div>
