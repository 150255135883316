<div id="report" *ngIf="!loading">
  <div class="form-row-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <h3>CREDIT PROFILE REPORT</h3>
      </div>

      <div class="col-sm-12 mt-1">
        <div class="field-value">
          <form>
            <div class="row">
              <div class="col-sm-4">
                <mat-form-field appearance="outline">
                  <mat-select [(ngModel)]="creditCheckId" [ngModelOptions]="{ standalone: true }" (selectionChange)="getCreditCheck($event.value)">
                    <mat-option *ngFor="let row of creditChecks" [value]="row.id">
                      {{
                      row.firstName ?
                      (row.firstName + ' ' + row.lastName + ' - ' + row.status | status) :
                      (row.contact.firstName + ' ' + row.contact.lastName + ' - ' + row.status | status)
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="report" *ngIf="!loading && creditCheck?.status === 'Pending'">
  <p>Credit check is still processing</p>
</div>

<div id="report" *ngIf="!loading && creditCheck?.status === 'Failed'">
  <div>
    Unable to retrieve credit check:
    <ul *ngIf="creditCheck.creditCheckResponse && creditCheck.creditCheckResponse.errors?.length > 0">
      <li *ngFor="let row of creditCheck.creditCheckResponse.errors">
        {{ row.errorCode }} - {{ row.message }}
      </li>
    </ul>

    <ul *ngIf="creditCheck.creditCheckResponse && creditCheck.creditCheckResponse.creditProfile?.length > 0">
      <li *ngFor="let row of creditCheck.creditCheckResponse.creditProfile[0].informationalMessage">
        {{ row.messageText }}
      </li>
    </ul>

    <h5 *ngIf="creditCheck?.errorMessage">
      {{ creditCheck.errorMessage === 'state:size must be between 2 and 2' ? 'Please verify the state entry for typos; an incorrect state will prevent the credit check from processing.' : creditCheck.errorMessage }}
    </h5>
  </div>
  <ng-container *ngIf="creditCheck?.creditCheckRequest">
    <p>Original request:</p>

    <div class="form-row-wrapper">
      <div class="row">
        <div class="col-sm-12">
          <h3>First Name</h3>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="field-value">
            {{ creditCheck?.creditCheckRequest?.firstName }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3>Last Name</h3>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="field-value">
            {{ creditCheck?.creditCheckRequest?.lastName }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3>DOB</h3>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="field-value">
            {{ creditCheck?.creditCheckRequest?.dob }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <h3>Address</h3>
        </div>

        <div class="col-sm-12 mt-1">
          <div class="field-value">
            <div [innerHtml]="creditCheck?.creditCheckRequest | address" *ngIf="creditCheck.creditCheckRequest"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>


  <div class="row mt-5">
    <div class="col-sm-12">
      <button mat-raised-button color="accent" (click)="rerunCreditCheck()">
        RE-RUN CREDIT CHECK
      </button>
    </div>
  </div>
</div>

<div id="report" *ngIf="!loading && creditCheck?.status === 'Successful'">
  <div class="form-row-wrapper">
    <div class="row">
      <div class="col-sm-12">
        <h3>CREATED ON</h3>
      </div>

      <div class="col-sm-12 mt-1">
        <div class="field-value">
          {{ (creditProfile?.createdDate | date) || (creditCheck?.createdDate | date) }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper" *ngIf="creditProfile?.consumerIdentity?.name || creditProfile?.addressInformation || creditProfile?.employmentInformation?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>PERSONAL INFORMATION</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollable">
        <table class="bg">
          <tbody>
            <!-- *ngIf="creditProfile?.consumerIdentity?.name" -->
            <tr class="bg-onyx-light">
              <th width="35%">Best Name</th>
              <th width="35%">Other Name(s)</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>{{ (getPrimaryRecord(creditProfile?.consumerIdentity?.name) | fullName) || '&ndash;' }}</td>
              <td>{{ getOtherNames(creditProfile?.consumerIdentity?.name) || '&ndash;' }}
                <div class="info-text">*Does not match inquiry</div>
              </td>
              <td>&nbsp;</td>
            </tr>

            <!-- *ngIf="creditProfile?.ssn" -->
            <tr class="bg-onyx-light">
              <th>Best Social Security number</th>
              <th>Other Social Security number(s)</th>
              <th>Date of Birth</th>
            </tr>
            <tr>
              <td>{{ (getPrimaryRecord(creditProfile?.ssn).number | ssn) || '&nbsp;' }}</td>
              <td>{{ getOtherSsns(creditProfile?.ssn) || '&nbsp;' }}</td>
              <td>{{
                (creditProfile?.consumerIdentity?.dob?.formatted) ? (creditProfile.consumerIdentity.dob.formatted) :
                (creditProfile?.consumerIdentity?.dob?.year ? (creditProfile.consumerIdentity.dob.month + '/' + creditProfile.consumerIdentity.dob.day + '/' + creditProfile.consumerIdentity.dob.year | date) : '&ndash;') }}</td>
            </tr>

            <!-- *ngIf="creditProfile?.addressInformation" -->
            <!-- *ngIf="creditProfile?.consumerIdentity?.dob" -->
            <tr class="bg-onyx-light">
              <th>Best Address</th>
              <th>Other Address(es)</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>
                <div class="field-value">
                  <div>{{ getPrimaryAddress()?.streetPrefix }} {{ getPrimaryAddress()?.streeName }}
                    {{ getPrimaryAddress()?.streetSuffix }}</div>
                  <div>{{ getPrimaryAddress()?.city }}, {{ getPrimaryAddress()?.state }} {{ getPrimaryAddress()?.zipCode }}
                  </div>
                  <p class="info-text">
                    {{ getPrimaryAddress().dwellingTypeDescription }} <br>
                    Reported {{ getPrimaryAddress()?.timesReported }} time(s) from
                    {{ formatDate(getPrimaryAddress()?.firstReportedDate) }} to
                    {{ formatDate(getPrimaryAddress()?.lastUpdatedDate) }} <br>
                    Last subscriber {{ getPrimaryAddress()?.lastReportingSubscriberCode }} by Update
                  </p>
                </div>
              </td>
              <td>
                <div class="field-value" *ngFor="let row of getOtherAddresses()">
                  <div>{{ row.streetPrefix }} {{ row.streeName }} {{ row.streetSuffix }}</div>
                  <div>{{ row.city }}, {{ row.state }} {{ row.zipCode }}</div>
                  <p class="info-text">
                    {{ row.dwellingTypeDescription }} <br>
                    Reported {{ row.timesReported }} time(s) from {{ formatDate(row.firstReportedDate) }} to
                    {{ formatDate(row.lastUpdatedDate) }} <br>
                    Last subscriber {{ row.lastReportingSubscriberCode }} by Update
                  </p>
                </div>
              </td>
              <td>&nbsp;</td>
            </tr>

            <!-- *ngIf="creditProfile?.employmentInformation?.length > 0" -->
            <tr class="bg-onyx-light">
              <th>Best Employer</th>
              <th>Other Employer</th>
              <th>&nbsp;</th>
            </tr>
            <tr>
              <td>
                <div class="field-value">{{ getPrimaryEmployer()?.name || '&ndash;' }}</div>
                <div class="info-text">Reported {{ formatDate(getPrimaryEmployer()?.lastUpdatedDate) }} by Update</div>
              </td>
              <td>
                <ng-container *ngFor="let row of getOtherEmployers()">
                  <div class="field-value">{{ row.name }}</div>
                  <div class="info-text">Reported {{ formatDate(row.lastUpdatedDate) }} by Update</div>
                </ng-container>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <ng-container *ngIf="creditProfile?.summaries && !creditProfile.customData.summaries">
    <div class="form-row-wrapper" *ngFor="let summary of creditProfile.summaries">
      <div class="row mb-0">
        <div class="col-sm-12 bg-table">
          <h3>{{ summary.summaryType | uppercase }}</h3>
          <!-- <div class="bg-onyx-light w-100 pb-1">&nbsp;</div> -->
        </div>
      </div>

      <ng-container *ngIf="summary.summaryType === 'Profile Summary'">
        <div class="row">
          <div class="col-sm-12">
            <table class="">
              <thead>
                <tr class="bg-onyx-light w-100 pb-1 text-right">
                  <th width="50%" class="field-value bolded">Disputed Accounts&nbsp;&nbsp;<strong>0</strong></th>
                  <th width="21.5%" class="field-value bolded">Delinquency Counter</th>
                  <th>30</th>
                  <th>60</th>
                  <th>90+</th>
                  <th>Derog</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-right">
                  <td></td>
                  <td></td>
                  <td class="field-value bolded">{{profileSummary.delinquencies30Days | number}}</td>
                  <td class="field-value bolded">{{profileSummary.delinquencies60Days | number}}</td>
                  <td class="field-value bolded">{{profileSummary.delinquencies90to180Days | number}}</td>
                  <td class="field-value bolded">{{profileSummary.derogCounter | number}}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div> <!-- derog table -->

        <div class="row bg-light m-0">
          <ng-container *ngFor="let item of profileSummaryList">
            <div class="col-sm-6 col-md-3 d-flex justify-content-between align-items-center table-cr">
              <div class="field-value bolded ms-2">{{ item | camelToLabel }}</div>
              <div class="field-value bolded me-2">
                <ng-container *ngIf="fieldBelongsToType(currenciesArray, item);else counts">
                  <div *ngIf="profileSummary[item]; else noValueTemplate" class="field-value me-2">
                    <span>{{ profileSummary[item] !== 'N/A' ? (profileSummary[item] | currency) : profileSummary[item] }}</span>
                  </div>
                </ng-container>
                <ng-template #counts>
                  <ng-container *ngIf="fieldBelongsToType(countsArray, item);else percent">
                    <div *ngIf="profileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ profileSummary[item] !== 'N/A' ? (profileSummary[item] | number) : profileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #percent>
                  <ng-container *ngIf="fieldBelongsToType(percentsArray, item);else date">
                    <div *ngIf="profileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ profileSummary[item] !== 'N/A' ? (profileSummary[item] | number: '1.2-2') + '%' : profileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #date>
                  <ng-container *ngIf="fieldBelongsToType(datesArray, item);else noType">
                    <div *ngIf="profileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ profileSummary[item] !== 'N/A' ? (formatDate(profileSummary[item]) | date) : profileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #noType>
                  <div *ngIf="profileSummary[item]; else noValueTemplate" class="field-value me-2">
                    <span>{{ profileSummary[item] }}</span>
                  </div>
                </ng-template>
                <ng-template #noValueTemplate>
                  <div class="field-value me-2">N/A</div>
                </ng-template>
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let item of extraProfileSummaryList">
            <div class="col-sm-6 col-md-3 d-flex justify-content-between align-items-center table-cr">
              <div class="field-value bolded ms-2">{{ item | camelToLabel }}</div>
              <div class="field-value bolded me-2">
                <ng-container *ngIf="fieldBelongsToType(currenciesArray, item);else counts">
                  <div *ngIf="item.value; else noValueTemplate" class="field-value me-2">
                    <span>{{ extraProfileSummary[item] !== 'N/A' ? (extraProfileSummary[item] | currency) : extraProfileSummary[item] }}</span>
                  </div>
                </ng-container>
                <ng-template #counts>
                  <ng-container *ngIf="fieldBelongsToType(countsArray, item);else percent">
                    <div *ngIf="extraProfileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ extraProfileSummary[item] !== 'N/A' ? (extraProfileSummary[item] | number) : extraProfileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #percent>
                  <ng-container *ngIf="fieldBelongsToType(percentsArray, item);else date">
                    <div *ngIf="extraProfileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ extraProfileSummary[item] !== 'N/A' ? (extraProfileSummary[item] | number: '1.2-2') + '%' : extraProfileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #date>
                  <ng-container *ngIf="fieldBelongsToType(datesArray, item);else noType">
                    <div *ngIf="extraProfileSummary[item]; else noValueTemplate" class="field-value me-2">
                      <span>{{ extraProfileSummary[item] !== 'N/A' ? (formatDate(extraProfileSummary[item]) | date) : extraProfileSummary[item] }}</span>
                    </div>
                  </ng-container>
                </ng-template>
                <ng-template #noType>
                  <div *ngIf="extraProfileSummary[item]; else noValueTemplate" class="field-value me-2">
                    <span>{{ extraProfileSummary[item] }}</span>
                  </div>
                </ng-template>
                <ng-template #noValueTemplate>
                  <div class="field-value me-2">N/A</div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="summary.summaryType !== 'Profile Summary'">
        <div class="row my-2">
          <ng-container *ngFor="let attribute of summary.attributes">
            <div class="col-sm-6 col-md-3 d-flex justify-content-between align-items-center table-cr">
              <div class="field-value bolded ms-2">{{ attribute.id | camelToLabel }}</div>
              <div class="field-value me-2">
                <span>{{ attribute.value || 'N/A' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

    <div class="form-row-wrapper" *ngIf="creditProfile.customData?.summaries">
      <ng-container *ngIf="creditProfile.customData?.summaries?.derogatorySummary">
        <div class="row">
          <div class="col-sm-12 bg-table">
            <h3>DEROGATORY SUMMARY</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="summary-table">
              <thead>
                <tr class="bg-onyx-light w-100 pb-1">
                  <th>Disputed Accounts</th>
                  <th>Charge offs</th>
                  <th>Collections</th>
                  <th>Bankruptcy</th>
                  <th>Public records</th>
                  <th>Most recent late</th>
                  <th>Now delinquent derog</th>
                  <th>Was delinquent derog</th>
                  <th>Inquiries total</th>
                  <th>Inquiries last 6 months</th>
                  <th>Other derog</th>
                  <th>30</th>
                  <th>60</th>
                  <th>90+</th>
                  <th>Derog</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.disputesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.chargeOffsCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.collectionsCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.bankruptciesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.publicRecordsCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.mostRecentLateDate | date: 'mediumDate'}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.nowDelinquentDerog | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.wasDelinquentDerog | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.inquiriesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.inquiriesDuringLast6Months | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.otherCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary._30DayLates | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary._60DayLates | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary._90DayLates | number}}</td>
                  <td>{{creditProfile.customData.summaries.derogatorySummary.derogCounter | number}}</td>
                </tr>
  
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="creditProfile.customData?.summaries?.tradeSummary">
        <div class="row mt-5">
          <div class="col-sm-12 bg-table">
            <h3>TRADE SUMMARY</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <table class="summary-table">
              <thead>
                <tr class="bg-onyx-light w-100 pb-1">
                  <th>Secured debt</th>
                  <th>Unsecured debt</th>
                  <th>Oldest tradeline</th>
                  <th>Paid accounts</th>
                  <th>Satisfactory accounts</th>
                  <th>Revolving credit utilization</th>
                  <th>Total debt/high credit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{creditProfile.customData.summaries.tradeSummary.securedDebtTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.unsecuredDebtTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.oldestTradeline | date: 'mediumDate'}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.paidAccounts | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.satisfactoryAccounts | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingCreditUtilization}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.totalDebtHighCreditRatio}}</td>
                </tr>
  
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="row">
          <div class="col-sm-12">
            <table class="tradelines summary-table" *ngIf="creditProfile.customData?.summaries?.tradeSummary">
              <thead>
                <tr class="bg-onyx-light w-100 pb-1">
                  <th class="empty-heading highlight-column">&nbsp;</th>
                  <th>#</th>
                  <th>Balance</th>
                  <th>High credit</th>
                  <th>Payments</th>
                  <th>Past due</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Mortgage</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.mortgageTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.mortgageBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.mortgageHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.mortgagePaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.mortgagePastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Auto</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.autoTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.autoBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.autoHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.autoPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.autoPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Education</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.educationTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.educationBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.educationHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.educationPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.educationPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Other Installment</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherInstallmentTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherInstallmentBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherInstallmentHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherInstallmentPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherInstallmentPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Revolving</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.revolvingPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Open</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.openTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.openBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.openHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.openPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.openPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end">Other</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherTradesCount | number}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherBalanceTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherHighCreditTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherPaymentsTotal | currency}}</td>
                  <td>{{creditProfile.customData.summaries.tradeSummary.otherPastDueTotal | currency}}</td>
                </tr>
                <tr>
                  <td class="bg-onyx-light highlight-column text-end"><strong>Totals</strong></td>
                  <td><strong>{{creditProfile.customData.summaries.tradeSummary.tradesCount | number}}</strong></td>
                  <td><strong>{{creditProfile.customData.summaries.tradeSummary.balanceTotal | currency}}</strong></td>
                  <td><strong>{{creditProfile.customData.summaries.tradeSummary.highCreditTotal | currency}}</strong></td>
                  <td><strong>{{creditProfile.customData.summaries.tradeSummary.paymentsTotal | currency}}</strong></td>
                  <td><strong>{{creditProfile.customData.summaries.tradeSummary.pastDueTotal | currency}}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>

  <div class="form-row-wrapper" *ngIf="creditProfile?.informationalMessage?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>MESSAGES</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollale">
        <table mat-table [dataSource]="messagesDatasource">
          <ng-container matColumnDef="type">
            <th width="35%" mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">{{ row.type }}</td>
          </ng-container>
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let row">{{ row.message }}</td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="messagesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: messagesColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="messagesDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper" *ngIf="creditProfile?.statement?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>STATEMENTS</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollale">
        <table mat-table [dataSource]="statementsDatasource">
          <ng-container matColumnDef="dateReported">
            <th width="35%" mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">{{ row.dateReported }}</td>
          </ng-container>
          <ng-container matColumnDef="statementText">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let row">{{ row.statementText }}</td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="statementsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: statementsColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="statementsDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper" *ngIf="publicRecordsDatasource?.data?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>PUBLIC RECORDS</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollale">
        <table mat-table [dataSource]="publicRecordsDatasource">
          <ng-container matColumnDef="courtName">
            <th mat-header-cell *matHeaderCellDef>Court Name</th>
            <td mat-cell *matCellDef="let row">{{ row.courtName }}</td>
          </ng-container>
          <ng-container matColumnDef="courtCode">
            <th mat-header-cell *matHeaderCellDef>Court Code</th>
            <td mat-cell *matCellDef="let row">{{ row.courtCode }}</td>
          </ng-container>
          <ng-container matColumnDef="ecoa">
            <th mat-header-cell *matHeaderCellDef>ECOA</th>
            <td mat-cell *matCellDef="let row">{{ row.ecoa }}</td>
          </ng-container>
          <ng-container matColumnDef="evaluation">
            <th mat-header-cell *matHeaderCellDef>Evaluation</th>
            <td mat-cell *matCellDef="let row">{{ row.evaluation }}</td>
          </ng-container>
          <ng-container matColumnDef="referenceNumber">
            <th mat-header-cell *matHeaderCellDef>Reference Number</th>
            <td mat-cell *matCellDef="let row">{{ row.referenceNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="filingDate">
            <th mat-header-cell *matHeaderCellDef>Filing Date</th>
            <td mat-cell *matCellDef="let row">{{ row.filingDate | experianDate }}</td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let row">{{ row.status }}</td>
          </ng-container>
          <ng-container matColumnDef="statusDate">
            <th mat-header-cell *matHeaderCellDef>Status Date</th>
            <td mat-cell *matCellDef="let row">{{ row.statusDate | experianDate }}</td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="publicRecordsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: publicRecordsColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="publicRecordsDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper" *ngIf="creditProfile?.fraudShield?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>FRAUD SHIELD</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollale">
        <table mat-table [dataSource]="creditProfile.fraudShield">
          <ng-container matColumnDef="addressCount">
            <th mat-header-cell *matHeaderCellDef>Address Count</th>
            <td mat-cell *matCellDef="let row">{{ row.addressCount | number }}</td>
          </ng-container>
          <ng-container matColumnDef="addressDate">
            <th mat-header-cell *matHeaderCellDef>Address Date</th>
            <td mat-cell *matCellDef="let row">{{ row.addressDate | date }}</td>
          </ng-container>
          <ng-container matColumnDef="addressErrorCode">
            <th mat-header-cell *matHeaderCellDef>Address Error Code</th>
            <td mat-cell *matCellDef="let row">{{ row.addressErrorCode }}</td>
          </ng-container>
          <ng-container matColumnDef="socialCount">
            <th mat-header-cell *matHeaderCellDef>Social Count</th>
            <td mat-cell *matCellDef="let row">{{ row.socialCount | number }}</td>
          </ng-container>
          <ng-container matColumnDef="socialDate">
            <th mat-header-cell *matHeaderCellDef>Social Date</th>
            <td mat-cell *matCellDef="let row">{{ row.socialDate | date }}</td>
          </ng-container>
          <ng-container matColumnDef="socialErrorCode">
            <th mat-header-cell *matHeaderCellDef>Social Error Code</th>
            <td mat-cell *matCellDef="let row">{{ row.socialErrorCode }}</td>
          </ng-container>
          <ng-container matColumnDef="ssnFirstPossibleIssuanceYear">
            <th mat-header-cell *matHeaderCellDef>SSN First Possible Issuence Year</th>
            <td mat-cell *matCellDef="let row">{{ row.ssnFirstPossibleIssuanceYear }}</td>
          </ng-container>
          <ng-container matColumnDef="ssnLastPossibleIssuanceYear">
            <th mat-header-cell *matHeaderCellDef>SSN Last Possible Issuence Year</th>
            <td mat-cell *matCellDef="let row">{{ row.ssnLastPossibleIssuanceYear }}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">{{ row.type }}</td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="fraudShieldColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: fraudShieldColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="publicRecordsDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <div class="form-row-wrapper" *ngIf="creditProfile?.riskModel?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>SCORE SUMMARY</h3>
      </div>

      <div class="col-sm-12 mt-1 scrollale" id="score-summary-table">
        <table mat-table [dataSource]="scoreSummaryDatasource">
          <ng-container matColumnDef="riskModel">
            <th width="30%" mat-header-cell *matHeaderCellDef>Risk Model</th>
            <td mat-cell *matCellDef="let row">
              <div>{{ row.modelIndicatorDescription || mapToDescription('riskModelType', row.modelIndicator) }}</div>
              <div class="info-text">{{ row.riskModel.range }}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="score">
            <th width="5%" mat-header-cell *matHeaderCellDef>Score</th>
            <td mat-cell *matCellDef="let row">{{ row.score | number }}</td>
          </ng-container>
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Code</th>
            <td mat-cell *matCellDef="let row">
              <div *ngFor="let c of row.scoreFactors">{{ c.code }}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="scoreFactorDescription">
            <th mat-header-cell *matHeaderCellDef>Score Factor Description<small><strong><i>(sorted by
                    importance)</i></strong></small></th>
            <td mat-cell *matCellDef="let row">
              <div *ngFor="let c of row.scoreFactors">
                {{ c.codeDescription || mapToDescription(row.modelIndicator, c.code) | titlecase }}</div>
            </td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="scoreSummaryColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: scoreSummaryColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="scoreSummaryDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <div *ngIf="creditProfile?.tradeline?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>CREDITORS</h3>
      </div>
    </div>

    <div class="row" *ngIf="creditProfile?.mortgageTradelines?.length > 0">
      <div class="col-sm-12 mt-2">
        <div class="field-value">Mortgage Accounts</div>
      </div>
    </div>

    <oiq-tradeline [row]="row" account="Mortgage" *ngFor="let row of creditProfile?.mortgageTradelines"></oiq-tradeline>

    <div class="row" *ngIf="creditProfile?.installmentTradelines?.length > 0">
      <div class="col-sm-12 mt-2">
        <div class="field-value">Installment Accounts</div>
      </div>
    </div>

    <oiq-tradeline [row]="row" account="Installment" *ngFor="let row of creditProfile?.installmentTradelines"></oiq-tradeline>

    <div class="row" *ngIf="creditProfile?.revolvingTradelines?.length > 0">
      <div class="col-sm-12 mt-2">
        <div class="field-value">Revolving Accounts</div>
      </div>
    </div>

    <oiq-tradeline [row]="row" account="Revolving" *ngFor="let row of creditProfile?.revolvingTradelines"></oiq-tradeline>
  </div>
  <div class="form-row-wrapper" *ngIf="creditProfile?.inquiry?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3>INQUIRIES</h3>
      </div>

      <div class="col-sm-12 mt-1">
        <table mat-table [dataSource]="inquiriesDatasource">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row">{{ row.date | experianDate }}</td>
          </ng-container>
          <ng-container matColumnDef="subscriber">
            <th mat-header-cell *matHeaderCellDef>Subscriber</th>
            <td mat-cell *matCellDef="let row">
              {{ row.subscriberName }}
              <span class="info-text">/ {{ row.subscriberCode }} / {{ row.kobDescription }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let row">{{ row.amount }}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let row">{{ row.typeDescription }}</td>
          </ng-container>
          <ng-container matColumnDef="terms">
            <th mat-header-cell *matHeaderCellDef>Terms</th>
            <td mat-cell *matCellDef="let row">{{ row.terms }}</td>
          </ng-container>
          <tr class="bg-onyx-light" mat-header-row *matHeaderRowDef="inquiriesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: inquiriesColumns;"></tr>
        </table>
        <oiq-loading-message [isDataTable]="true" [showNoRecordsMessage]="inquiriesDatasource?.data?.length === 0">
        </oiq-loading-message>
      </div>
    </div>
  </div>

  <button class="scroll-top" color="primary" (click)="scrollToTop()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    <span>Scroll to top</span>
  </button>
</div>
