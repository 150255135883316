<div class="content">
  <div class="content-wrapper">
    <div class="clearfix heading">
      <h2>{{ type !== 'all' ? name : 'General' }} Report</h2>
    </div>
    <div class="body body-padding" *ngIf="editMode">
      <form [formGroup]="form">
        <div class="form-row-wrapper">
          <div class="row">
            <div class="col-sm-6">
              <mat-label>Report Name</mat-label>
              <mat-form-field appearance="outline">
                <input type="text" matInput formControlName="name" required>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf="type === 'Borrowing_Base_Payments'" class="row">
            <div class="col-sm-6">
              <div>
                <mat-radio-group (change)="reValidate()" [(ngModel)]="reportDates" name="reportDates" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button class="me-3" color="primary" [value]="'fixed'" >
                    Fixed Dates
                  </mat-radio-button>
                  <mat-radio-button color="primary" [value]="'range'">
                    Date Range
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <div *ngIf="reportDates === 'fixed'">
                <div class="row" *ngIf="type !== 'monthly-funding' && type !== 'syndication' && type !== 'users-report'">
                  <div class="col-sm-12">
                    <mat-label>Start Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="startDate" formControlName="startDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="startDate"></mat-datepicker-toggle>
                      <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type !== 'monthly-funding' && type !== 'syndication' && type !== 'users-report'">
                  <div class="col-sm-12">
                    <mat-label>End Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="endDate" formControlName="endDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="endDate"></mat-datepicker-toggle>
                      <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type === 'monthly-funding' || type === 'syndication'">
                  <div class="col-sm-12">
                    <mat-label>Funding Start Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="fundingStartDate" formControlName="fundingStartDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="fundingStartDate"></mat-datepicker-toggle>
                      <mat-datepicker #fundingStartDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type === 'monthly-funding' || type === 'syndication'">
                  <div class="col-sm-12">
                    <mat-label>Funding End Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="fundingEndDate" formControlName="fundingEndDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="fundingEndDate"></mat-datepicker-toggle>
                      <mat-datepicker #fundingEndDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type === 'monthly-funding' || type === 'syndication'">
                  <div class="col-sm-12">
                    <mat-label>Payment Start Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="paymentStartDate" formControlName="paymentStartDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="paymentStartDate"></mat-datepicker-toggle>
                      <mat-datepicker #paymentStartDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type === 'monthly-funding' || type === 'syndication'">
                  <div class="col-sm-12">
                    <mat-label>Payment End Date</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput [matDatepicker]="paymentEndDate" formControlName="paymentEndDate" oiqValidateDate>
                      <mat-datepicker-toggle matPrefix [for]="paymentEndDate"></mat-datepicker-toggle>
                      <mat-datepicker #paymentEndDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div *ngIf="reportDates === 'range'">

                <div class="row" *ngIf="type !== 'monthly-funding' && type !== 'syndication'">

                  <div class="col-sm-6">
                    <mat-label>Run For Previous</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="range" type="number">
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-label>&nbsp;</mat-label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="rangeUnits">
                        <mat-option *ngFor="let unit of rangeUnits" [value]="unit.value">
                          {{ unit.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row" *ngIf="type === 'monthly-funding' || type === 'syndication'">

                  <div class="col-sm-6">
                    <mat-label>Funded in Past</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="fundingRange" type="number">
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-label>&nbsp;</mat-label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="fundingRangeUnits">
                        <mat-option *ngFor="let unit of rangeUnits" [value]="unit.value">
                          {{ unit.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-label>Payment in Past</mat-label>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="paymentRange" type="number">
                    </mat-form-field>
                  </div>

                  <div class="col-sm-6">
                    <mat-label>&nbsp;</mat-label>
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="paymentRangeUnits">
                        <mat-option *ngFor="let unit of rangeUnits" [value]="unit.value">
                          {{ unit.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-sm-12">
                  <mat-label>White Label</mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select formControlName="whiteLabelIds" (selectionChange)="onWhiteLabelSelected($event.value)" multiple>
                      <mat-option *ngFor="let row of whiteLabels" [value]="row.id">
                        {{ row.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>Report Recipients</mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="row">
                        <div class="col-sm-12">
                          <mat-label>{{ tenant.name }} User Recipients</mat-label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="tenantUserIdRecipients" multiple>
                              <mat-option *ngFor="let user of tenantUsers" [value]="user.id">
                                {{ user.firstName }} {{ user.lastName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <mat-label>Select ISO</mat-label>
                          <mat-form-field appearance="outline" class="select-typeahead">
                            <span class="btn btn-primary btn-sm selected" *ngFor="let iso of selectedIsos">
                              {{iso}}<span class="close-selected" (click)="removeIso(iso)">&nbsp;x</span>
                            </span>
                            <input
                                matInput
                                type="text"
                                autofocus
                                placeholder="Start typing to search for ISO"
                                [ngbTypeahead]="searchIsos"
                                (focus)="focus$.next($event.target.value)"
                                (click)="click$.next($event.target.value)"
                                (selectItem)="onSelected($event, 'iso')"
                                [inputFormatter]="formatter"
                                [resultFormatter]="formatter"
                                #isoInstance="ngbTypeahead"/>
                            <mat-icon matSuffix>arrow_drop_down</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row ms-3" *ngIf="isoUsers.length">
                        <div class="col-sm-12">
                          <mat-label>ISO User Recipients</mat-label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="isoUserIdRecipients" multiple>
                              <mat-option *ngFor="let user of isoUsers" [value]="user.id">
                                {{ user.isoName }} - {{ user.firstName }} {{ user.lastName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <mat-label>Select Syndicator</mat-label>
                          <mat-form-field appearance="outline" class="select-typeahead">
                            <span class="btn btn-primary btn-sm selected" *ngFor="let syndicator of selectedSyndicators">
                              {{syndicator}}<span class="close-selected" (click)="removeSyndicator(syndicator)">&nbsp;x</span>
                            </span>
                            <input
                                matInput
                                type="text"
                                autofocus
                                placeholder="Start typing to search for Syndicator"
                                [ngbTypeahead]="searchSyndicators"
                                (focus)="focus$.next($event.target.value)"
                                (click)="click$.next($event.target.value)"
                                (selectItem)="onSelected($event, 'syndicator')"
                                [inputFormatter]="formatter"
                                [resultFormatter]="formatter"
                                #syndicatorInstance="ngbTypeahead"/>
                            <mat-icon matSuffix>arrow_drop_down</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row ms-3" *ngIf="syndicatorUsers.length">
                        <div class="col-sm-12">
                          <mat-label>Syndicator User Recipients</mat-label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="syndicatorUserIdRecipients" multiple>
                              <mat-option *ngFor="let user of syndicatorUsers" [value]="user.id">
                                {{ user.syndicatorName }} - {{ user.firstName }} {{ user.lastName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <mat-label>Select Law Firm</mat-label>
                          <mat-form-field appearance="outline" class="select-typeahead">
                            <span class="btn btn-primary btn-sm selected" *ngFor="let lawFirm of selectedLawFirms">
                              {{ lawFirm }}<span class="close-selected" (click)="removeLawFirm(lawFirm)">&nbsp;x</span>
                            </span>
                            <input
                                matInput
                                type="text"
                                autofocus
                                placeholder="Start typing to search for Law Firm"
                                [ngbTypeahead]="searchLawFirms"
                                (focus)="focus$.next($event.target.value)"
                                (click)="click$.next($event.target.value)"
                                (selectItem)="onSelected($event, 'lawFirm')"
                                [inputFormatter]="formatter"
                                [resultFormatter]="formatter"
                                #lawFirmInstance="ngbTypeahead"/>
                            <mat-icon matSuffix>arrow_drop_down</mat-icon>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row ms-3" *ngIf="lawFirmUsers.length">
                        <div class="col-sm-12">
                          <mat-label>Law Firm User Recipients</mat-label>
                          <mat-form-field appearance="outline">
                            <mat-select formControlName="lawFirmUserIdRecipients" multiple>
                              <mat-option *ngFor="let user of lawFirmUsers" [value]="user.id">
                                {{ user.lawFirmName }} - {{ user.firstName }} {{ user.lastName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <mat-label>Email Addresses</mat-label>
                          <mat-form-field appearance="outline">
                            <textarea matInput formControlName="emailAddressRecipients"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div><!-- recipients -->
            </div>
          </div>

          <div *ngIf="type !== 'Borrowing_Base_Payments'">
            <div class="row">
              <div class="col-sm-4">
                <mat-label>As Of Date</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput [matDatepicker]="asOfDate" formControlName="asOfDate">
                  <mat-datepicker-toggle matPrefix [for]="asOfDate"></mat-datepicker-toggle>
                  <mat-datepicker #asOfDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-sm-4" *ngIf="type === 'exhibit'">
                <mat-label>Date of Last Report</mat-label>
                <mat-form-field appearance="outline">
                  <input matInput [matDatepicker]="priorReportDate" formControlName="priorReportDate" oiqValidateDate>
                  <mat-datepicker-toggle matPrefix [for]="priorReportDate"></mat-datepicker-toggle>
                  <mat-datepicker #priorReportDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-label>White Label</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select formControlName="whiteLabelIds" (selectionChange)="onWhiteLabelSelected($event.value)" multiple>
                    <mat-option *ngFor="let row of whiteLabels" [value]="row.id">
                      {{ row.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="col-sm-4" *ngIf="type !== 'exhibit'">
                <mat-label>Create Exemptions for Ineligible Deals</mat-label>
                <label class="togglebox">
                  <input type="checkbox" [(ngModel)]="exclusions" [ngModelOptions]="{ standalone: true }" #enabledSwitch data-md-icheck (change)="toggleExclusions($event)"/>
                  <div class="slider" [ngClass]="{ 'active': enabledSwitch.checked }">
                    <div class="switch"></div>
                  </div>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>Report Recipients</mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row">
                      <div class="col-sm-12">
                        <mat-label>{{ tenant.name }} User Recipients</mat-label>
                        <mat-form-field appearance="outline">
                          <mat-select formControlName="tenantUserIdRecipients" multiple>
                            <mat-option *ngFor="let user of tenantUsers" [value]="user.id">
                              {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <mat-label>Select ISO</mat-label>
                        <mat-form-field appearance="outline" class="select-typeahead">
                          <span class="btn btn-primary btn-sm selected" *ngFor="let iso of selectedIsos">
                            {{iso}}<span class="close-selected" (click)="removeIso(iso)">&nbsp;x</span>
                          </span>
                          <input
                              matInput
                              type="text"
                              autofocus
                              placeholder="Start typing to search for ISO"
                              [ngbTypeahead]="searchIsos"
                              (focus)="focus$.next($event.target.value)"
                              (click)="click$.next($event.target.value)"
                              (selectItem)="onSelected($event, 'iso')"
                              [inputFormatter]="formatter"
                              [resultFormatter]="formatter"
                              #isoInstance="ngbTypeahead"/>
                          <mat-icon matSuffix>arrow_drop_down</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row ms-3" *ngIf="isoUsers.length">
                      <div class="col-sm-12">
                        <mat-label>ISO User Recipients</mat-label>
                        <mat-form-field appearance="outline">
                          <mat-select formControlName="isoUserIdRecipients" multiple>
                            <mat-option *ngFor="let user of isoUsers" [value]="user.id">
                              {{ user.isoName }} - {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <mat-label>Select Syndicator</mat-label>
                        <mat-form-field appearance="outline" class="select-typeahead">
                          <span class="btn btn-primary btn-sm selected" *ngFor="let syndicator of selectedSyndicators">
                            {{syndicator}}<span class="close-selected" (click)="removeSyndicator(syndicator)">&nbsp;x</span>
                          </span>
                          <input
                              matInput
                              type="text"
                              autofocus
                              placeholder="Start typing to search for Syndicator"
                              [ngbTypeahead]="searchSyndicators"
                              (focus)="focus$.next($event.target.value)"
                              (click)="click$.next($event.target.value)"
                              (selectItem)="onSelected($event, 'syndicator')"
                              [inputFormatter]="formatter"
                              [resultFormatter]="formatter"
                              #syndicatorInstance="ngbTypeahead"/>
                          <mat-icon matSuffix>arrow_drop_down</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row ms-3" *ngIf="syndicatorUsers.length">
                      <div class="col-sm-12">
                        <mat-label>Syndicator User Recipients</mat-label>
                        <mat-form-field appearance="outline">
                          <mat-select formControlName="syndicatorUserIdRecipients" multiple>
                            <mat-option *ngFor="let user of syndicatorUsers" [value]="user.id">
                              {{ user.syndicatorName }} - {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <mat-label>Select Law Firm</mat-label>
                        <mat-form-field appearance="outline" class="select-typeahead">
                          <span class="btn btn-primary btn-sm selected" *ngFor="let lawFirm of selectedLawFirms">
                            {{ lawFirm }}<span class="close-selected" (click)="removeLawFirm(lawFirm)">&nbsp;x</span>
                          </span>
                          <input
                              matInput
                              type="text"
                              autofocus
                              placeholder="Start typing to search for Law Firm"
                              [ngbTypeahead]="searchLawFirms"
                              (focus)="focus$.next($event.target.value)"
                              (click)="click$.next($event.target.value)"
                              (selectItem)="onSelected($event, 'lawFirm')"
                              [inputFormatter]="formatter"
                              [resultFormatter]="formatter"
                              #lawFirmInstance="ngbTypeahead"/>
                          <mat-icon matSuffix>arrow_drop_down</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row ms-3" *ngIf="lawFirmUsers.length">
                      <div class="col-sm-12">
                        <mat-label>Law Firm User Recipients</mat-label>
                        <mat-form-field appearance="outline">
                          <mat-select formControlName="lawFirmUserIdRecipients" multiple>
                            <mat-option *ngFor="let user of lawFirmUsers" [value]="user.id">
                              {{ user.lawFirmName }} - {{ user.firstName }} {{ user.lastName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12">
                        <mat-label>Email Addresses</mat-label>
                        <mat-form-field appearance="outline">
                          <textarea matInput formControlName="emailAddressRecipients"></textarea>
                        </mat-form-field>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div><!-- recepients -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <button mat-raised-button color="accent" class="btn-wide" (click)="previewReport()" [disabled]="!form.valid">
              NEXT
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="body body-padding" *ngIf="!editMode && !reportGenerated">

      <div *ngIf="type === 'Borrowing_Base_Payments'">
        <div class="form-row-wrapper">
          <div class="row">
            <div class="col-sm-12">
              <mat-label>Report Name</mat-label>
              <div class="field-value">{{ form.value.name }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12" *ngIf="reportDates === 'range'">
              <mat-label>Run for Previous</mat-label>
              <div class="field-value">{{ form.value.range + ' ' + form.value.rangeUnits }}</div>
            </div>

            <div class="col-sm-12" *ngIf="reportDates !== 'range'">
              <mat-label>Start Date</mat-label>
              <div class="field-value">{{ form.value.startDate | date }}</div>
            </div>
          </div>

          <div class="row" *ngIf="reportDates !== 'range'">
            <div class="col-sm-12">
              <mat-label>End Date</mat-label>
              <div class="field-value">{{ form.value.endDate | date }}</div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <mat-label>White Label</mat-label>
              <div class="field-value">{{ this.selectedWhiteLabels }}</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="type !== 'Borrowing_Base_Payments'" class="form-row-wrapper">
        <div class="row">
          <div class="col-sm-6">
            <mat-label>Report Name</mat-label>
            <div class="field-value">{{ form.value.name }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <mat-label>As Of Date</mat-label>
            <div class="field-value">{{ form.value.asOfDate | date }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6" *ngIf="type === 'exhibit'">
            <mat-label>Date of Last Report</mat-label>
            <div class="field-value">{{ form.value.priorReportDate | date }}</div>
          </div>

          <div class="col-sm-6" *ngIf="type !== 'exhibit'">
            <mat-label>Create Exemptions for Ineligible Deals</mat-label>
            <div class="field-value">{{ form.value.createExclusions }}</div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <mat-label>White Label</mat-label>
            <div class="field-value">{{ this.selectedWhiteLabels }}</div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getUserRecipients('tenantUserIdRecipients', tenantUsers).length > 0">
        <div class="col-sm-6">
          <mat-label>{{ tenant.name }} Recipients</mat-label>
          <div class="field-value">
            <div *ngFor="let row of getUserRecipients('tenantUserIdRecipients', tenantUsers)">
              {{ row.firstName }} {{ row.lastName }}
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getUserRecipients('isoUserIdRecipients', isoUsers).length > 0">
        <div class="col-sm-6">
          <mat-label>ISO Recipients</mat-label>
          <div class="field-value">
            <div *ngFor="let row of getUserRecipients('isoUserIdRecipients', isoUsers)">
              {{ row.isoName }} - {{ row.firstName }} {{ row.lastName }}
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getUserRecipients('syndicatorUserIdRecipients', syndicatorUsers).length > 0">
        <div class="col-sm-6">
          <mat-label>Syndicator Recipients</mat-label>
          <div class="field-value">
            <div *ngFor="let row of getUserRecipients('syndicatorUserIdRecipients', syndicatorUsers)">
              {{ row.syndicatorName }} - {{ row.firstName }} {{ row.lastName }}
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getUserRecipients('lawFirmUserIdRecipients', lawFirmUsers).length > 0">
        <div class="col-sm-6">
          <mat-label>Law Firm Recipients</mat-label>
          <div class="field-value">
            <div *ngFor="let row of getUserRecipients('lawFirmUserIdRecipients', lawFirmUsers)">
              {{ row.lawFirmName }} - {{ row.firstName }} {{ row.lastName }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <button mat-stroked-button class="btn-wide me-4" (click)="editReport()">EDIT</button>

          <button mat-raised-button color="accent" class="btn-wide" (click)="submitReport()" [ngClass]="{ 'loading': loading }" [disabled]="loading">
            <mat-spinner [diameter]="20"></mat-spinner>{{ loading ? 'GENERATING REPORT' : 'SUBMIT' }}
          </button>
        </div>
      </div>
    </div>

    <div class="body body-padding" *ngIf="!editMode && reportGenerated">
      <div class="report-link">
        <h4>Report Generated</h4>
        <a href="#" mat-raised-button color="primary" class="btn-wide" (click)="download()">DOWNLOAD</a>
      </div>
    </div>
  </div>
</div>
