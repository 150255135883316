import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { FundingService, TransmissionService, SyndicatorService } from '@core/services';

import * as moment from 'moment';

@Component({
  selector: 'oiq-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss']
})
export class PaymentPlanComponent implements OnInit {
  @Input() data: any;

  @Input() recurringFee: boolean;

  @Output() closeDialog = new EventEmitter();

  @Output() formChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output() fieldValidatorChanged: EventEmitter<any> = new EventEmitter<any>();

  weekendFilter = (d: Date): boolean => {
    const day = d?.getDay();
    return day !== 0 && day !== 6;
  }

  form: FormGroup;
  paymentPlan: any;
  funding: any;
  fundingOffer: any;
  minDate: any;
  errorMessage: string;

  editingNote = {};

  startDateValid = true;

  automatedProcessing = false;

  whiteLabelId: number;

  paymentReason: string;

  transmissionMethodList = [];

  transmissionMethods = [];

  transmissionProcessors = [];

  selectedTransmissionProcessor: any;

  statuses = [
    { name: 'Active', value: 'Active' },
    { name: 'Completed', value: 'Completed' },
    { name: 'Paused', value: 'Paused' },
    { name: 'Stopped', value: 'Stopped' }
  ];

  daysOfTheMonth = Array.from({ length: 31 }, (_, index) => index + 1);

  constructor(
    private fb: FormBuilder,
    private syndicatorService: SyndicatorService,
    private fundingService: FundingService,
    private transmissionService: TransmissionService
  ) { }

  ngOnInit() {
    this.whiteLabelId = this.data.whiteLabelId;
    if (this.data.paymentPlan) {
      this.selectedTransmissionProcessor = this.data.paymentPlan.processorAccountName;
    }

    if (this.data && this.data.type && this.data.type === 'syndication') {
      this.paymentReason = 'Syndicator_Repayment';
      if (!this.recurringFee && this.data.syndicator) {
        this.transmissionService.getSyndicatorRepaymentAutomatedProcessors()
          .subscribe((r: any) => {
            this.transmissionMethods = r;
            this.setTransmissionMethodList();
            this.transmissionProcessors = r.filter((method: any) => method.name === 'ACH')[0].processors;
            if (!this.transmissionProcessors || this.transmissionProcessors.length === 0) {
              this.errorMessage = 'There are no automated processors configured for syndication repayments on your account. Please contact your Onyx IQ administrator to use this feature.';
            }

            if (this.data && this.data.syndicator.repaymentPlan) {
              this.setTransmissionMethodProcessors(this.data.syndicator.repaymentPlan.transmissionMethod);
            }
          });
      } else if (!this.recurringFee && !this.data.syndicator) {
        this.transmissionService.getProcessorsWithMultipleAccounts(this.whiteLabelId, this.paymentReason)
          .subscribe((r: any) => {
            this.transmissionMethods = r;
            this.setTransmissionMethodList();
            this.transmissionProcessors = r.filter((method: any) => method.name === 'ACH')[0].processors;
            if (!this.transmissionProcessors || this.transmissionProcessors.length === 0) {
              this.errorMessage = 'There are no automated processors configured for syndication repayments on your account. Please contact your Onyx IQ administrator to use this feature.';
            }

            if (this.data && this.data.syndicator.repaymentPlan) {
              this.setTransmissionMethodProcessors(this.data.syndicator.repaymentPlan.transmissionMethod);
            }
          });
      } else {
        this.transmissionService.getProcessorsByWhiteLabel(this.whiteLabelId, this.paymentReason)
          .subscribe((r: any) => {
            this.transmissionMethods = r;
            this.setTransmissionMethodList();
            this.transmissionProcessors = r.filter((method: any) => method.name === 'ACH')[0].processors;
            if (!this.transmissionProcessors || this.transmissionProcessors.length === 0) {
              this.errorMessage = 'There are no automated processors configured for syndication repayments on your account. Please contact your Onyx IQ administrator to use this feature.';
            }

            if (this.data && this.data.syndicator.repaymentPlan) {
              this.setTransmissionMethodProcessors(this.data.syndicator.repaymentPlan.transmissionMethod);
            }
          });
      }
    } else {
      this.paymentReason = 'Client_Repayment';
      if (this.data.funding) {
        this.whiteLabelId = this.data.funding.whiteLabelId;
      } else {
        this.whiteLabelId = this.data.application.whiteLabelId;
      }
      if (!this.recurringFee && !this.data.syndicator) {
      this.transmissionService.getProcessorsWithMultipleAccounts(this.whiteLabelId, this.paymentReason)
        .subscribe((r: any) => {
          this.transmissionMethods = r;
          this.setTransmissionMethodList();

          if (this.data && this.data.paymentPlan && this.data.paymentPlan.transmissionMethod) {
            this.setTransmissionMethodProcessors(this.data.paymentPlan.transmissionMethod);
          }
        });
    } else {
        this.transmissionService.getProcessorsByWhiteLabel(this.whiteLabelId, this.paymentReason)
          .subscribe((r: any) => {
            this.transmissionMethods = r;
            this.setTransmissionMethodList();

            if (this.data && this.data.paymentPlan && this.data.paymentPlan.transmissionMethod) {
              this.transmissionProcessors = r.filter((method: any) => method.name === this.data.paymentPlan.transmissionMethod)[0].processors;
            }
          });
      }
    }

    if (this.data) {

      if (this.data.funding) {
        this.form = this.fb.group({
          id: [],
          amount: [0, [Validators.required, Validators.minLength(1)]],
          transmissionMethod: ['', Validators.required],
          transmissionProcessor: [''],
          bankId: [''],
          status: ['Active'],
          startDate: ['', Validators.required],
          endDate: [''],
          notes: new FormArray([]),
          paymentFrequency: new FormControl('Daily', Validators.required),
          paymentDay: new FormControl(null),
          paymentDays: this.fb.group({
            monday: new FormControl(false),
            tuesday: new FormControl(false),
            wednesday: new FormControl(false),
            thursday: new FormControl(false),
            friday: new FormControl(false)
          })
        });

        if(this.recurringFee) {
          this.form.removeControl('amount');
        }

        this.funding = this.data.funding;
        this.paymentPlan = this.data.paymentPlan;


        if (this.paymentPlan && this.paymentPlan.id) {

          if (this.paymentPlan.type === 'Automatic') {
            this.automatedProcessing = true;
          }

          this.paymentPlan.startDate = this.formatDate(this.paymentPlan.startDate);

          this.paymentPlan.endDate ? this.paymentPlan.endDate = this.formatDate(this.paymentPlan.endDate) : '';

          this.form.patchValue(this.paymentPlan);

          const notes = this.form.get('notes') as FormArray;

          if (this.paymentPlan.notes && this.paymentPlan.notes.length) {

            (this.form.get('notes') as FormArray).clear();

            this.paymentPlan.notes.forEach((note) => {

              const formNote = this.fb.group({
                content: [note.content],
                type: ['payment_plan']
              })

              notes.push(formNote);

            });
          }
          this.onTransmissionProcessorChanged();

          this.form.updateValueAndValidity();
        }

      } else if (this.data.syndicator) {
        this.form = this.fb.group({
          id: [],
          transmissionMethod: ['', Validators.required],
          transmissionProcessor: [''],
          bankId: [''],
          status: ['Active'],
          paymentFrequency: new FormControl('Daily', Validators.required),
          paymentDay: new FormControl(null),
          paymentDays: this.fb.group({
            monday: new FormControl(false),
            tuesday: new FormControl(false),
            wednesday: new FormControl(false),
            thursday: new FormControl(false),
            friday: new FormControl(false)
          })
        });
        if (this.data.syndicator.repaymentPlan) {
          this.form.patchValue(this.data.syndicator.repaymentPlan);
          if(this.data.syndicator.repaymentPlan.bank) {
            this.form.get('bankId').setValue(this.data.syndicator.repaymentPlan.bank.id);
          }
        }

        if(this.data.syndicator.fundingBankId) {
          this.form.get('bankId').setValue(this.data.syndicator.fundingBankId)
        }
        if(this.data.syndicator.paymentBankId) {
          this.form.get('bankId').setValue(this.data.syndicator.paymentBankId)
        }

        this.whiteLabelId = this.data.whiteLabelId;

        if (this.data.syndicator.repaymentPlan) {
          this.onTransmissionProcessorChanged();
        }

        this.form.updateValueAndValidity();
      } else if (this.data.application) {
        this.form = this.fb.group({
          id: [],
          transmissionMethod: [''],
          transmissionProcessor: [''],
          status: ['Active'],
          startDate: [''],
          notes: new FormArray([]),
          paymentFrequency: new FormControl('Daily', Validators.required),
          paymentDay: new FormControl(null),
          paymentDays: this.fb.group({
            monday: new FormControl(false),
            tuesday: new FormControl(false),
            wednesday: new FormControl(false),
            thursday: new FormControl(false),
            friday: new FormControl(false)
          })
        });

        this.paymentPlan = this.data.paymentPlan;
        if (this.paymentPlan) {

          if (this.paymentPlan.type === 'Automatic') {
            this.automatedProcessing = true;
          }

          this.paymentPlan.startDate ? this.paymentPlan.startDate = this.formatDate(this.paymentPlan.startDate) : '';

          this.paymentPlan.endDate ? this.paymentPlan.endDate = this.formatDate(this.paymentPlan.endDate) : '';


          const notes = this.form.get('notes') as FormArray;

          if (this.paymentPlan.notes && this.paymentPlan.notes.length) {

            (this.form.get('notes') as FormArray).clear();

            this.paymentPlan.notes.forEach((note) => {

              const formNote = this.fb.group({
                content: [note.content],
                type: ['payment_plan']
              })

              notes.push(formNote);

            });
          }
          this.onTransmissionProcessorChanged();

          this.form.updateValueAndValidity();
        }
      }
      if (this.form.value.paymentFrequency === 'Daily') {
        this.form.patchValue({
          paymentDays: {
            monday: true,
            tuesday: true,
            wednesday: true,
            thursday: true,
            friday: true
          }
        });
      } else if (this.form.value.paymentFrequency === 'Weekly' || this.form.value.paymentFrequency === 'Biweekly') {
        const keys = Object.keys(this.form.value.paymentDays).filter(k => this.form.value.paymentDays[k])
        this.form.patchValue({
          paymentDay: keys[0]
        });
      }
    }

    this.updateDisabledDays();

    this.form.valueChanges.subscribe(val => {
      this.formChanged.emit(this.form.value);
    });
  }

  formatDate(date) {
      const formattedDate = date[1] + '/' + date[2] + '/' + date[0];
      return new Date(formattedDate);
  }

  onTransmissionProcessorChanged() {
    if (!this.recurringFee && !this.data.syndicator) {
      this.transmissionService.getTransmissionProcessor(
        this.whiteLabelId,
        this.paymentReason,
        this.form.get('transmissionMethod').value,
        this.form.get('transmissionProcessor').value.processor || this.form.get('transmissionProcessor').value
      )
        .subscribe((r: any) => {
          if (r.type === 'Automatic') {
            this.automatedProcessing = true;

            if (this.data.funding) {
              this.form.get('startDate').setValue('');
            }

            this.form.get('bankId') ? this.form.get('bankId').setValidators([Validators.required]) : null;

            const today = new Date();
            this.minDate = new Date(today);
            this.minDate.setDate(this.minDate.getDate() + 1);

            this.form.get('bankId') ? this.fieldValidatorChanged.emit(true) : null;
          } else if (r.type === 'Manual') {
            this.automatedProcessing = false;

            if (this.form.get('bankId')) {
              this.fieldValidatorChanged.emit(false);
              this.form.get('bankId').clearValidators()
            }
          }
          this.form.get('bankId') ? this.form.get('bankId').updateValueAndValidity() : null;
        });
    } else {
      this.transmissionService.getTransmissionProcessor(
        this.whiteLabelId,
        this.paymentReason,
        this.form.get('transmissionMethod').value,
        this.form.get('transmissionProcessor').value.processor || this.form.get('transmissionProcessor').value
      )
        .subscribe((r: any) => {
          if (r.type === 'Automatic') {
            this.automatedProcessing = true;

            if (this.data.funding) {
              this.form.get('startDate').setValue('');
            }

            this.form.get('bankId') ? this.form.get('bankId').setValidators([Validators.required]) : null;

            const today = new Date();
            this.minDate = new Date(today);
            this.minDate.setDate(this.minDate.getDate() + 1);

            this.form.get('bankId') ? this.fieldValidatorChanged.emit(true) : null;
          } else if (r.type === 'Manual') {
            this.automatedProcessing = false;

            if (this.form.get('bankId')) {
              this.fieldValidatorChanged.emit(false);
              this.form.get('bankId').clearValidators()
            }
          }
          this.form.get('bankId') ? this.form.get('bankId').updateValueAndValidity() : null;
        });
    }
  }

  selectProcessor(event, processorName) {
    if (event.isUserInput) {
      this.selectedTransmissionProcessor = processorName;
    }
  }

  checkDate() {
    this.startDateValid = true;
    const year = this.form.get('startDate').value.getFullYear();
    const date = this.form.get('startDate').value.getDate();
    const month = this.form.get('startDate').value.getMonth() + 1;

    if (!this.recurringFee && !this.data.syndicator) {
      this.transmissionService.getSettlementDate(
        this.whiteLabelId,
        this.paymentReason,
        this.form.get('transmissionMethod').value,
        this.form.get('transmissionProcessor').value.processor,
        {
          year: year,
          month: month,
          day: date
        }
      ).subscribe((r: any) => {
          this.form.get('startDate').setErrors(null);
        },
        (error) => {
          this.form.get('startDate').setValue('');
          this.form.get('startDate').setErrors({'incorrect': true});
          this.startDateValid = false;
        });
    } else {
      this.transmissionService.getSettlementDate(
        this.whiteLabelId,
        this.paymentReason,
        this.form.get('transmissionMethod').value,
        this.form.get('transmissionProcessor').value,
        {
          year: year,
          month: month,
          day: date
        }
      ).subscribe((r: any) => {
          this.form.get('startDate').setErrors(null);
        },
        (error) => {
          this.form.get('startDate').setValue('');
          this.form.get('startDate').setErrors({'incorrect': true});
          this.startDateValid = false;
        });
    }
  }

  onTransmissionMethodChanged(event) {
    this.form.get('transmissionProcessor').setValue('');
    this.automatedProcessing = false;
    this.setTransmissionMethodProcessors(event.value);

    if ((this.paymentReason === 'Client_Repayment' && this.transmissionProcessors) || (this.paymentReason === 'Syndicator_Repayment' && this.form.value.transmissionMethod === 'ACH')) {
      this.form.get('transmissionProcessor').setValidators([Validators.required]);

      this.form.patchValue({
        transmissionProcessor: ''
      });
    } else {
      this.form.get('transmissionProcessor').clearValidators();

      // used until BE removes hardcoded 'Manual' value for manual processors
      this.form.patchValue({
        transmissionProcessor: 'Manual'
      });
    }

    this.form.get('transmissionProcessor').updateValueAndValidity();
  }

  onPaymentFrequencyChange(event) {
    if (this.form.value.paymentFrequency === 'Daily') {
      this.form.patchValue({
        paymentDays: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true
        }
      });
    } else if (this.form.value.paymentFrequency === 'Weekly' || this.form.value.paymentFrequency === 'Biweekly') {
      if (this.form.get('startDate') && this.form.get('startDate').value) {
        this.selectDayOfTheWeek();
      } else {
        this.form.get('paymentDay').setValue('monday');
      }
  } else if (this.form.value.paymentFrequency === 'Monthly'){
    this.form.get('paymentDay').setValue( (this.data.syndicator.repaymentPlan && this.data.syndicator.repaymentPlan.paymentDay) ? this.data.syndicator.repaymentPlan.paymentDay: 1);
  }
    this.updateDisabledDays();
  }

  selectDayOfTheWeek() {
    let dayName = moment(this.form.get('startDate').value).format('dddd').toLowerCase();

    this.form.get('paymentDay').setValue(dayName);

    const paymentDays = this.form.get('paymentDays');

    this.form.patchValue({
      paymentDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false
      }
    });

    paymentDays.get(dayName).setValue(true);
  }

  onBankSelected(event) {

    if (event !== null) {
      this.form.get('bankId').setValue(event);
    }
  }

  updateDisabledDays() {
    if (this.form.value.paymentFrequency === 'Daily') {
      this.form.get('paymentDays').get('monday').disable();
      this.form.get('paymentDays').get('tuesday').disable();
      this.form.get('paymentDays').get('wednesday').disable();
      this.form.get('paymentDays').get('thursday').disable();
      this.form.get('paymentDays').get('friday').disable();
    } else {
      this.form.get('paymentDays').get('monday').enable();
      this.form.get('paymentDays').get('tuesday').enable();
      this.form.get('paymentDays').get('wednesday').enable();
      this.form.get('paymentDays').get('thursday').enable();
      this.form.get('paymentDays').get('friday').enable();
    }
  }

  editNote(index) {
    this.editingNote[index] = true;
  }

  deleteNote(index) {
    if (index > -1) {
      (this.form.get('notes') as FormArray).removeAt(index);
    }
  }

  addNote() {
    const noteIndex = this.form.get('notes').value.length;
    (this.form.get('notes') as FormArray).push(this.fb.group({
      content: [''],
      type: ['payment_plan']
    }))
    this.editingNote[noteIndex] = true;
  }

  savePayment() {
    this.errorMessage = null;

    const data = this.form.getRawValue();
    if (!this.recurringFee && !this.data.syndicator) {
      if (data.transmissionMethod !== 'ACH') {
        data.transmissionProcessor = 'Manual'
      } else {
        data.processorAccountName = this.selectedTransmissionProcessor;
        data.transmissionProcessor = data.transmissionProcessor.processor;
      }
    }

    if (data.paymentFrequency === 'Daily') {
      data.paymentDays = {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true
      };
      delete data['paymentDay'];
    } else if (data.paymentFrequency === 'Monthly') {
      data.paymentDays = {};
    } else {
      data.paymentDays = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false
      };

      data.paymentDays[data.paymentDay] = true;
      delete data['paymentDay'];
    }

    if (this.data.funding) {
      const startDate = moment(data.startDate)
      const endDate = data.endDate ? moment(data.endDate) : '';

      data.startDate = [startDate.year(), startDate.month() + 1, startDate.date()];
      if (endDate) {
        data.endDate = [endDate.year(), endDate.month() + 1, endDate.date()];
      }

    }

    let observable;

    if (this.data.type === 'syndication') {
      observable = this.syndicatorService.updateAutomatedPayments(this.data.syndicator.id, data)
    } else {
      if (data.id) {
        if (this.paymentPlan.status=== 'Active') {
          delete data['id'];
          observable = this.fundingService.createPaymentPlan(this.funding.id, data)
        } else {
          observable = this.fundingService.updatePaymentPlan(this.funding.id, data.id, data)
        }
      } else {
        delete data['id'];
        observable = this.fundingService.createPaymentPlan(this.funding.id, data)
      }
    }

    observable.subscribe((r: any) => {
      let data = r;

      if (this.paymentPlan && this.paymentPlan.status === 'Active') {
        data['pausePaymentPlanId'] = this.paymentPlan.id;
      }

      this.closeDialog.emit(data);
    }, (err) => {
      if (err.message === 'Cannot create a new payment plan with a start date before tomorrow.') {
        this.errorMessage = err.message;
      }
    });
  }

  setTransmissionMethodProcessors(methodName: string) {
    this.transmissionProcessors = this.transmissionMethods.filter((method: any) => method.name === methodName)[0].processors;
    if (this.transmissionProcessors && this.data.paymentPlan) {
      if (this.data.paymentPlan.processorAccountName) {
        const matchedProcessor = this.transmissionProcessors.find(processor => processor.processorAccountName === this.data.paymentPlan.processorAccountName)
        this.form.get('transmissionProcessor').setValue(matchedProcessor);
      } else {
        const matchedProcessor = this.transmissionProcessors.find(processor => processor.processor === this.data.paymentPlan.transmissionProcessor)
        this.form.get('transmissionProcessor').setValue(matchedProcessor)
      }
    }
  }

  setTransmissionMethodList() {
     this.transmissionMethods.map((obj:any) => {
      this.transmissionMethodList.push({
        name: obj.name.replace('_', ' '),
        value: obj.name
      });
    });
  }

  close() {
    this.closeDialog.emit();
  }

}
