<div class="heading application-heading">
  <div class="row">
    <div class="col-6">
      <h2>
        <span class="app-id"> {{ 'Application ID: ' + application.id }} <span *ngIf="application?.numberOfResubmissions > 0">- Resubmission #{{ application.numberOfResubmissions }}</span></span>
        <br>
        <div class="d-flex align-items-center" >
          <span class="app-name">{{ application.name ? application.name : application.client.name }}</span>
          <oiq-dynamic-status class="ms-3" [disabled]="disabled" [applicationId]="application.id" [applicationStatus]="application.status"></oiq-dynamic-status>
          <ng-container  *ngIf="applicationNotificationSettings">
            <img class="app-notification-icon" *ngIf="applicationNotificationSettings.notificationSettings.sendEmailNotifications" src = "/assets/images/notifications-mail-on.svg" alt=""/>
            <img class="app-notification-icon" *ngIf="!applicationNotificationSettings.notificationSettings.sendEmailNotifications" src = "/assets/images/notifications-mail-off.svg" alt=""/>
            <img class="app-notification-icon" *ngIf="applicationNotificationSettings.notificationSettings.sendSmsNotifications" src = "/assets/images/notifications-phone-on.svg" alt=""/>
            <img class="app-notification-icon" *ngIf="!applicationNotificationSettings.notificationSettings.sendSmsNotifications" src = "/assets/images/notifications-phone-off.svg" alt=""/>
          </ng-container>
        </div>
      </h2>
    </div>
    <div class="col-6 d-flex align-items-center flex-row-reverse">
      <div class="status-indicator high-priority-deal mt-3" *ngIf="application.urgent">High Priority Deal</div>
    </div>
  </div>

</div>
