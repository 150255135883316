import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { ApplicationService, FundingService } from '@core/services';

@Component({
  selector: 'oiq-floating-notes',
  templateUrl: './floating-notes.component.html',
  styleUrls: ['./floating-notes.component.scss']
})
export class FloatingNotesComponent implements OnInit {

  @Input()
  type: string;

  @Input()
  id: number;

  @Input()
  category: string;

  @Input()
  isReportPage: boolean;

  @ViewChild('notesListContainer', { static: false }) notesListContainer: ElementRef;

  loading = false;

  showNotes: boolean = false;
  notes: Array<any> = [];

  newResultsSet: Array<any> = [];
  
  content: string = '';

  initiateNote: boolean;

  totalResults: number;
  currentPage: number;
  totalPages: number;
  sortDir = 'DESC';
  limit = 100;
  offset = 0;

  constructor(
    private applicationService: ApplicationService,
    private fundingService: FundingService
  ) {
  }

  ngOnInit() {
    this.getNotes();
  }

  ngAfterViewInit() {
    this.scrollNotesToBottom();
  }

  adjustPadding(height: number): void {
    this.notesListContainer.nativeElement.style.paddingBottom = `${height}px`;
  }

  scrollNotesToBottom() {
    let scroll = document.getElementById('scrollable');
    scroll.scrollTop = scroll.scrollHeight;
    let input = document.getElementById('note-input');
    input.focus();
  }

  toggleNotes() {
    this.showNotes = !this.showNotes;

    if (this.showNotes) {
      setTimeout(() => {
        let input = document.getElementById('note-input');
        input.focus();
      });
    }
  }

  addNote() {
    const data = {
      content: this.content,
      category: this.category
    };

    this.initiateNote = false;

    this.content = '';

    let observable;

    if (this.type === 'application') {
      observable = this.applicationService.saveNote(this.id, data);
    } else if (this.type === 'funding') {
      observable = this.fundingService.saveNote(this.id, data);
    }

    observable.subscribe((data) => {
      this.notes.push(data);
      this.initiateNote = true;
      setTimeout(() => {
        this.scrollNotesToBottom();
      }, 100);
    });
  }

  loadMoreNotes() {
    this.offset = this.offset + 1;

    this.getNotes();
  }

  getNotes() {
    let observable;

    if (this.type === 'application') {
      observable = this.applicationService.getNotes(this.id, 'floating', this.offset, this.limit, 'createdDate', this.sortDir);
    } else if (this.type === 'funding') {
      observable = this.fundingService.getNotes(this.id, null, this.offset, this.limit, 'createdDate', this.sortDir);
    }
    this.loading = true;
    observable.subscribe((data) => {

      this.totalResults = data.totalElements;
      this.currentPage = data.number;
      this.totalPages = data.totalPages;
      if (this.notes.length > 0 && this.offset > 0) {
        this.newResultsSet = data.content.reverse();
        this.notes = [ ...this.newResultsSet, ...this.notes];
        
      } else {
        this.notes = data.content.reverse();

        setTimeout(() => {
          this.scrollNotesToBottom();
        }, 100);
      }
      this.loading = false;
    });
  }
}
