import { map, switchMap } from 'rxjs/operators';
import { Observable, interval } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'report/';
  }

  listByTenant(name: string, reportTypes: Array<string>, scheduled?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (name) { params['name'] = name.toString(); }
    if (scheduled) { params['scheduledFilter'] = scheduled.toString(); }
    if (reportTypes && reportTypes.length > 0) { params['reportTypes'] = reportTypes.join(','); }

    return this.http.get(this.getUrl() + `tenant`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listByUser(name: string, reportTypes: Array<string>, scheduled?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (name) { params['name'] = name.toString(); }
    if (scheduled) { params['scheduledFilter'] = scheduled.toString(); }
    if (reportTypes && reportTypes.length > 0) { params['reportTypes'] = reportTypes.join(','); }

    return this.http.get(this.getUrl() + `current-user`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  poll(id): Observable<any> {
    return interval(2000).pipe(
      switchMap(() => this.http.get(this.getUrl() + id)), map(res => {
        return res;
      }));
  }

  checkIsReportCompleted(id): Observable<any>{
   return this.http.get(this.getUrl() + id)
  }

  generateFundingReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'funding', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateAuditReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'audit', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateBorrowingBaseReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'borrowing-base', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateBorrowingBaseExhibitReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'borrowing-base-exhibit', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateBorrowingBasePaymentsReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'bb-payments', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateIsoUnderwritingReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'iso-underwriting', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateMonthlyFundingReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'monthly-funding', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateSyndicationReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'syndication', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateMerchantStatement(data): Observable<any> {
    return this.http.post(this.getUrl() + 'merchant-statement', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generatePayoffLetter(data): Observable<any> {
    return this.http.post(this.getUrl() + 'payoff-letter', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generatePaymentsListReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'payments-list', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateCollectionsReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'collections', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateUnderwritingReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'underwriting', data)
      .pipe(map(res => {
        return res;
      }));
  }

  generateUsersReport(data): Observable<any> {
    return this.http.post(this.getUrl() + 'users-report', data)
      .pipe(map(res => {
        return res;
      }));
  }

  sendReport(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/email`)
    .pipe(map(res => {
      return res;
    }));
  }

  scheduleReport(data: any) {
    return this.http.post(this.getUrl() + 'schedule-report', data)
      .pipe(map(res => {
        return res;
      }));
  }

  getScheduledReports(showAll: boolean, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    { params['showAll'] = showAll }

    return this.http.get(this.getUrl() + `report-schedules`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  stopSchedule(id: number) {

    return this.http.post(this.getUrl() + 'end-schedule?id=' + id, {})
      .pipe(map(res => {
        return res;
      }));
  }

  editSchedule(id, data) {

    return this.http.put(this.getUrl() + `update-schedule?id=${id}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getOutstandingCommissionsReportForISO(isoId:string,tenantId:string): Observable<any> {

    const params: any = {};
    if (tenantId) { params['tenantId'] = tenantId; }
    if (isoId) { params['isoId'] = isoId; }
    const options = { params };

    return this.http.get(this.getUrl() + 'outstanding-commission-report-iso', options)
      .pipe(map(res => {
        return res;
      }));
  }

  generateOutstandingCommissionsReport(fundingIdsStr:string,fundingStartDateStr:string, fundingEndDateStr: string, data: any, owedOnly=true): Observable<any> {
    const params: any = {};
    if (fundingIdsStr) { params['fundingIdsStr'] = fundingIdsStr; }
    if (owedOnly) { params['owedOnly'] = owedOnly; }
    if (fundingStartDateStr) { params['fundingStartDateStr'] = fundingStartDateStr; }
    if (fundingEndDateStr) { params['fundingEndDateStr'] = fundingEndDateStr; }

    const options = { params };

    return this.http.post(this.getUrl() + 'outstanding-commission-report', data, options)
      .pipe(map(res => {
        return res;
      }));
  }

  getCreditRequests(tenantId: number) {
    const params: any = {};
    if (tenantId) { params['tenantId'] = tenantId; }

    return this.http.get(this.getUrl() + `credit-requests`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getClearRequests(tenantId: number) {
    const params: any = {};
    if (tenantId) { params['tenantId'] = tenantId; }

    return this.http.get(this.getUrl() + `clear-requests`, { params: params })
    .pipe(map(res => {
      return res;
    }));
  }

  getSyndicatorReports(syndicatorId,tenantId): Observable<any> {
    const params: any = {};
    if (tenantId) { params['tenantId'] = tenantId; }
    if (syndicatorId) { params['syndicatorId'] = syndicatorId; }
    const options = { params };
    return this.http.get('syndicators/reports', options)
      .pipe(map(res => {
        return res;
      }));
  }
}
