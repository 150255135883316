import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'oiq-application-event-confirmation-dialog',
  templateUrl: './application-event-confirmation-dialog.component.html',
  styleUrls: ['./application-event-confirmation-dialog.component.scss']
})
export class ApplicationEventConfirmationDialogComponent implements OnInit {

  application: any;
  event: string;
  form: FormGroup;
  tenant: any = {};
  tenantUsers: Array<any> = [];
  isos: Array<any> = [];
  isoUsers: Array<any> = [];
  submitEnabled = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ApplicationEventConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      client: [false],
      applicationSubmitter: [false],
      underwriter: [false],
      isoManager: [false],
      isoSalesRepresentative: [false],
      tenantUserIdRecipients: [''],
      isoEmailAddressRecipients: new FormArray([]),
      isoUserIdRecipients: [''],
      emailAddressRecipients: new FormArray([])
    });

    this.event = this.data.event;
    this.application = this.data.application;
  }

  toggleLoading(event) {
    if (event) {
      this.submitEnabled = true;
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit() {
    let result = {
      client: this.form.value.client,
      applicationSubmitter: this.form.value.applicationSubmitter,
      underwriter: this.form.value.underwriter,
      isoManager: this.form.value.isoManager,
      isoSalesRepresentative: this.form.value.isoSalesRepresentative,
      tenantUserIdRecipients: this.form.value.tenantUserIdRecipients,
      isoUserIdRecipients: this.form.value.isoUserIdRecipients,
      isoEmailAddressRecipients: this.form.value.isoEmailAddressRecipients.map((row) => row.email),
      emailAddressRecipients: this.form.value.emailAddressRecipients.map((row) => row.email)
    };

    this.dialogRef.close(result);
  }
}
