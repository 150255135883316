import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

import { AuthService } from '@core/authentication/auth.service';
import { Title } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '@core/services//google-analytics.service';
import { HotjarService } from '@core/services/hotjar.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'oiq-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly hotJarService: HotjarService
  ) {
    const routerSubscription = router.events.pipe(filter(event => event instanceof NavigationEnd));
      routerSubscription.subscribe((event: NavigationEnd) => {
        this.hotJarService.init();
      })
    }
  

  production = environment.production;
  staging = environment.staging;
  dev = environment.development;
  isLoggedIn$: Observable<boolean>;
  isMimicUserLoggedIn$: Observable<boolean>;
  mimicUser: any;
  loggedInAs: boolean;

  ngOnInit() {
    if (this.production) {
      this.googleAnalyticsService.initialize();
      this.hotJarService.init();
      this.title.setTitle("Onyx IQ");
    }
    else if (this.staging) {
        this.title.setTitle("[STAGING] Onyx IQ");
    } 
    else if (this.dev) {
        this.title.setTitle("[DEV] Onyx IQ");
      } 
    else {
      this.title.setTitle("[DEV/LOCAL] Onyx IQ");
    }

    if (localStorage.getItem('masterUser')) {
      this.authService.loginAsSet(true);
    } else {
      this.authService.loginAsSet(false);
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isMimicUserLoggedIn$ = this.authService.isMimicUserLoggedIn;
    this.isMimicUserLoggedIn$.subscribe(loggedInAs => this.handleMimicUser(loggedInAs));

    // this.hotJarService.init();
  }

  handleMimicUser(loggedInAs: boolean) {
    this.loggedInAs = loggedInAs;
    if (loggedInAs) {
      this.mimicUser = {
        name: this.authService.getUserName(),
        title: this.authService.returnRolesArray()
      };
    } else {
      this.mimicUser = {};
    }
  }

  logoutAsUser() {
    this.authService.logoutAs()
      .subscribe((r: any) => {
        this.removeMasterUser();
        const date = new Date(Date.now() + 1800 * 1000);
        const expireDate = date.toUTCString();
        const userCookieObject = {
          'name': r.firstName + ' ' + r.lastName,
          'email': r.email,
          'roles': r.roles,
          'id': r.id,
          'tenantId': r.tenantId,
          'expiryTime': expireDate
        };
        document.cookie = 'currentUser=' + JSON.stringify(userCookieObject) + '; expires = ' + expireDate + '; path=/';
        localStorage.setItem('token', r.apiKey);

        if (localStorage.getItem('redirect')) {
          this.router.navigate(localStorage.getItem('redirect').split('/'));

          localStorage.removeItem('redirect');
        } else {
          this.router.navigate(['/settings/users']);
        }
      },
        null,
        () => {
          this.authService.handleMimicUser(false);
        }
      );
  }

  removeMasterUser() {
    localStorage.removeItem('masterUser');
  }

}
