import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttachmentService } from '@core/services';

@Component({
  selector: 'oiq-download-reports-dialog',
  templateUrl: './download-reports-dialog.component.html',
  styleUrls: ['./download-reports-dialog.component.scss']
})
export class DownloadReportsDialogComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private attachmentService: AttachmentService) { }

  download() {
    if (this.data && this.data.report && this.data.report.attachmentId) {
      this.attachmentService.openInWindowById(this.data.report.attachmentId);
    }
    return false;
  }
}
