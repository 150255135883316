<div class="loader" *ngIf="isLoading">
  <img src="/assets/images/loading-spinner-full.svg">
</div>
<ng-container [formGroup]="form" *ngIf="!isLoading">
  <mat-label>Paid Towards Fees</mat-label>

  <ng-container formArrayName="feePayments" *ngIf="fees.length > 0">
    <div class="fee-row" *ngFor="let item of form.get('feePayments').controls; let i = index;" [formGroupName]="i">
      <div>
        <mat-form-field appearance="outline">
          <input matInput type="text" formControlName="amount" [max]="item.value.maxAmount" currencyMask>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline">
          <mat-select formControlName="feeId" (selectionChange)="onSelectionChange(item, i, $event)">
            <mat-option *ngFor="let row of (fees | unusedFees)" [value]="row.id"
              [disabled]="row.amount <= calcPaid(row.feePayments)">
              {{ row.type === 'Other' ? row.name : row.type + ' Fee' }} |
              {{ row.feeDate ? (row.feeDate | date: 'shortDate') + ' | ' : '' }}
              {{ row.amount | currency }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div>
        <button type="button" class="close" title="Delete" (click)="removeFee(i)"></button>
      </div>

      <div class="error-text warn" *ngIf="item.errors?.amountPaidToFee">Fee cannot be more than amount owed on fee</div>
    </div>
  </ng-container>

  <div class="link blue und" (click)="addFee()" *ngIf="fees.length > 0 && fees.length >= countUnpaidFees()">+ Add fee
  </div>

  <div class="text-danger" *ngIf="fees.length === 0">
    No unpaid fees exist
  </div>

  <div class="error-text warn" *ngIf="form.get('feePayments').errors?.amountPaidToFees">Fees cannot be more than amount
  </div>
</ng-container>