import { FormControl, FormGroup, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export class Address extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      address1: new FormControl(),
      address2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zipCode: new FormControl(),
      country: new FormControl()
    });
  }
}

export function addressValidator(control: AbstractControl): ValidationErrors {
  const formGroup = control as FormGroup;

  if (!formGroup.get('address1').value || formGroup.get('address1').value === '') {
    return null;
  }

  if (!formGroup.get('city').value || formGroup.get('city').value === '') {
    return {
      invalidCity: true
    };
  }

  if (!formGroup.get('state').value || formGroup.get('state').value === '') {
    return {
      invalidState: true
    };
  }

  if (!formGroup.get('zipCode').value || formGroup.get('zipCode').value === '') {
    return {
      invalidZipCode: true
    };
  }

  return null;
}

export function addressValidatorRequired(control: AbstractControl): ValidationErrors | null {
  const formGroup = control as FormGroup;

  const address1Control = formGroup.get('address1');
  const cityControl = formGroup.get('city');
  const stateControl = formGroup.get('state');
  const zipCodeControl = formGroup.get('zipCode');

  let hasError = false;

  if (!address1Control.value || address1Control.value === '') {
    address1Control.setErrors({ required: true });
    hasError = true;
  } else {
    address1Control.setErrors(null);
  }

  if (!cityControl.value || cityControl.value === '') {
    cityControl.setErrors({ required: true });
    hasError = true;
  } else {
    cityControl.setErrors(null);
  }

  if (!stateControl.value || stateControl.value === '') {
    stateControl.setErrors({ required: true });
    hasError = true;
  } else {
    stateControl.setErrors(null);
  }

  if (!zipCodeControl.value || zipCodeControl.value === '') {
    zipCodeControl.setErrors({ required: true });
    hasError = true;
  } else {
    zipCodeControl.setErrors(null);
  }

  // If any of the fields have an error, return a general error for the FormGroup
  if (hasError) {
    return { invalidAddress: true };
  }

  // If no errors, return null
  return null;
}

