import { FormControl, FormGroup, Validators } from '@angular/forms';

export class Offer extends FormGroup {
  constructor() {
    super({
      id: new FormControl(),
      fundedAmount: new FormControl(),
      buyRate: new FormControl(),
      factorRate: new FormControl(),
      termLength: new FormControl(),
      commissionAmount: new FormControl(),
      commissionPercent: new FormControl(),
      paybackAmount: new FormControl(),
      paymentAmount: new FormControl(),
      paymentBreakdown: new FormControl(),
      paymentFrequency: new FormControl('Daily'),
      percentOfGross: new FormControl(),
      position: new FormControl('', Validators.required),
      totalPayments: new FormControl(),
      updatedField: new FormControl(),
      sendOffer: new FormControl(),
      transmissionMethod: new FormControl('ACH'),
      splitPercent: new FormControl('')
    }, { updateOn: 'blur' });
  }
}
