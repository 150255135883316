<div class="mat-dialog-title-wrapper">
  <h1 mat-dialog-title>Add Syndication</h1>
  <button mat-button class="close-dialog" mat-dialog-close>
    <i class="material-icons">clear</i>
  </button>
</div>
<div mat-dialog-content>
  <ng-container *ngIf="data">
    <oiq-add-syndications
      [parentForm]="form"
      [syndications]="data.syndications"
      [fundedAmount]="data.application.appliedFundingOffer.fundedAmount"
      [disbursementAmount]="data.funding.disbursementAmount"
      [factorRate]="data.application?.appliedFundingOffer?.factorRate"
      [commissionPercent]="data.application?.appliedFundingOffer?.commissionPercent"
      [paybackAmount]="data.application?.appliedFundingOffer?.paybackAmount"
      [whiteLabelId]="data.funding?.whiteLabelId"
      >
    </oiq-add-syndications>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" type="submit"  (click)="save()" [buttonDisabled]="!form.valid" oiq-loading cdkFocusInitial>
    Send
  </button>
</div>
