<div class="mt-5" *ngFor="let item of userTasks | keyvalue">
    <div class="heading">
      <h2>{{ item.key }}</h2>
    </div>

    <div class="body scrollable">
      <table class="user-queue clickable mat-table">
        <thead>
          <tr>
            <th class="mat-header-cell">ID</th>
            <th class="mat-header-cell">Type</th>
            <th class="mat-header-cell">Client</th>
            <th class="mat-header-cell">Referring ISO</th>
            <th class="mat-header-cell">ISO Manager</th>
            <th class="mat-header-cell column-fixed-width">Date Submitted</th>
            <th class="mat-header-cell column-fixed-width wide">Status</th>
            <th class="mat-header-cell column-fixed-width">Updated Date</th>
            <th class="mat-header-cell column-fixed-width">Updated By</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of item.value" [ngClass]="{'disabled': !row.accessible}" (click)="viewApplication(row.applicationId)">
            <td class="mat-cell">{{ row.applicationId }}</td>
            <td class="mat-cell">{{ row.type | type }}</td>
            <td class="mat-cell">{{ row.clientName }}</td>
            <td class="mat-cell">{{ row.isoName }}</td>
            <td class="mat-cell">{{ row.isoManagerName }}</td>
            <td class="mat-cell column-fixed-width">{{ row.createdDate | date: 'shortDate' }}</td>
            <td class="mat-cell column-fixed-width wide"><oiq-dynamic-status [listView]="true" [applicationId]="row.id" [applicationStatus]="row.applicationStatus"></oiq-dynamic-status></td>
            <td class="mat-cell column-fixed-width">{{ row.lastUpdatedDate | date: 'shortDate' }}<br>{{ row.lastUpdatedDate | date: 'shortTime' }}</td>
            <td class="mat-cell column-fixed-width">{{ row.updatedByName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
