import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ChangeContext, LabelType, Options, PointerType } from '@angular-slider/ngx-slider';

@Component({
  selector: 'oiq-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  providers: [ CurrencyPipe, PercentPipe ]
})
export class RangeSliderComponent implements OnInit {

  @Input() minValue: any;
  @Input() maxValue: any;
  @Input() options: any = {};

  @Output() changed = new EventEmitter();

  sliderOptions: Options;

  constructor(
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) {}

  ngOnInit() {
    this.sliderOptions = {
      floor: (this.options.floor !== null && this.options.floor !== undefined) ? this.options.floor : this.minValue,
      ceil: (this.options.ceil !== null && this.options.ceil !== undefined) ? this.options.ceil : this.maxValue,
      step: (this.options.step !== null && this.options.step !== undefined) ? this.options.step : 1,
      noSwitching: true,
      animate: false,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return this.getValueByType(value);
          case LabelType.High:
            return this.getValueByType(value);
          default:
            return this.getValueByType(value);
        }
      }
    };
  }

  getValueByType(value) {
    if(this.options && this.options.type && this.options.type === 'percent') {
      return this.percentPipe.transform(value/100);
    }

    if(this.options && this.options.type && this.options.type === 'value') {
      return value;
    }

    return this.currencyPipe.transform(value);
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    let pointerType = changeContext.pointerType === PointerType.Min ? 'Min' : 'Max';
    let min = changeContext.value;
    let max = changeContext.highValue;

    this.changed.emit({ min, max });
  }
}
