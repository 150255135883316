<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Stipulation Notes</h1>
    <button mat-button class="close-dialog" mat-dialog-close>
      <i class="material-icons">clear</i>
    </button>
  </div>
  <form [formGroup]="form" autocomplete="off">
    <div mat-dialog-content>
      <div class="row">
        <div class="col-sm-12">
          <mat-label>Enter notes</mat-label>
          <mat-form-field appearance="outline">
            <textarea matInput formControlName="notes" required></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div mat-dialog-actions>
      <button mat-raised-button cdkFocusInitial color="accent" type="submit"
              [disabled]="!form.valid" (click)="submit()">
        Submit
      </button>
    </div>
  </form>
</div>
