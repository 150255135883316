import { Directive, OnInit, Input, ViewContainerRef, TemplateRef, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Directive({
  selector: '[authorizeRole]'
})
export class AuthorizeRoleDirective implements OnInit, OnDestroy {

  @Input() authorizeRole: Array<string>;

  stop = new Subject();

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.isMimicUserLoggedIn.subscribe(loggedInAs => {
      if (this.authService.getUser()) {
        this.resolveRoles();
      }
    });
  }

  resolveRoles() {
    if (!this.authorizeRole) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.authService.returnRolesArrayObs().pipe(
        takeUntil(this.stop)
      ).subscribe(roles => {
        if (!roles) {
          this.viewContainerRef.clear();
        }
        if (roles.some(r => this.authorizeRole.includes(r))) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
    }
  }

  ngOnDestroy() {
    this.stop.next();
  }
}
