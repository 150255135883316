import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class SyndicatorService extends AbstractService {
  private _syndicator: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public syndicator$ = this._syndicator.asObservable();

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'syndicators/';
  }

  setSyndicatorObject(data: any) {
    this._syndicator.next(data);
  }

  clearSyndicatorData() {
    this._syndicator.next(undefined)
  }

  listByCriteria(name: string, whiteLabelId: number, startDate: string, endDate: string, upToDate: string, paidOnly: boolean, paidOnlyStartDate: string, paidOnlyEndDate: string, owedOnly: boolean, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (name) { params['name'] = name.toString(); }
    if (whiteLabelId) { params['whiteLabelId'] = whiteLabelId; }
    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }
    if (upToDate) { params['upToDate'] = upToDate; }
    if (owedOnly) { params['owedOnly'] = owedOnly; }

    if (paidOnly) { params['paidOnly'] = paidOnly; }
    if (paidOnlyStartDate) { params['paidOnlyStartDate'] = paidOnlyStartDate; }
    if (paidOnlyEndDate) { params['paidOnlyEndDate'] = paidOnlyEndDate; }

    return this.http.get(this.getUrl(), { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listUsers(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/users`)
      .pipe(map(res => {
        return res;
      }));
  }

  listUsersMinimal(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/users-minimal`)
      .pipe(map(res => {
        return res;
      }));
  }

  listBanks(id: number) {
    return this.http.get(this.getUrl() + `${id}/banks`)
      .pipe(map(res => {
        return res;
      }));
  }

  listOffers(offset?: number, limit?: number, sortBy?: string, sortDir?: string, syndicatorId?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);
    if (syndicatorId) { params['id'] = syndicatorId; }

    return this.http.get(this.getUrl() + `offers`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listOffersById(id?: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/offers`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listAllFundings(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/syndications`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listActiveFundings(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `active`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listDefaultFundings(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `default`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaidOffFundings(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `paid-off`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPaymentsHistory(periodType: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (periodType) { params['periodType'] = periodType; }

    return this.http.get(this.getUrl() + `payments-history`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listPayments(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicationOfferById(id: number, syndicationOfferId: number) {
    return this.http.get(this.getUrl() + `${id}/offers/${syndicationOfferId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getDashboard() {
    return this.http.get(this.getUrl() + `dashboard`)
      .pipe(map(res => {
        return res;
      }));
  }

  getPaymentsGraph(dataType) {
    return this.http.get(this.getUrl() + `payments-graph?dataType=${dataType}`)
      .pipe(map(res => {
        return res;
      }));
  }

  createSyndicatorUser(data: any, syndicatorId?: number) {
    return this.http.post(this.getUrl() + `${syndicatorId}/users`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  assignSyndicatorUserWhiteLabels(data: any, syndicatorId: number, userId: number) {
    return this.http.put(this.getUrl() + `${syndicatorId}/users/${userId}/whitelabels`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSyndicatorUser(data: any, syndicatorId?: number, userId?: number) {
    return this.http.put(this.getUrl() + `${syndicatorId}/users/${userId}`, data)
    .pipe(map(res => {
      return res;
    }));
  }

  deleteSyndicatorUser(syndicatorId?: number, userId?: number) {
    return this.http.delete(this.getUrl() + `${syndicatorId}/users/${userId}`)
    .pipe(map(res => {
      return res;
    }));
  }

  addSyndicatorUser(syndicatorId: number, userId: number) {
    return this.http.put(this.getUrl() + `${syndicatorId}/users/${userId}`, null)
      .pipe(map(res => {
        return res;
      }));
  }

  getBilling(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/billing`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateBilling(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/billing`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  assignBank(id: number, bankId: number): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/banks/${bankId}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  initiateRepayment(id: number): Observable<any> {
    return this.http.post(this.getUrl() + `${id}/repayments`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  listRepayments(id: number, fundingId: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/repayments?fundingId=${fundingId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  initiateRepayments(): Observable<any> {
    return this.http.post(this.getUrl() + `repayments`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  listOutstandingRepayments(): Observable<any> {
    return this.http.get(this.getUrl() + `repayments/outstanding`)
      .pipe(map(res => {
        return res;
      }));
  }

  listActiveSyndications(offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `active-syndications`, { params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSettings(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/settings`)
      .pipe(map(res => {
        return res;
      }));
  }

  getWhiteLabels(id: number): Observable<any> {
    return this.http.get(this.getUrl() + `${id}/whitelabels`)
      .pipe(map(res => {
        return res;
      }));
  }

  assignSyndicatorWhiteLabels(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/whitelabels`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateSettings(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/settings`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorFundingPayments(fundingId: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) : Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${fundingId}/syndication-payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  postRepayments(id: number, data: any): Observable<any> {
    return this.http.post(this.getUrl() + `${id}/repayments`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getAutomatedPaymentsList(offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `repayments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  updateAutomatedPayments(id: number, data: any): Observable<any> {
    return this.http.put(this.getUrl() + `${id}/repayment-plans`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  payOffSyndicators(data: any): Observable<any> {
    return this.http.post(this.getUrl() + `repayments`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorFundingsList(startDate?: string, endDate?: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (startDate) { params['startDate'] = startDate; }
    if (endDate) { params['endDate'] = endDate; }

    return this.http.get(this.getUrl() + `syndicator-fundings`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorFundings(statusType: string, offset?: number, limit?: number, sortBy?: string, sortDir?: string, syndicatorId?: string){
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    if (statusType) { params['statusType'] = statusType; }
    if (syndicatorId) { params['syndicatorId'] = syndicatorId; }

    return this.http.get(this.getUrl() + `fundings`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorPayments(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/payments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorRepayments(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string) {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/repayments`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  markAsPaid(syndicatorIds: any[], date: string) {
    return this.http.put(this.getUrl() + `${syndicatorIds}/zero-out?date=${date}`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  resendSyndicationOfferEmail(applicationId: number, syndicationOfferId: number) {
    return this.http.put(`applications/${applicationId}/syndications/offers/${syndicationOfferId}/resend-offer`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  deleteSyndicationOffer(applicationId: number, syndicationOfferId: number) {
    return this.http.delete(`applications/${applicationId}/syndications/offers/${syndicationOfferId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getFirstPossibleEffectiveDate(syndicatorIds: any[]) {
    const params: any = {};
    params['syndicatorIds'] = syndicatorIds.toString();

    return this.http.get(this.getUrl() + `first-possible-effective-date`, { params: params, responseType: 'text' })
    .pipe(map(res => {
      return res;
    }));
  }

  getAllSyndicatorsWithIdAndName(whiteLabelId: number){
    return this.http.get(this.getUrl() + `list`, { params: { whiteLabelId: whiteLabelId.toString() } })
    .pipe(map(res => {
      return res;
    }));
  }

  getSyndicatorWhiteLabels(id: number) {
    return this.http.get(this.getUrl() + `${id}/whitelabelnames`)
      .pipe(map(res => {
        return res;
      }));
  }

  getRepaymentParts(syndicatorId: number, repaymentId: number) {
    return this.http.get(this.getUrl() + `${syndicatorId}/repayment/${repaymentId}/parts`)
      .pipe(map(res => {
        return res;
      }));
  }

  listSyndicationReports(tenantId: number, syndicatorId: number, page?: number, size?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(page, size, sortBy, sortDir);

    if (tenantId) { params['tenantId'] = tenantId; }
    if (syndicatorId) { params['syndicatorId'] = syndicatorId; }

    return this.http.get(this.getUrl() + 'syndication-reports', { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  getSyndicatorWhiteLabelsMinimal(id: number) {
    return this.http.get(this.getUrl() + `${id}/whitelabels-minimal`)
      .pipe(map(res => {
        return res;
      }));
  }
}
