<div>
  <div class="mat-dialog-title-wrapper">

  </div>
  <div mat-dialog-content>
    <p class="text-center" style="white-space:pre">{{ message }}</p>
  </div>
  <div mat-dialog-actions>
    <button class="mx-auto" mat-raised-button mat-dialog-close color="accent" cdkFocusInitial type="submit" (click)="close()">Ok</button>
  </div>
</div>
