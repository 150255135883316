<div class="content">
  <div class="content-wrapper">
    <div class="tabbed-tables">
      <div class="heading">
        <h5 [ngClass]="{'active': activeTab === 'email'}" (click)="selectTab('email')">Email Templates</h5>
        <h5 [ngClass]="{'active': activeTab === 'sms'}" (click)="selectTab('sms')">SMS Templates</h5>
      </div>

      <div class="body">
        <div *ngIf="activeTab === 'email'">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let templateType of templateTypes; let i = index;" [expanded]="i === this.currentPanelIndex"
                                 (opened)="loadTemplates(templateType, 'Email')"
                                 (closed)="panelOpenState = false"
                                 id={{templateType}}>
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                  {{ templateType | type | titlecase }}
                </mat-panel-title>
                <mat-panel-description>
                  <span></span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-radio-group>
                <div class="scrollable">
                  <table class="table my-3">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>From</th>
                      <th>Actions</th>
                    <th>Enabled</th>
                  </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of templates; let typeIndex = index" [ngClass]="row.defaultNotification || templates.length === 1 ? 'selected' : ''">

                      <td class="nowrap"><b>{{ (!row.tenantId) ? '[SYSTEM DEFAULT]' : row.name }}</b></td>
                      <td class="nowrap">{{ row.from }}</td>
                      <td>
                        <button mat-raised-button color="primary" class="btn-table me-2" type="button"
                                (click)="markAsDefault(row, $event)" *ngIf="templates.length > 1 && !row.defaultNotification">
                          Mark as Default
                        </button>
                        <button mat-raised-button color="primary" class="btn-table me-2" type="button"
                                (click)="editTemplate(row, $event)" *ngIf="row.tenantId || (!row.tenantId && isUserWallfundingTenant)">
                          Edit</button>
                      <button mat-raised-button color="primary" class="btn-table me-2" type="button"
                      (click)="viewTemplate(row)">
                        Preview
                      </button>
                      <button mat-raised-button color="warn" class="btn-table me-2" type="button"
                      (click)="deleteTemplate(row)" *ngIf="row.tenantId">
                        Delete
                        </button></td>
                    <td>
                      <label class="togglebox">
                        <input type="checkbox" [(ngModel)]="row.enabled" #enabledSwitch data-md-icheck (change)="toggleType($event, row.id, i, typeIndex)"/>
                        <div class="slider" [ngClass]="{ 'active': enabledSwitch.checked }">
                          <div class="switch"></div>
                          <span class="success" [ngClass]="{ 'active': flagActive[typeIndex] === 'success' }">
                            Changes saved
                          </span>
                          <span class="failure" [ngClass]="{ 'active': flagActive[typeIndex] === 'error' }">
                            Error occured
                          </span>
                        </div>
                      </label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </mat-radio-group>

              <div class="link blue" (click)="addTemplate(templateType, 'Email')">
                + Add Custom Notification
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="activeTab === 'sms'">
          <mat-accordion>
            <mat-expansion-panel *ngFor="let templateType of templateTypes; let i = index;" [expanded]="i === 0"
                                 (opened)="loadTemplates(templateType, 'SMS')"
                                 (closed)="panelOpenState = false">
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                  {{ templateType | type | titlecase }}
                </mat-panel-title>
                <mat-panel-description>
                  <span></span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-radio-group>
                <div class="scrollable">
                  <table class="table my-3">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>From</th>
                      <th>Actions</th>
                    <th>Enabled</th>
                  </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of templates; let typeIndex = index" [ngClass]="row.defaultNotification || templates.length === 1 ? 'selected' : ''"
                        >
                      <td class="nowrap"><b>{{ (!row.tenantId) ? '[SYSTEM DEFAULT]' : row.name }}</b></td>
                      <td class="nowrap">{{ row.from }}</td>
                      <td>
                        <button mat-raised-button color="primary" class="btn-table me-2" type="button"
                                (click)="markAsDefault(row, $event)" *ngIf="templates.length > 1 && !row.defaultNotification">
                          Mark as Default
                        </button>
                        <button mat-raised-button color="primary" class="btn-table me-2" type="button"
                                (click)="editTemplate(row, $event)">
                          Edit
                        </button><button mat-raised-button color="primary" class="btn-table me-2" type="button"
                      (click)="viewTemplate(row)">
                        Preview
                      </button>
                      <button mat-raised-button color="warn" class="btn-table me-2" type="button"
                      (click)="deleteTemplate(row)">
                        Delete
                      </button>
                    </td>
                    <td>
                      <label class="togglebox">
                        <input type="checkbox" [(ngModel)]="row.enabled" #enabledSwitch data-md-icheck (change)="toggleType($event, row.id, i, typeIndex)"/>
                        <div class="slider" [ngClass]="{ 'active': enabledSwitch.checked }">
                          <div class="switch"></div>
                          <span class="success" [ngClass]="{ 'active': flagActive[typeIndex] === 'success' }">
                            Changes saved
                          </span>
                          <span class="failure" [ngClass]="{ 'active': flagActive[typeIndex] === 'error' }">
                            Error occured
                          </span>
                        </div>
                      </label>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </mat-radio-group>

              <div class="link blue" (click)="addTemplate(templateType, 'SMS')">
                + Add Custom Notification
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>