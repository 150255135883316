import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends AbstractService {

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  getUrl(): string {
    return 'offers/';
  }

  getOffer(offerId: number) {
    return this.http.get(this.getUrl() + offerId)
      .pipe(map(res => {
        return res;
      }));
  }

  applyOffer(offerId: number) {
    return this.http.put(this.getUrl() + `${offerId}/apply`, {})
      .pipe(map(res => {
        return res;
      }));
  }

  listPrepayments(id: number) {
    return this.http.get(this.getUrl() + `${id}/prepayments`)
      .pipe(map(res => {
        return res;
      }));
  }

  createPrepayment(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/prepayments`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updatePrepayment(id: number, offerId: number, data: any) {
    return this.http.put(this.getUrl() + `${offerId}/prepayments/${id}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deletePrepayment(id: number, offerId: number) {
    return this.http.delete(this.getUrl() + `${offerId}/prepayments/${id}`)
      .pipe(map(res => {
        return res;
      }));
  }

  createFee(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/fees`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  updateFee(id: number, feeId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/fees/${feeId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteFee(id: number, feeId: number) {
    return this.http.delete(this.getUrl() + `${id}/fees/${feeId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  listFees(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/fees`, { params: params })
      .pipe(map(res => {
        return res;
      }));
  }

  listGroupedFees(id: number, offset?: number, limit?: number, sortBy?: string, sortDir?: string): Observable<any> {
    const params = super.preparePaginationParams(offset, limit, sortBy, sortDir);

    return this.http.get(this.getUrl() + `${id}/grouped-fees`)
      .pipe(map(res => {
        return res;
      }));
  }

  createFees(id: number, data: any) {
    return this.http.post(this.getUrl() + `${id}/fees/bulk`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getFeeById(id: number, feeId: number) {
    return this.http.get(this.getUrl() + `${id}/fees/${feeId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  getRecurringFeeById(id: number, feeId: number) {
    return this.http.get(this.getUrl() + `${id}/recurring-fees/${feeId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateRecurringFee(id: number, feeId: number, data: any) {
    data['recurring'] = true;
    return this.http.put(this.getUrl() + `${id}/recurring-fees/${feeId}`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  deleteRecurringFee(id: number, feeId: number) {
    return this.http.delete(this.getUrl() + `${id}/recurring-fees/${feeId}`)
      .pipe(map(res => {
        return res;
      }));
  }

  updateField(id: number, field: string, value: number) {
    const data = { 
      'updatedField': field,
      'value': value
    };
    return this.http.put(this.getUrl() + `${id}/update`, data)
      .pipe(map(res => {
        return res;
      }));
  }

  getFinalPaymentDetails(id: number, prepayId: number) {
    return this.http.get(this.getUrl() + `${id}/prepayments/${prepayId}/final-payment`)
      .pipe(map(res => {
        return res;
      }));
  }

  applyPrepay(id: number, prepayId: number, data: any) {
    return this.http.put(this.getUrl() + `${id}/prepayments/${prepayId}/apply`, data)
      .pipe(map(res => {
        return res;
      }));
  }
}
