<div>
  <div class="mat-dialog-title-wrapper">
    <h1 mat-dialog-title>Inactivity Warning</h1>
  </div>
  <div mat-dialog-content>
    Please confirm you're still using the system. <br>
    Your session will end in
    <strong>
      <countdown #cd [config]="config" (event)="logoutUser($event)"></countdown>
    </strong>
    seconds and you'll be logged out.
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="logoutUser()">
      LOG OUT
    </button>
    <button mat-raised-button color="accent" (click)="extendSession()">
      CONTINUE USING THE APP
    </button>
  </div>
</div>