import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import { AttachmentService } from '@core/services';

@Component({
  selector: 'oiq-view-notification-dialog',
  templateUrl: './view-notification-dialog.component.html',
  styleUrls: ['./view-notification-dialog.component.scss']
})
export class ViewNotificationDialogComponent implements OnInit {

  notification: any;
  attachments: Array<any> = [];
  notificationBody: any;

  constructor(
    private domSanitizer: DomSanitizer,
    private attachmentService: AttachmentService,
    public dialogRef: MatDialogRef<ViewNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
        this.notification = this.data.notificationHistory;
    this.attachments = this.data.attachments;

    this.notificationBody = this.domSanitizer.bypassSecurityTrustHtml(this.notification.body);
  }

  close(): void {
    this.dialogRef.close();
  }

  downloadFile(attachmentId) {
    this.attachmentService.openInWindowById(attachmentId);
  }

}
